import React, { useEffect, useState } from "react";
import { HStack, VStack, Text, Box } from "@chakra-ui/react";
import dayjs from "dayjs";
import Layout from "../components/Layout";
import HeadMeta from "../components/common/HeadMeta";
import SectionHeading from "../components/common/SectionHeading";
import ArticleCard from "../components/ArticleCard";
import { useParams } from "react-router-dom";
import { EVENT_TYPES } from "../constants/events";
import { BACKEND_API_ENDPOINT } from "../constants/default";
import { Parser } from "html-to-react";
import { MdFileDownload } from "react-icons/md";
import PrimaryButton from "../components/common/PrimaryButton";
import axios from "axios";
import "../styles/article.css";
import { useDispatch, useSelector } from "react-redux";
import { getArticleRequest } from "../redux/article/action";
import { setLoading } from "../redux/ui/action";

function ArticleDetails() {
  const params: any = useParams();
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const { article } = useSelector((state) => state.article);

  useEffect(() => {
    if (params.id) {
      dispatch(getArticleRequest({ id: params.id }));
    }
  }, [dispatch, params.id]);

  // fetch PDF file
  useEffect(() => {
    dispatch(setLoading("FETCH_FILE_REQUEST"));
    if (!file && article?.file?.url) {
      axios(BACKEND_API_ENDPOINT + article.file.url, {
        method: "GET",
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      })
        .then((response) => {
          const file = new Blob([response.data], { type: "application/pdf" });
          return URL.createObjectURL(file);
        })
        .then((fileUrl) => {
          setFile(fileUrl);
          dispatch(setLoading("FETCH_FILE_SUCCESS"));
        })
        .catch((error) => {
          dispatch(setLoading("FETCH_FILE_ERROR"));
        });
    }
  }, [article]);

  const relatedArticles =
    article && article.relatedArticles && article.relatedArticles.length > 0
      ? article.relatedArticles
          .map((a) => ({
            id: a.id,
            category: a.committee?.category || "Other",
            date: a.created,
            url: "/articles/" + a.id,
            title: a.title,
            bgColor: a.committee?.bgColor || EVENT_TYPES.other.bgColor,
            image: a.committee?.image || EVENT_TYPES.other.image,
          }))
          .filter((article) => article.id !== params.id)
      : [];

  const handleFileDownload = () => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";

    a.href = file;
    a.download = article.title;
    a.click();
    window.URL.revokeObjectURL(file);
  };

  return (
    <Layout path={[{ title: "Article" }]}>
      <HeadMeta
        title={`BRT Member Portal | ${article && article.title ? article.title : "Article"}`}
      />

      <VStack w="100%" spacing="25px" align="flex-start">
        {file && (
          <Box width="100%" height="75vh">
            <iframe
              title="Article"
              frameBorder="0"
              src={file + "#toolbar=0"}
              type="application/pdf"
              style={{ width: "100%", height: "100%" }}
            ></iframe>
          </Box>
        )}
        <VStack w="100%" flex="1" align="flex-start">
          <PrimaryButton
            rightIcon={<MdFileDownload size="20px" />}
            onClick={handleFileDownload}
          >
            Download File
          </PrimaryButton>
          <Text
            as="div"
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
            fontFamily="opensans"
            color="grey.950"
          >
            {dayjs(article && (article.publishedDate || article.created)).format("MMM D, YYYY")}
          </Text>
          <Text
            fontSize="36px"
            fontWeight="600"
            as="h2"
            pt="8px"
            lineHeight="44px"
            color="grey.950"
            fontFamily="montserrat"
          >
            {article && article.title}
          </Text>
          <Box
            className="article-body"
            fontWeight="400"
            pt="0"
            pb="32px"
            lineHeight="22px"
            color="grey.950"
            fontFamily="opensans"
          >
            {article && Parser().parse(article.body)}
          </Box>

          {relatedArticles.length > 0 && (
            <VStack spacing="25px" w="100%">
              <SectionHeading
                pt="0px"
                pl="0px"
                pb={{ base: 0, xl: "0px" }}
                mb="0px !important"
              >
                Related
              </SectionHeading>
            </VStack>
          )}
        </VStack>
      </VStack>
      {relatedArticles.length > 0 && (
        <HStack
          spacing="25px"
          alignItems="stretch"
          overflow={{ base: "auto" }}
          margin={{ base: "0px !important" }}
          padding={{
            base: "16px",
            md: "16px 24px !important",
            lg: "16px 32px !important",
            xl: "16px 32px !important",
          }}
          w={{ base: "100vw", xl: "calc(100vw - 94px)" }}
        >
          {relatedArticles.map((item: any) => (
            <Box key={item.id} w="266px">
              <ArticleCard data={item} />
            </Box>
          ))}
        </HStack>
      )}
    </Layout>
  );
}

export default ArticleDetails;
