import { handleActions } from "redux-actions";
import { setAlert, setLoading } from "./action";

const DEFAULT_STATE = {
  loading: false,
  alert: {
    type: undefined,
    message: undefined,
  },
  showComfirm: false,
};

const handlers = {
  [setLoading]: (state, action) => {
    const type = action?.payload || "";
    const matches = /(.*)_(REQUEST|SUCCESS|ERROR)/.exec(type);

    // not a *_REQUEST / *_SUCCESS /  *_ERROR actions, so we ignore them
    if (!matches) return state;

    const [, requestName, requestState] = matches;
    return {
      ...state,
      // Store whether a request is happening at the moment or not
      // e.g. will be true when receiving GET_TODOS_REQUEST
      //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_ERROR
      loading: {
        ...state.loading,
        [requestName]: requestState === "REQUEST",
      },
    };
  },

  [setAlert]: (state, action) => ({
    ...state,
    alert: { type: action.payload.type, message: action.payload.message },
  }),
};

export default handleActions(handlers, DEFAULT_STATE);
