import React, { useState, useEffect } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { useHistory } from "react-router";
import { connect, useDispatch } from "react-redux";

import HeadMeta from "../../components/common/HeadMeta";
import PublicLayout from "../../components/PublicLayout";
import SignInForm from "../../components/Onboard/SignInForm";
import { login, setUserError } from "../../redux/auth/action";
import routes from "../../constants/routes.json";
import Loading from "./Loading";
import { checkAuth } from "../../utils";

function SignIn({ userError }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [error, setError] = useState("");

  const onSubmit = (values: any) => {
    setError("");
    dispatch(login(values));
  };

  const onForgotPassword = () => {
    history.push(routes.FORGOT_PASSWORD);
  };

  useEffect(() => {
    if (checkAuth()) {
      setShowLoading(true);
      setTimeout(() => {
        history.push(routes.HOME);
      }, 2000);
    }
  }, [checkAuth()]);

  useEffect(() => {
    if (userError) {
      setError(userError);
      setShowLoading(false);
    }
    return () => dispatch(setUserError(""));
  }, [userError]);

  return showLoading ? (
    <Loading />
  ) : (
    <PublicLayout>
      <HeadMeta />

      <Flex
        bg="white"
        w="100%"
        justify="center"
        align="center"
        mt={{ base: "3.5rem", sm: "0px" }}
      >
        <Box w="500px">
          <SignInForm
            onSubmit={onSubmit}
            onForgotPassword={onForgotPassword}
            userError={error}
          />
        </Box>
      </Flex>
    </PublicLayout>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userError: (state.auth && state.auth.error) || "",
  };
};

export default connect(mapStateToProps)(SignIn);
