import { useRef } from "react";
import { useSelector } from "react-redux";

function isValidJSON(str) {
  if (typeof str !== "string") {
    return false;
  }

  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

export default function useProfile() {
  let profile = useRef<Record<string, any>>();
  const { profile: profileInRedux } = useSelector((state) => state.auth);

  const user = localStorage.getItem("user");
  let profileInLocalStorage;
  if (user) {
    const isValid = isValidJSON(user);
    if (isValid) {
      profileInLocalStorage = JSON.parse(user);
      profile.current = profileInLocalStorage;
    } else {
      profile.current = profileInRedux;
      // window.localStorage.setItem("user", JSON.stringify(profileInRedux));
    }

  } else {
    profile.current = profileInRedux;
    // window.localStorage.setItem("user", JSON.stringify(profileInRedux));
  }

  // useEffect(() => {
  //   const user = localStorage.getItem("user");
  //   let profileInLocalStorage;
  //   if (user) {
  //     profileInLocalStorage = JSON.parse(user);
  //     profile.current = profileInLocalStorage;
  //   } else {
  //     profile.current = profileInRedux;
  //   }
  // }, [profileInRedux.webRole, profileInLocalStorage?.webRole]);
  return profile.current;
}
