export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const SET_INVITATION_REQUEST_REQUEST = "SET_INVITATION_REQUEST_REQUEST";
export const SET_INVITATION_REQUEST_SUCCESS = "SET_INVITATION_REQUEST_SUCCESS";
export const SET_INVITATION_REQUEST_ERROR = "SET_INVITATION_REQUEST_ERROR";

export const CREATE_ONBOARD_REQUEST = "CREATE_ONBOARD_REQUEST";
export const CREATE_ONBOARD_SUCCESS = "CREATE_ONBOARD_SUCCESS";
export const CREATE_ONBOARD_ERROR = "CREATE_ONBOARD_ERROR";

export const CREATE_UPDATE_PROFILE_REQUEST = "CREATE_UPDATE_PROFILE_REQUEST";
export const CREATE_UPDATE_PROFILE_SUCCESS = "CREATE_UPDATE_PROFILE_SUCCESS";
export const CREATE_UPDATE_PROFILE_ERROR = "CREATE_UPDATE_PROFILE_ERROR";

export const CREATE_POST_HELP_FORM_SUBMISSION_REQUEST = "CREATE_POST_HELP_FORM_SUBMISSION_REQUEST";
export const CREATE_POST_HELP_FORM_SUBMISSION_SUCCESS = "CREATE_POST_HELP_FORM_SUBMISSION_SUCCESS";
export const CREATE_POST_HELP_FORM_SUBMISSION_ERROR = "CREATE_POST_HELP_FORM_SUBMISSION_ERROR";

export const GET_USER_SETTING_REQUEST = "GET_USER_SETTING_REQUEST";
export const GET_USER_SETTING_SUCCESS = "GET_USER_SETTING_SUCCESS";
export const GET_USER_SETTING_ERROR = "GET_USER_SETTING_ERROR";


export const SAVE_USER_SETTING_REQUEST = "SAVE_USER_SETTING_REQUEST";
export const SAVE_USER_SETTING_SUCCESS = "SAVE_USER_SETTING_SUCCESS";
export const SAVE_USER_SETTING_ERROR = "SAVE_USER_SETTING_ERROR";