import {
  Avatar,
  Flex,
  HStack,
  Icon,
  Text,
  Tooltip,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import About from "../Icons/About";
import Committees from "../Icons/Committees";
import CorporateInitiatives from "../Icons/CorporateInitiatives";
import Events from "../Icons/Events";
import Home from "../Icons/Home";
import MdPIU from "../Icons/MdPIU";
import MemberCenter from "../Icons/MemberCenter";
import Search from "../Icons/Search";

const components = {
  home: Home,
  about: About,
  events: Events,
  committees: Committees,
  mdpiu: MdPIU,
  corporate: CorporateInitiatives,
  memberCenter: MemberCenter,
  search: Search,
};

type MenuItemProps = {
  id: number;
  icon?: any;
  title: string;
  isActive: boolean;
  collapsed: boolean;
  isMenu?: boolean;
  avatarImage?: string;
  url: string;
  isAvatar?: boolean;
  toolTip?: string;
  custom: boolean;
};

const MenuItem = ({
  id,
  icon,
  title,
  isActive,
  collapsed,
  isMenu,
  avatarImage,
  url,
  isAvatar,
  toolTip,
  custom,
}: MenuItemProps) => {
  const [isShort] = useMediaQuery("(max-height: 768px)");

  const getComponent = (component: string, isActive: boolean = false) => {
    const SpecificStory = components[component];
    return (
      <SpecificStory
        color={isActive ? "rgba(13, 125, 189, 1)" : "rgba(13, 125, 189, 0.5)"}
      />
    );
  };

  const renderItem = () => (
    <Tooltip label={toolTip || title} hasArrow placement="right">
      <HStack
        key={id}
        w="100%"
        py={isShort ? "12px" : "24px"}
        px={avatarImage ? "21px" : "34px"}
        alignItems="center"
        bg={isActive ? "primary.50" : "transparent"}
        sx={{ marginTop: "0 !important" }}
        cursor="pointer"
        _hover={{
          background: "primary.50",
          color: "primary.500",
        }}
      >
        <Flex
          w={avatarImage ? "40px" : "24px"}
          mr={!collapsed ? { base: "32px", md: "32px" } : {}}
          justifyContent="center"
        >
          {isAvatar ? (
            <Avatar
              boxSize="37px"
              bg="grey.200"
              name={title}
              src={avatarImage}
            />
          ) : custom ? (
            getComponent(icon, isActive || !isMenu)
          ) : (
            <Icon
              as={icon}
              color={
                isActive || !isMenu ? "primary.500" : "rgba(13, 125, 189, 0.5)"
              }
            />
          )}
        </Flex>
        {!collapsed && (
          <Text
            fontSize="14px"
            lineHeight="19px"
            fontWeight={isActive || avatarImage ? 700 : 500}
            color="#333"
            fontFamily="montserrat"
            wordBreak="normal"
            overflow="hidden"
            ml="0px !important"
          >
            {title}
          </Text>
        )}
      </HStack>
    </Tooltip>
  );

  return url ? (
    <Link
      to={url}
      className="sidebar-link w-100"
      onClick={() => {
        document.body.style.overflowY = "unset";
      }}
    >
      {renderItem()}
    </Link>
  ) : (
    renderItem()
  );
};

export default MenuItem;
