import React from 'react';

const Home = ({color}: {color: string}) => {
   return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M11.9113 3L3.9707 9V21H8.9336V14H14.8891V21H19.852V9L11.9113 3Z" fill={color} />
      </svg>
   );
};

export default Home;
