import { snakeCase, upperCase } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IMessageType } from "../constants/default";
import { setLoading } from "../redux/ui/action";
import { getAllMessages } from "../services/messages";
import { IMessage } from "../types";

export default function useMessages(type?: IMessageType): IMessage[] {
  const dispatch = useDispatch();
  const [messages, setMessages] = useState([]);
  const name = upperCase(snakeCase(type)).replace(" ", "_");

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setLoading(`GET_${name}_MESSAGES_REQUEST`));
        const res = await getAllMessages(type);
        setMessages(res);
        dispatch(setLoading(`GET_${name}_MESSAGES_SUCCESS`));
      } catch (error) {
        dispatch(setLoading(`GET_${name}_MESSAGES_ERROR`));
      }
    };
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return messages;
}
