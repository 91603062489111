import { createAction } from "redux-actions";
import * as Type from "./type";

export const getMemberCompaniesRequest = createAction(
  Type.GET_MEMBER_COMPANIES_REQUEST
);
export const getMemberCompaniesSuccess = createAction(
  Type.GET_MEMBER_COMPANIES_SUCCESS
);
export const getMemberCompaniesError = createAction(
  Type.GET_MEMBER_COMPANIES_ERROR
);

export const getCompanyByIDRequest = createAction(
  Type.GET_COMPANY_BY_ID_REQUEST
);
export const getCompanyByIDSuccess = createAction(
  Type.GET_COMPANY_BY_ID_SUCCESS
);
export const getCompanyByIDError = createAction(Type.GET_COMPANY_BY_ID_ERROR);

export const getCurrentUserCompanyRequest = createAction(
  Type.GET_CURRENT_USER_COMPANY_REQUEST
);
export const getCurrentUserCompanySuccess = createAction(
  Type.GET_CURRENT_USER_COMPANY_SUCCESS
);
export const getCurrentUserCompanyError = createAction(
  Type.GET_CURRENT_USER_COMPANY_ERROR
);
