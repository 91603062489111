import { Input, Text, Box } from "@chakra-ui/react";
import React from "react";

const FormField = ({
  required,
  isInvalid,
  ...props
}: {
  required: boolean;
  isInvalid: any;
  props: any;
}) => (
  <Box w="100%" position="relative">
    <Input
      size="lg"
      w="100%"
      variant="filled"
      bg="white"
      borderWidth="1px"
      borderRadius="4px"
      borderColor="grey.80"
      h="56px"
      color="grey.950"
      fontSize="16px"
      lineHeight="16px"
      fontWeight={400}
      fontFamily="opensans"
      isInvalid={isInvalid}
      _hover={{ bg: "white" }}
      _placeholder={{
        color: "grey.200",
        fontWeight: 400,
        fontFamily: "opensans",
      }}
      {...props}
    />
    {isInvalid && (
      <Box position="absolute" right="8px" top="8px">
        <Text
          color="error.500"
          fontSize="20px"
          lineHeight="16px"
          fontWeight={500}
          fontFamily="montserrat"
        >
          *
        </Text>
      </Box>
    )}
  </Box>
);

export default FormField;
