import { all, call, put, takeLatest } from "redux-saga/effects";
import { setLoading } from "../ui/action";
import {
  getArticleSuccess,
  getArticleError,
  getArticleRequest,
  getArticlesError,
  getArticlesRequest,
  getArticlesSuccess,
} from "./action";
import {
  getArticle as getArticleQuery,
  getArticles as getArticlesQuery,
} from "./query";
import * as Type from './type';

export function* getArticlesSaga({ payload }) {
  try {
    yield put(setLoading(Type.GET_ARTICLES_REQUEST));
    const { data } = yield call(getArticlesQuery, payload);
    yield put(setLoading(Type.GET_ARTICLES_SUCCESS));

    if (!data.success) {
      yield put(getArticlesError(data.error || "Get articles error."));
    } else {
      yield put(getArticlesSuccess(data.articles || []));
    }
  } catch (error) {
    yield put(setLoading(Type.GET_ARTICLES_ERROR));
  }
}

export function* getArticleSaga({ payload }) {
  try {
    yield put(setLoading(Type.GET_ARTICLE_REQUEST));
    const { data } = yield call(getArticleQuery, payload);
    yield put(setLoading(Type.GET_ARTICLE_SUCCESS));

    if (data) {
      yield put(getArticleSuccess(data));
    } else {
      yield put(getArticleError(data.error || "Get Article error."));
    }
  } catch (error) {
    yield put(setLoading(Type.GET_ARTICLE_ERROR));
  }
}

export default function* articleManagerSaga() {
  yield all([takeLatest(getArticlesRequest, getArticlesSaga)]);
  yield all([takeLatest(getArticleRequest, getArticleSaga)]);
}
