import BRTInboxSection from "./BRTInboxSection";
import React from "react";
import useMessages from "../../hooks/useMessages";

export default function PriorityIssuesUpdateSection() {
  const messages = useMessages("priority");

  return (
    <BRTInboxSection
      data={messages}
      title="Priority Issues Update & Resources"
      type="priority"
    />
  );
}
