import React, { useState, useEffect, SetStateAction, Dispatch } from "react";
import { ButtonProps, Box, Text, HStack, StackProps } from "@chakra-ui/react";
import { Paginator, Container, Previous, Next } from "chakra-paginator";
import { BsFillCaretRightFill, BsFillCaretLeftFill } from "react-icons/bs";
import { Link } from "react-router-dom";

interface PaginationProps extends StackProps {
  total: number;
  pageSize: number;
  useOuter: boolean;
  outerCurrentPage: number;
  setOuterCurrentPage: Dispatch<SetStateAction<number>>;
  showItemShown?: boolean;
  nameOfResult?: string;
  viewAllUrl?: string;
  compact?: boolean;
  showNavigation?: boolean;
}

export const Pagination = ({
  total,
  pageSize = 10,
  useOuter = false,
  outerCurrentPage = 1,
  setOuterCurrentPage = () => {},
  nameOfResult,
  viewAllUrl,
  showItemShown,
  compact = false,
  showNavigation = true,
  ...props
}: PaginationProps) => {
  // react hooks
  const [pagesQuantity, setPagesQuantity] = useState<number>(0);
  const [innerCurrentPage, setInnerCurrentPage] = useState<number>(1);

  let currentPage = useOuter ? outerCurrentPage : innerCurrentPage;
  let setCurrentPage = useOuter ? setOuterCurrentPage : setInnerCurrentPage;

  // constants
  const outerLimit = 2;
  const innerLimit = 2;

  // styles
  const baseStyles: ButtonProps = {
    w: 7,
    fontSize: "sm",
    bg: "transparent",
    color: "primary.500",
  };

  const normalStyles: ButtonProps = {
    ...baseStyles,
    _hover: {
      bg: "transparent",
      color: "#2D9CDB",
    },
    bg: "transparent",
    color: "#2D9CDB",
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: "400",
    fontFamily: "opensans",
  };

  const activeStyles: ButtonProps = {
    ...baseStyles,
    _hover: {
      bg: "transparent",
      color: "#828282",
    },
    bg: "transparent",
    color: "#828282",
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: "400",
    fontFamily: "opensans",
  };

  const separatorStyles: ButtonProps = {
    w: 7,
    bg: "transparent",
    color: "grey.500",
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: "400",
    fontFamily: "opensans",
  };

  // handlers
  const handlePageChange = (nextPage: number) => {
    // -> request new data using the page number
    setCurrentPage(nextPage);
    // window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (total > 0) {
      setPagesQuantity(Math.ceil(total / pageSize));
    }
  }, [total, pageSize]);

  let startIndex = 0;
  let endIndex = 0;

  if (currentPage > 1) {
    startIndex = pageSize * (currentPage - 1) + 1;
  } else if (total <= 0) {
    startIndex = 0;
  } else {
    startIndex = 1;
  }

  if (total <= pageSize) {
    endIndex = total;
  } else if (pageSize * currentPage > total) {
    endIndex = total;
  } else {
    endIndex = pageSize * currentPage;
  }

  return (
    <HStack
      className="pagination"
      justify="space-between"
      align="center"
      flexWrap="wrap"
      w="100%"
      {...props}
    >
      <HStack
        spacing="32px"
        {...(!showNavigation
          ? { justifyContent: "space-between", w: "100%" }
          : {})}
        align="center"
      >
        {showItemShown ? (
          <>
            <Box
              whiteSpace="nowrap"
              fontSize="14px"
              lineHeight="19px"
              fontWeight="400"
              fontFamily="opensans"
              color="grey.500"
              display={{ base: "block", md: "none" }}
            >
              {startIndex} - {endIndex} of {total}
            </Box>
            <Text
              whiteSpace="nowrap"
              fontSize="14px"
              lineHeight="19px"
              fontWeight="400"
              fontFamily="opensans"
              color="grey.500"
              display={{ base: "none", md: "block" }}
              ml="0px !important"
            >
              Showing {endIndex - startIndex + 1} out of {total} {nameOfResult}
            </Text>
            {viewAllUrl && (
              <Link to={viewAllUrl}>
                <Text
                  fontSize="14px"
                  color="primary.500"
                  fontFamily="opensans"
                  whiteSpace="nowrap"
                >
                  See all
                </Text>
              </Link>
            )}
          </>
        ) : (
          <></>
        )}
      </HStack>

      {showNavigation && (
        <Paginator
          activeStyles={activeStyles}
          innerLimit={innerLimit}
          currentPage={currentPage}
          outerLimit={outerLimit}
          normalStyles={normalStyles}
          separatorStyles={separatorStyles}
          pagesQuantity={pagesQuantity}
          onPageChange={handlePageChange}
        >
          <HStack spacing={7} align="center">
            {!compact && (
              <Box
                whiteSpace="nowrap"
                fontSize="14px"
                lineHeight="19px"
                fontWeight="400"
                fontFamily="opensans"
                color="grey.500"
              >
                {startIndex} - {endIndex} of {total}
              </Box>
            )}
            <Container>
              {/* <PageGroup isInline align="center" className="pagination-group" /> */}

              <Previous
                bg="transparent"
                color="primary.500"
                variant="unstyled"
                height="auto"
                minW="unset"
                mr="32px"
              >
                <BsFillCaretLeftFill size="16px" />
              </Previous>

              <Next
                bg="transparent"
                color="primary.500"
                variant="unstyled"
                height="auto"
                minW="unset"
              >
                <BsFillCaretRightFill size="16px" />
              </Next>
            </Container>
          </HStack>
        </Paginator>
      )}
    </HStack>
  );
};

export default Pagination;
