import { HStack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import HeadMeta from "../components/common/HeadMeta";
import PrimaryButton from "../components/common/PrimaryButton";
import Layout from "../components/Layout";
import routes from "../constants/routes.json";
import { checkAuth } from "../utils";

const Page404 = () => {
  return (
    <Layout>
      <VStack w="100%" h="100%" flex="1">
        <HeadMeta title="BRT Member Portal | 404" />
        {checkAuth() ? (
          <HStack w="100%" flex="1" justify="center" align="center">
            <Text
              fontSize="3xl"
              borderRight="1px solid rgba(0, 0, 0,.3)"
              p="10px 23px 10px 0"
              mr="20px"
            >
              404
            </Text>
            <div>This page could not be found</div>
          </HStack>
        ) : (
          <HStack w="100%" flex="1" justify="center" align="center">
            <VStack w="380px" align={{ base: "flex-start", sm: "center" }}>
              <Text
                fontSize="36px"
                lineHeight="44px"
                fontWeight="400"
                fontFamily="montserrat"
                color="grey.950"
              >
                404
              </Text>
              <Text
                fontSize="24px"
                lineHeight="30px"
                fontWeight="400"
                fontFamily="montserrat"
                color="grey.950"
              >
                Page not found
              </Text>
              <div style={{ marginTop: 24, marginBottom: 48 }}>
                <Text
                  fontSize="16px"
                  lineHeight="22px"
                  fontWeight="400"
                  fontFamily="opensans"
                  color="grey.950"
                  align={{ base: "left", sm: "center" }}
                >
                  Sorry, we couldn't find the page you're looking for. To sign
                  in please click on the button below.
                </Text>
              </div>
              <Link to={routes.SIGNIN}>
                <PrimaryButton>Sign In</PrimaryButton>
              </Link>
            </VStack>
          </HStack>
        )}
      </VStack>
    </Layout>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loading: state.ui.loading,
  };
};

export default connect(mapStateToProps)(Page404);
