import React from "react";
import routes from "../../constants/routes.json";
import { cardShadowHover } from "../../styles/theme";
import { Link } from "react-router-dom";
import { Box, Flex, Stack, HStack, Text, VStack } from "@chakra-ui/react";
import dayjs from "dayjs";
import { getTrueDateTime } from "../../utils";
import { snakeCase } from "lodash";
import { colors } from "../../constants/default";

export interface RelevantCardData {
  id: number;
  title: string;
  type: string;
  committee?: string;
  publishedDate?: string;
  multiCommittee?: string;
}

interface UpdateCardProps {
  data: RelevantCardData;
}

export default function RelevantContentCard({ data }: UpdateCardProps) {
  if (!data) {
    return <></>;
  }

  const {
    id,
    title,
    type,
    committee = "",
    publishedDate,
    multiCommittee,
  } = data;
  const getUrl = () => {
    let route;

    switch (type) {
      case "policy-statement":
      case "document":
      case "article":
        route = routes.ARTICLE_DETAILS.replace(":id", `${id}`);
        break;
      case "event":
        route = routes.EVENT_DETAILS.replace(":id", `${id}`);
        break;
      case "committee":
        route = routes.COMMITTEE_DETAILS.replace(":id", `${id}`);
        break;
      case "subcommittee":
        route = routes.COMMITTEE_DETAILS.replace(":id", `${id}`) + "?type=sub";
        break;
      case "email":
        route = routes.BRT_INBOX_DETAIL.replace(":id", `${data.id}`);
        break;
      default:
        route = null;
    }

    return route || "";
  };

  const cardDate = getTrueDateTime(publishedDate, "");
  const bgImage =
    !committee || committee === "Business Roundtable"
      ? "/images/committees/br-badge.svg"
      : `/images/committees/${snakeCase(committee)}.svg`;
  const bgColor = !committee
    ? "#DDDDDD"
    : colors[snakeCase(committee)] || "#DDD";
  const committeeName = () => {
    if (committee) {
      return committee;
    } else if (multiCommittee) {
      return "MULTI-COMMITTEE";
    } else {
      return "";
    }
  };

  return (
    <Link to={getUrl()}>
      <Stack
        minW={{ base: "265px", md: "auto" }}
        direction={{ base: "column", md: "row" }}
        overflow="hidden"
        bg="white"
        flex={{ base: 0, md: 1 }}
        borderRadius="16px"
        alignItems="flex-start"
        _hover={{ filter: cardShadowHover }}
        transition="0.3s all"
      >
        <Box
          minH={{ base: "52px", md: "90px" }}
          w={{ base: "100%", md: "120px" }}
          bgImage={bgImage}
          bgColor={bgColor}
          bgRepeat="no-repeat"
          bgPosition="center"
          bgSize="auto 32px"
        />
        <HStack
          justify="space-between"
          w="100%"
          align="flex-start"
          p="20px 24px"
        >
          <VStack alignItems="flex-start">
            <Stack
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "flex-start", md: "center" }}
            >
              {committeeName() && (
                <>
                  <Text
                    fontSize="14px"
                    lineHeight="17px"
                    fontWeight="700"
                    fontFamily="montserrat"
                    color="grey.950"
                    textTransform="uppercase"
                  >
                    {committeeName()}
                  </Text>
                  <Box
                    width="1px"
                    height="14px"
                    mx="4px"
                    bgColor="grey.700"
                    display={{ base: "none", md: "block" }}
                  ></Box>
                </>
              )}

              <HStack>
                <Text
                  as="div"
                  fontSize="12px"
                  lineHeight="16px"
                  fontWeight="400"
                  fontFamily="opensans"
                  color="grey.700"
                  textTransform="capitalize"
                >
                  Type: {type}
                </Text>

                {cardDate && (
                  <>
                    <Box
                      width="1px"
                      height="14px"
                      mx="4px"
                      bgColor="grey.700"
                    ></Box>
                    <Text
                      as="div"
                      fontSize="12px"
                      lineHeight="16px"
                      fontWeight="400"
                      fontFamily="opensans"
                      color="grey.700"
                    >
                      {dayjs(cardDate).format("MMM D, YYYY")}
                    </Text>
                  </>
                )}
              </HStack>
            </Stack>

            <Box>
              <Text
                fontSize="16px"
                lineHeight="21px"
                fontWeight="500"
                fontFamily="montserrat"
                color="grey.930"
                noOfLines={1}
              >
                {title}
              </Text>
            </Box>
          </VStack>
        </HStack>
      </Stack>
    </Link>
  );
}
