import React from "react";
import { VStack, Text } from "@chakra-ui/react";

import HeadMeta from "../../components/common/HeadMeta";
import PublicLayout from "../../components/PublicLayout";

function Loading() {
  return (
    <PublicLayout progress={100} hideLogo>
      <HeadMeta />

      <VStack
        h="100vh"
        w="100%"
        flex={1}
        alignItems={"center"}
        justifyContent={"center"}
        ml="0 !important"
        display={{ base: "none", md: "flex" }}
      >
        <VStack flex={1} alignItems="center" justifyContent="center">
          <img src="/images/logo.svg" width="72" alt="logo" />
          <Text
            fontSize="36px"
            fontFamily="montserrat"
            fontWeight={600}
            pt="40px"
            color="grey.950"
          >
            Loading...
          </Text>
        </VStack>
      </VStack>
    </PublicLayout>
  );
}
export default Loading;
