import React from "react";

const Close = ({ color }: { color?: string }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.90039 9.34644L2.13849 16.1083C1.99034 16.2565 1.82103 16.3306 1.63055 16.3306C1.44007 16.3306 1.27076 16.2565 1.12261 16.1083C0.974465 15.9602 0.900391 15.7909 0.900391 15.6004C0.900391 15.4099 0.974465 15.2406 1.12261 15.0925L7.88452 8.33057L1.12261 1.56866C0.974465 1.42051 0.900391 1.2512 0.900391 1.06073C0.900391 0.870249 0.974465 0.700937 1.12261 0.552789C1.27076 0.404641 1.44007 0.330566 1.63055 0.330566C1.82103 0.330566 1.99034 0.404641 2.13849 0.552789L8.90039 7.31469L15.6623 0.552789C15.8104 0.404641 15.9798 0.330566 16.1702 0.330566C16.3607 0.330566 16.53 0.404641 16.6782 0.552789C16.8263 0.700937 16.9004 0.870249 16.9004 1.06073C16.9004 1.2512 16.8263 1.42051 16.6782 1.56866L9.91626 8.33057L16.6782 15.0925C16.8263 15.2406 16.9004 15.4099 16.9004 15.6004C16.9004 15.7909 16.8263 15.9602 16.6782 16.1083C16.53 16.2565 16.3607 16.3306 16.1702 16.3306C15.9798 16.3306 15.8104 16.2565 15.6623 16.1083L8.90039 9.34644Z"
        fill="black"
      />
    </svg>
  );
};

export default Close;
