import React, { ChangeEvent } from "react";
import { HStack, Text, Select } from "@chakra-ui/react";
import { BsFillCaretDownFill } from "react-icons/bs";

export interface PageSizeSelectProps {
  pageSize: number;
  onChange: Function;
}

export function PageSizeSelect({ pageSize, onChange }: PageSizeSelectProps) {
  function handleOnChange(e: ChangeEvent<HTMLSelectElement>) {
    onChange(e);
  }
  return (
    <HStack justifyContent="flex-end">
      {/* Page size select */}
      <Text color="grey.500" fontSize="14" fontFamily="opensans">
        Show
      </Text>
      <Select
        bg="white"
        border="none"
        defaultValue="15"
        minW="75px"
        onChange={handleOnChange}
        value={pageSize}
        icon={<BsFillCaretDownFill fontSize="14px" />}
        fontSize="14px"
      >
        <option value="15">15</option>
        <option value="20">20</option>
        <option value="50">50</option>
      </Select>
    </HStack>
  );
}
