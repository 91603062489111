import { Box, Stack, StackProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface TwoColLayoutProps extends StackProps {
  children: ReactNode[];
  isHeader?: boolean;
}

export default function TwoColLayout({
  children,
  isHeader = false,
  ...props
}: TwoColLayoutProps) {
  return (
    <Stack
      w="100%"
      justifyContent="space-between"
      align={{
        ...(isHeader
          ? { base: "flex-start", lg: "center" }
          : { base: "flex-start", lg: "flex-start" }),
      }}
      spacing={{
        ...(isHeader ? { base: 6, lg: 12 } : { base: 10, lg: 12 }),
      }}
      direction={{
        ...(isHeader
          ? { base: "column", lg: "row" }
          : { base: "column", lg: "row" }),
      }}
      {...props}
    >
      <Box w="100%" flex={1} className="left-col">
        {children[0]}
      </Box>
      <Box
        className="right-col"
        w={{
          ...(isHeader
            ? { base: "100%", lg: "311px" }
            : { base: "100%", lg: "311px" }),
        }}
      >
        {children[1]}
      </Box>
    </Stack>
  );
}
