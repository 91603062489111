import { createAction } from "redux-actions";
import * as Type from "./type";

export const getArticlesRequest = createAction(Type.GET_ARTICLES_REQUEST);
export const getArticlesSuccess = createAction(Type.GET_ARTICLES_SUCCESS);
export const getArticlesError = createAction(Type.GET_ARTICLES_ERROR);

export const getArticleRequest = createAction(Type.GET_ARTICLE_REQUEST);
export const getArticleSuccess = createAction(Type.GET_ARTICLE_SUCCESS);
export const getArticleError = createAction(Type.GET_ARTICLE_ERROR);
