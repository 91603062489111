import {
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { PASSWORD_PATTERN } from "../../constants/default";
import PrimaryButton from "../common/PrimaryButton";

const initialValues = {
  lastName: "",
  firstName: "",
  // email: '',
  password: "",
  confirmPassword: "",
};

const errorMessages = {
  required: "* Fields are required",
  email: "Email not correct",
  password: "Password doesn’t meet the requirements",
  passwordsNotEqual: "Password not equal",
};

const SignUpForm = ({
  onSubmit,
  onModal,
  first,
  last,
  isLoading,
}: {
  onSubmit: (fields: any) => {};
  onModal: () => {};
  first: string;
  last: string;
  isLoading: boolean;
}) => {
  const [errors, setErrors] = React.useState<any>({});
  const [messages, setMessages] = React.useState<any>({});
  const [show, setShow] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const firstName = first || "New";
  const lastName = last || "User";
  const [fields, setFields] = React.useState(
    Object.assign(initialValues, { firstName, lastName })
  );

  const handleClick = () => setShow(!show);
  const handleClickConfirm = () => setShowConfirm(!showConfirm);

  const handleChange = (field: string) => (event: any) =>
    setFields({ ...fields, [field]: event.target.value });

  const validate = () => {
    let errors = {};
    let messages = {};

    if (!fields.firstName) {
      errors.firstName = true;
      messages.required = true;
    }

    if (!fields.lastName) {
      errors.lastName = true;
      messages.required = true;
    }

    // if (!fields.email) {
    //   errors.email = true
    //   messages.required = true
    // }
    //
    // if (fields.email && !EMAIL_PATTERN.test(fields.email)) {
    //   errors.email = true
    //   messages.email = true
    // }

    if (!fields.password) {
      errors.password = true;
      messages.required = true;
    }

    if (fields.password && !PASSWORD_PATTERN.test(fields.password)) {
      errors.password = true;
      messages.password = true;
    }

    if (
      !fields.confirmPassword ||
      (fields.confirmPassword &&
        fields.password &&
        fields.password !== fields.confirmPassword)
    ) {
      errors.confirmPassword = true;
      messages.passwordsNotEqual = true;
    }

    setErrors(errors);
    setMessages(messages);
    return !Object.keys(errors).find((key: string) => errors[key]);
  };

  const onSubmitForm = () => {
    if (validate()) {
      onSubmit({
        firstName: fields.firstName,
        lastName: fields.lastName,
        password: fields.password,
      });
    }
  };

  //   useEffect(() => {
  //     validate()
  //   }, [fields])

  return (
    <VStack w="100%" alignItems="flex-start">
      <Text
        fontSize={{ base: "24px", md: "36px" }}
        lineHeight={{ base: "29px", md: "44px" }}
        fontWeight={700}
        fontFamily="montserrat"
        maxW={{ base: "60%", md: "100%" }}
      >
        Member Portal Credentials
      </Text>
      <Text
        mt="16px !important"
        fontSize={{ base: "14px", md: "16px" }}
        lineHeight={{ base: "19px", md: "22px" }}
        fontWeight={400}
        fontFamily="opensans"
        color="grey.950"
      >
        Please confirm your full name and create a password for accessing the
        Business Roundtable Member Portal.
      </Text>

      <HStack
        w="100%"
        mt="32px !important"
        spacing={{ base: "16px", md: "21px" }}
      >
        <VStack flex={1} alignItems="flex-start">
          <Text
            fontFamily="montserrat"
            fontWeight="500"
            fontSize="14px"
            lineHeight="17px"
          >
            First Name
          </Text>
          <Input
            value={fields.firstName}
            onChange={handleChange("firstName")}
            placeholder="First name"
            size="lg"
            w="100%"
            variant="filled"
            bg="white"
            borderRadius="4px"
            borderColor="grey.80"
            borderWidth="1px"
            isInvalid={errors.firstName}
          />
        </VStack>
        <VStack flex={1} alignItems="flex-start">
          <HStack w="100%" justifyContent="space-between" alignItems="center">
            <Text
              fontFamily="montserrat"
              fontWeight="500"
              fontSize="14px"
              lineHeight="17px"
            >
              Last Name
            </Text>
            {/* <Text
              fontSize="12px"
              lineHeight="15px"
              fontWeight={400}
              decoration="underline"
              color="primary.500"
              fontFamily="opensans"
            >
              Not You?
            </Text> */}
          </HStack>
          <Input
            value={fields.lastName}
            onChange={handleChange("lastName")}
            placeholder="Last name"
            size="lg"
            w="100%"
            variant="filled"
            bg="white"
            borderRadius="4px"
            borderColor="grey.80"
            borderWidth="1px"
            isInvalid={errors.lastName}
          />
        </VStack>
      </HStack>

      {/*<Text mt="30px !important" mb="8px" fontWeight={700}>Email</Text>*/}
      {/*<Input*/}
      {/*  value={fields.email}*/}
      {/*  onChange={handleChange('email')}*/}
      {/*  placeholder="Email"*/}
      {/*  size="lg"*/}
      {/*  w="100%"*/}
      {/*  variant="filled"*/}
      {/*  bg="white"*/}
      {/*  borderRadius="4px"*/}
      {/*  borderColor="grey.80"*/}
      {/*  borderWidth="1px" */}
      {/*  h="56px"*/}
      {/*  isInvalid={errors.email}*/}
      {/*/>*/}

      <HStack
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        mb="8px"
        mt="24px !important"
      >
        <Text
          fontFamily="montserrat"
          fontWeight="500"
          fontSize="14px"
          lineHeight="17px"
        >
          Create Password
        </Text>
        <Text
          fontSize="12px"
          lineHeight="15px"
          fontWeight={400}
          decoration="underline"
          color="primary.500"
          fontFamily="opensans"
          cursor="pointer"
          onClick={() => onModal()}
        >
          Password Requirements?
        </Text>
      </HStack>
      <InputGroup size="lg">
        <Input
          value={fields.password}
          type={show ? "text" : "password"}
          onChange={handleChange("password")}
          placeholder="Password"
          size="lg"
          w="100%"
          variant="filled"
          bg="white"
          borderRadius="4px"
          borderColor="grey.80"
          borderWidth="1px"
          h="56px"
          isInvalid={errors.password}
        />
        <InputRightElement h="100%" pr="20px">
          <Icon
            onClick={handleClick}
            bg="transparent"
            color="grey.80"
            boxSize="16px"
            as={show ? BsEyeFill : BsEyeSlashFill}
          />
        </InputRightElement>
      </InputGroup>
      <InputGroup size="lg" mt="16px !important">
        <Input
          value={fields.confirmPassword}
          type={showConfirm ? "text" : "password"}
          onChange={handleChange("confirmPassword")}
          placeholder="Confirm Password"
          size="lg"
          w="100%"
          variant="filled"
          bg="white"
          borderRadius="4px"
          borderColor="grey.80"
          borderWidth="1px"
          h="56px"
          isInvalid={errors.confirmPassword}
        />
        <InputRightElement h="100%" pr="20px">
          <Icon
            onClick={handleClickConfirm}
            bg="transparent"
            color="grey.80"
            boxSize="16px"
            as={showConfirm ? BsEyeFill : BsEyeSlashFill}
          />
        </InputRightElement>
      </InputGroup>
      {Object.keys(messages)
        .filter((key: string) => messages[key])
        .map((key: string) => (
          <Text
            key={key}
            fontSize="12px"
            fontWeight={600}
            color="error.500"
            fontFamily="opensans"
          >
            {errorMessages[key]}
          </Text>
        ))}
      <PrimaryButton
        onClick={onSubmitForm}
        isLoading={isLoading}
        mt="56px !important"
      >
        ACCESS PORTAL
      </PrimaryButton>
    </VStack>
  );
};

export default SignUpForm;
