import { Button, ButtonProps } from "@chakra-ui/react";
import React from "react";

function PrimaryButton({ children, ...props }: ButtonProps) {
  return (
    <Button
      {...props}
      filter="unset"
      _hover={{
        filter: "drop-shadow(0px 8px 16px rgba(146, 204, 236, 0.6))",
      }}
      transition="filter 0.3s"
    >
      {children}
    </Button>
  );
}

export default PrimaryButton;
