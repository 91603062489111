import React from "react";
import { HStack, VStack, Text } from "@chakra-ui/react";
import ProfilePicture from "./ProfilePicture";

export interface ProfileInfoProps {
  image?: string;
  firstName?: string;
  lastName?: string;
  subTitle?: string;
  size?: number;
}

export default function ProfileInfo({
  image,
  firstName,
  lastName,
  subTitle,
  size = 156,
  ...props
}: ProfileInfoProps) {
  return (
    <HStack>
      <ProfilePicture
        image={image}
        firstName={firstName}
        lastName={lastName}
        size={size}
      />

      <VStack align="flex-start" pl="10px" spacing={0}>
        <Text
          fontSize="20px"
          lineHeight="32px"
          fontWeight="600"
          fontFamily="montserrat"
          pb="8px"
        >
          {`${firstName || ""} ${lastName || ""}`}
        </Text>
        <Text
          fontSize="16px"
          lineHeight="21px"
          fontWeight="500"
          fontFamily="montserrat"
        >
          {subTitle}
        </Text>
      </VStack>
    </HStack>
  );
}
