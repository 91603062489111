import axios from "axios";
import { BACKEND_API_ENDPOINT } from "../../constants/default";
import apiClient, { apiClientWithoutToken } from "../../api/apiClient";

export const login = async (payload) => {
  const response = await apiClientWithoutToken.post(`/auth/login`, payload);
  return response;
};

export const forgotPassword = async (payload) => {
  const response = await apiClient.post(
    `${BACKEND_API_ENDPOINT}/auth/forgot-password`,
    payload
  );
  return response;
};

export const resetPassword = async (payload) => {
  const response = await apiClient.post(
    `${BACKEND_API_ENDPOINT}/auth/reset-password`,
    payload
  );
  return response;
};

export const setOnboarding = async (payload) => {
  const response = await apiClient.post(
    `${BACKEND_API_ENDPOINT}/auth/set-onboarding`,
    payload
  );
  return response;
};

export const createOnboard = async (payload) => {
  const response = await axios.post(
    `${BACKEND_API_ENDPOINT}/auth/onboard`,
    payload
  );
  return response;
};

export const updateProfile = async (payload) => {
  const response = await axios.post(
    `${BACKEND_API_ENDPOINT}/user/profile`,
    payload
  );
  return response;
};

export const postHelpFormSubmission = async (payload) => {
  const response = await axios.post(`${BACKEND_API_ENDPOINT}/help`, payload);
  return response;
};

// export const changePassword = ({ password_old, password }) => {
//   return axios.post(
//       `${BACKEND_API_ENDPOINT}/password-change`,
//       {password_old, password}
//     )
//     .then(response => {
//     })
//     .catch(error => {
//     });
// };

/*
export const saveUserSettings = (payload) => {
  return axios
    .post(
      `${BACKEND_API_ENDPOINT}/user-getinfo`,
      { ...payload }
      // {auth}
    )
    .then((response) => {
      return { success: true, data: response.data };
    })
    .catch((error) => {
      return { success: false, error };
    });
};
*/

// export const avatarUpload = ({ file }) => {
//   const blob = new Blob([file]);
//   const formData = new FormData();
//   formData.append('data', blob, file.name);
//
//   return axios.post(
//       `${BACKEND_API_ENDPOINT}/avatar-upload`,
//       formData,
//       {auth, headers: {'Content-Type': 'multipart/form-data'}}
//     )
//     .then(response => {
//       return {success: true, data: response.data};
//     })
//     .catch(error => {
//       return {success: false, error};
//     });
// }
//
// export const avatarDelete = () => {
//   return axios.post(
//       `${BACKEND_API_ENDPOINT}/avatar-remove`,
//       {},
//       {auth}
//     )
//     .then(response => {
//       return {success: true, data: response.data};
//     })
//     .catch(error => {
//       return {success: false, error};
//     });
// };
