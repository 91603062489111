import {
  HStack,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ArticleCard } from "../components/Committees/ArticleCard";
import { CommitteeProfileBlock } from "../components/Committees/CommitteeProfileBlock";
import { ReferenceCard } from "../components/Committees/ReferenceCard";
import ApplyModal from "../components/common/ApplyModal";
import HeadMeta from "../components/common/HeadMeta";
import Pagination from "../components/common/Pagination";
import SectionHeading from "../components/common/SectionHeading";
import SectionHeader from "../components/Home/SectionHeader";
import Layout from "../components/Layout";
import TwoColLayout from "../components/Layout/TwoColLayout";
import { MEMBER_STATUS, CORPORATE_INITIATIVES } from "../constants/default";
import routes from "../constants/routes.json";
import { useCommitteeDetail } from "../hooks/useCommitteeDetail";
import useCommitteeLeads from "../hooks/useCommitteeLeads";
import { useCommunicationList } from "../hooks/useCommunicationList";
import { useMEPQuery } from "../hooks/useMEPQuery";
import { setMembershipRequest } from "../redux/committee/action";
import { isCorporateInitiatives } from "../utils";

var dayjsUTC = require("dayjs/plugin/utc");
var dayjsTimezone = require("dayjs/plugin/timezone");
var dayjsAdvancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(dayjsUTC);
dayjs.extend(dayjsTimezone);
dayjs.extend(dayjsAdvancedFormat);

function CommitteesCommunications() {
  const dispatch = useDispatch();
  const params: any = useParams();
  const query: any = useMEPQuery();
  const type = query.get("type") || false;
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState();
  const [newStatus, setNewStatus] = useState("Not Active");
  const [pageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortDropdown, setSortDropdown] = useState<string>("0");
  const committee = useCommitteeDetail(params.id, type);
  const committeeMembers = useCommitteeLeads(committee.id);
  const profile = useSelector((state) => state.auth.profile);
  const communnicationList = useCommunicationList(committee?.key);

  const updateStatus = (s: string) => {
    const newStatus = MEMBER_STATUS.find((item: any) => item.name === s);
    setStatus(newStatus);
  };

  useEffect(() => {
    let newStatus = "Not Active";
    if (committee && profile) {
      if (profile.areasOfInterest) {
        profile.areasOfInterest.forEach((a) => {
          if (a.name === committee.name || a.id === committee.id) {
            newStatus = "Interested";
          }
        });

        profile.committees.forEach((c) => {
          if (c.name === committee.name || c.id === committee.id) {
            newStatus = "Member";
          }
        });
      }
    }

    updateStatus(newStatus);
  }, [profile, committee]);

  const updateMemberStatus = () => {
    if (!status || (status && status.name !== newStatus)) {
      let ms = "";
      switch (newStatus) {
        case "Interested":
          ms = "interested";
          break;
        case "Member":
          ms = "join";
          break;
        case "Not Active":
          ms = "leave";
          break;
      }

      dispatch(
        setMembershipRequest({
          name: committee.key || committee.id,
          membership: ms,
          type: query.get("type") ? "subcommittee" : "committee",
        })
      );
      setShowModal(false);
      updateStatus(newStatus);
    }
  };

  const referenceMaterials =
    committee &&
    committee.referenceMaterials &&
    committee.referenceMaterials.length > 0
      ? committee.referenceMaterials.map((mat) => ({
          id: mat.id,
          date: mat.created,
          url: "/articles/" + mat.id,
          title: mat.title,
        }))
      : [];

  const content =
    communnicationList?.length > 0 && communnicationList
      ? communnicationList.sort((c1, c2) => {
          const c1Date = c1.content.publishedDate;
          const c2Date = c2.content.publishedDate;
          const c1Title = c1.title || c1.content?.title;
          const c2Title = c2.title || c2.content?.title;
          if (sortDropdown === "0") {
            return (new Date(c1Date) > new Date(c2Date) && -1) || 1;
          } else if (sortDropdown === "1") {
            return (new Date(c1Date) > new Date(c2Date) && 1) || -1;
          } else if (sortDropdown === "2") {
            return (c1Title.toLowerCase() > c2Title.toLowerCase() && 1) || -1;
          } else if (sortDropdown === "3") {
            return (c1Title.toLowerCase() > c2Title.toLowerCase() && -1) || 1;
          } else {
            return 0;
          }
        })
      : [];

  const currentPageFilteredContent = content
    ? content.slice((currentPage - 1) * pageSize, currentPage * pageSize)
    : [];

  const ModalSectionAddInterested = () => {
    return (
      <>
        <Radio size="lg" value="Interested">
          Interested
        </Radio>
        <Text fontSize="14px" fontFamily="opensans" lineHeight="19px" pl="28px">
          Selecting the "Interested" status automatically adds you to receive
          communications and updates from the committee.
        </Text>
      </>
    );
  };

  const ModalSectionAddMember = () => {
    return (
      <>
        <Radio size="lg" value="Member">
          Join Committee
        </Radio>
        <Text fontSize="14px" fontFamily="opensans" lineHeight="19px" pl="28px">
          Selecting the "Join Committee" status makes you an active member of
          the committee.
        </Text>
      </>
    );
  };

  const ModalSectionRemoveInterested = () => {
    return (
      <>
        <Radio size="lg" value="Not Active">
          Remove Interest
        </Radio>
        <Text fontSize="14px" fontFamily="opensans" lineHeight="19px" pl="28px">
          Selecting the "Remove Interest" status automatically removes you from
          receiving communications and updates from the committee.
        </Text>
      </>
    );
  };

  const ModalSectionRemoveMember = () => {
    return (
      <>
        <Radio size="lg" value="Not Active">
          Leave Committee
        </Radio>
        <Text fontSize="14px" fontFamily="opensans" lineHeight="19px" pl="28px">
          Selecting the "Leave Committee" status removes you as an active member
          of the committee.
        </Text>
      </>
    );
  };

  useEffect(() => {
    if (showModal) {
      const currentStatus = status && status.name ? status.name : "Not Active";

      if (profile?.userType && profile?.userType.toLowerCase() === "ceo") {
        setNewStatus("Interested");
      } else if (
        profile?.userType &&
        profile?.userType.toLowerCase() === "staff"
      ) {
        if (currentStatus === "Not Active") {
          setNewStatus("Interested");
        } else if (currentStatus === "Interested") {
          setNewStatus("Not Active");
        }
      }
    }
  }, [showModal]);

  const compactPagination = useBreakpointValue({ base: true, md: false });

  let path = [
    { title: "Committees", url: routes.COMMITTEES },
    {
      title: get(committee, "name", ""),
      url: `/committees/${get(committee, "key", "")}`,
    },
    { title: "Communications" },
  ];

  if (isCorporateInitiatives(get(committee, "key", ""))) {
    path = [
      {
        title: get(committee, "name", ""),
        url: `/${get(committee, "key", "")}`,
      },
      { title: "Communications" },
    ];
  }

  return (
    <Layout path={path} backUrl={routes.COMMITTEES}>
      <HeadMeta
        title={`BRT Member Portal | ${
          get(committee, "name", "") + " Committee"
        }`}
      />

      <TwoColLayout>
        <VStack spacing={{ base: 4, md: "21px" }}>
          {content.length > 0 && (
            <>
              <Stack
                justify="space-between"
                align={{ base: "flex-start", md: "center" }}
                w="100%"
                direction={{ base: "column", md: "row" }}
                spacing={4}
              >
                <SectionHeader
                  title={
                    committee?.name
                      ? `${committee.name} Communications`
                      : query.get("type") === "sub"
                      ? "Working Group Communications"
                      : "Committee Communications"
                  }
                  mb={0}
                />

                <HStack
                  justify={{ base: "space-between", md: "flex-end" }}
                  w="100%"
                >
                  <Text
                    color="grey.500"
                    fontSize="14"
                    lineHeight="24px"
                    fontFamily="opensans"
                    whiteSpace="nowrap"
                  >
                    Sort by
                  </Text>
                  <Select
                    bg="white"
                    border="none"
                    minW="144px"
                    w="auto"
                    defaultValue="0"
                    value={sortDropdown}
                    onChange={(e) => setSortDropdown(e.target.value)}
                  >
                    <option value="0">New to Old</option>
                    <option value="1">Old to New</option>
                    <option value="2">A to Z</option>
                    <option value="3">Z to A</option>
                  </Select>
                  {/* mobile pagination navigation */}
                  <Pagination
                    total={content.length}
                    pageSize={pageSize}
                    useOuter={true}
                    outerCurrentPage={currentPage}
                    setOuterCurrentPage={setCurrentPage}
                    compact
                    display={{ base: "flex", md: "none" }}
                  />
                </HStack>
              </Stack>
              {/* List */}
              <VStack spacing={3} align="stretch" position="relative" w="100%">
                {currentPageFilteredContent.map((item: any, index: number) => (
                  <ArticleCard
                    content={item}
                    key={index}
                    image={committee.image}
                    bgColor={committee.bgColor}
                  />
                ))}
                <Pagination
                  mt="16px !important"
                  total={content.length}
                  pageSize={pageSize}
                  useOuter={true}
                  outerCurrentPage={currentPage}
                  setOuterCurrentPage={setCurrentPage}
                  showItemShown
                  compact={compactPagination}
                />
              </VStack>
            </>
          )}
        </VStack>

        <VStack>
          <VStack w="100%" alignItems="flex-start">
            {Object.keys(committeeMembers || {}).length > 0 && (
              <CommitteeProfileBlock
                committee={committee}
                members={committeeMembers}
              />
            )}
          </VStack>

          {referenceMaterials.length > 0 && (
            <>
              <SectionHeading
                fontSize="20px"
                lineHeight="24px"
                fontWeight={600}
                pt="32px"
                pb="8px"
              >
                Reference Materials
              </SectionHeading>
              <ReferenceCard data={referenceMaterials} />
            </>
          )}
        </VStack>
      </TwoColLayout>

      {status && (
        <ApplyModal
          show={showModal}
          onClose={() => setShowModal(false)}
          onApply={() => updateMemberStatus()}
        >
          <Text
            fontSize="20px"
            fontFamily="montserrat"
            fontWeight="600"
            lineHeight="24px"
            pb="24px"
          >
            Change Your Committee Status
          </Text>
          <RadioGroup onChange={setNewStatus} value={newStatus}>
            <Stack direction="column">
              {profile?.userType &&
                profile?.userType.toLowerCase() === "ceo" && (
                  <>
                    {(!status.name || status.name === "Not Active") && (
                      <>
                        <ModalSectionAddInterested />
                        <ModalSectionAddMember />
                      </>
                    )}
                    {status.name === "Interested" && (
                      <>
                        <ModalSectionRemoveInterested />
                        <ModalSectionAddMember />
                      </>
                    )}
                    {status.name === "Member" && (
                      <>
                        <ModalSectionAddInterested />
                        <ModalSectionRemoveMember />
                      </>
                    )}
                  </>
                )}
              {profile?.userType &&
                profile?.userType.toLowerCase() === "staff" && (
                  <>
                    {(!status.name || status.name === "Not Active") && (
                      <ModalSectionAddInterested />
                    )}
                    {status.name === "Interested" && (
                      <ModalSectionRemoveInterested />
                    )}
                  </>
                )}
            </Stack>
          </RadioGroup>
        </ApplyModal>
      )}
    </Layout>
  );
}

export default CommitteesCommunications;
