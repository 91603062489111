import { Box, Button, HStack, Stack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { BiCalendarCheck } from "react-icons/bi";
import { Link } from "react-router-dom";
import { CardType } from "../../constants/default";
import routes from "../../constants/routes.json";
import { cardShadowHover } from "../../styles/theme";
import { getEventType, getTrueDateTime } from "../../utils";

interface INewsData {
  id: number;
  category: string;
  date: Date | string;
  created: Date | string;
  title: string;
  description?: string;
  body?: string;
  url: string;
  image: string;
  time?: number;
  type?: string;
  bgColor: string;
  content: any;
}

interface NewsCardProps {
  data: INewsData;
}

export default function NewsCard({ data }: NewsCardProps) {
  if (!data) {
    return <></>;
  }

  const type = getEventType(data.category);
  const cardDate = getTrueDateTime(
    data.content?.publishedDate || data.content.created,
    data.content?.timeZone
  );

  const getUrl = () => {
    let route;

    switch (data.content?.type) {
      case "article":
      case "policy-statement":
        route = routes.ARTICLE_DETAILS.replace(":id", `${data.id}`);
        break;
      case "event":
        route = routes.EVENT_DETAILS.replace(
          ":id",
          `${data.id || data.content?.id}`
        );
        break;
      case "committee":
        route = routes.COMMITTEE_DETAILS.replace(":id", `${data.id}`);
        break;
      case "subcommittee":
        route =
          routes.COMMITTEE_DETAILS.replace(":id", `${data.id}`) + "?type=sub";
        break;
      case "Email":
        route = routes.BRT_INBOX_DETAIL.replace(":id", `${data.id}`);
        break;
      default:
        route = null;
    }

    return route || "";
  };

  return (
    <Link to={getUrl()}>
      <Stack
        w="100%"
        h="100%"
        direction={{ base: "row", md: "row" }}
        bg="white"
        overflow="hidden"
        borderRadius="16px"
        _hover={{ filter: cardShadowHover }}
        transition="0.3s all"
      >
        <Box
          w="16px"
          minH={{ base: "", md: "90px" }}
          backgroundColor={type.bgColor}
        />
        <VStack
          alignItems="flex-start"
          p={{ base: "20px 22px 20px 20px", md: "32px 48px 32px 20px" }}
          justify="space-between"
          flex={{ base: 1, md: "1" }}
          w={{ base: "calc(78% - 32px)", md: "100%" }}
        >
          <VStack alignItems="flex-start" w="100%" justifyContent="center">
            <Box>
              <Text
                fontSize="16px"
                lineHeight="21px"
                fontWeight="500"
                fontFamily="montserrat"
                color="grey.930"
              >
                {data.title || data.content?.title || data.content?.name}
              </Text>
            </Box>
          </VStack>

          {data.content?.type === CardType.Event && data.content?.eventUrl && (
            <Stack direction={{ base: "column", md: "row" }} w="100%" pt="18px">
              <HStack>
                <a
                  href={data.content?.eventUrl}
                  onClick={(e) => e.stopPropagation()}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    colorScheme="primary"
                    leftIcon={<BiCalendarCheck size="20px" />}
                    mr="10px"
                    filter="none"
                  >
                    RSVP
                  </Button>
                </a>
              </HStack>
            </Stack>
          )}
        </VStack>
      </Stack>
    </Link>
  );
}
