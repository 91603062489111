import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  HStack,
  VStack,
  Avatar,
  Stack,
  Text,
  Box,
  Grid,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import Layout from "../components/Layout";
import HeadMeta from "../components/common/HeadMeta";
import SectionHeading from "../components/common/SectionHeading";
import EventIssueCallCard from "../components/EventIssueCallCard";
import routes from "../constants/routes.json";
import { cardShadow } from "../styles/theme";

import "../styles/eventCustomStyling.css";
import { useDispatch, useSelector } from "react-redux";
import { getEventRequest } from "../redux/event/action";

type updateProps = {
  id: number;
  size: string;
  type: string;
  title: string;
  url: string;
  created: string;
};

const DocumentCard = ({ data }: { data: updateProps }) => {
  return (
    <Link to={"/articles/" + data.id}>
      <HStack
        w="100%"
        bg="white"
        flex={1}
        borderRadius="17px"
        justifyContent="space-between"
        px="24px"
        py="15px"
        _hover={{ filter: cardShadow }}
        transition="0.3s all"
      >
        <VStack align="flex-start">
          <HStack>
            <Text
              fontSize="16px"
              lineHeight="32px"
              fontWeight="700"
              fontFamily="montserrat"
              color="primary.500"
              decoration="underline"
            >
              {data.title}
            </Text>
          </HStack>
          <Text
            mt="0px !important"
            as="div"
            fontSize="14px"
            lineHeight="32px"
            fontWeight="400"
            fontFamily="opensans"
            color="grey.950"
          >
            {dayjs(data.date).format("MMM D, YYYY")}
          </Text>
        </VStack>
      </HStack>
    </Link>
  );
};

const EventProfileBlock = ({ data }: { data: any[] }) => {
  return (
    <Stack
      direction={{ base: "column", md: "row", xl: "column" }}
      w="100%"
      align="flex-start"
      flexWrap="wrap"
      justifyContent={{ base: "space-between", xl: "flex-start" }}
    >
      {data.map((item: any, index: any) => (
        <VStack
          key={index}
          w={{ base: "100%", md: "calc(50% - 20px)", xl: "100%" }}
          minW={{ base: "100%", md: "calc(50% - 20px)", xl: "100%" }}
          align="flex-start"
          pb="32px"
          ml="0 !important"
          pl="0 !important"
        >
          <HStack mb={{ base: 0, xl: "35px" }}>
            <Avatar boxSize="119px" bg="grey.200" name={item.name} />
            <VStack align="flex-start" pl="10px">
              <Text fontWeight="700">{item.name}</Text>
              <Text>{item.position}</Text>
              {item.company && <Text>{item.company}</Text>}
            </VStack>
          </HStack>
        </VStack>
      ))}
    </Stack>
  );
};

function EventDetails() {
  const dispatch = useDispatch();
  const eventId =
    window.location.pathname.split("/events/").length > 1
      ? window.location.pathname.split("/events/")[1]
      : "";
  const { event } = useSelector((state) => state.event);

  useEffect(() => {
    if (eventId) {
      dispatch(getEventRequest({ id: eventId }));
    }
  }, [eventId, dispatch]);

  const speakersList =
    event.speakers && event.speakers.length > 0
      ? event.speakers.map((speaker) => {
          return {
            avatarImageUrl: speaker.entityimage_url,
            name: speaker.firstname + " " + speaker.lastname,
            position: speaker.jobtitle,
            company: speaker.company,
          };
        })
      : [];

  const documentsList = (event && event.documents) || [];

  let vimeoId = "";
  const parsedVimeoUrl = event.vimeoUrl
    ? event.vimeoUrl.split("vimeo.com/")
    : [];
  if (parsedVimeoUrl.length > 1) {
    const path = parsedVimeoUrl[1].split("/");
    vimeoId = path.length === 2 ? path[0] : parsedVimeoUrl[0];
  }

  return (
    <Layout
      path={[{ title: "Events", url: routes.EVENTS }, { title: event.name }]}
    >
      <HeadMeta title={`BRT Member Portal | ${event?.name || "Event"}`} />

      <HStack w="100%" spacing="25px" align="flex-start">
        <VStack w="100%" flex="1" align="flex-start">
          {speakersList.length > 0 && (
            <VStack
              w="100%"
              display={{ base: "flex", xl: "none" }}
              pl="0 !important"
            >
              <VStack w="100%">
                <SectionHeading pt="5px" pb="30px">
                  Speakers
                </SectionHeading>
                <EventProfileBlock data={speakersList} />
              </VStack>
            </VStack>
          )}
          {event.vimeoUrl && (
            <div className="iframe-wrapper">
              <iframe
                src={"https://player.vimeo.com/video/" + vimeoId}
                style={{ width: "100%", height: "100%" }}
                title="Event Video"
              />
            </div>
          )}
          <Text
            as="div"
            fontSize="14px"
            lineHeight="19px"
            fontWeight="700"
            fontFamily="opensans"
            color="grey.950"
          >
            {dayjs(event.eventStartDate).format("MMM D, YYYY")}
          </Text>
          {event.name && (
            <Text
              fontSize="36px"
              fontWeight="700"
              as="h2"
              pt="5px"
              pb="32px"
              lineHeight="49px"
            >
              {event.name}
            </Text>
          )}
          {event.description && (
            <Text
              fontSize="16px"
              fontWeight="400"
              pt="5px"
              pb="32px"
              lineHeight="32px"
            >
              {event.description}
            </Text>
          )}
          {documentsList.length > 0 && (
            <>
              <SectionHeading pt={{ base: "10px", xl: "40px" }} pb="16px">
                Documents from Event
              </SectionHeading>
              <Grid
                w="100%"
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  md: "repeat(2, 1fr)",
                }}
                gap={6}
              >
                {documentsList.map((item: any, index: number) => (
                  <Box>
                    <DocumentCard key={item.id} data={item} />
                  </Box>
                ))}
              </Grid>
            </>
          )}
        </VStack>

        <VStack w="24%" display={{ base: "none", xl: "flex" }}>
          {speakersList.length > 0 && (
            <VStack w="100%" pb="60px">
              <SectionHeading pt="5px" pb="30px">
                Speakers
              </SectionHeading>
              <EventProfileBlock data={speakersList} />
            </VStack>
          )}
        </VStack>
      </HStack>
      {event && event.relatedEvents && event.relatedEvents.length > 0 && (
        <VStack spacing="25px" w="100%">
          <SectionHeading pt={{ base: "30px", xl: "40px" }} pb="0">
            Related Events
          </SectionHeading>

          <Stack
            direction="row"
            spacing="25px"
            alignItems="stretch"
            overflow="auto"
            w={{ base: "100vw", xl: "calc(100vw - 94px)" }}
            p={{ base: "16px", md: "16px 24px", lg: "16px 32px" }}
            m={{ base: "0 !important", xl: "0 -32px !important" }}
          >
            {event?.relatedEvents.map((item: any, index: number) => (
              <Box key={item.id} w="266px" flexShrink="0">
                <EventIssueCallCard data={item} internal />
              </Box>
            ))}
          </Stack>
        </VStack>
      )}
    </Layout>
  );
}

export default EventDetails;
