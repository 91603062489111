import { createAction } from "redux-actions";
import * as Type from "./type";

export const getEventsRequest = createAction(Type.GET_EVENTS_REQUEST);
export const getEventsSuccess = createAction(Type.GET_EVENTS_SUCCESS);
export const getEventsError = createAction(Type.GET_EVENTS_ERROR);

export const getEventRequest = createAction(Type.GET_EVENT_REQUEST);
export const getEventSuccess = createAction(Type.GET_EVENT_SUCCESS);
export const getEventError = createAction(Type.GET_EVENT_ERROR);

export const getEventLocationsRequest = createAction(
  Type.GET_EVENT_LOCATIONS_REQUEST
);
export const getEventLocationsSuccess = createAction(
  Type.GET_EVENT_LOCATIONS_SUCCESS
);
export const getEventLocationsError = createAction(
  Type.GET_EVENT_LOCATIONS_ERROR
);
