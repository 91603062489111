import React, { useState } from "react";
import { Box, VStack, useMediaQuery } from "@chakra-ui/react";
import UpdateCard from "./UpdateCard";
import Pagination from "../common/Pagination";
import SectionHeader from "./SectionHeader";

interface Props {
  data: any;
}

export default function TheLatest({ data }: Props) {
  const pageSize = 4;
  const [isMD] = useMediaQuery("(min-width: 48em)");
  const [currentMsgPage, setCurrentMsgPage] = useState<number>(1);

  const paginatedData = data?.length
    ? data.slice((currentMsgPage - 1) * pageSize, currentMsgPage * pageSize)
    : [];

  return (
    <Box w="100%">
      <SectionHeader
        title="The Latest"
        subTitle="The most recent email communications and published resources."
        pagination={
          <Pagination
            total={data.length}
            pageSize={pageSize}
            useOuter={true}
            outerCurrentPage={currentMsgPage}
            setOuterCurrentPage={setCurrentMsgPage}
            compact
            display={{ base: "flex", md: "none" }}
          />
        }
      />
      <VStack spacing="12px" w="100%">
        {paginatedData.map((item: any, index: number) => (
          <div key={index} style={{ flex: 1, width: "100%" }}>
            <UpdateCard data={item} />
          </div>
        ))}
      </VStack>
      {true ? (
        <Pagination
          mt="16px"
          showItemShown
          total={data.length}
          pageSize={pageSize}
          useOuter={true}
          outerCurrentPage={currentMsgPage}
          setOuterCurrentPage={setCurrentMsgPage}
        />
      ) : (
        <></>
      )}

      {/* small screen */}
      {/* <VStack spacing="12px" w="100%" display={{ base: "block", md: "none" }}>
        {data.map((item: any, index: number) => (
          <div key={index} style={{ width: "100%", flexShrink: 0 }}>
            <UpdateCard data={item} />
          </div>
        ))}
      </VStack> */}
    </Box>
  );
}
