import { createAction } from "redux-actions";
import * as Type from "./type";

export const setCommitteesRequest = createAction(Type.SET_COMMITTEES_REQUEST);
export const setCommitteesError = createAction(Type.SET_COMMITTEES_ERROR);
export const setCommitteesSuccess = createAction(Type.SET_COMMITTEES_SUCCESS);

export const getCommitteesRequest = createAction(Type.GET_COMMITTEES_REQUEST);
export const getCommitteesSuccess = createAction(Type.GET_COMMITTEES_SUCCESS);
export const getCommitteesError = createAction(Type.GET_COMMITTEES_ERROR);

export const getSubCommitteesRequest = createAction(
  Type.GET_SUB_COMMITTEES_REQUEST
);
export const getSubCommitteesSuccess = createAction(
  Type.GET_SUB_COMMITTEES_SUCCESS
);
export const getSubCommitteesError = createAction(
  Type.GET_SUB_COMMITTEES_ERROR
);

export const getCommitteeRequest = createAction(Type.GET_COMMITTEE_REQUEST);
export const getCommitteeSuccess = createAction(Type.GET_COMMITTEE_SUCCESS);
export const getCommitteeError = createAction(Type.GET_COMMITTEE_ERROR);

export const getCommitteeMembersRequest = createAction(
  Type.GET_COMMITTEE_MEMBERS_REQUEST
);
export const getCommitteeMembersSuccess = createAction(
  Type.GET_COMMITTEE_MEMBERS_SUCCESS
);
export const getCommitteeMembersError = createAction(
  Type.GET_COMMITTEE_MEMBERS_ERROR
);

export const setMembershipRequest = createAction(Type.SET_MEMBERSHIP_REQUEST);
export const setMembershipSuccess = createAction(Type.SET_MEMBERSHIP_SUCCESS);
export const setMembershipError = createAction(Type.SET_MEMBERSHIP_ERROR);

export const getTagsRequest = createAction(Type.GET_TAGS_REQUEST);
export const getTagsSuccess = createAction(Type.GET_TAGS_SUCCESS);
export const getTagsError = createAction(Type.GET_TAGS_ERROR);

export const getSearchRequest = createAction(Type.GET_SEARCH_REQUEST);
export const getSearchSuccess = createAction(Type.GET_SEARCH_SUCCESS);
export const getSearchError = createAction(Type.GET_SEARCH_ERROR);

export const getUpdatesRequest = createAction(Type.GET_UPDATES_REQUEST);
export const getUpdatesSuccess = createAction(Type.GET_UPDATES_SUCCESS);
export const getUpdatesError = createAction(Type.GET_UPDATES_ERROR);

export const updateCommitteeMembershipsRequest = createAction(
  Type.UPDATE_COMMITTEE_MEMBERSHIPS_REQUEST
);
export const updateCommitteeMembershipsSuccess = createAction(
  Type.UPDATE_COMMITTEE_MEMBERSHIPS_SUCCESS
);
export const updateCommitteeMembershipsError = createAction(
  Type.UPDATE_COMMITTEE_MEMBERSHIPS_ERROR
);
