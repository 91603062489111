import { Helmet } from "react-helmet";

function HeadMeta({ children, title }: { children?: any; title?: string }) {
  return (
    <Helmet>
      <title>{title || "BRT Member Portal"}</title>
      <link rel="icon" href="/favicon.ico" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      {children}
    </Helmet>
  );
}

export default HeadMeta;
