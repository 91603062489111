import { Button } from "@chakra-ui/react";
import React from "react";

const SecondaryButton = ({
  children,
  ...props
}: {
  children: any;
  props: any;
}) => (
  <Button variant="outline" color="primary.500" bg="white" p="auto" {...props}>
    {children}
  </Button>
);

export default SecondaryButton;
