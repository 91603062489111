import React, { useState, useEffect } from "react";
import {
  Checkbox,
  Radio,
  RadioGroup,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import ApplyModal from "../common/ApplyModal";

export default function CommitteeModal({
  onClose,
  onApply,
  show,
  profileAreas,
  tags,
  committees,
}: {
  onClose: () => {};
  onApply: () => {};
  show: boolean;
  profileAreas: any[];
  tags: any;
  committees: any;
}) {
  const [values, setValues] = useState<any>({});

  const handleChangeStatus = (value: any) => {
    setValues({
      ...values,
      [value.target.value]: !!!values[value.target.value],
    });
  };

  const handleClose = () => {
    setValues({});
    onClose();
  };

  const handleApply = () => {
    onApply(Object.keys(values).filter(areaName => values[areaName]));
    handleClose();
  };

  useEffect(() => {
    if (!show) return;

    const areas = { ...values };
    let memberships = {};

    profileAreas && profileAreas.forEach((item: any) => {
      const exist = Object.keys(tags).filter((key: string) => {
        return tags[key].name === item.name ? item.name : null;
      });

      if (exist.length) {
        areas[item.name] = true;
      }
    });

    setValues({ ...areas });
  }, [profileAreas, show, committees]);

  return (
    <ApplyModal
      show={show}
      onClose={handleClose}
      onApply={handleApply}
      size="sm"
    >
      <Text
        fontSize="20px"
        fontFamily="montserrat"
        fontWeight="600"
        lineHeight="24px"
        pb="48px"
      >
        Update Committee Status
      </Text>

      <Stack direction="column" maxH="400px" overflowY="auto">
        {committees &&
          committees.map((item: any) => (
            <VStack key={item.id} alignItems="flex-start" pb="0px">
              <Checkbox
                value={item.name}
                onChange={handleChangeStatus}
                isChecked={values[item.name]}
              >
                {item.name}
              </Checkbox>
            </VStack>
          ))}
      </Stack>
    </ApplyModal>
  );
}
