import * as React from "react";
import { Stack, Flex, VStack, HStack, Text, Box } from "@chakra-ui/react";
import { cardShadowHover } from "../../styles/theme";
import dayjs from "dayjs";
import { getEventType } from "../../utils";
import { Link } from "react-router-dom";

export interface ArticleCardProps {
  content: any;
  bgColor: string;
  image: string;
  modified?: string;
}

export function ArticleCard({
  content,
  bgColor,
  image,
  modified,
}: ArticleCardProps) {
  const { id, category, title, publishedDate } = content;
  const type = getEventType(category);
  return (
    <Link to={id ? "/articles/" + id : ""}>
      <Stack
        direction={{ base: "column", md: "row" }}
        bg="white"
        borderRadius="16px"
        overflow="hidden"
        _hover={{ filter: cardShadowHover }}
        transition="0.3s all"
        spacing={0}
      >
        <Flex
          minH={{ base: "52px", md: "87px" }}
          minW={{ base: "100%", md: "78px" }}
          backgroundColor={bgColor || "#fbefd3"}
          backgroundImage={`url(${
            type.image || image || "/images/committees/health.svg"
          })`}
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          backgroundSize="30px 40px"
          justify="center"
          align="center"
        ></Flex>
        <VStack
          flex="1"
          alignItems="flex-start"
          p={{ base: "16px 20px", md: "20px 24px" }}
          spacing={0}
        >
          <HStack justify="space-between" w="100%" align="flex-start">
            <VStack alignItems="flex-start" spacing={3}>
              <Stack
                w="100%"
                justify="flex-start"
                alignItems={{ base: "flex-start", md: "center" }}
                direction={{ base: "column", md: "row" }}
                spacing={{ base: 1, md: 2}}
              >
                <Text
                  fontSize={{ base: "14px", xl: "14px" }}
                  lineHeight="17px"
                  fontWeight="700"
                  fontFamily="montserrat"
                  textTransform="uppercase"
                >
                  {category}
                </Text>

                <Box
                  width="1px"
                  height="14px"
                  mx="8px"
                  bgColor="grey.700"
                  display={{ base: "none", md: "block" }}
                ></Box>

                {id && title && (
                  <Text
                    fontSize="12px"
                    lineHeight="16px"
                    fontWeight="400"
                    fontFamily="opensans"
                    color="grey.700"
                  >
                    {dayjs(publishedDate ? publishedDate : modified)
                      .tz("America/New_York")
                      .format("MMM D, YYYY")}
                  </Text>
                )}
              </Stack>
              <Text
                w="fit-content"
                m="0"
                fontSize="16px"
                lineHeight="22px"
                fontWeight="400"
                fontFamily="opensans"
                color="grey.950"
                noOfLines={1}
              >
                {title}
              </Text>
            </VStack>
          </HStack>
        </VStack>
      </Stack>
    </Link>
  );
}
