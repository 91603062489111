import { all, call, put, takeLatest } from "redux-saga/effects";
import { setLoading } from "../ui/action";
import {
  getCommitteeError,
  getCommitteeRequest,
  getCommitteeSuccess,
  getCommitteeMembersError,
  getCommitteeMembersRequest,
  getCommitteeMembersSuccess,
  getCommitteesError,
  getCommitteesRequest,
  getCommitteesSuccess,
  getSearchError,
  getSearchRequest,
  getSearchSuccess,
  getSubCommitteesError,
  getSubCommitteesRequest,
  getSubCommitteesSuccess,
  getTagsError,
  getTagsRequest,
  getTagsSuccess,
  getUpdatesError,
  getUpdatesRequest,
  getUpdatesSuccess,
  setCommitteesError,
  setCommitteesRequest,
  setMembershipError,
  setMembershipRequest,
  updateCommitteeMembershipsError,
  updateCommitteeMembershipsRequest,
} from "./action";
import {
  getCommittee as getCommitteeQuery,
  getCommitteeMembers as getCommitteeMembersQuery,
  getCommittees as getCommitteesQuery,
  getSubCommittees as getSubCommitteesQuery,
  getTags as getTagsQuery,
  getSearch as getSearchQuery,
  setCommittees as setCommitteesQuery,
  setMembership as setMembershipQuery,
  getUpdates as getUpdatesQuery,
  updateCommitteeMemberships as updateCommitteeMembershipsQuery,
} from "./query";
import { setProfile } from "../auth/action";
import * as Type from "./type";

export function* setCommitteesSaga({ payload }) {
  try {
    yield put(setLoading(Type.SET_COMMITTEES_REQUEST));
    const { data } = yield call(setCommitteesQuery, payload);
    yield put(setLoading(Type.SET_COMMITTEES_SUCCESS));

    if (!data.success) {
      yield put(setCommitteesError(data.error || "Set committees error."));
    } else {
      yield put(setProfile({ ...data.user }));
    }
  } catch (error) {
    yield put(setLoading(Type.SET_COMMITTEES_ERROR));
  }
}

export function* setMembershipSaga({ payload }) {
  try {
    yield put(setLoading(Type.SET_MEMBERSHIP_REQUEST));
    const { data } = yield call(setMembershipQuery, payload);
    yield put(setLoading(Type.SET_MEMBERSHIP_SUCCESS));

    if (!data.success) {
      yield put(setMembershipError(data.error || "Set membership error."));
    } else {
      // TODO
    }
  } catch (error) {
    yield put(setLoading(Type.SET_MEMBERSHIP_ERROR));
  }
}

export function* getCommitteesSaga({ payload }) {
  try {
    yield put(setLoading(Type.GET_COMMITTEES_REQUEST));
    const { data } = yield call(getCommitteesQuery, payload);
    yield put(setLoading(Type.GET_COMMITTEES_SUCCESS));

    if (!data.success) {
      yield put(getCommitteesError(data.error || "Get committees error."));
    } else {
      yield put(getCommitteesSuccess(data.committees || []));
    }
  } catch (error) {
    yield put(setLoading(Type.GET_COMMITTEES_ERROR));
  }
}

export function* getCommitteeSaga({ payload }) {
  try {
    yield put(setLoading(Type.GET_COMMITTEE_REQUEST));
    const { data } = yield call(getCommitteeQuery, payload);
    yield put(setLoading(Type.GET_COMMITTEE_SUCCESS));
    if (!data.success) {
      yield put(getCommitteeError(data.error || "Get committee error."));
    } else {
      yield put(getCommitteeSuccess(data.committee || undefined));
    }
  } catch (error) {
    yield put(setLoading(Type.GET_COMMITTEE_ERROR));
  }
}

export function* getCommitteeMembersSaga({ payload }) {
  try {
    yield put(setLoading(Type.GET_COMMITTEE_MEMBERS_REQUEST));
    const { data } = yield call(getCommitteeMembersQuery, payload);
    yield put(setLoading(Type.GET_COMMITTEE_MEMBERS_SUCCESS));
    if (!data.success) {
      yield put(
        getCommitteeMembersError(data.error || "Get committee members error.")
      );
    } else {
      yield put(getCommitteeMembersSuccess(data || undefined));
    }
  } catch (error) {
    yield put(setLoading(Type.GET_COMMITTEE_MEMBERS_ERROR));
  }
}

export function* getSubCommitteesSaga({ payload }) {
  try {
    yield put(setLoading(Type.GET_SUB_COMMITTEES_REQUEST));
    const { data } = yield call(getSubCommitteesQuery, payload);
    yield put(setLoading(Type.GET_SUB_COMMITTEES_SUCCESS));

    if (!data.success) {
      yield put(getSubCommitteesError(data.error || "Get committee error."));
    } else {
      yield put(getSubCommitteesSuccess(data.committee || undefined));
    }
  } catch (error) {
    yield put(setLoading(Type.GET_SUB_COMMITTEES_ERROR));
  }
}

export function* getTagsSaga({ payload }) {
  try {
    yield put(setLoading(Type.GET_TAGS_REQUEST));
    const { data } = yield call(getTagsQuery, payload);
    yield put(setLoading(Type.GET_TAGS_SUCCESS));

    if (!data.success) {
      yield put(getTagsError(data.error || "Get committees error."));
    } else {
      yield put(getTagsSuccess(data));
    }
  } catch (error) {
    yield put(setLoading(Type.GET_TAGS_ERROR));
  }
}

export function* getSearchSaga({ payload }) {
  try {
    yield put(setLoading(Type.GET_SEARCH_REQUEST));
    const { data } = yield call(getSearchQuery, payload);
    yield put(setLoading(Type.GET_SEARCH_SUCCESS));

    if (data.status === "ok") {
      yield put(getSearchSuccess(data));
    } else {
      yield put(getSearchError(data?.error || "Search error."));
    }
  } catch (error) {
    yield put(setLoading(Type.GET_SEARCH_ERROR));
  }
}

export function* getUpdatesSaga({ payload }) {
  try {
    yield put(setLoading(Type.GET_UPDATES_REQUEST));
    const { data } = yield call(getUpdatesQuery, payload);
    yield put(setLoading(Type.GET_UPDATES_SUCCESS));

    if (data.success) {
      yield put(getUpdatesSuccess(data));
    } else {
      yield put(getUpdatesError(data.error || "Get updates error."));
    }
  } catch (error) {
    yield put(setLoading(Type.GET_UPDATES_ERROR));
  }
}

export function* updateCommitteeMembershipsSaga({ payload }) {
  try {
    yield put(setLoading(Type.UPDATE_COMMITTEE_MEMBERSHIPS_REQUEST));
    const { data } = yield call(updateCommitteeMembershipsQuery, payload);
    yield put(setLoading(Type.UPDATE_COMMITTEE_MEMBERSHIPS_SUCCESS));

    if (!data.success) {
      yield put(
        updateCommitteeMembershipsError(
          data.error || "Update committee memberships error."
        )
      );
    }
  } catch (error) {
    yield put(setLoading(Type.UPDATE_COMMITTEE_MEMBERSHIPS_ERROR));
  }
}

export default function* commiteeManagerSaga() {
  yield all([takeLatest(setCommitteesRequest, setCommitteesSaga)]);
  yield all([takeLatest(setMembershipRequest, setMembershipSaga)]);
  yield all([takeLatest(getCommitteesRequest, getCommitteesSaga)]);
  yield all([takeLatest(getCommitteeRequest, getCommitteeSaga)]);
  yield all([takeLatest(getCommitteeMembersRequest, getCommitteeMembersSaga)]);
  yield all([takeLatest(getSubCommitteesRequest, getSubCommitteesSaga)]);
  yield all([takeLatest(getTagsRequest, getTagsSaga)]);
  yield all([takeLatest(getSearchRequest, getSearchSaga)]);
  yield all([takeLatest(getUpdatesRequest, getUpdatesSaga)]);
  yield all([
    takeLatest(
      updateCommitteeMembershipsRequest,
      updateCommitteeMembershipsSaga
    ),
  ]);
}
