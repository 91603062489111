import {
  FormControl,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
  Box,
} from "@chakra-ui/react";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteList,
  AutoCompleteItem,
} from "@choc-ui/chakra-autocomplete";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setViewAsCompany } from "../../redux/auth/action";
import { getMemberCompaniesRequest } from "../../redux/company/action";
import PrimaryButton from "../common/PrimaryButton";
import SecondaryButton from "../common/SecondaryButton";

type SelectCompanyModalProps = {
  show: boolean;
  onClose: () => void;
};

function SelectCompanyModal({
  show,
  onClose,
}: SelectCompanyModalProps): JSX.Element {
  const [selectedID, setSelectedID] = useState<string>();
  const dispacth = useDispatch();
  const { memberCompanies } = useSelector((state) => state?.company);

  const handleSelect = ({ item: { originalValue } }) => {
    setSelectedID(originalValue.id);
  };

  const handleApply = () => {
    dispacth(setViewAsCompany(selectedID));
    onClose();
  };

  useEffect(() => {
    if (isEmpty(memberCompanies)) {
      dispacth(getMemberCompaniesRequest());
    }
  }, []);

  return (
    <Modal isOpen={show} onClose={onClose} size="4xl" isCentered>
      <ModalOverlay zIndex={1300} />
      <Box>
        <ModalContent borderRadius="8px">
          <ModalHeader
            pb={0}
            pt="72px !important"
            fontFamily="montserrat"
            fontSize="19px"
            lineHeight="24px"
          >
            View as Member Company
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6} pt={4}>
            <VStack w="100%" alignItems="flex-start" spacing={10}>
              <FormControl>
                <AutoComplete rollNavigation onSelectOption={handleSelect}>
                  <AutoCompleteInput
                    variant="outline"
                    placeholder="Enter name"
                    autoFocus
                    padding={6}
                  />
                  <AutoCompleteList>
                    {memberCompanies?.map((com) => (
                      <AutoCompleteItem
                        key={`option-${com?.id}`}
                        value={com}
                        textTransform="capitalize"
                      >
                        {com.name}
                      </AutoCompleteItem>
                    ))}
                  </AutoCompleteList>
                </AutoComplete>
              </FormControl>
              <HStack justifyContent="center" w="100%" spacing={6}>
                <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
                <PrimaryButton onClick={handleApply}>Apply</PrimaryButton>
              </HStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Box>
    </Modal>
  );
}

export default SelectCompanyModal;
