import React from "react";
import { Link } from "react-router-dom";
import { Stack, VStack, HStack, Box, Text, Flex } from "@chakra-ui/react";
import dayjs from "dayjs";
import { cardShadow } from "../styles/theme";

export type updateProps = {
  id: number;
  category: string;
  date: Date;
  title: string;
  url: string;
  bgColor: string;
  image: string;
  isNew: boolean;
  isBookmark: boolean;
};

const ArticleCard = ({
  data,
  internal,
}: {
  data: updateProps;
  internal?: boolean;
}) => {
  return (
    <Link to={data.url} onClick={() => window.scrollTo(0, 0)}>
      <Stack
        direction="column"
        minW="266px"
        w="266px"
        minH="392px"
        bg="white"
        // flex={1}
        borderRadius="17px"
        alignItems={{ base: "flex-start", xl: "stretch" }}
        overflow="hidden"
        _hover={{ filter: cardShadow }}
        transition="0.3s all"
      >
        <Flex
          minH="162px"
          minW="100%"
          backgroundColor={internal ? "#D4D3E9" : data.bgColor}
          backgroundImage={internal ? "" : `url(${data.image})`}
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          justify="center"
          align="center"
        >
          {internal && (
            <img
              src="/images/committees/default-committee.svg"
              width="60"
              height="82"
              alt=""
            />
          )}
        </Flex>

        <VStack flex="1" alignItems="flex-start" p="30px">
          <VStack justify="space-between" w="100%" align="flex-start">
            <Text
              fontSize="14px"
              lineHeight="17px"
              fontWeight="700"
              fontFamily="montserrat"
              color="grey.950"
            >
              {data.category}
            </Text>

            <Box>
              <Text
                w="fit-content"
                fontSize="16px"
                lineHeight="21px"
                fontWeight="500"
                fontFamily="opensans"
              >
                {data.title}
              </Text>
            </Box>
          </VStack>

          <HStack w="100%" justify="flex-start" mt="16px !important">
            <Text
              as="div"
              fontSize="12px"
              lineHeight="16px"
              fontWeight="400"
              fontFamily="montserrat"
              color="grey.300"
            >
              {dayjs(data.date).format("MMM D, YYYY")}
            </Text>
          </HStack>
        </VStack>
      </Stack>
    </Link>
  );
};

export default ArticleCard;
