import React from "react";

const Hamburger = ({ color }: { color?: string }) => {
  return (
    <svg
      width="20"
      height="15"
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="1"
        y1="1.33057"
        x2="19"
        y2="1.33057"
        stroke="#333333"
        stroke-width="2"
        stroke-linecap="square"
      />
      <line
        x1="1"
        y1="13.3306"
        x2="19"
        y2="13.3306"
        stroke="#333333"
        stroke-width="2"
        stroke-linecap="square"
      />
      <line
        x1="1"
        y1="7.33057"
        x2="19"
        y2="7.33057"
        stroke="#333333"
        stroke-width="2"
        stroke-linecap="square"
      />
    </svg>
  );
};

export default Hamburger;
