import React from "react";
import useMessages from "../../hooks/useMessages";
import BRTInboxSection from "./BRTInboxSection";

export default function AntitrustAndLegalAdvisory() {
  const messages = useMessages("antitrust-and-legal-advisory");

  return (
    <BRTInboxSection
      data={messages}
      title="Antitrust & Legal Advisory"
      type="antitrust-and-legal-advisory"
    />
  );
}
