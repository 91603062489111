import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router";
import HeadMeta from "../../components/common/HeadMeta";
import PrimaryButton from "../../components/common/PrimaryButton";
import PublicLayout from "../../components/PublicLayout";
import routes from "../../constants/routes.json";

function ForgotPasswordSent() {
  const history = useHistory();

  const handleDone = () => {
    history.push(routes.SIGNIN);
  };

  const handleResend = () => {
    history.push(routes.FORGOT_PASSWORD);
  };

  return (
    <PublicLayout>
      <HeadMeta />

      <Flex
        bg="white"
        w="100%"
        justify="center"
        align="center"
        mt={{ base: "3.5rem", sm: "0px" }}
      >
        <Box w="500px">
          <Text
            fontSize={{ base: "24px", md: "36px" }}
            lineHeight={{ base: "29px", md: "44px" }}
            fontWeight={700}
            fontFamily="montserrat"
            color="grey.950"
            mb={{ base: "16px", sm: "16px" }}
          >
            Password reset link sent
          </Text>
          <Text
            fontSize={{ base: "14px", sm: "16px" }}
            lineHeight={{ base: "19px", sm: "22px" }}
            fontWeight={400}
            fontFamily="opensans"
            color="grey.950"
            maxW={{ base: "100%", md: "371px" }}
          >
            You should receive the reset link to your inbox within a few minutes
            and will be able to set your new password.
          </Text>

          <Box mt="56px !important">
            <PrimaryButton onClick={handleDone}>
              Return to Sign In
            </PrimaryButton>
          </Box>
        </Box>
      </Flex>
    </PublicLayout>
  );
}

export default ForgotPasswordSent;
