import {
  Box,
  CloseButton,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import { ReactJSXElementChildrenAttribute } from "@emotion/react/types/jsx-namespace";
import React, { useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import HeadMeta from "../../components/common/HeadMeta";
import Descriptions from "../../components/Onboard/Descriptions";
import SignUpForm from "../../components/Onboard/SignUpForm";
import StepProgress from "../../components/Onboard/StepProgress";
import PublicLayout from "../../components/PublicLayout";
import { PASSWORD_REQUIREMENTS } from "../../constants/default";
import useOnClickOutside from "../../hooks/useClickOutside";
import { createOnboard } from "../../redux/auth/action";

interface InfoModalProps extends ReactJSXElementChildrenAttribute {
  title: string;
  show: boolean;
  onClose: () => void;
}

function InfoModal({ children, title, show, onClose }: InfoModalProps) {
  const contentRef = useRef();
  useOnClickOutside(contentRef, onClose);
  return (
    <Box hidden={!show} px="32px">
      <Box
        bg="#E5E5E5"
        opacity="0.7"
        w="100%"
        h="100%"
        position="absolute"
        top="0"
        left="0"
        zIndex="1300"
      ></Box>
      <Box
        ref={contentRef}
        position="absolute"
        zIndex="1301"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        px={{ base: "32px", sm: "0px" }}
        w={{ base: "100%" }}
        maxW="375px"
      >
        <Box
          bg="white"
          borderRadius="8px"
          px="24px"
          py="64px"
          position="relative"
        >
          <CloseButton
            position="absolute"
            top="32px"
            right="32px"
            onClick={onClose}
            width="fit-content"
            boxSize="12px"
          />
          <Text
            fontSize="20px"
            lineHeight="24px"
            fontFamily="montserrat"
            fontWeight="600"
            pb="32px"
          >
            {title}
          </Text>
          <Text>{children}</Text>
        </Box>
      </Box>
    </Box>
  );
}

function StepAbout({ first, last }: { first: string; last: string }) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state) => state.ui.loading?.["CREATE_ONBOARD"]
  );

  const onSubmit = (fields: any) => {
    dispatch(createOnboard(fields));
  };

  return (
    <PublicLayout progress={95}>
      <HeadMeta />

      <Stack
        direction={{ base: "column", md: "row" }}
        spacing="25px"
        flex={1}
        mx={{ base: "-24px", md: "0px" }}
      >
        <VStack
          h="100%"
          w={{ base: "100%", md: "42%" }}
          maxWidth={{ base: "100%", md: "619px" }}
          bg="white"
          flex={1}
          alignItems={"center"}
          justifyContent={"center"}
          position="relative"
          px="24px"
        >
          <InfoModal
            show={showModal}
            onClose={() => setShowModal(false)}
            title="Password Requirements"
          >
            <UnorderedList>
              {PASSWORD_REQUIREMENTS.map((item: any, index: any) => (
                <ListItem key={index} _notLast={{ pb: "24px" }}>
                  <Text
                    fontSize="14px"
                    lineHeight="24px"
                    fontFamily="opensans"
                    fontWeight="400"
                  >
                    {item}
                  </Text>
                </ListItem>
              ))}
            </UnorderedList>
          </InfoModal>
          <VStack maxW={{ base: "100%", md: "375px" }} h="100%" flex={1}>
            <VStack
              flex={1}
              alignItems="flex-start"
              justifyContent={{ base: "flex-start", md: "center" }}
              mt={{ base: "3.5rem", md: "0px" }}
            >
              <SignUpForm
                onSubmit={onSubmit}
                onModal={() => setShowModal(true)}
                first={first}
                last={last}
                isLoading={isLoading}
              />
            </VStack>
          </VStack>
        </VStack>

        <VStack
          h={{ base: "fit-content", md: "100%" }}
          w={{ base: "100%", md: "60%" }}
          flex={1}
          alignItems={"center"}
          justifyContent={"center"}
          ml="0 !important"
          display={{ base: "none", md: "flex" }}
        >
          <VStack
            flex={1}
            alignItems={"center"}
            justifyContent={"center"}
            px={{ base: "40px", md: "60px", xl: "120px" }}
          >
            <Box
              borderRadius="8px"
              filter="drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1)) drop-shadow(0px 16px 32px rgba(0, 0, 0, 0.08));"
              mb="16px"
            >
              <img
                src="/images/onboard/about.png"
                alt=""
                style={{ maxHeight: "453px", maxWidth: "581px" }}
                width="100%"
              />
            </Box>
            <Box mt="0 !important" maxW="581px" pl="40px" pr="16px">
              <Descriptions
                title="Look Ahead or Get Up to Speed"
                description="The Business Roundtable Member Portal features upcoming and past events. View agendas, download meeting materials and RSVP to attend."
              />
            </Box>
          </VStack>

          <StepProgress value={95} />
        </VStack>
      </Stack>
    </PublicLayout>
  );
}

const mapStateToProps = (state: any) => {
  return {
    first: (state.auth && state.auth.first) || "",
    last: (state.auth && state.auth.last) || "",
  };
};

export default connect(mapStateToProps)(StepAbout);
