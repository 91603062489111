import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../redux/ui/action";
import { getMessagesByCommitteeId } from "../services/committee";

export function useEmailAndUpdateList(committeeId: string) {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLoading("GET_MSG_BY_COMMITTEE_REQUEST"));
      try {
        const messages = await getMessagesByCommitteeId({ id: committeeId });
        setData(messages);
        dispatch(setLoading("GET_MSG_BY_COMMITTEE_SUCCESS"));
      } catch (error) {
        dispatch(setLoading("GET_MSG_BY_COMMITTEE_ERROR"));
      }
    };

    if (committeeId) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [committeeId]);
  return data;
}
