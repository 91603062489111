import {
  Avatar,
  Box,
  Button,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { FiLogOut } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { MENU_TOP } from "../../constants/menu";
import routes from "../../constants/routes.json";
import { logoutUser } from "../../redux/auth/action";
import MenuItem from "./MenuItem";

const Sidebar = ({
  collapsed,
  company,
  isLoggedIn,
  name,
  onToggle,
  profilePicture,
}: {
  collapsed: boolean;
  company: string;
  isLoggedIn: boolean;
  name: string;
  onToggle: () => void;
  profilePicture: string;
}) => {
  const {
    profile: { webRole = null },
  } = useSelector((state) => state.auth);
  const location = useLocation();
  const dispatch = useDispatch();
  const initialFocusRef = React.useRef();

  const handleLogout = () => {
    dispatch(logoutUser());
    window.location.href = routes.SIGNIN;
  };

  return (
    <VStack
      color="grey.950"
      h="100%"
      w={collapsed ? "94px" : "270px"}
      align="flex-start"
      justify="space-between"
      position="fixed"
      className="shadow"
      bg="white"
      zIndex={1250}
    >
      <VStack w="100%">
        <HStack w="100%" pt="24px" pb="40px" pl="30px">
          <Box cursor="pointer">
            <img
              {...(true
                ? { src: "/images/logo.svg", width: "35px" }
                : { src: "/images/logo-full.svg" })}
              height="40px"
              alt="logo"
              onClick={onToggle}
              style={{ height: "40px" }}
            />
          </Box>
        </HStack>

        {isLoggedIn &&
          MENU_TOP.filter((item) => item?.acl?.includes(webRole)).map(
            (item: any) => (
              <MenuItem
                key={item.id}
                id={item.id}
                custom={item.custom ?? false}
                icon={item.icon}
                title={item.title}
                url={item.path}
                isActive={item.isActive(location.pathname)}
                collapsed={collapsed}
                isMenu
              />
            )
          )}
      </VStack>

      {isLoggedIn && (
        <VStack w="100%">
          {/* {MENU_BOTTOM.map((item: any) => (
              <MenuItem
                key={item.id}
                id={item.id}
                icon={item.icon}
                title={item.title}
                url={item.path}
                isActive={item.isActive(location.pathname)}
                collapsed={collapsed}
              />
            ))} */}
          <Popover
            initialFocusRef={initialFocusRef}
            placement="right-end"
            closeOnBlur
          >
            <PopoverTrigger>
              <Button
                bgColor="transparent"
                w="100%"
                sx={{
                  height: "61px !important",
                  padding: "0 !important",
                  margin: "0 !important",
                  zIndex: 10000,
                }}
                filter="unset"
              >
                <MenuItem
                  id={99}
                  isAvatar={true}
                  avatarImage={profilePicture}
                  title={name}
                  // url={routes.PROFILE}
                  isActive={false}
                  collapsed={collapsed}
                  onClick={() => {}}
                  toolTip="User Profile"
                />
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody p="30px">
                <VStack h="100%">
                  <HStack w="100%">
                    <Avatar
                      boxSize="80px"
                      bg="grey.200"
                      color="grey.200"
                      name={name}
                      src={profilePicture}
                    />
                    <VStack alignItems="flex-start">
                      <Text
                        fontSize="18px"
                        fontFamily="montserrat"
                        fontWeight={600}
                      >
                        {name}
                      </Text>
                      <Text
                        fontSize="14px"
                        fontFamily="opensans"
                        fontWeight={400}
                      >
                        {company}
                      </Text>
                    </VStack>
                  </HStack>
                  <VStack w="100%">
                    <Link to="/profile" style={{ width: "100%" }}>
                      <Button
                        bgColor="grey.50"
                        color="primary.500"
                        w="100%"
                        mt="10px !important"
                        filter={false}
                      >
                        Edit profile information
                      </Button>
                    </Link>
                    <Button
                      bgColor="transparent"
                      color="primary.500"
                      w="100%"
                      mt="10px !important"
                      filter={false}
                      leftIcon={<FiLogOut />}
                      onClick={handleLogout}
                    >
                      Logout
                    </Button>
                  </VStack>
                </VStack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </VStack>
      )}
    </VStack>
  );
};

export default Sidebar;
