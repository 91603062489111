export const CEO = {
  name: 'Doug McMillon',
  position: 'President & CEO',
  description: 'Chairman'
}
export const COMPANY = {
  name: 'Walmart',
  location: 'Bentonville, AR',
  description: 'What started small, with a single discount store and the simple idea of selling more for less, has grown over the last 50 years into the largest retailer in the world. Each week, approximately 220 million customers and members visit approximately 10,500 stores and clubs under 48 banners in 24 countries and e-commerce websites. With fiscal year 2021 revenue of $559 billion, Walmart employs over 2.2 million associates worldwide. Walmart continues to be a leader in sustainability, corporate philanthropy and employment opportunity. It’s all part of our unwavering commitment to creating opportunities and bringing value to customers and communities around the world.',
  address: {
    street: '702 SW 8th Street',
    location: 'Bentonville, AR 72712'
  },
  website: 'walmart.com',
  contact: {
    position: 'Head of Washington',
    name: 'John Smith',
    phone: '1.800.WAL.MART'
  }
}

export const PROFILE = {
  logo: '/images/demo/company-logo.jpg',
  name: 'Shelly Vega',
  email: 'shelly.vega@walmart.com'
}

export const STATISTICS = [
  {title: 'Member Since', value: 2002},
  {title: 'Statistic Name', value: 24},
  {title: 'Statistic Name', value: 12},
  {title: 'Statistic Name', value: 9},
  {title: 'Active Staff', value: 56},
  {title: 'Statistic Name', value: 21},
]

export const TABLE_COLUMNS = [
  {title: 'Full Name', name: 'name'},
  {title: 'Title', name: 'title'},
  {title: 'Email', name: 'emailaddress1'},
]
export const NEW_MEMBER_COMPANIES_TABLE_COLUMNS = [
  {title: 'Company', name: 'company'},
  {title: 'CEO', name: 'ceo'},
]
export const COMMITEE_LIST = [
  {id: 1, active: false, title: 'Committee Name'},
  {id: 2, active: true, title: 'Committee Name'},
  {id: 3, active: false, title: 'Committee NameT'},
  {id: 4, active: false, title: 'Committee NameT'},
]

export const TABLE_DATA = [
  {name: 'First + Last Name', title: 'Title',
    areasOfInterest: [
      {
        brt_contactcategoryid: "1acb12ce-48e0-eb11-bacb-00224808d772",
        brt_portalcategoryid: "40dc6d00-a9d2-eb11-bacc-000d3a36328a",
        createdOn: "2021-07-09T00:01:35.000Z",
        id: "40dc6d00-a9d2-eb11-bacc-000d3a36328a",
        name: "Trade & International"
      },
      {
        brt_contactcategoryid: "3e7d0e5e-49e0-eb11-bacb-00224808d772",
        brt_portalcategoryid: "bfaa3314-a9d2-eb11-bacc-000d3a36328a",
        createdOn: "2021-07-09T00:05:34.000Z",
        id: "bfaa3314-a9d2-eb11-bacc-000d3a36328a",
        name: "Smart Regulation"
      },
      {
        brt_contactcategoryid: "417d0e5e-49e0-eb11-bacb-00224808d772",
        brt_portalcategoryid: "348c8a1b-a9d2-eb11-bacc-000d3a36328a",
        createdOn: "2021-07-09T00:05:34.000Z",
        id: "348c8a1b-a9d2-eb11-bacc-000d3a36328a",
        name: "Energy & Environment"
      }
    ],
    lastEvent: '00/00/0000'
  },
  {name: 'First + Last Name', title: 'Title',
    committees: [
      {
        bgColor: "#c8e6fb",
        createdOn: "2021-07-08T17:19:09.000Z",
        id: "a4fb1821-de99-e511-80f5-3863bb3610e8",
        image: "/images/committees/infrastructure.svg",
        key: "infrastructure",
        membershipid: "e50a389a-10e0-eb11-bacb-00224808d772",
        membershipstatus: 1,
        name: "Infrastructure"
      }
    ],
    areasOfInterest: [
      {
        brt_contactcategoryid: "1acb12ce-48e0-eb11-bacb-00224808d772",
        brt_portalcategoryid: "40dc6d00-a9d2-eb11-bacc-000d3a36328a",
        createdOn: "2021-07-09T00:01:35.000Z",
        id: "40dc6d00-a9d2-eb11-bacc-000d3a36328a",
        name: "Trade & International"
      },
      {
        brt_contactcategoryid: "3e7d0e5e-49e0-eb11-bacb-00224808d772",
        brt_portalcategoryid: "bfaa3314-a9d2-eb11-bacc-000d3a36328a",
        createdOn: "2021-07-09T00:05:34.000Z",
        id: "bfaa3314-a9d2-eb11-bacc-000d3a36328a",
        name: "Smart Regulation"
      },
      {
        brt_contactcategoryid: "417d0e5e-49e0-eb11-bacb-00224808d772",
        brt_portalcategoryid: "348c8a1b-a9d2-eb11-bacc-000d3a36328a",
        createdOn: "2021-07-09T00:05:34.000Z",
        id: "348c8a1b-a9d2-eb11-bacc-000d3a36328a",
        name: "Energy & Environment"
      }
    ],
    lastEvent: '00/00/0000'
  },
  {name: 'First + Last Name', title: 'Title',
    committees: [
      {
        bgColor: "#c8e6fb",
        createdOn: "2021-07-08T17:19:09.000Z",
        id: "a4fb1821-de99-e511-80f5-3863bb3610e8",
        image: "/images/committees/infrastructure.svg",
        key: "infrastructure",
        membershipid: "e50a389a-10e0-eb11-bacb-00224808d772",
        membershipstatus: 1,
        name: "Infrastructure"
      }
    ],
    areasOfInterest: [
      {
        brt_contactcategoryid: "1acb12ce-48e0-eb11-bacb-00224808d772",
        brt_portalcategoryid: "40dc6d00-a9d2-eb11-bacc-000d3a36328a",
        createdOn: "2021-07-09T00:01:35.000Z",
        id: "40dc6d00-a9d2-eb11-bacc-000d3a36328a",
        name: "Trade & International"
      },
      {
        brt_contactcategoryid: "3e7d0e5e-49e0-eb11-bacb-00224808d772",
        brt_portalcategoryid: "bfaa3314-a9d2-eb11-bacc-000d3a36328a",
        createdOn: "2021-07-09T00:05:34.000Z",
        id: "bfaa3314-a9d2-eb11-bacc-000d3a36328a",
        name: "Smart Regulation"
      },
      {
        brt_contactcategoryid: "417d0e5e-49e0-eb11-bacb-00224808d772",
        brt_portalcategoryid: "348c8a1b-a9d2-eb11-bacc-000d3a36328a",
        createdOn: "2021-07-09T00:05:34.000Z",
        id: "348c8a1b-a9d2-eb11-bacc-000d3a36328a",
        name: "Energy & Environment"
      }
    ],
    lastEvent: '00/00/0000'
  },
  {name: 'First + Last Name', title: 'Title',
    committees: [
      {
        bgColor: "#c8e6fb",
        createdOn: "2021-07-08T17:19:09.000Z",
        id: "a4fb1821-de99-e511-80f5-3863bb3610e8",
        image: "/images/committees/infrastructure.svg",
        key: "infrastructure",
        membershipid: "e50a389a-10e0-eb11-bacb-00224808d772",
        membershipstatus: 1,
        name: "Infrastructure"
      }
    ],
    areasOfInterest: [
      {
        brt_contactcategoryid: "1acb12ce-48e0-eb11-bacb-00224808d772",
        brt_portalcategoryid: "40dc6d00-a9d2-eb11-bacc-000d3a36328a",
        createdOn: "2021-07-09T00:01:35.000Z",
        id: "40dc6d00-a9d2-eb11-bacc-000d3a36328a",
        name: "Trade & International"
      },
      {
        brt_contactcategoryid: "3e7d0e5e-49e0-eb11-bacb-00224808d772",
        brt_portalcategoryid: "bfaa3314-a9d2-eb11-bacc-000d3a36328a",
        createdOn: "2021-07-09T00:05:34.000Z",
        id: "bfaa3314-a9d2-eb11-bacc-000d3a36328a",
        name: "Smart Regulation"
      },
      {
        brt_contactcategoryid: "417d0e5e-49e0-eb11-bacb-00224808d772",
        brt_portalcategoryid: "348c8a1b-a9d2-eb11-bacc-000d3a36328a",
        createdOn: "2021-07-09T00:05:34.000Z",
        id: "348c8a1b-a9d2-eb11-bacc-000d3a36328a",
        name: "Energy & Environment"
      }
    ],
    lastEvent: '00/00/0000'
  },
  {name: 'First + Last Name', title: 'Title',
    committees: [
      {
        bgColor: "#c8e6fb",
        createdOn: "2021-07-08T17:19:09.000Z",
        id: "a4fb1821-de99-e511-80f5-3863bb3610e8",
        image: "/images/committees/infrastructure.svg",
        key: "infrastructure",
        membershipid: "e50a389a-10e0-eb11-bacb-00224808d772",
        membershipstatus: 1,
        name: "Infrastructure"
      }
    ],
    areasOfInterest: [
      {
        brt_contactcategoryid: "1acb12ce-48e0-eb11-bacb-00224808d772",
        brt_portalcategoryid: "40dc6d00-a9d2-eb11-bacc-000d3a36328a",
        createdOn: "2021-07-09T00:01:35.000Z",
        id: "40dc6d00-a9d2-eb11-bacc-000d3a36328a",
        name: "Trade & International"
      },
      {
        brt_contactcategoryid: "3e7d0e5e-49e0-eb11-bacb-00224808d772",
        brt_portalcategoryid: "bfaa3314-a9d2-eb11-bacc-000d3a36328a",
        createdOn: "2021-07-09T00:05:34.000Z",
        id: "bfaa3314-a9d2-eb11-bacc-000d3a36328a",
        name: "Smart Regulation"
      },
      {
        brt_contactcategoryid: "417d0e5e-49e0-eb11-bacb-00224808d772",
        brt_portalcategoryid: "348c8a1b-a9d2-eb11-bacc-000d3a36328a",
        createdOn: "2021-07-09T00:05:34.000Z",
        id: "348c8a1b-a9d2-eb11-bacc-000d3a36328a",
        name: "Energy & Environment"
      }
    ],
    lastEvent: '00/00/0000'
  },
  {name: 'First + Last Name', title: 'Title',
    committees: [
      {
        bgColor: "#c8e6fb",
        createdOn: "2021-07-08T17:19:09.000Z",
        id: "a4fb1821-de99-e511-80f5-3863bb3610e8",
        image: "/images/committees/infrastructure.svg",
        key: "infrastructure",
        membershipid: "e50a389a-10e0-eb11-bacb-00224808d772",
        membershipstatus: 1,
        name: "Infrastructure"
      }
    ],
    areasOfInterest: [
      {
        brt_contactcategoryid: "1acb12ce-48e0-eb11-bacb-00224808d772",
        brt_portalcategoryid: "40dc6d00-a9d2-eb11-bacc-000d3a36328a",
        createdOn: "2021-07-09T00:01:35.000Z",
        id: "40dc6d00-a9d2-eb11-bacc-000d3a36328a",
        name: "Trade & International"
      },
      {
        brt_contactcategoryid: "3e7d0e5e-49e0-eb11-bacb-00224808d772",
        brt_portalcategoryid: "bfaa3314-a9d2-eb11-bacc-000d3a36328a",
        createdOn: "2021-07-09T00:05:34.000Z",
        id: "bfaa3314-a9d2-eb11-bacc-000d3a36328a",
        name: "Smart Regulation"
      },
      {
        brt_contactcategoryid: "417d0e5e-49e0-eb11-bacb-00224808d772",
        brt_portalcategoryid: "348c8a1b-a9d2-eb11-bacc-000d3a36328a",
        createdOn: "2021-07-09T00:05:34.000Z",
        id: "348c8a1b-a9d2-eb11-bacc-000d3a36328a",
        name: "Energy & Environment"
      }
    ],
    lastEvent: '00/00/0000'
  },
  {name: 'First + Last Name', title: 'Title',
    areasOfInterest: [
      {
        brt_contactcategoryid: "1acb12ce-48e0-eb11-bacb-00224808d772",
        brt_portalcategoryid: "40dc6d00-a9d2-eb11-bacc-000d3a36328a",
        createdOn: "2021-07-09T00:01:35.000Z",
        id: "40dc6d00-a9d2-eb11-bacc-000d3a36328a",
        name: "Trade & International"
      },
      {
        brt_contactcategoryid: "3e7d0e5e-49e0-eb11-bacb-00224808d772",
        brt_portalcategoryid: "bfaa3314-a9d2-eb11-bacc-000d3a36328a",
        createdOn: "2021-07-09T00:05:34.000Z",
        id: "bfaa3314-a9d2-eb11-bacc-000d3a36328a",
        name: "Smart Regulation"
      },
      {
        brt_contactcategoryid: "417d0e5e-49e0-eb11-bacb-00224808d772",
        brt_portalcategoryid: "348c8a1b-a9d2-eb11-bacc-000d3a36328a",
        createdOn: "2021-07-09T00:05:34.000Z",
        id: "348c8a1b-a9d2-eb11-bacc-000d3a36328a",
        name: "Energy & Environment"
      }
    ],
    lastEvent: '00/00/0000'
  },
  {name: 'First + Last Name', title: 'Title',
    areasOfInterest: [
      {
        brt_contactcategoryid: "1acb12ce-48e0-eb11-bacb-00224808d772",
        brt_portalcategoryid: "40dc6d00-a9d2-eb11-bacc-000d3a36328a",
        createdOn: "2021-07-09T00:01:35.000Z",
        id: "40dc6d00-a9d2-eb11-bacc-000d3a36328a",
        name: "Trade & International"
      },
      {
        brt_contactcategoryid: "3e7d0e5e-49e0-eb11-bacb-00224808d772",
        brt_portalcategoryid: "bfaa3314-a9d2-eb11-bacc-000d3a36328a",
        createdOn: "2021-07-09T00:05:34.000Z",
        id: "bfaa3314-a9d2-eb11-bacc-000d3a36328a",
        name: "Smart Regulation"
      },
      {
        brt_contactcategoryid: "417d0e5e-49e0-eb11-bacb-00224808d772",
        brt_portalcategoryid: "348c8a1b-a9d2-eb11-bacc-000d3a36328a",
        createdOn: "2021-07-09T00:05:34.000Z",
        id: "348c8a1b-a9d2-eb11-bacc-000d3a36328a",
        name: "Energy & Environment"
      }
    ],
    lastEvent: '00/00/0000'
  },
  {name: 'First + Last Name', title: 'Title',
    areasOfInterest: [
      {
        brt_contactcategoryid: "1acb12ce-48e0-eb11-bacb-00224808d772",
        brt_portalcategoryid: "40dc6d00-a9d2-eb11-bacc-000d3a36328a",
        createdOn: "2021-07-09T00:01:35.000Z",
        id: "40dc6d00-a9d2-eb11-bacc-000d3a36328a",
        name: "Trade & International"
      },
      {
        brt_contactcategoryid: "3e7d0e5e-49e0-eb11-bacb-00224808d772",
        brt_portalcategoryid: "bfaa3314-a9d2-eb11-bacc-000d3a36328a",
        createdOn: "2021-07-09T00:05:34.000Z",
        id: "bfaa3314-a9d2-eb11-bacc-000d3a36328a",
        name: "Smart Regulation"
      },
      {
        brt_contactcategoryid: "417d0e5e-49e0-eb11-bacb-00224808d772",
        brt_portalcategoryid: "348c8a1b-a9d2-eb11-bacc-000d3a36328a",
        createdOn: "2021-07-09T00:05:34.000Z",
        id: "348c8a1b-a9d2-eb11-bacc-000d3a36328a",
        name: "Energy & Environment"
      }
    ],
    lastEvent: '00/00/0000'
  },
  {name: 'First + Last Name', title: 'Title',
    areasOfInterest: [
      {
        brt_contactcategoryid: "1acb12ce-48e0-eb11-bacb-00224808d772",
        brt_portalcategoryid: "40dc6d00-a9d2-eb11-bacc-000d3a36328a",
        createdOn: "2021-07-09T00:01:35.000Z",
        id: "40dc6d00-a9d2-eb11-bacc-000d3a36328a",
        name: "Trade & International"
      },
      {
        brt_contactcategoryid: "3e7d0e5e-49e0-eb11-bacb-00224808d772",
        brt_portalcategoryid: "bfaa3314-a9d2-eb11-bacc-000d3a36328a",
        createdOn: "2021-07-09T00:05:34.000Z",
        id: "bfaa3314-a9d2-eb11-bacc-000d3a36328a",
        name: "Smart Regulation"
      },
      {
        brt_contactcategoryid: "417d0e5e-49e0-eb11-bacb-00224808d772",
        brt_portalcategoryid: "348c8a1b-a9d2-eb11-bacc-000d3a36328a",
        createdOn: "2021-07-09T00:05:34.000Z",
        id: "348c8a1b-a9d2-eb11-bacc-000d3a36328a",
        name: "Energy & Environment"
      }
    ],
    lastEvent: '00/00/0000'
  },
]

