import React from 'react';

const About = ({color}: {color: string}) => {
   return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
         <mask
            id="mask0_6457_13140"
            style={{maskType: 'alpha'}}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
         >
            <rect width="24" height="24" fill="#D9D9D9" />
         </mask>
         <g mask="url(#mask0_6457_13140)">
            <path
               d="M15 12.85C15.4833 12.85 15.896 12.679 16.238 12.337C16.5793 11.9957 16.75 11.5833 16.75 11.1C16.75 10.6167 16.5793 10.2043 16.238 9.863C15.896 9.521 15.4833 9.35 15 9.35C14.5167 9.35 14.104 9.521 13.762 9.863C13.4207 10.2043 13.25 10.6167 13.25 11.1C13.25 11.5833 13.4207 11.9957 13.762 12.337C14.104 12.679 14.5167 12.85 15 12.85ZM11.35 16.65H18.65V16.3C18.65 15.6333 18.321 15.1083 17.663 14.725C17.0043 14.3417 16.1167 14.15 15 14.15C13.8833 14.15 12.996 14.3417 12.338 14.725C11.6793 15.1083 11.35 15.6333 11.35 16.3V16.65ZM4.3 19.5C3.8 19.5 3.375 19.325 3.025 18.975C2.675 18.625 2.5 18.2 2.5 17.7V6.7C2.5 6.18333 2.675 5.75 3.025 5.4C3.375 5.05 3.8 4.875 4.3 4.875H9.625C9.875 4.875 10.1083 4.92067 10.325 5.012C10.5417 5.104 10.7333 5.23333 10.9 5.4L12 6.5H19.7C20.2 6.5 20.625 6.675 20.975 7.025C21.325 7.375 21.5 7.8 21.5 8.3V17.7C21.5 18.2 21.325 18.625 20.975 18.975C20.625 19.325 20.2 19.5 19.7 19.5H4.3Z"
               fill={color}
            />
         </g>
      </svg>
   );
};

export default About;
