import {
  AREAS_OF_INTERESTS,
  CORPORATE_INITIATIVES,
  EXTRA_AREAS_OF_INTERESTS,
  TOKEN_KEY,
} from "../constants/default";
import { EVENT_TYPES } from "../constants/events";
import jwtDecode from "jwt-decode";

export const setLogin = (value: string) => {
  localStorage.setItem(TOKEN_KEY, value);
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem("user");
};

export const checkAuth = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (token) {
    const user: Record<string, any> = jwtDecode(token);
    if (user.exp * 1000 > Date.now()) {
      return true;
    }
  }
  return false;
};

export const getEventType = (name: string) => {
  if (name === "Business Roundtable") {
    const eventType = EXTRA_AREAS_OF_INTERESTS.find(
      (item: any) => item.title === name
    );
    return eventType ? eventType.type : EVENT_TYPES.other;
  }

  const eventType = AREAS_OF_INTERESTS.find((item: any) => item.title === name);
  return eventType ? eventType.type : EVENT_TYPES.other;
};

export const getTrueDateTime = (date: any, timeZone: string) => {
  const timezoneOffset = timeZone
    ? timeZone.split("(GMT")[1].split(")")[0]
    : "";

  return date ? date.split("Z")[0] + timezoneOffset : "";
};

export const getStaticContent = (committeeName: string) => {
  if (committeeName === "covid_19_response") {
    return {
      id: "covid_19_response",
      name: "COVID-19 Response",
      content: [],
      referenceMaterials: [],
      workingToward: [],
      workingGroups: [],
    };
  } else if (committeeName === "racial_equity_and_justice") {
    return {
      id: "racial_equity_and_justice",
      name: "Racial Equity & Justice",
      content: [],
      referenceMaterials: [],
      workingToward: [],
      workingGroups: [],
    };
  }
};

export const isCorporateInitiatives = (committeeKey: string) =>
  committeeKey === CORPORATE_INITIATIVES;
