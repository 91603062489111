import { Box, Text, BoxProps, Flex } from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface Props extends BoxProps {
  title: string;
  subTitle?: string;
  pagination?: ReactNode;
}

export default function SectionHeader({
  title,
  subTitle,
  mb = { base: "16px !important", md: "24px !important" },
  pagination,
  ...props
}: Props) {
  return (
    <Box mb={mb} mt="0px !important" w="100%" {...props}>
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        mb={!!subTitle ? "0.5rem" : "0"}
      >
        <Text
          minW="250px"
          w="auto"
          textAlign="left"
          fontSize={{ base: "20px", md: "20px" }}
          lineHeight={{ base: "24px", md: "24px" }}
          fontWeight={"600"}
          fontFamily="montserrat"
          color="grey.950"
          whiteSpace="unset"
        >
          {title}
        </Text>
        {pagination}
      </Flex>
      {!!subTitle && (
        <Box>
          <Text fontFamily="montserrat" fontSize="14px" lineHeight="19px">
            {subTitle}
          </Text>
        </Box>
      )}
    </Box>
  );
}
