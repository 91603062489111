export const GET_MEMBER_COMPANIES_REQUEST = "GET_MEMBER_COMPANIES_REQUEST";

export const GET_MEMBER_COMPANIES_SUCCESS = "GET_MEMBER_COMPANIES_SUCCESS";

export const GET_MEMBER_COMPANIES_ERROR = "GET_MEMBER_COMPANIES_ERROR";

export const GET_COMPANY_BY_ID_REQUEST = "GET_COMPANY_BY_ID_REQUEST";
export const GET_COMPANY_BY_ID_SUCCESS = "GET_COMPANY_BY_ID_SUCCESS";
export const GET_COMPANY_BY_ID_ERROR = "GET_COMPANY_BY_ID_ERROR";

export const GET_CURRENT_USER_COMPANY_REQUEST =
  "GET_CURRENT_USER_COMPANY_REQUEST";

export const GET_CURRENT_USER_COMPANY_SUCCESS =
  "GET_CURRENT_USER_COMPANY_SUCCESS";

export const GET_CURRENT_USER_COMPANY_ERROR = "GET_CURRENT_USER_COMPANY_ERROR";
