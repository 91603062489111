import * as React from "react";
import { VStack, Link, HStack, Icon, Text } from "@chakra-ui/react";
import { cardShadowHover } from "../../styles/theme";
import dayjs from "dayjs";
import { FaRegFilePdf } from "react-icons/fa";

interface IReferenceCardData {
  id: number;
  date: Date;
  title: string;
  url: string;
}

export interface ReferenceCardProps {
  data: IReferenceCardData[];
}

export function ReferenceCard({ data }: ReferenceCardProps) {
  return (
    <VStack
      w="100%"
      bg="white"
      sx={{ flex: 1 }}
      borderRadius="17px"
      alignItems="flex-start"
      px="32px"
      py="32px"
      maxW={{ base: "300px", xl: "auto" }}
      _hover={{ filter: cardShadowHover }}
    >
      {data.map((item) => (
        <VStack key={item.id} align="flex-start" pb="12px">
          <Text
            as="div"
            fontSize="12px"
            lineHeight="18px"
            fontWeight="400"
            fontFamily="montserrat"
            color="grey.700"
          >
            {dayjs(item.date).tz("America/New_York").format("MMM D, YYYY")}
          </Text>

          <Link to={item.url}>
            <HStack>
              <Icon as={FaRegFilePdf} boxSize="20px" color="primary.400" />
              <Text
                fontSize="16px"
                lineHeight="22px"
                fontWeight="400"
                fontFamily="montserrat"
                color="primary.400"
              >
                {item.title}
              </Text>
            </HStack>
          </Link>
        </VStack>
      ))}
    </VStack>
  );
}
