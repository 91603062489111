import React from 'react';

const Search = ({color}: {color: string}) => {
   return (
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path
            d="M14.9911 14.2129H14.2271L13.9563 13.9388C14.9041 12.7815 15.4747 11.2789 15.4747 9.6444C15.4747 5.99973 12.6604 3.04541 9.18852 3.04541C5.71662 3.04541 2.90234 5.99973 2.90234 9.6444C2.90234 13.2891 5.71662 16.2434 9.18852 16.2434C10.7456 16.2434 12.1769 15.6444 13.2794 14.6495L13.5405 14.9337V15.7358L18.376 20.8018L19.817 19.2891L14.9911 14.2129ZM9.18852 14.2129C6.78043 14.2129 4.83655 12.1723 4.83655 9.6444C4.83655 7.11648 6.78043 5.07587 9.18852 5.07587C11.5966 5.07587 13.5405 7.11648 13.5405 9.6444C13.5405 12.1723 11.5966 14.2129 9.18852 14.2129Z"
            fill={color}
         />
      </svg>
   );
};

export default Search;
