import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../redux/ui/action";
import { getArticleCommunicationContent } from "../services/article";

export function useCommunicationList(committeeKey: string) {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLoading("GET_ARTICLE_COMMUNICATION_CONTENT_REQUEST"));
      try {
        const articleContent = await getArticleCommunicationContent({
          id: committeeKey,
        });
        setData(articleContent);
        dispatch(setLoading("GET_ARTICLE_COMMUNICATION_CONTENT_SUCCESS"));
      } catch (error) {
        dispatch(setLoading("GET_ARTICLE_COMMUNICATION_CONTENT_ERROR"));
      }
    };

    if (committeeKey) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [committeeKey]);
  return data;
}
