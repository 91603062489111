import { Box, Flex } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import HeadMeta from "../../components/common/HeadMeta";
import ResetPasswordForm from "../../components/Onboard/ResetPasswordForm";
import PublicLayout from "../../components/PublicLayout";
import routes from "../../constants/routes.json";
import { resetPassword } from "../../redux/auth/action";

function ResetPassword({ userError }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get("token");

  const onSubmit = (values: any) => {
    dispatch(resetPassword({ ...values, token }));
  };

  useEffect(() => {
    if (!token) {
      history.push(routes.NOT_FOUND);
    }
  }, []);

  return (
    <PublicLayout>
      <HeadMeta />
      <Flex
        bg="white"
        w="100%"
        justify="center"
        align="center"
        mt={{ base: "3.5rem", sm: "0px" }}
      >
        <Box w="500px">
          <ResetPasswordForm onSubmit={onSubmit} userError={userError} />
        </Box>
      </Flex>
    </PublicLayout>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userError: (state.auth && state.auth.error) || "",
  };
};

export default connect(mapStateToProps)(ResetPassword);
