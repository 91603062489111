import { handleActions } from "redux-actions";
import {
  changePassword,
  setUser,
  addOnboardUser,
  setInvitation,
  setUserError,
  logoutUser,
  setProfile,
  setHelpFormSubmission,
  setViewAsCompany,
} from "./action";
import { logout } from "../../utils";

const DEFAULT_STATE = {
  invitation: "",
  onboardUser: {},
  changePasswordStatus: "",
  user: {},
  profile: {},
  isAuthenticated: false,
  error: "",
  token: null,
  helpFormSubmission: {},
  viewAsCompanyID: "",
};
const handlers = {
  [setUserError]: (state, action) => {
    return { ...state, error: action.payload };
  },

  [setInvitation]: (state, action) => {
    return {
      ...state,
      invitation: action.payload.id,
      first: action.payload.first,
      last: action.payload.last,
    };
  },

  [addOnboardUser]: (state, action) => {
    return { ...state, onboardUser: action.payload };
  },

  [changePassword]: (state, { payload: { error } }) => {
    if (error) {
      return { ...state, changePasswordStatus: "ERROR" };
    }
    return { ...state, changePasswordStatus: "DONE" };
  },

  [setUser]: (state, action) => {
    return {
      ...state,
      user: action.payload.user,
      token: action.payload.token,
      isAuthenticated: true,
    };
  },

  [setProfile]: (state, action) => {
    return { ...state, profile: action.payload };
  },

  [setHelpFormSubmission]: (state, action) => {
    return { ...state, helpFormSubmission: action.payload };
  },

  [logoutUser]: () => {
    logout();
    return { ...DEFAULT_STATE };
  },

  [setViewAsCompany]: (state, action) => {
    return { ...state, viewAsCompanyID: action.payload };
  },
};

export default handleActions(handlers, DEFAULT_STATE);
