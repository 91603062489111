import axios, { AxiosInstance } from "axios";
import jwtDecode from "jwt-decode";
import {
  BACKEND_API_ENDPOINT,
  TOKEN_KEY,
  USER_KEY,
} from "../constants/default";

interface CustomAxiosInstance extends AxiosInstance {
  headers?: {
    "Content-Type": string;
  };
}

const apiClient = axios.create({
  baseURL: BACKEND_API_ENDPOINT,
  headers: {
    "Content-type": "application/json",
  },
});

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("accessToken");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// check for expired token
apiClient.interceptors.response.use((response) => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (token) {
    const user: Record<string, any> = jwtDecode(token);
    if (user.exp * 1000 < Date.now()) {
      alert("Token expired, please login again");
      // clear info
      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem(USER_KEY);

      window.location.href = "/";
      return Promise.reject();
    }
  }
  return response;
});

export const apiClientWithoutToken: CustomAxiosInstance = axios.create({
  baseURL: BACKEND_API_ENDPOINT,
});

export default apiClient;
