import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  createOnboard,
  forgotPassword,
  login,
  resetPassword,
  setInvitationRequest,
  setUserError,
  setUser,
  updateProfile,
  getUserSettings,
  saveUserSettings,
  setProfile,
  postHelpFormSubmission,
  setHelpFormSubmission,
} from "./action";
import {
  forgotPassword as forgotPasswordQuery,
  resetPassword as resetPasswordQuery,
  createOnboard as createOnboardQuery,
  login as loginQuery,
  setOnboarding as setOnboardingQuery,
  updateProfile as updateProfileQuery,
  // saveUserSettings as saveUserSettingsQuery,
  postHelpFormSubmission as postHelpFormSubmissionQuery,
} from "./query";
import history from "../../history";
import routes from "../../constants/routes.json";
import { setLoading } from "../ui/action";
import * as Type from "./type";
import { userSettings } from "../../services/auth";

export const getAuth = (state) => state.auth;

export function* loginSaga({ payload }) {
  const ERROR_MSG = "Email or password is not correct";
  try {
    yield put(setLoading(Type.LOGIN_REQUEST));
    const { data } = yield call(loginQuery, payload);
    console.log({ data });
    yield put(setLoading(Type.LOGIN_SUCCESS));
    const { passwordExpired, success, token, user } = data;

    if (passwordExpired) {
      history.push(routes.UPDATE_EXPIRED_PASSSWORD);
    } else if (success) {
      window.localStorage.setItem("accessToken", token);
      window.localStorage.setItem("user", JSON.stringify(user));
      yield put(setUser(data));
      history.push(routes.HOME);
    } else {
      yield put(setUserError(data.error || ERROR_MSG));
    }
  } catch (error) {
    yield put(setLoading(Type.LOGIN_ERROR));
    yield put(setUserError(ERROR_MSG));
  }
}

export function* forgotPasswordSaga({ payload }) {
  const ERROR_MSG = "Email is not correct";
  try {
    yield put(setLoading(Type.FORGOT_PASSWORD_REQUEST));
    const { data } = yield call(forgotPasswordQuery, payload);
    yield put(setLoading(Type.FORGOT_PASSWORD_SUCCESS));

    if (!data.success) {
      yield put(setUserError(data.error));
    } else {
      history.push(routes.FORGOT_PASSWORD_SENT);
    }
  } catch (error) {
    yield put(setLoading(Type.FORGOT_PASSWORD_ERROR));
    yield put(setUserError(ERROR_MSG));
  }
}

export function* resetPasswordSaga({ payload }) {
  const ERROR_MSG = "Password reset error";
  try {
    yield put(setLoading(Type.RESET_PASSWORD_REQUEST));
    const { data } = yield call(resetPasswordQuery, payload);
    yield put(setLoading(Type.RESET_PASSWORD_SUCCESS));

    if (data.success) {
      history.push(routes.RESET_PASSWORD_DONE);
    } else {
      yield put(setUserError(data.error));
    }
  } catch (error) {
    yield put(setLoading(Type.RESET_PASSWORD_ERROR));
    yield put(setUserError(ERROR_MSG));
  }
}

export function* setInvitationRequestSaga({ payload }) {
  try {
    yield put(setLoading(Type.SET_INVITATION_REQUEST_REQUEST));
    const { data } = yield call(setOnboardingQuery, payload);
    yield put(setLoading(Type.SET_INVITATION_REQUEST_SUCCESS));

    if (!data.success) {
      yield put(setUserError(data.error || "Invalid invitation."));
    } else {
      history.push(routes.ABOUT);
    }
  } catch (error) {
    yield put(setLoading(Type.SET_INVITATION_REQUEST_ERROR));
  }
}

export function* createOnboardSaga({ payload }) {
  try {
    yield put(setLoading(Type.CREATE_ONBOARD_REQUEST));
    let auth = yield select(getAuth);
    const payloadData = {
      ...payload,
      ...auth.onboardUser,
      invitation: auth.invitation,
      categories: [],
    };
    const { data } = yield call(createOnboardQuery, payloadData);
    yield put(setLoading(Type.CREATE_ONBOARD_SUCCESS));

    if (data.success) {
      history.push(routes.SIGNIN);
    }

    if (!data.success) {
      yield put(setUserError(data.error));
    }
  } catch (error) {
    yield put(setLoading(Type.CREATE_ONBOARD_ERROR));
  }
}

export function* createUpdateProfileSaga({ payload }) {
  try {
    yield put(setLoading(Type.CREATE_UPDATE_PROFILE_REQUEST));
    const { data } = yield call(updateProfileQuery, payload);
    yield put(setLoading(Type.CREATE_UPDATE_PROFILE_SUCCESS));

    if (!data.success) {
      yield put(setUserError(data.error));
    } else {
      yield put(setProfile(data));
    }
  } catch (error) {
    yield put(setLoading(Type.CREATE_UPDATE_PROFILE_ERROR));
  }
}

export function* createPostHelpFormSubmissionSaga({ payload }) {
  try {
    yield put(setLoading(Type.CREATE_POST_HELP_FORM_SUBMISSION_REQUEST));
    const { data } = yield call(postHelpFormSubmissionQuery, payload);
    yield put(setLoading(Type.CREATE_POST_HELP_FORM_SUBMISSION_SUCCESS));

    if (!data.success) {
      yield put(setUserError(data.error));
      yield put(setHelpFormSubmission({ isSuccessful: false }));
    } else {
      yield put(setHelpFormSubmission({ isSuccessful: true }));
    }
  } catch (error) {
    yield put(setLoading(Type.CREATE_POST_HELP_FORM_SUBMISSION_ERROR));
    yield put(setHelpFormSubmission({ isSuccessful: false }));
  }
}

// export function* changePasswordSaga({ payload }) {
//   try {
//     const { data } = yield call(changePasswordQuery, payload);
//     history.go(0);
//   } catch (error) {
//   }
// }
//
// export function* tfaSecretCreateSaga({ payload }) {
//   try {
//     const { data } = yield call(tfaSecretCreateQuery, payload);
//   } catch (error) {
//   }
// }

export function* getUserSettingsSaga({ payload }) {
  try {
    yield put(setLoading(Type.GET_USER_SETTING_REQUEST));
    const { data } = yield call(userSettings, payload);
    yield put(setLoading(Type.GET_USER_SETTING_SUCCESS));
    if (data) {
      yield put(setProfile({ ...data.user }));
      window.localStorage.setItem("user", JSON.stringify(data.user));
    } else {
      yield put(setProfile({ success: false }));
    }
  } catch (error) {
    yield put(setLoading(Type.GET_USER_SETTING_ERROR));
  }
}

export function* saveUserSettingsSaga({ payload }) {
  try {
    yield put(setLoading(Type.SAVE_USER_SETTING_REQUEST));
    yield put(setLoading(Type.SAVE_USER_SETTING_SUCCESS));
  } catch (error) {
    yield put(setLoading(Type.SAVE_USER_SETTING_ERROR));
  }
}

// export function* avatarUploadSaga({ payload }) {
//   try {
//     yield put(setLoading(true));
//     yield call(avatarUploadQuery, payload);
//     yield put(setLoading());
//
//   } catch (error) {
//     yield put(setLoading());
//   }
// }
//
// export function* avatarDeleteSaga({ payload }) {
//   try {
//     yield put(setLoading(true));
//     yield call(avatarDeleteQuery, payload);
//     yield put(setLoading());
//
//   } catch (error) {
//     yield put(setLoading());
//   }
// }

export default function* authManagerSaga() {
  yield all([takeLatest(login, loginSaga)]);
  yield all([takeLatest(forgotPassword, forgotPasswordSaga)]);
  yield all([takeLatest(resetPassword, resetPasswordSaga)]);
  yield all([takeLatest(createOnboard, createOnboardSaga)]);
  yield all([takeLatest(setInvitationRequest, setInvitationRequestSaga)]);
  yield all([takeLatest(updateProfile, createUpdateProfileSaga)]);
  yield all([
    takeLatest(postHelpFormSubmission, createPostHelpFormSubmissionSaga),
  ]);
  // yield all([takeLatest(changePassword, changePasswordSaga)]);
  yield all([takeLatest(getUserSettings, getUserSettingsSaga)]);
  yield all([takeLatest(saveUserSettings, saveUserSettingsSaga)]);
  // yield all([takeLatest(avatarUpload, avatarUploadSaga)]);
  // yield all([takeLatest(avatarDelete, avatarDeleteSaga)]);
}
