import { Box, Text, useBreakpointValue, VStack } from "@chakra-ui/react";
import { get } from "lodash";
import React, { useState } from "react";
import Pagination from "../common/Pagination";
import { ArticleCard } from "./ArticleCard";

export interface CommunicationListProps {
  committee: any;
  sort: string;
  data: never[];
}

export default function CommunicationList({
  committee,
  sort,
  data,
}: CommunicationListProps) {
  const pageSize = 8;
  const [currentPage, setCurrentPage] = useState<number>(1);

  const sortedData = data
    ? data.sort((c1, c2) => {
        const c1Date = c1.publishedDate;
        const c2Date = c2.publishedDate;
        const c1Title = c1.title;
        const c2Title = c2.title;
        if (sort === "0") {
          return (new Date(c1Date) > new Date(c2Date) && -1) || 1;
        } else if (sort === "1") {
          return (new Date(c1Date) > new Date(c2Date) && 1) || -1;
        } else if (sort === "2") {
          return (c1Title.toLowerCase() > c2Title.toLowerCase() && 1) || -1;
        } else if (sort === "3") {
          return (c1Title.toLowerCase() > c2Title.toLowerCase() && -1) || 1;
        } else {
          return 0;
        }
      })
    : [];

  const slicedData = sortedData
    ? sortedData.slice((currentPage - 1) * pageSize, currentPage * pageSize)
    : [];

  const showPaginationNav = useBreakpointValue({
    base: false,
    md: true,
  });

  return (
    <VStack spacing={3} w="100%" position="relative" alignItems="stretch">
      {slicedData?.length > 0 ? (
        slicedData.map((item: any, index: number) => (
          <ArticleCard
            content={item}
            key={index}
            image={committee.image}
            bgColor={committee.bgColor}
          />
        ))
      ) : (
        <Box
          fontFamily="montserrat"
          fontWeight="semibold"
          fontSize="20px"
          w="100%"
          textAlign="center"
          display="flex"
          alignItems="center"
          justifyContent="center"
          px="24px"
          py="64px"
        >
          <Text>
            {/* There are no current messaging <br />
              documents for this policy area. */}
          </Text>
        </Box>
      )}
      {/* mobile pagination navigation */}
      <Pagination
        total={data.length}
        pageSize={pageSize}
        useOuter={true}
        outerCurrentPage={currentPage}
        setOuterCurrentPage={setCurrentPage}
        compact
        display={{ base: "flex", md: "none" }}
        position="absolute"
        top="-60px"
      />

      {/* desktop pagination */}
      <Pagination
        mt="16px !important"
        showItemShown
        total={data.length}
        pageSize={pageSize}
        useOuter={true}
        outerCurrentPage={currentPage}
        setOuterCurrentPage={setCurrentPage}
        viewAllUrl={`${get(committee, "key", "")}/communications`}
        showNavigation={showPaginationNav}
      />
    </VStack>
  );
}
