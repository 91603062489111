import axios from "axios";
import { BACKEND_API_ENDPOINT } from "../../constants/default";

const getHeaders = () => {
  let h = {
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const token = window.localStorage
    ? window.localStorage.getItem("accessToken")
    : undefined;
  if (token) {
    h["Authorization"] = `Bearer ${token}`;
  }

  return h;
};

export const getMemberCompaniesQuery = () => {
  return axios
    .get(`${BACKEND_API_ENDPOINT}/company/member-companies`, {
      headers: getHeaders(),
    })
    .then((response) => {
      return response;
    });
};

export const getCompanyByIDQuery = (id) => {
  return axios
    .get(`${BACKEND_API_ENDPOINT}/company/${id}`, { headers: getHeaders() })
    .then((response) => {
      return response;
    });
};

export const getCurrentUserCompanyQuery = () => {
  return axios
    .get(`${BACKEND_API_ENDPOINT}/company`, { headers: getHeaders() })
    .then((response) => {
      return response;
    });
};
