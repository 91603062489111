import { Text } from "@chakra-ui/react";
import React from "react";

const FormLabel = ({ children, ...props }: { children: any; props: any }) => (
  <Text
    mb="8px"
    color="grey.950"
    fontSize="14px"
    lineHeight="17px"
    fontWeight={500}
    fontFamily="montserrat"
    {...props}
  >
    {children}
  </Text>
);

export default FormLabel;
