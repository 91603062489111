export const GET_EVENTS_REQUEST = "GET_EVENTS_REQUEST";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_ERROR = "GET_EVENTS_ERROR";

export const GET_EVENT_REQUEST = "GET_EVENT_REQUEST";
export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS";
export const GET_EVENT_ERROR = "GET_EVENT_ERROR";

export const GET_EVENT_LOCATIONS_REQUEST = 'GET_EVENT_LOCATIONS_REQUEST';
export const GET_EVENT_LOCATIONS_SUCCESS = 'GET_EVENT_LOCATIONS_SUCCESS';
export const GET_EVENT_LOCATIONS_ERROR = 'GET_EVENT_LOCATIONS_ERROR';
