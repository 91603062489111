import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../redux/ui/action";
import { getEventByCommitteeLocation } from "../services/event";

export function useEventByCommittee(committeeKey: string) {
  const dispatch = useDispatch();
  const [data, setData] = useState<any>({});

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLoading("GET_EVENT_BY_COMMITTEE_REQUEST"));
      try {
        const data = await getEventByCommitteeLocation(committeeKey);
        setData(data);
        dispatch(setLoading("GET_EVENT_BY_COMMITTEE_SUCCESS"));
      } catch (error) {
        dispatch(setLoading("GET_EVENT_BY_COMMITTEE_ERROR"));
      }
    };
    if (committeeKey) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [committeeKey]);
  return data;
}
