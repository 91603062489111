import axios from "axios";
import { BACKEND_API_ENDPOINT } from "../../constants/default";

const getHeaders = () => {
  let h = {
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const token = window.localStorage
    ? window.localStorage.getItem("accessToken")
    : undefined;
  if (token) {
    h["Authorization"] = `Bearer ${token}`;
  }

  return h;
};

export const setCommittees = (payload) => {
  return axios
    .post(`${BACKEND_API_ENDPOINT}/user/committees`, payload, {
      headers: getHeaders(),
    })
    .then((response) => {
      return response;
    });
};

export const setMembership = (payload) => {
  return axios
    .post(`${BACKEND_API_ENDPOINT}/committee/membership`, payload, {
      headers: getHeaders(),
    })
    .then((response) => {
      return response;
    });
};

export const getCommittees = () => {
  return axios
    .get(`${BACKEND_API_ENDPOINT}/committee/list`, { headers: getHeaders() })
    .then((response) => {
      return response;
    });
};

export const getSubCommittees = (id) => {
  return axios
    .get(`${BACKEND_API_ENDPOINT}/committee/${id}?type=sub`, {
      headers: getHeaders(),
    })
    .then((response) => {
      return response;
    });
};

export const getCommittee = ({ id, type }) => {
  return axios
    .get(
      `${BACKEND_API_ENDPOINT}/committee/${id}${type ? "?type=" + type : ""}`,
      { headers: getHeaders() }
    )
    .then((response) => {
      return response;
    });
};

export const getCommitteeMembers = (id) => {
  return axios
    .get(
      `${BACKEND_API_ENDPOINT}/committee/${id}/members`,
      { headers: getHeaders() }
    )
    .then((response) => {
      return response;
    });
};

export const getTags = () => {
  return axios
    .get(`${BACKEND_API_ENDPOINT}/tags`, { headers: getHeaders() })
    .then((response) => {
      return response;
    });
};

export const getSearch = (query) => {
  return axios
    .get(`${BACKEND_API_ENDPOINT}/v2/search?q=${query}`, {
      headers: getHeaders(),
    })
    .then((response) => {
      return response;
    });
};

export const getUpdates = (query) => {
  return axios
    .get(`${BACKEND_API_ENDPOINT}/updates`, { headers: getHeaders() })
    .then((response) => {
      return response;
    });
};

export const updateCommitteeMemberships = ({id, payload}) => {
  return axios
    .post(`${BACKEND_API_ENDPOINT}/committee/membership/${id}`, payload, {
      headers: getHeaders(),
    })
    .then((response) => {
      return response;
    });
};
