import {
  Avatar,
  Box,
  Button,
  Divider,
  Fade,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
  Stack,
  Text,
  useMediaQuery,
  VStack,
  Wrap,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { BiCheck } from "react-icons/bi";
import { MdArrowDropDown } from "react-icons/md";
import { useDispatch } from "react-redux";
import CommitteeModal from "../components/Committees/CommitteeModal";
import HeadMeta from "../components/common/HeadMeta";
import PrimaryButton from "../components/common/PrimaryButton";
import SecondaryButton from "../components/common/SecondaryButton";
import SectionHeading from "../components/common/SectionHeading";
import TagItem from "../components/common/TagItem";
import SectionHeader from "../components/Home/SectionHeader";
import Layout from "../components/Layout";
import TwoColLayout from "../components/Layout/TwoColLayout";
import { NETWORKS } from "../constants/default";
import routes from "../constants/routes.json";
import { setLoading } from "../redux/ui/action";
import { updateProfile } from "../services/auth";
import {
  getCommittees,
  getTags,
  setCommittees as setCommitteesAPI,
} from "../services/committee";
import "../styles/profileInformationCustomStyling.css";

export type FieldProps = {
  title: string;
  phone: string;
  username: string;
  location: string;
  socialMedia: any;
};

const initialNetwork = { network: "", username: "" };

const Label = ({ children }: { children: any }) => (
  <FormLabel
    fontSize="14px"
    fontWeight={600}
    color="grey.950"
    fontFamily="montserrat"
  >
    {children}
  </FormLabel>
);

const ProfilePicture = ({ image, firstName, lastName, size }) => {
  if (image) {
    return (
      <Avatar boxSize={`${size}px`} src={`data:image/png;base64,${image}`} />
    );
  }

  return (
    <div
      style={{
        width: size,
        height: size,
        borderRadius: "50%",
        backgroundColor: "var(--chakra-colors-grey-200)",
        fontSize: `calc(${size}px / 4)`,
        color: "var(--chakra-colors-white)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {firstName ? firstName[0].toUpperCase() : ""}
      {lastName ? lastName[0].toUpperCase() : ""}
    </div>
  );
};

function ProfileInformation() {
  const [areasOfInterest, setAreasOfInterest] = useState<any>({});
  const [originalAreasOfInterest, setOriginalAreasOfInterest] =
    useState<any>(null);
  const [fields, setFields] = useState<FieldProps>({});
  const [originalFields, setOriginalFields] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [sortOption, setSortOption] = useState("0");
  const [profile, setProfile] = useState<any>({});
  const [committees, setCommittees] = useState<any>([]);
  const [tags, setTags] = useState<any>([]);
  const [isSM] = useMediaQuery("(min-width: 480px)");
  const [isLoading, setIsLoading] = useState<boolean | undefined>(undefined);
  const [isSavedButtonShow, setSavedButtonShow] = useState(false);
  const dispatch = useDispatch();

  const handleChangeAreasOfInterest = (key: string) => {
    setAreasOfInterest({ ...areasOfInterest, [key]: !areasOfInterest[key] });
  };

  const onChange = (field: string) => (e: any) => {
    setFields({ ...fields, [field]: e.target.value });
  };

  const onChangeLocation = (location: string) => {
    setFields({ ...fields, location });
  };

  const onChangeNetwork = (key: string, index: any) => (e: any) => {
    const socialMedia = [...fields.socialMedia];
    socialMedia[index][key] = e.target.value;
    setFields({ ...fields, socialMedia });
  };

  const onAddNetwork = () => {
    const socialMedia = [...fields.socialMedia];
    socialMedia.push({ ...initialNetwork });
    setFields({ ...fields, socialMedia });
  };

  const onRemoveNetwork = (indexToRemove: number) => {
    const socialMedia = [...fields.socialMedia];
    setFields({
      ...fields,
      socialMedia: socialMedia.filter(
        (social, index) => index !== indexToRemove
      ),
    });
  };

  const getNetworks = (value: string) => {
    const selected = fields.socialMedia
      ? fields.socialMedia.map((item: any) => item.network)
      : [];
    const networks = NETWORKS.filter(
      (item: any) => !selected.includes(item.name) || item.name === value
    );
    return networks;
  };

  const handleApplyCommittees = async (values: any) => {
    const data = await setCommitteesAPI({ committees: values });
    const profile = data.data.user;
    setProfile(profile);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const categories = Object.keys(tags)
      .filter((key: string) => areasOfInterest[tags[key].name])
      .map((key: string) => tags[key].name);
    const payload = {
      ...fields,
      areasOfInterest: categories,
      socialMedia: fields.socialMedia.filter(
        (item: any) => item.network && item.username
      ),
    };

    try {
      setIsLoading(true);
      await updateProfile(payload);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLoading("EDIT_PROFILE_DATA_REQUEST"));
      try {
        const committees = await getCommittees();
        const tags = await getTags();

        const sortedCommittees = committees.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setCommittees(sortedCommittees);
        setTags(tags);
        dispatch(setLoading("EDIT_PROFILE_DATA_SUCCESS"));
      } catch (error) {
        dispatch(setLoading("EDIT_PROFILE_DATA_SUCCESS"));
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (profile) {
      setFields({
        ...fields,
        title: profile.title,
        phone: profile.phone,
        username: profile.username,
        socialMedia:
          profile.socialMedia && profile.socialMedia.length > 0
            ? profile.socialMedia
            : [{ ...initialNetwork }],
        location: profile.location,
      });

      setOriginalFields({
        ...originalFields,
        title: profile.title,
        phone: profile.phone,
        username: profile.username,
        socialMedia:
          profile.socialMedia && profile.socialMedia.length > 0
            ? profile.socialMedia
            : [{ ...initialNetwork }],
        location: profile.location,
      });

      const areas = {};

      profile.areasOfInterest &&
        profile.areasOfInterest.forEach((item: any) => {
          const exist = Object.keys(tags).find((key: string) => {
            return tags[key].name === item.name ? item.name : null;
          });

          if (exist) {
            areas[item.name] = true;
          }
        });

      setAreasOfInterest({ ...areas });
      setOriginalAreasOfInterest({ ...areas });
    }
  }, [profile, tags]);

  const sortedTags = Object.keys(tags)
    .filter((key) => key !== "success")
    .sort((a, b) =>
      sortOption === "0"
        ? (tags[a].name.toLowerCase() > tags[b].name.toLowerCase() && 1) || -1
        : (tags[a].name.toLowerCase() < tags[b].name.toLowerCase() && 1) || -1
    );

  const isSocialMediaDisabled = (currentSocial) => {
    let hasMatch = false;
    if (!currentSocial.username) return hasMatch;

    if (originalFields && originalFields.socialMedia) {
      originalFields.socialMedia.forEach((originalSocial) => {
        if (
          originalSocial.username === currentSocial.username &&
          originalSocial.network === currentSocial.network
        ) {
          hasMatch = true;
        }
      });
    }

    return hasMatch;
  };

  const isSaveButtonDisabled = useCallback(() => {
    let isDifferent = false;
    Object.keys(fields).forEach((key) => {
      if (!(key in originalFields && fields[key] === originalFields[key])) {
        isDifferent = true;
      }
    });

    Object.keys(areasOfInterest).forEach((key) => {
      if (
        areasOfInterest[key] !==
        (key in originalAreasOfInterest && originalAreasOfInterest[key])
      ) {
        isDifferent = true;
      }
    });

    return !isDifferent;
  }, [areasOfInterest, fields, originalAreasOfInterest, originalFields]);

  useEffect(() => {
    Object.keys(fields).forEach((key) => {
      if (!(key in originalFields && fields[key] === originalFields[key])) {
        setIsLoading(undefined);
        setSavedButtonShow(false);
      }
    });
  }, [fields, originalFields]);

  useEffect(() => {
    if (isLoading === false) {
      setSavedButtonShow(true);
    } else if (isLoading === undefined) {
      setSavedButtonShow(false);
    }
  }, [isLoading]);

  const AreasOfInterestSection = ({ size = "large" }: { size: string }) => {
    return (
      <>
        <HStack>
          <SectionHeading pt="6px" pl="0px" pb="10px">
            Areas of Interest
          </SectionHeading>
          {size === "large" && (
            <HStack display={{ base: "none", sm: "flex" }}>
              <Text
                mr="16px"
                color="grey.500"
                fontSize="14"
                fontFamily="opensans"
                whiteSpace="nowrap"
                display={{ base: "none", md: "flex" }}
              >
                Sort by
              </Text>
              <Select
                icon={<MdArrowDropDown />}
                bg="white"
                border="none"
                w="140px"
                defaultValue="0"
                value={sortOption}
                onChange={(e) => setSortOption(e.target.value)}
              >
                <option value="0">A to Z</option>
                <option value="1">Z to A</option>
              </Select>
            </HStack>
          )}
        </HStack>
        <Wrap pb={{ base: "50px", xl: "0px" }} spacing="16px">
          {sortedTags &&
            sortedTags.map((key: string) => (
              <TagItem
                key={key}
                item={tags[key]}
                selected={areasOfInterest[tags[key].name]}
                size={size}
                onToggle={handleChangeAreasOfInterest}
              />
            ))}
        </Wrap>
      </>
    );
  };

  return (
    <Layout
      path={[
        { title: "Member Center", url: routes.MEMBER_CENTER },
        { title: "Profile Information" },
      ]}
    >
      <HeadMeta title="BRT Member Portal | Profile Information" />

      <TwoColLayout>
        <form onSubmit={handleSubmit}>
          <Stack
            direction={{ base: "column", md: "row" }}
            w="100%"
            spacing="0px"
            align="stretch"
          >
            {/* <VStack
            pr="30px"
            w="190px"
            pt="60px"
            align="flex-start"
            display={{ base: "none", md: "flex" }}
          >
            <ProfilePicture
              image={profile.profilePicture}
              firstName={profile.firstName}
              lastName={profile.lastName}
              size={156}
            />
          </VStack> */}

            <VStack flex="1" align="flex-start" spacing={6}>
              <HStack
                w="100%"
                alignItems="flex-start"
                justifyContent="space-between"
                spacing={0}
              >
                <SectionHeader title=" Profile Information" mb={0} />
              </HStack>

              <HStack>
                {/* <VStack
                  pr={{ base: "8px", sm: "30px" }}
                  pt={{ base: 0, md: "60px" }}
                  align="flex-start"
                  display={{ base: "flex", md: "none" }}
                >
                  <ProfilePicture
                    image={profile.profilePicture}
                    firstName={profile.firstName}
                    lastName={profile.lastName}
                    size={isSM ? 100 : 60}
                  />
                </VStack> */}
                <VStack alignItems="flex-start" ml={{ md: "0 !important" }}>
                  <Text
                    fontSize={{ base: "18px", sm: "20px", md: "20px" }}
                    lineHeight={{ base: "20px", sm: "32px" }}
                    fontWeight="600"
                    fontFamily="montserrat"
                    color="grey.950"
                  >
                    {profile.firstName} {profile.lastName}
                  </Text>
                  <Text
                    fontSize={{ base: "14px", sm: "16px" }}
                    lineHeight={{ base: "20px", sm: "26px" }}
                    fontWeight="400"
                    fontFamily="montserrat"
                  >
                    {profile.email}
                  </Text>
                </VStack>
              </HStack>
              <Divider
                h="12px"
                orientation="horizontal"
                borderColor="grey.200"
              />
              {/* {profile?.userType && profile?.userType.toLowerCase() === "ceo" && ( */}
              {/* <VStack
                pl="0"
                w="100%"
                align="flex-start"
                display={{ base: "flex", xl: "none" }}
                pt="4"
              >
                <HStack justifyContent="space-between" w="100%">
                  <SectionHeading pt="0px" pl="0px" pb="14px" w="100%">
                    Committees
                  </SectionHeading>
                  <IconButton
                    variant="link"
                    bg="transparent"
                    icon={<MdEdit size="22" />}
                    color="primary.500"
                    aria-label="Edit"
                    onClick={() => setShowModal(true)}
                  />
                </HStack>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {profile &&
                    profile.committees &&
                    profile.committees
                      .filter((item: any) => item.type === "committee")
                      .map((item: any, index: any) => (
                        <Button
                          key={index}
                          size="sm"
                          bg="grey.80"
                          color={"grey.700"}
                          m="0 10px 10px 0"
                          filter="none"
                          fontFamily="opensans"
                          textTransform="none"
                          fontWeight={600}
                          fontSize="10px"
                          lineHeight="10px"
                          h="34px"
                          borderRadius="17px"
                          p="11px 25px"
                          style={{
                            cursor: "default",
                            background: "var(--chakra-colors-grey-80)",
                          }}
                        >
                          {item.name}
                        </Button>
                      ))}
                </div>
              </VStack> */}
              {/* )} */}
              {/* {profile?.userType && profile?.userType.toLowerCase() === "staff" && (
              <VStack
                pl="0"
                w="100%"
                align="space-between"
                display={{ base: "flex", xl: "none" }}
                pt="4"
              >
                <AreasOfInterestSection size="small" />
              </VStack>
            )} */}

              <VStack spacing={6} w="100%" align="stretch">
                <FormControl id="title">
                  <Label
                    fontSize="14px"
                    fontWeight={600}
                    color="grey.950"
                    fontFamily="montserrat"
                  >
                    Title
                  </Label>
                  <Input
                    placeholder="Executive Assistant"
                    size="lg"
                    w="100%"
                    h="56px"
                    variant="outline"
                    bg="white"
                    borderRadius="4px"
                    borderColor="grey.80"
                    color="grey.950"
                    _placeholder={{ color: "grey.200" }}
                    _focus={{ borderColor: "grey.200" }}
                    value={fields.title || ""}
                    onChange={onChange("title")}
                    isRequired
                  />
                </FormControl>
                <FormControl id="phone">
                  <Label
                    fontSize="14px"
                    fontWeight={600}
                    color="grey.950"
                    fontFamily="montserrat"
                  >
                    Business Phone Number
                  </Label>
                  <Input
                    placeholder="(555) 555-5555"
                    size="lg"
                    w="100%"
                    h="56px"
                    variant="outline"
                    bg="white"
                    borderRadius="4px"
                    borderColor="grey.80"
                    value={fields.phone || ""}
                    color="grey.950"
                    _placeholder={{ color: "grey.200" }}
                    _focus={{ borderColor: "grey.200" }}
                    onChange={onChange("phone")}
                  />
                </FormControl>

                {/* <Divider
                  h="12px"
                  orientation="horizontal"
                  borderColor="grey.200"
                />
                <SectionHeading pt="6px" pl="0px" pb="4px" fontWeight={600}>
                  Office Information
                </SectionHeading>
                <FormControl id="location">
                  <Label
                    fontSize="14px"
                    fontWeight={600}
                    color="grey.950"
                    fontFamily="montserrat"
                  >
                    Work Location
                  </Label>
                  <RadioGroup
                    value={fields.location}
                    onChange={onChangeLocation}
                    pt="8px"
                  >
                    <Stack direction={{ base: "column", md: "row" }}>
                      {LOCATIONS.map((item: any) => (
                        <Radio
                          key={item.id}
                          size="lg"
                          pr={{ base: "0", md: "16px" }}
                          pb={{ base: "20px", md: "0" }}
                          value={item.name}
                        >
                          <Text
                            fontSize="14px"
                            fontWeight={400}
                            lineHeight="24px"
                            color="grey.950"
                          >
                            {item.title}
                          </Text>
                        </Radio>
                      ))}
                    </Stack>
                  </RadioGroup>
                </FormControl> */}

                {/* <SectionHeading pt="6px" pl="0px" pb="4px">
                Social Media
              </SectionHeading>
              {fields.socialMedia &&
                fields.socialMedia.map((item: any, index: any) => (
                  <Stack
                    key={index}
                    direction="row"
                    spacing={{ base: "8px", sm: "24px" }}
                    w="100%"
                  >
                    <FormControl id="network">
                      <Label
                        fontSize="14px"
                        fontWeight={600}
                        color="grey.950"
                        fontFamily="montserrat"
                      >
                        Network
                      </Label>
                      <Select
                        icon={<MdArrowDropDown />}
                        placeholder="Select"
                        size="md"
                        w="100%"
                        h="56px"
                        variant="filled"
                        bg="white"
                        borderRadius="4px"
                        borderColor="grey.200"
                        color="grey.950"
                        _focus={{ borderColor: "grey.200", bg: "white" }}
                        value={item.network}
                        onChange={onChangeNetwork("network", index)}
                        disabled={isSocialMediaDisabled(item)}
                        isRequired
                      >
                        {getNetworks(item.network).map((item: any) => (
                          <option key={item.id} value={item.name}>
                            {item.title}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl id="username">
                      <Label
                        fontSize="14px"
                        fontWeight={600}
                        color="grey.950"
                        fontFamily="montserrat"
                      >
                        Username
                      </Label>
                      <Input
                        placeholder=""
                        size="md"
                        w="100%"
                        h="56px"
                        variant="filled"
                        bg="white"
                        borderRadius="4px"
                        borderColor="grey.200"
                        color="grey.950"
                        _placeholder={{ color: "grey.200" }}
                        _focus={{ borderColor: "grey.200", bg: "white" }}
                        value={item.username || ""}
                        onChange={onChangeNetwork("username", index)}
                        disabled={isSocialMediaDisabled(item)}
                        isRequired
                      />
                    </FormControl>
                    <div className="delete-button">
                      <IconButton
                        variant="link"
                        bg="transparent"
                        icon={<MdDelete size="22" />}
                        color="primary.500"
                        aria-label="Delete"
                        onClick={() => onRemoveNetwork(index)}
                      />
                    </div>
                  </Stack>
                ))} */}

                {/* <HStack pt={{ base: "24px", md: "12px" }}>
                <IconButton
                  variant="link"
                  bg="transparent"
                  icon={<BsPlusCircleFill size="20px" />}
                  color="primary.500"
                  aria-label="Add Network"
                  onClick={onAddNetwork}
                  minW={{ base: "30px", md: "12" }}
                />
                <Text
                  fontSize="14px"
                  lineHeight="14px"
                  fontWeight="400"
                  fontFamily="montserrat"
                  color="primary.500"
                  decoration="none"
                  cursor="pointer"
                  onClick={onAddNetwork}
                >
                  Add Network
                </Text>
              </HStack> */}

                {/* {profile?.userType && profile?.userType.toLowerCase() === "ceo" && (
                <>
                  <Divider
                    h="12px"
                    orientation="horizontal"
                    borderColor="grey.200"
                  />
                  <AreasOfInterestSection />
                </>
              )} */}

                <HStack mt={{ base: "56px !important" }}>
                  <Fade in={!isSavedButtonShow}>
                    <PrimaryButton
                      isLoading={isLoading}
                      colorScheme="primary"
                      mr="10px"
                      type="submit"
                      disabled={isSaveButtonDisabled()}
                      filter="none"
                      display={isSavedButtonShow ? "none" : "flex"}
                    >
                      SAVE
                    </PrimaryButton>
                  </Fade>

                  <Fade
                    in={isSavedButtonShow}
                    style={{ marginInlineStart: "unset" }}
                  >
                    <Button
                      mr="10px"
                      ml="0"
                      bg="green.500"
                      type="submit"
                      filter="none"
                      display={isSavedButtonShow ? "flex" : "none"}
                      disabled
                      color="#fff !important"
                      _hover={{ background: "green.500" }}
                      leftIcon={<BiCheck size={28} />}
                      alignItems="center"
                      justifyContent="center"
                    >
                      CHANGES SAVED
                    </Button>
                  </Fade>

                  <SecondaryButton
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    CANCEL
                  </SecondaryButton>
                </HStack>
              </VStack>
            </VStack>

            {/* {profile?.userType && profile?.userType.toLowerCase() === "ceo" && (
            <VStack
              pl="60px"
              w="25%"
              align="flex-start"
              display={{ base: "none", xl: "flex" }}
            >
              <SectionHeading pt="0px" pl="0px" pb="14px">
                Committees
                <IconButton
                  variant="link"
                  bg="transparent"
                  icon={<MdEdit size="22" />}
                  color="primary.500"
                  aria-label="Edit"
                  onClick={() => setShowModal(true)}
                />
              </SectionHeading>

              {profile &&
                profile.committees &&
                profile.committees
                  .filter((item: any) => item.type === "committee")
                  .map((item: any, index: any) => (
                    <Button
                      key={index}
                      size="sm"
                      bg="grey.80"
                      color={"grey.700"}
                      mb="10px"
                      filter="none"
                      fontFamily="opensans"
                      textTransform="none"
                      fontWeight={600}
                      fontSize="10px"
                      lineHeight="10px"
                      h="34px"
                      borderRadius="17px"
                      p="11px 25px"
                      style={{
                        cursor: "default",
                        background: "var(--chakra-colors-grey-80)",
                      }}
                    >
                      {item.name}
                    </Button>
                  ))}
            </VStack>
          )} */}
            {/* {profile?.userType && profile?.userType.toLowerCase() === "staff" && (
            <VStack
              pl="60px"
              w="45%"
              align="flex-start"
              display={{ base: "none", xl: "flex" }}
            >
              <AreasOfInterestSection size="small" />
              <HStack pt="24px">
                <Button
                  colorScheme="primary"
                  mr="10px"
                  type="submit"
                  disabled={isSaveButtonDisabled()}
                >
                  Save
                </Button>
              </HStack>
            </VStack>
          )} */}
          </Stack>
        </form>

        <Box></Box>
      </TwoColLayout>
      <CommitteeModal
        onClose={() => setShowModal(false)}
        onApply={handleApplyCommittees}
        show={showModal}
        profileAreas={profile.areasOfInterest}
        profileCommittees={profile.committees}
        tags={tags}
        committees={committees}
      />
    </Layout>
  );
}

export default ProfileInformation;
