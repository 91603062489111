import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { HStack, VStack, Text, Box } from "@chakra-ui/react";
import Layout from "../components/Layout";
import HeadMeta from "../components/common/HeadMeta";
import { getMessageById } from "../services/messages";
import DownloadIcon from "../components/Icons/Download";
import { useDispatch } from "react-redux";
import { setLoading } from "../redux/ui/action";
import "../styles/brtInbox.css";

function BRTInboxDetail() {
  const params: any = useParams();
  const dispatch = useDispatch();
  const [message, setMessage] = useState<any>({});

  useEffect(() => {
    if (params.id) {
      const fetchData = async () => {
        dispatch(setLoading("GET_MESSAGE_REQUEST"));
        try {
          const message = await getMessageById({ id: params.id });
          setMessage(message);
          dispatch(setLoading("GET_MESSAGE_SUCCESS"));
        } catch (error) {
          dispatch(setLoading("GET_MESSAGE_ERROR"));
        }
      };

      fetchData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const getAttachment = (attachment: string) => {
    const arr = attachment.split("/");
    const index = arr.length - 1;
    const attachmentInfo = arr[index];
    const fileInfoArr = attachmentInfo.split(".");
    const fileExtensionIndex = fileInfoArr.length - 1;
    const fileExtension = fileInfoArr[fileExtensionIndex];
    const fileName = fileInfoArr.slice(0, fileExtensionIndex).join(".");

    let fileType = "";
    if (fileExtension === "docx" || fileType === "doc") {
      fileType = "Microsoft Word";
    } else if (fileExtension === "ppt") {
      fileType = "Microsoft Powerpoint";
    } else {
      fileType = "PDF";
    }

    return (
      <HStack bg="white" borderRadius="17px" p="15px 24px">
        <VStack alignItems="flex-start" mr="75px">
          <Text
            fontSize="16px"
            fontWeight="600"
            as="h4"
            pb="8px"
            lineHeight="21px"
            color="#2D9CDB"
            fontFamily="montserrat"
          >
            {fileName}
          </Text>
          <Text
            fontSize="16px"
            fontWeight="400"
            as="h5"
            lineHeight="24px"
            color="#202020"
            fontFamily="opensans"
          >
            {fileType}
          </Text>
        </VStack>
        <VStack cursor="pointer" onClick={() => downloadFile(attachment)}>
          <DownloadIcon color="#0D7DBD" />
        </VStack>
      </HStack>
    );
  };

  const downloadFile = (path: string) => {
    const filePath = path.includes("https://") ? path : "https://" + path;
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";

    a.href = filePath;
    a.click();
    window.URL.revokeObjectURL(filePath);
  };

  let messageBody =
    message && message.body
      ? /<div[^>]*>[\s\S]*<\/div>/gi.exec(message.body)
      : "";
  const attachments = message && message.attachments ? message.attachments : [];

  return (
    <Layout
      path={[
        {
          title: "BRT Inbox",
          url: "/brt-inbox",
        },
        { title: "Update" },
      ]}
    >
      <HeadMeta
        title={`BRT Member Portal | ${
          message && message.title ? message.title : "Priority Issue Updates"
        }`}
      />

      <HStack w="100%" spacing="48px" align="flex-start">
        <VStack w={{ base: "60%", xl: "76%" }} flex="1" align="flex-start">
          <Text
            fontSize="36px"
            fontWeight="600"
            as="h2"
            pt="8px"
            lineHeight="44px"
            color="grey.950"
            fontFamily="montserrat"
          >
            {message && message.title}
          </Text>
          <Box
            fontWeight="400"
            pt="0"
            pb="32px"
            lineHeight="22px"
            color="grey.950"
            fontFamily="opensans"
          >
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: messageBody }}
            ></div>
          </Box>
          {attachments.length > 0 && (
            <VStack pt="48px" alignItems="flex-start">
              <Text
                fontSize="20px"
                fontWeight="600"
                as="h2"
                pb="16px"
                lineHeight="24px"
                color="#202020"
                fontFamily="montserrat"
              >
                Attachments
              </Text>
              {attachments.map((attachment, index) => (
                <VStack key={index}>{getAttachment(attachment)}</VStack>
              ))}
            </VStack>
          )}
        </VStack>
      </HStack>
    </Layout>
  );
}

export default BRTInboxDetail;
