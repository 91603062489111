import { createAction } from "redux-actions";

export const login = createAction("LOGIN");
export const logoutUser = createAction("LOGOUT_USER");
export const forgotPassword = createAction("FORGOT_PASSWORD");
export const resetPassword = createAction("RESET_PASSWORD");
export const setInvitation = createAction("SET_INVITATION");
export const setInvitationRequest = createAction("SET_INVITATION_REQUEST");
export const createOnboard = createAction("CREATE_ONBOARD");
export const addOnboardUser = createAction("ADD_ONBOARD_USER");
export const changePassword = createAction("CHANGE_PASSWORD");
export const setUserError = createAction("SET_USER_ERROR");
export const getUserSettings = createAction("GET_USER_SETTINGS");
export const setUser = createAction("SET_USER");
export const setProfile = createAction("SET_PROFILE");
export const saveUserSettings = createAction("SAVE_USER_SETTINGS");
export const avatarUpload = createAction("AVATAR_UPLOAD");
export const avatarDelete = createAction("AVATAR_DELETE");
export const updateProfile = createAction("UPDATE_PROFILE");
export const postHelpFormSubmission = createAction("POST_HELP_FORM_SUBMISSION");
export const setHelpFormSubmission = createAction("SET_HELP_FORM_SUBMISSION");
export const setViewAsCompany = createAction("SET_VIEW_AS_COMPANY");
