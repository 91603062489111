import { VStack, Box } from "@chakra-ui/react";
import dayjs from "dayjs";
import React from "react";
import AntitrustAndLegalAdvisory from '../components/BRTInbox/AntitrustAndLegalAdvisory';
import GovernmentRelationsSection from "../components/BRTInbox/GovernmentRelationsSection";
import LeadershipCommunicationsSection from "../components/BRTInbox/LeadershipCommunicationsSection";
import PriorityIssuesUpdateSection from "../components/BRTInbox/PriorityIssuesUpdateSection";
import HeadMeta from "../components/common/HeadMeta";
import ViewAllStaffButton from "../components/common/ViewAllStaffButton";
import SectionHeader from "../components/Home/SectionHeader";
import Layout from "../components/Layout";
import TwoColLayout from "../components/Layout/TwoColLayout";

var dayjsUTC = require("dayjs/plugin/utc");
var dayjsTimezone = require("dayjs/plugin/timezone");
var dayjsAdvancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(dayjsUTC);
dayjs.extend(dayjsTimezone);
dayjs.extend(dayjsAdvancedFormat);

const BRTInbox = () => {
  return (
    <Layout path={[{ title: "BRT Inbox" }]}>
      <HeadMeta title="BRT Inbox" />
      <TwoColLayout>
        <VStack flex="1" w="100%" spacing={10}>
          {/* header */}
          <SectionHeader
            title="BRT Inbox"
            subTitle="Explore email communications from BRT Chair Chuck Robbins and BRT staff leads. Priority Issues Update newsletters as well as Government Relations and Antitrust & Legal Advocacy emails are accessible on this dedicated page."
            mb="0"
          />

          <Box display={{ base: "block", lg: "none" }} w="100%">
            <ViewAllStaffButton />
          </Box>

          {/* Sections */}
          <Box mt={{ base: "40px", md: "36px !important" }} w="100%">
            <PriorityIssuesUpdateSection />
          </Box>
          <LeadershipCommunicationsSection />
          <GovernmentRelationsSection />
          <AntitrustAndLegalAdvisory />
        </VStack>

        {/* Staff leads */}
        <Box display={{ base: "none", lg: "block" }}>
          <ViewAllStaffButton />
        </Box>
      </TwoColLayout>
    </Layout>
  );
};

export default BRTInbox;
