import { Box, Flex, HStack, Stack, Text, VStack } from "@chakra-ui/react";
import dayjs from "dayjs";
import React from "react";
import { BiCalendarCheck } from "react-icons/bi";
import { cardShadowHover } from "../styles/theme";
import PrimaryButton from "./common/PrimaryButton";
import { LinkedEventCard } from "./EventAgenda/EventCard";

var dayjsUTC = require("dayjs/plugin/utc");
var dayjsTimezone = require("dayjs/plugin/timezone");
var dayjsAdvancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(dayjsUTC);
dayjs.extend(dayjsTimezone);
dayjs.extend(dayjsAdvancedFormat);

export type updateProps = {
  id: number;
  category: string;
  date: Date;
  title: string;
  url: string;
};

const EventIssueCallCard = ({
  data,
  internal,
}: {
  data: updateProps;
  internal?: boolean;
}) => {
  return (
    <LinkedEventCard linkId={data.email}>
      <Stack
        bg="white"
        overflow="hidden"
        borderRadius="24px"
        _hover={{ filter: data.email ? cardShadowHover : "unset" }}
        transition="0.3s all"
        spacing={0}
        w="100%"
      >
        <Flex
          h={{ base: "60px", md: "96px" }}
          w="100%"
          bg={data?.committee?.bgColor || "#DDDDDD"}
          backgroundImage={
            data?.committee?.image || "/images/committees/br-badge.svg"
          }
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          backgroundSize="auto 32px"
          justify="center"
          align="center"
        ></Flex>

        <VStack
          alignItems="flex-start"
          p={{ base: "16px 20px", md: "20px 24px" }}
          flex={1}
          justifyContent="space-between"
        >
          <VStack alignItems="flex-start">
            <HStack
              justifyContent="space-between"
              w="100%"
              alignItems="flex-start"
            >
              <VStack alignItems="flex-start" spacing="12px">
                <VStack alignItems="flex-start" spacing="4px">
                  <Text
                    fontSize="14px"
                    lineHeight="17px"
                    fontWeight="700"
                    fontFamily="montserrat"
                    color="grey.950"
                    textTransform="uppercase"
                  >
                    {data?.committee?.name || "Event"}
                  </Text>

                  <Text
                    as="div"
                    fontSize="12px"
                    lineHeight="16px"
                    fontWeight="400"
                    fontFamily="opensans"
                    color="grey.700"
                  >
                    Attendee type: {data.meetingLevel || "CEO"}
                  </Text>

                  <Text
                    as="div"
                    fontSize="12px"
                    lineHeight="16px"
                    fontWeight="400"
                    fontFamily="opensans"
                    color="grey.700"
                  >
                    {dayjs(data.eventStartDate)
                      .tz("America/New_York")
                      .format("MMM D, YYYY")}
                  </Text>
                </VStack>

                <Box>
                  <Text
                    fontSize="16px"
                    lineHeight="21px"
                    fontWeight="500"
                    fontFamily="montserrat"
                    color="grey.930"
                  >
                    {data.name}
                  </Text>
                </Box>
                <Text
                  as="div"
                  fontSize="12px"
                  lineHeight="16px"
                  fontWeight="400"
                  fontFamily="opensans"
                  color="grey.700"
                >
                  Meeting type: {data?.meetingType}
                </Text>
              </VStack>
            </HStack>
          </VStack>

          {(data.eventUrl || data.memberStaff) && (
            <VStack spacing={0}>
              {data.eventUrl && (
                <HStack w="100%" pt="18px" spacing={0}>
                  <a
                    href={data.eventUrl}
                    onClick={(e) => e.stopPropagation()}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <>
                      <PrimaryButton
                        leftIcon={<BiCalendarCheck size="20px" />}
                        mr="10px"
                      >
                        RSVP
                      </PrimaryButton>
                      {/* <IconButton
                            variant="flat"
                            isRound
                            icon={<BiCalendarPlus size="20px"/>}
                            bg="grey.50"
                            color="primary.400"
                            aria-label="RSVP"
                          /> */}
                    </>
                  </a>
                </HStack>
              )}
              {data.memberStaff && (
                <HStack w="100%" pt="10px">
                  <Text
                    fontSize="10px"
                    lineHeight="14px"
                    fontWeight="400"
                    fontFamily="opensans"
                    color="grey.700"
                    fontStyle="italic"
                  >
                    This event is open to {data.memberStaff}
                  </Text>
                </HStack>
              )}
            </VStack>
          )}
        </VStack>
      </Stack>
    </LinkedEventCard>
  );
};

export default EventIssueCallCard;
