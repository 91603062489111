import React from 'react';

const Events = ({color}: {color: string}) => {
   return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path
            d="M16.8746 10H6.94884V12H16.8746V10ZM18.8598 3H17.8672V1H15.8821V3H7.94142V1H5.95626V3H4.96368C3.86191 3 2.98844 3.9 2.98844 5L2.97852 19C2.97852 20.1 3.86191 21 4.96368 21H18.8598C19.9516 21 20.845 20.1 20.845 19V5C20.845 3.9 19.9516 3 18.8598 3ZM18.8598 19H4.96368V8H18.8598V19ZM13.8969 14H6.94884V16H13.8969V14Z"
            fill={color}
         />
      </svg>
   );
};

export default Events;
