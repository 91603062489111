import React from "react";
import { Link } from "react-router-dom";
import { Text, HStack, IconButton, Divider, Select } from "@chakra-ui/react";
import { FaThLarge, FaList } from "react-icons/fa";

export type Props = {
  pt?: any;
  pb?: any;
  pl?: any;
  pr?: any;
  children?: any;
  filter?: boolean;
  viewAllUrl?: string;
  fontSize?: string;
  fontWeight?: string | number;
  display?: any;
  w?: any;
  props: any;
};
const SectionHeading = ({
  pt,
  pb,
  pl,
  pr,
  children,
  filter,
  icons,
  viewAllUrl,
  fontSize,
  fontWeight,
  display,
  w,
  customFilterOptions,
  minWOverride,
  ...props
}: Props) => {
  return (
    <HStack
      w={w || "100%"}
      align="center"
      pt={pt || "32px"}
      pb={pb || "16px"}
      pl={pl || "0px"}
      pr={pr || "0px"}
      justify="space-between"
      display={display || "flex"}
      {...props}
    >
      {children && (
        <Text
          minW={minWOverride || "250px"}
          w="fit-content"
          textAlign="left"
          fontSize={fontSize || "20px"}
          fontFamily="montserrat"
          fontWeight={fontWeight || 700}
          lineHeight="32px"
          color="grey.950"
          whiteSpace="unset"
        >
          {children}
        </Text>
      )}
      {filter && (
        <HStack mr={0} pr="0 !important">
          <Text mr="16px" color="grey.500" fontSize="14" fontFamily="opensans">
            Filter&nbsp;by:
          </Text>
          {customFilterOptions ? (
            <Select
              bg="white"
              border="none"
              defaultValue={customFilterOptions[0]}
              minW="160px"
            >
              {customFilterOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          ) : (
            <Select
              placeholder="Select option"
              bg="white"
              border="none"
              defaultValue="option1"
              minW="150px"
            >
              <option value="option1">All</option>
              <option value="option2">Option 2</option>
              <option value="option3">Option 3</option>
            </Select>
          )}
          {icons && (
            <IconButton
              variant="flat"
              size="sm"
              color="grey.100"
              icon={<FaThLarge size="16px" />}
              aria-label="Gallery"
            />
          )}
          {icons && (
            <Divider h="30px" orientation="vertical" borderColor="grey.950" />
          )}
          {icons && (
            <IconButton
              variant="flat"
              size="sm"
              color="primary.500"
              icon={<FaList size="16px" />}
              aria-label="List"
            />
          )}
        </HStack>
      )}
      {viewAllUrl && (
        <Link to={viewAllUrl}>
          <Text
            fontSize="12px"
            color="primary.500"
            decoration="underline"
            fontFamily="opensans"
            whiteSpace="nowrap"
          >
            View All
          </Text>
        </Link>
      )}
    </HStack>
  );
};

export default SectionHeading;
