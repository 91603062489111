import {
  Flex,
  Stack,
  Text,
  useMediaQuery,
  Box,
  VStack,
} from "@chakra-ui/react";
import React, { useMemo, ReactNode } from "react";
import { cardShadowHover } from "../../styles/theme";
import { getTrueDateTime } from "../../utils";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import routes from "../../constants/routes.json";

interface EventCardProps {
  event: any;
  showAttendeeType?: boolean;
  showMeetingType?: boolean;
}

export function LinkedEventCard({
  linkId,
  children,
}: {
  linkId: string;
  children: ReactNode;
}) {
  if (!!linkId) {
    return (
      <Link
        to={routes.BRT_INBOX_DETAIL.replace(":id", `${linkId}`)}
        onClick={(e) => e.stopPropagation()}
        rel="noreferrer"
        style={{ width: "100%" }}
      >
        {children}
      </Link>
    );
  }
  return <>{children}</>;
}

function EventCard(props: EventCardProps) {
  const {
    event: eventProp,
    showAttendeeType = true,
    showMeetingType = true,
  } = props;
  const event = {
    ...eventProp,
    eventStartDate: dayjs(
      getTrueDateTime(
        eventProp?.eventStartDate || eventProp.startDate,
        eventProp.timeZone
      )
    ).tz("America/New_York"),
    eventEndDate: dayjs(
      getTrueDateTime(
        eventProp?.eventEndDate || eventProp.endDate,
        eventProp.timeZone
      )
    ).tz("America/New_York"),
  };
  const committee = useMemo(() => {
    return event.committee || {};
  }, [event.committee]);

  const committeeName = () => {
    if (event.multiCommittee) {
      return "multi-committee";
    } else if (committee?.name) {
      return committee.name;
    } else if (typeof committee === "string") {
      return committee;
    } else return "Business Roundtable";
  };

  return !!event?.name || !!event?.title ? (
    <LinkedEventCard linkId={event?.email || event.id}>
      <Stack
        marginInlineStart="unset"
        flexDirection={{ base: "column", md: "row" }}
        w="100%"
        h="fit-content"
        spacing={0}
        _hover={{ filter: event.email ? cardShadowHover : "unset" }}
      >
        <Flex
          w={{ base: "100%", md: "120px" }}
          borderRadius={{ base: "16px 16px 0 0", md: "16px 0 0 16px" }}
          bg={event.multiCommittee ? "#DDDDDD" : committee.bgColor ?? "#DDDDDD"}
          backgroundImage={
            event.multiCommittee
              ? "/images/committees/br-badge.svg"
              : committee.image ?? "/images/committees/br-badge.svg"
          }
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          backgroundSize="auto 36px"
          justifyContent="center"
          alignItems="center"
          flexShrink={0}
          minH={{ base: "60px", md: "120px" }}
        />

        <Flex
          borderRadius={{ base: "0 0 16px 16px", md: "0 16px 16px 0" }}
          h="100%"
          w="100%"
          bg="#fff"
          padding={{ base: "16px 20px", md: "20px 24px" }}
          justifyContent="space-between"
        >
          <VStack spacing={3} align="flex-start" flex={1}>
            <Stack
              flexDirection={{ base: "column", xl: "row" }}
              alignItems={{ base: "flex-start", xl: "center" }}
              spacing={{ base: 1, xl: 0 }}
            >
              <Text
                color="#202020"
                fontSize="14px"
                fontWeight="700"
                lineHeight="17px"
                textTransform="uppercase"
                fontFamily="montserrat"
              >
                {committeeName()}
              </Text>

              <Stack
                direction={{ base: "column", md: "row" }}
                align={{ base: "flex-start", md: "center" }}
                spacing={{ base: 1, md: 2 }}
              >
                <Box
                  width="1px"
                  height="14px"
                  mx="8px"
                  bgColor="grey.700"
                  display={{ base: "none", xl: "block" }}
                ></Box>

                <Text
                  color="#696969"
                  fontSize="12px"
                  lineHeight="16px"
                  mt="0px !important"
                  mb={{ base: "4px", md: 0 }}
                  ml="0px !important"
                >
                  {showAttendeeType && (
                    <>Attendee type: {event?.meetingLevel || "CEO"}</>
                  )}
                </Text>
                {showAttendeeType && (
                  <Box
                    width="1px"
                    height="14px"
                    mx="8px"
                    bgColor="grey.700"
                    display={{ base: "none", md: "block" }}
                  ></Box>
                )}

                <Text color="#696969" fontSize="12px" lineHeight="16px">
                  {event.eventStartDate.format("MMM D, YYYY - h:mm z")}
                </Text>
              </Stack>
            </Stack>

            <Text
              color="#3F3F3F"
              fontSize="16px"
              fontWeight="500"
              lineHeight="20px"
              marginTop="12px"
              fontFamily="montserrat"
            >
              {event?.portalTitle || event?.name || event?.title}
            </Text>

            {event.description && (
              <Text color="#3F3F3F" fontSize="14px" lineHeight="19px">
                {event.description}
              </Text>
            )}

            {showMeetingType && (
              <Text color="#3F3F3F" fontSize="14px" lineHeight="19px">
                Meeting type: {event?.meetingType || "N/A"}
              </Text>
            )}

            {/* {event.eventUrl ? (
            <a
              href={event.eventUrl}
              onClick={(e) => e.stopPropagation()}
              target="_blank"
              rel="noreferrer"
              style={{ width: "fit-content", marginTop: "20px" }}
            >
              <PrimaryButton
                w="120px"
                h="48px"
                leftIcon={<BiCalendarCheck size="20px" />}
                colorScheme="primary"
                variant="solid"
              >
                RSVP
              </PrimaryButton>
            </a>
          ) : (
            <></>
          )} */}
          </VStack>
          <Text fontSize="12px" lineHeight="16px" color="#696969">
            {event.eventEndDate.diff(event.eventStartDate, "m")} Min
          </Text>
        </Flex>
      </Stack>
    </LinkedEventCard>
  ) : null;
}

export default EventCard;
