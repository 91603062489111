import React from "react";
import routes from "../../constants/routes.json";
import { cardShadowHover } from "../../styles/theme";
import { Link } from "react-router-dom";
import { Box, Flex, Stack, HStack, Text, VStack } from "@chakra-ui/react";
import dayjs from "dayjs";
import { IMessage } from "../../types";
import { getTrueDateTime } from "../../utils";
import { colors } from "../../constants/default";

export interface IUpdateData {
  id: number;
  category: string;
  date: Date;
  title: string;
  url: string;
  content: any;
}

interface UpdateCardProps {
  data: IUpdateData | IMessage;
}

export default function UpdateCard({ data }: UpdateCardProps) {
  if (!data) {
    return <></>;
  }

  const getUrl = () => {
    let route;

    const cardType = data?.content?.type || "Email"; //@TODO hack, update this
    const id = data.id || data?.content?.id;

    switch (cardType) {
      case "policy-statement":
      case "article":
        route = routes.ARTICLE_DETAILS.replace(":id", `${id}`);
        break;
      case "event":
        route = routes.EVENT_DETAILS.replace(":id", `${id}`);
        break;
      case "committee":
        route = routes.COMMITTEE_DETAILS.replace(":id", `${id}`);
        break;
      case "subcommittee":
        route = routes.COMMITTEE_DETAILS.replace(":id", `${id}`) + "?type=sub";
        break;
      case "Email":
        route = routes.BRT_INBOX_DETAIL.replace(":id", `${data.id}`);
        break;
      default:
        route = null;
    }

    return route || "";
  };

  const cardDate = getTrueDateTime(
    data?.memberPortalDate ||
      data.content?.publishedDate ||
      data.content?.created ||
      data?.date ||
      data?.updatedAt,
    ""
  );

  return (
    <Link to={getUrl()}>
      <Stack
        direction={{ base: "column", md: "row" }}
        bg="white"
        borderRadius="16px"
        overflow="hidden"
        _hover={{ filter: cardShadowHover }}
        transition="0.3s all"
        spacing={0}
        w="100%"
      >
        <Box
          minH={{ base: "52px", md: "87px" }}
          minW={{ base: "100%", md: "78px" }}
          bgRepeat="no-repeat"
          bgPosition="center"
          bgSize="auto 32px"
          bgImage={
            data.multiCommittee || !data.committee.key
              ? "/images/committees/br-badge.svg"
              : `/images/committees/${data.committee.key}.svg`
          }
          bgColor={
            data.multiCommittee
              ? "#DDDDDD"
              : colors[data.committee.key] || "#DDD"
          }
        />
        <HStack
          justify="space-between"
          w="100%"
          align="flex-start"
          p={{ base: "16px 24px", md: "20px 24px" }}
        >
          <VStack alignItems="flex-start">
            <Flex
              alignItems={{ base: "flex-start", md: "center" }}
              direction={{ base: "column", md: "row" }}
            >
              <Text
                fontSize="14px"
                lineHeight="17px"
                fontWeight="700"
                fontFamily="montserrat"
                color="grey.950"
                textTransform="uppercase"
                mb={{ base: "4px", md: "0px" }}
              >
                {data.multiCommittee
                  ? "multi-committee"
                  : data?.category || data?.committee?.name}
              </Text>

              <Box
                width="1px"
                height="14px"
                mx="8px"
                bgColor="grey.700"
                display={{ base: "none", md: "block" }}
              ></Box>

              <Text
                as="div"
                fontSize="12px"
                lineHeight="16px"
                fontWeight="400"
                fontFamily="opensans"
                color="grey.700"
              >
                {dayjs(cardDate).format("MMM D, YYYY")}
              </Text>
            </Flex>

            <Box mt="12px !important">
              <Text
                fontSize="16px"
                lineHeight="21px"
                fontWeight="500"
                fontFamily="montserrat"
                color="grey.930"
                // noOfLines={1}
              >
                {data.title || data.content?.title || data.content?.name}
              </Text>
            </Box>
          </VStack>
        </HStack>
      </Stack>
    </Link>
  );
}
