import axios from "axios";
import { BACKEND_API_ENDPOINT } from "../../constants/default";
import apiClient from "../../api/apiClient";

const getHeaders = () => {
  let h = {
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const token = window.localStorage
    ? window.localStorage.getItem("accessToken")
    : undefined;
  if (token) {
    h["Authorization"] = `Bearer ${token}`;
  }

  return h;
};

export const postHelpFormSubmission = (payload) => {
  return axios
    .post(`${BACKEND_API_ENDPOINT}/help`, payload, { headers: getHeaders() })
    .then((response) => {
      return response;
    });
};

export const userSettings = async () => {
  try {
    const response = await apiClient.get(`/user/me`);
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, error };
  }
};

export const updateProfile = (payload) => {
  return axios
    .post(`${BACKEND_API_ENDPOINT}/user/profile`, payload, {
      headers: getHeaders(),
    })
    .then((response) => {
      return response;
    });
};
