import { Box, VStack } from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeadMeta from "../components/common/HeadMeta";
import EventAgenda from "../components/EventAgenda";
import EventIssueCallCard from "../components/EventIssueCallCard";
import SectionHeader from "../components/Home/SectionHeader";
import Layout from "../components/Layout";
import TwoColLayout from "../components/Layout/TwoColLayout";
import routes from "../constants/routes.json";
import {
  getEventLocationsRequest,
  getEventsRequest,
} from "../redux/event/action";

var dayjsUTC = require("dayjs/plugin/utc");
var dayjsTimezone = require("dayjs/plugin/timezone");
var dayjsAdvancedFormat = require("dayjs/plugin/advancedFormat");
var isSameOrBefore = require("dayjs/plugin/isSameOrBefore");

dayjs.extend(dayjsUTC);
dayjs.extend(dayjsTimezone);
dayjs.extend(dayjsAdvancedFormat);
dayjs.extend(isSameOrBefore);

function Events() {
  const dispatch = useDispatch();
  const eventState = useSelector((state) => state.event);
  const { events, eventLocations } = eventState;

  useEffect(() => {
    dispatch(getEventsRequest());
    dispatch(getEventLocationsRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortedEvents = events
    .filter((event) =>
      dayjs(event.eventStartDate).isSameOrAfter(dayjs().startOf("day"))
    )
    .sort((a, b) =>
      dayjs(a.eventStartDate).isSameOrBefore(dayjs(b.eventStartDate)) ? -1 : 1
    );

  let sortedFeaturedEvents = eventLocations.featured_event
    ? eventLocations.featured_event.sort((a, b) =>
        dayjs(a.eventStartDate).isSameOrBefore(dayjs(b.eventStartDate)) ? -1 : 1
      )
    : [];

  sortedFeaturedEvents = sortedFeaturedEvents.slice(0, 4);

  return (
    <Layout path={[{ title: "Events" }]} backUrl={routes.HOME}>
      <VStack w="100%" spacing={0}>
        <HeadMeta title="BRT Member Portal | Events" />
        <TwoColLayout>
          <Box>
            {sortedEvents.length ? (
              <EventAgenda events={sortedEvents} />
            ) : (
              <></>
            )}
          </Box>

          <Box>
            {sortedFeaturedEvents && sortedFeaturedEvents.length > 0 && (
              <>
                <SectionHeader title="Upcoming Quarterly Meetings" />
                <VStack w="100%" spacing={3}>
                  {sortedFeaturedEvents.map((item: any) => (
                    <EventIssueCallCard data={item} vertical key={item.id} />
                  ))}
                </VStack>
              </>
            )}
          </Box>
        </TwoColLayout>
      </VStack>
    </Layout>
  );
}

export default Events;
