import { combineReducers } from "redux";
import authReducer from "./auth/reducer";
import uiReducer from "./ui/reducer";
import committeeReducer from "./committee/reducer";
import articleReducer from "./article/reducer";
import eventReducer from "./event/reducer";
import helpCenterReducer from "./helpCenter/reducer";
import companyReducer from "./company/reducer";

export default combineReducers({
  auth: authReducer,
  ui: uiReducer,
  committee: committeeReducer,
  article: articleReducer,
  event: eventReducer,
  helpCenter: helpCenterReducer,
  company: companyReducer,
});
