import React, { useEffect, useState } from "react";
import { Box, VStack, Text, Stack } from "@chakra-ui/react";
import dayjs from "dayjs";
import Layout from "../components/Layout";
import HeadMeta from "../components/common/HeadMeta";
import { useDispatch, useSelector } from "react-redux";
import { getEventsRequest } from "../redux/event/action";
import { getUpdatesRequest } from "../redux/committee/action";
import BRTEssentials from "../components/Home/BRTEssentials";
import TheLatest from "../components/Home/TheLatest";
import UpcomingEvents from "../components/Home/UpcomingEvents";
import AboutBRTButton from "../components/common/AboutBRTButton";
import useTheLatest from "../hooks/useTheLatest";
import useScrollToTop from '../hooks/useScrollToTop';

var dayjsUTC = require("dayjs/plugin/utc");
var dayjsTimezone = require("dayjs/plugin/timezone");
var dayjsAdvancedFormat = require("dayjs/plugin/advancedFormat");

dayjs.extend(dayjsUTC);
dayjs.extend(dayjsTimezone);
dayjs.extend(dayjsAdvancedFormat);

function Home() {
  useScrollToTop();
  const dispatch = useDispatch();
  const [weekly, setWeekly] = useState<any[]>([]);
  const [allEvents, setAllEvents] = useState<any[]>([]);
  const eventState = useSelector((state) => state.event);
  const { events } = eventState;
  const updates = useSelector((state) => state.committee.updates);
  const theLatestData = useTheLatest();

  useEffect(() => {
    dispatch(getEventsRequest());
    dispatch(getUpdatesRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setWeekly(
      (updates &&
        updates.weekly && [
          updates.weekly.this_week_1,
          updates.weekly.this_week_2,
          updates.weekly.this_week_3,
        ]) || [null, null, null]
    );
  }, [updates]);

  useEffect(() => {
    setAllEvents(events);
  }, [events]);

  const upcomingEvents = allEvents
    .filter((e) =>
      dayjs(e.eventStartDate).isSameOrAfter(dayjs().startOf("day"))
    )
    .sort((e1, e2) => dayjs(e1.eventStartDate) - dayjs(e2.eventStartDate))
    .slice(0, 3);

  return (
    <Layout showHeader={false}>
      <VStack w="100%" spacing="40px" alignItems="start">
        <HeadMeta />
        <Box w={{ base: "100%", md: "80%" }} mb="32px">
          <Text
            variant="h1"
            fontSize="2xl"
            fontWeight="bold"
            fontFamily="montserrat"
            mb="12px"
          >
            Business Roundtable Member Portal
          </Text>
          <Text fontFamily="montserrat" mb="1rem" fontSize="14px">
            This digital platform centralizes Business Roundtable (BRT) content
            to support your CEO and team engagement. We invite you to explore
            information and resources from our Policy Committees and Corporate
            Initiatives. We encourage you to review our upcoming meetings in the
            Events section and read the latest Priority Issues Update &
            Resources email.
          </Text>
          <Text fontFamily="montserrat" fontSize="14px">
            We hope that you will find the BRT Member Portal to be a helpful
            tool. We welcome your feedback.
          </Text>
        </Box>
        <Stack
          spacing="16px"
          mt="0px !important"
          direction={{ base: "column", md: "row" }}
        >
          <AboutBRTButton title="ABOUT BRT" link="/about-brt" />
          <AboutBRTButton title="Policy Committees" link="/committees" />
          <AboutBRTButton
            title="Corporate Initiatives"
            link="/corporate_initiatives"
          />
        </Stack>

        {weekly && Array.isArray(weekly) && weekly.length > 0 && (
          <BRTEssentials data={weekly} />
        )}

        {theLatestData.length > 0 && <TheLatest data={theLatestData} />}

        {upcomingEvents.length > 0 && <UpcomingEvents data={upcomingEvents} />}
      </VStack>
    </Layout>
  );
}

export default Home;
