import { useState, useEffect } from "react";
import { getCommitteeLeads } from "../services/committee";
import { setLoading } from "../redux/ui/action";
import { useDispatch } from "react-redux";

export default function useCommitteeLeads(committeeId: string) {
  const dispatch = useDispatch();
  const [committeeMembers, setCommitteeMembers] = useState([]);

  // get committee leads
  useEffect(() => {
    const fetchCommitteeLeads = async () => {
      dispatch(setLoading("GET_COMMITTEE_LEADS_REQUEST"));
      try {
        const committeLeads = await getCommitteeLeads(committeeId);
        setCommitteeMembers(committeLeads);
        dispatch(setLoading("GET_COMMITTEE_LEADS_SUCCESS"));
      } catch (error) {
        dispatch(setLoading("GET_COMMITTEE_LEADS_ERROR"));
      }
    };

    if (committeeId) {
      fetchCommitteeLeads();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [committeeId]);

  return committeeMembers;
}
