import React from 'react';

const CorporateInitiatives = ({color}: {color: string}) => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="33" viewBox="0 0 19 33" fill="none">
         <g clipPath="url(#clip0_6457_14561)">
            <path
               d="M5.97616 2.36363C6.13935 2.11993 6.32458 1.90412 6.53334 1.71326C6.41573 1.32422 6.20844 0.985086 5.90853 0.69587C5.90853 0.687061 5.90412 0.682657 5.8953 0.682657C5.57775 0.34793 5.18082 0.130651 4.70009 0.0308198V0.0455008C4.23699 -0.0455215 3.77684 -1.03338e-05 3.31375 0.180566V0.167353C2.87859 0.34793 2.52576 0.64155 2.25378 1.04821C1.99063 1.43726 1.85538 1.87622 1.84656 2.36363V2.37831C1.84656 3.02868 2.08178 3.58509 2.55222 4.04608C3.02119 4.51587 3.58425 4.75077 4.23552 4.75077C4.63393 4.75077 5.00587 4.66121 5.34841 4.47917V4.37053C5.36018 3.62033 5.56893 2.95234 5.97616 2.36363Z"
               fill={color}
            />
            <path
               d="M0 8.57668V13.2291C0 13.7987 0.099969 14.245 0.299907 14.5724C0.498375 14.8425 0.805633 15.051 1.22315 15.1949C1.32165 15.2316 1.40986 15.2947 1.48042 15.3857C1.54511 15.4665 1.58039 15.5634 1.58921 15.6706L2.486 25.4085V25.4217C2.49482 25.6478 2.58449 25.8386 2.75797 25.9928C2.92115 26.1469 3.1108 26.2233 3.32838 26.2233H5.2572C5.44831 26.2233 5.62326 26.1631 5.78644 26.0456L5.12194 18.8049C4.58682 18.5701 4.18841 18.2529 3.92526 17.8551C3.58271 17.3662 3.40924 16.6835 3.40924 15.8071V10.9095C3.40924 8.56641 4.54565 7.36109 6.81701 7.28769C6.68175 7.17905 6.55091 7.0616 6.42448 6.93535C6.18926 6.70045 5.98932 6.44794 5.82614 6.17634H2.40367C0.801223 6.17634 0 6.97498 0 8.57668Z"
               fill={color}
            />
            <path
               d="M11.9242 9.59261C11.7508 9.47663 11.5891 9.3401 11.4347 9.18594C11.173 8.92462 10.9598 8.64862 10.7967 8.35793H7.04782C5.3454 8.35793 4.49567 9.20797 4.49567 10.908V15.8041C4.49567 16.409 4.60299 16.8891 4.82057 17.2414C5.02785 17.5321 5.35422 17.7523 5.7982 17.9065C5.8967 17.9432 5.98344 18.0063 6.05548 18.0973C6.12016 18.1781 6.15545 18.2735 6.16427 18.3821L7.11544 28.6354V28.6486C7.12426 28.8923 7.21982 29.0963 7.40065 29.2593C7.58294 29.4311 7.79023 29.5162 8.02545 29.5162H10.0484C10.2924 29.5162 10.51 29.4311 10.7011 29.2593C10.7187 29.2402 10.7378 29.2226 10.7555 29.2035L10.0631 21.678C9.5 21.4329 9.08542 21.0996 8.81345 20.6738V20.6885C8.45179 20.1718 8.2695 19.4583 8.2695 18.5451V13.3921C8.27097 10.9139 9.48824 9.64693 11.9242 9.59261Z"
               fill={color}
            />
            <path
               d="M10.7687 2.58099C10.4173 2.22865 9.98218 1.99815 9.46469 1.88805C8.97661 1.79849 8.48852 1.84694 7.9975 2.03779C7.53735 2.22865 7.16099 2.53988 6.87138 2.97444C6.59058 3.38991 6.44504 3.8553 6.43622 4.37061V4.38529C6.43622 5.07236 6.68467 5.66401 7.18305 6.16169H7.19628C7.69465 6.65791 8.29153 6.90602 8.98837 6.90602C9.45881 6.90602 9.89838 6.77976 10.3071 6.52725V6.49936V6.48614C10.3247 5.71686 10.5467 5.02538 10.973 4.41025C11.1171 4.18563 11.2803 3.9845 11.4611 3.8142C11.3435 3.3356 11.1127 2.92893 10.7672 2.5942L10.7687 2.58099Z"
               fill={color}
            />
            <path
               d="M11.8698 5.02235C11.5714 5.46572 11.4141 5.9634 11.395 6.51394C11.395 7.25387 11.6625 7.88662 12.1977 8.4122H12.2109C12.7357 8.93631 13.3693 9.1991 14.1118 9.1991C14.6557 9.1991 15.157 9.04495 15.6187 8.73812L15.6319 8.7249C16.0773 8.44303 16.3978 8.04958 16.5963 7.54455C16.8036 7.03805 16.858 6.52275 16.7595 5.99717C16.6419 5.45544 16.3934 4.99446 16.0126 4.61422C15.6319 4.23545 15.1717 3.98587 14.6278 3.86989C14.1029 3.76125 13.5825 3.8097 13.065 4.01817H13.0797C12.5799 4.22664 12.1771 4.56137 11.8698 5.02235Z"
               fill={color}
            />
            <path
               d="M16.2832 10.6775H12.0727C10.263 10.6775 9.35736 11.5818 9.35736 13.3905V18.5435C9.35736 19.1866 9.47497 19.696 9.71019 20.0762H9.72342C9.93071 20.3845 10.2718 20.6194 10.7422 20.7809C10.8407 20.8176 10.9275 20.8793 10.9995 20.9718C11.0642 21.0525 11.0995 21.148 11.1083 21.2566L12.1124 32.0515V32.0662C12.1227 32.3276 12.2256 32.5492 12.4255 32.7313C12.6166 32.9119 12.843 33.0014 13.1062 33.0014H15.2364C15.4996 33.0014 15.7304 32.9119 15.9303 32.7313C16.1214 32.5492 16.2199 32.3276 16.2302 32.0662V32.0383L17.2476 21.2434C17.2579 21.1435 17.299 21.0525 17.3696 20.9718C17.4343 20.8808 17.5151 20.822 17.6151 20.7941C18.0855 20.6224 18.434 20.3787 18.6604 20.063C18.8868 19.6916 19 19.1866 19 18.5435V13.3905C19 11.5818 18.0944 10.6775 16.2832 10.6775Z"
               fill={color}
            />
         </g>
         <defs>
            <clipPath id="clip0_6457_14561">
               <rect width="19" height="33" fill="white" />
            </clipPath>
         </defs>
      </svg>
   );
};

export default CorporateInitiatives;
