import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Spinner,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import React, { ReactNode, useEffect, useState } from "react";
import { FiLogOut } from "react-icons/fi";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { MENU_TOP } from "../../constants/menu";
import routes from "../../constants/routes.json";
import useProfile from "../../hooks/useProfile";
import { logoutUser, setProfile } from "../../redux/auth/action";
import { checkAuth, logout } from "../../utils";
import Close from "../Icons/Close";
import Hamburger from "../Icons/Hamburger";
import Breadcrumbs from "./Breadcrumbs";
import MenuItem from "./MenuItem";
import SearchBar from "./SearchBar";
import Sidebar from "./Sidebar";
import TwoColLayout from "./TwoColLayout";

const Header = ({ path }: { path?: any }) => {
  return (
    <TwoColLayout mt="0 !important" isHeader mb="32px !important">
      <Box>{path && <Breadcrumbs path={path} />}</Box>
      <SearchBar />
    </TwoColLayout>
  );
};

const HeaderMobile = ({
  onToggle,
  path,
  isLoggedIn,
  collapsed,
}: {
  onToggle: () => void;
  path?: any;
  backUrl?: string;
  isLoggedIn: boolean;
  collapsed: boolean;
}) => {
  const history = useHistory();
  return (
    <>
      <HStack
        w={{ base: "calc(100% + 32px)", md: "100vw" }}
        h="72px"
        display={{ base: "flex", md: "none" }}
        mx="-16px !important"
        px="16px"
        bg="white"
        spacing="37px"
        mt="0 !important"
        mb="32px"
        justifyContent="space-between"
        alignItems="center"
      >
        <img
          src="/images/logo-full.svg"
          height="40px"
          width="180px"
          alt="logo"
          onClick={() => {
            history.push("/");
            if (!collapsed) {
              onToggle();
            }
          }}
        />
        <Box
          aria-label="menu"
          variant="flat"
          bg="transparent"
          onClick={onToggle}
          mx="0px !important"
        >
          {collapsed ? <Hamburger /> : <Close />}
        </Box>
      </HStack>
    </>
  );
};

const MobileMenu = ({
  avatarImage,
  collapsed,
  name = "",
  onToggle,
}: {
  avatarImage: string;
  collapsed: boolean;
  name: string;
  onToggle: () => void;
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.auth.profile);
  const [view, setView] = useState("main");

  const handleLogout = () => {
    dispatch(logoutUser());
    window.location.href = routes.SIGNIN;
  };

  return (
    <Box
      display={{ base: collapsed ? "none" : "block", md: "none" }}
      w={{ base: "100vw", md: "225px" }}
      h="100%"
      zIndex="100000"
      top="0"
      left="0"
      overflow="hidden"
      position="fixed"
      bg="white"
      mt="72px"
      pt="32px"
    >
      {/* main menu */}
      <VStack
        color="grey.950"
        w="100%"
        h="calc(100% - 72px - 32px)"
        align="flex-start"
        justify="space-between"
        pb="48px"
        position="absolute"
        left={view === "main" ? "0px" : "-100%"}
        transition="left 0.2s"
        justifyContent="space-between"
      >
        <VStack w="100%">
          {MENU_TOP.filter((item) =>
            item?.acl?.includes(userProfile?.webRole)
          ).map((item: any) => (
            <MenuItem
              key={item.id}
              id={item.id}
              custom={item.custom ?? false}
              icon={item.icon}
              title={item.title}
              url={item.path}
              isActive={item.isActive(location.pathname)}
              collapsed={collapsed}
              isMenu
            />
          ))}
        </VStack>

        <Box w="100%" pl="26px">
          <Button
            display="flex"
            justifyContent="flex-start"
            bgColor="transparent"
            variant="unstyled"
            onClick={() => setView("profile")}
          >
            <Avatar
              boxSize="37px"
              bg="grey.200"
              name={name.toUpperCase()}
              src={avatarImage}
            />
            <Text
              fontSize="14px"
              lineHeight="19px"
              fontWeight={700}
              fontFamily="montserrat"
              ml="16px"
            >
              {name.toUpperCase()}
            </Text>
          </Button>
        </Box>
      </VStack>

      {/* profile panel */}
      <VStack
        px="24px"
        spacing={4}
        position="absolute"
        right={view === "profile" ? "0px" : "-100%"}
        w="100%"
        h="calc(100% - 72px - 32px)"
        bg="white"
        transition="right 0.2s"
      >
        <Flex
          alignItems="center"
          cursor="pointer"
          alignSelf="flex-start"
          onClick={() => setView("main")}
        >
          <Box mr="8px">
            <svg
              width="10"
              height="16"
              viewBox="0 0 10 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 16L0 8L8 0L9.12 1.14L2.26 8L9.12 14.86L8 16Z"
                fill="#0D7DBD"
              />
            </svg>
          </Box>

          <Text
            fontFamily="montserrat"
            fontSize="14px"
            lineHeight="19px"
            fontWeight="500"
            color="primary.500"
          >
            Back
          </Text>
        </Flex>
        <HStack w="100%" mt="40px !important" spacing={4}>
          <Avatar
            boxSize="80px"
            bg="grey.200"
            color="grey.200"
            name={name}
            src={avatarImage}
          />
          <VStack alignItems="flex-start">
            <Text
              fontSize="18px"
              lineHeight="22px"
              fontFamily="montserrat"
              fontWeight={600}
            >
              {name}
            </Text>
            <Text
              fontSize="14px"
              lineHeight="19px"
              fontFamily="opensans"
              fontWeight={400}
            >
              {userProfile?.company?.name}
            </Text>
          </VStack>
        </HStack>
        <VStack w="100%" spacing={3}>
          <Link to="/profile" style={{ width: "100%" }}>
            <Button
              bgColor="grey.50"
              color="primary.500"
              w="100%"
              filter="none"
            >
              Edit profile information
            </Button>
          </Link>
          <Button
            bgColor="transparent"
            color="primary.500"
            w="100%"
            filter="none"
            leftIcon={<FiLogOut />}
            onClick={handleLogout}
          >
            Logout
          </Button>
        </VStack>
      </VStack>
    </Box>
  );
};

const Layout = ({
  children,
  loading,
  path,
  backUrl,
  subHeader,
  showHeader = true,
}: {
  children: any;
  loading: {};
  path?: any;
  backUrl?: string;
  subHeader?: ReactNode;
  showHeader?: boolean;
}) => {
  const isLoading = Object.keys(loading).some((key) => loading?.[key]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const profile = useProfile();
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    document.body.style.overflow = !collapsed && isMobile ? "hidden" : "auto";
  }, [isMobile, collapsed]);

  const onToggle = () => {
    setCollapsed(!collapsed);
  };

  const redirectToSignIn = () => {
    history.push(routes.SIGNIN);
  };

  if (profile && profile.success === false) {
    logout();
    dispatch(setProfile({}));
    redirectToSignIn();
  }

  return (
    <>
      <Flex
        minH="100vh"
        minW="100%"
        w="100%"
        align="stretch"
        overflow={{ base: "hidden", md: "inherit" }}
      >
        <Box
          display={{ base: "none", md: "block" }}
          onMouseEnter={() => setCollapsed(false)}
          onMouseLeave={() => setCollapsed(true)}
        >
          <Sidebar
            collapsed={collapsed}
            company={profile?.company ? profile.company.name : ""}
            isLoggedIn={checkAuth()}
            name={profile?.firstName + " " + profile?.lastName}
            onToggle={checkAuth() ? onToggle : redirectToSignIn}
            profilePicture={`data:image/png;base64,${profile?.profilePicture}`}
          />
        </Box>
        <Box
          w="100%"
          ml={{ base: "0px", md: "94px" }}
          pt={{ base: "0px", md: "32px" }}
        >
          {subHeader && <Box mb={{ base: "0px", md: "32px" }}>{subHeader}</Box>}
          <VStack
            flex="1"
            w={{ base: "100%" }}
            h="100%"
            mx="auto"
            maxW="1440px"
            px={{ base: "16px", md: "24px", lg: "64px" }}
            pb="24px"
            mt="0 !important"
            spacing={0}
            alignItems="flex-start"
          >
            <HeaderMobile
              onToggle={checkAuth() ? onToggle : redirectToSignIn}
              path={path}
              backUrl={backUrl}
              isLoggedIn={checkAuth()}
              collapsed={collapsed}
            />

            {checkAuth() && showHeader && <Header path={path} />}

            <VStack w="100%" maxW="100%" flex="1">
              {children}
            </VStack>

            <Box
              color="#c4c4c4"
              textDecor="underline"
              fontSize="14px"
              lineHeight="19px"
              mt="40px !important"
            >
              <Link to="/privacy-policy">Privacy Policy</Link>
            </Box>
          </VStack>
        </Box>
        {isLoading && (
          <Flex
            minW="100%"
            w="100%"
            h="100vh"
            minH="100vh"
            position="fixed"
            zIndex={10000}
            alignItems="center"
            justifyContent="center"
            display="flex"
            bg="rgba(255,255,255,.6)"
          >
            <Spinner size="xl" color="primary.500" />
          </Flex>
        )}
      </Flex>
      <MobileMenu
        avatarImage={`data:image/png;base64,${profile?.profilePicture}`}
        collapsed={collapsed}
        name={profile?.firstName + " " + profile?.lastName}
        onToggle={onToggle}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loading: state.ui.loading,
  };
};

export default connect(mapStateToProps)(Layout);
