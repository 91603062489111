export const SET_COMMITTEES_REQUEST = "SET_COMMITTEES_REQUEST";
export const SET_COMMITTEES_ERROR = "SET_COMMITTEES_ERROR";
export const SET_COMMITTEES_SUCCESS = "SET_COMMITTEES_SUCCESS";

export const GET_COMMITTEES_REQUEST = "GET_COMMITTEES_REQUEST";
export const GET_COMMITTEES_SUCCESS = "GET_COMMITTEES_SUCCESS";
export const GET_COMMITTEES_ERROR = "GET_COMMITTEES_ERROR";

export const GET_SUB_COMMITTEES_REQUEST = "GET_SUB_COMMITTEES_REQUEST";
export const GET_SUB_COMMITTEES_SUCCESS = "GET_SUB_COMMITTEES_SUCCESS";
export const GET_SUB_COMMITTEES_ERROR = "GET_SUB_COMMITTEES_ERROR";

export const GET_COMMITTEE_REQUEST = "GET_COMMITTEE_REQUEST";
export const GET_COMMITTEE_SUCCESS = "GET_COMMITTEE_SUCCESS";
export const GET_COMMITTEE_ERROR = "GET_COMMITTEE_ERROR";

export const GET_COMMITTEE_MEMBERS_REQUEST = "GET_COMMITTEE_MEMBERS_REQUEST";
export const GET_COMMITTEE_MEMBERS_SUCCESS = "GET_COMMITTEE_MEMBERS_SUCCESS";
export const GET_COMMITTEE_MEMBERS_ERROR = "GET_COMMITTEE_MEMBERS_ERROR";

export const SET_MEMBERSHIP_REQUEST = "SET_MEMBERSHIP_REQUEST";
export const SET_MEMBERSHIP_SUCCESS = "SET_MEMBERSHIP_SUCCESS";
export const SET_MEMBERSHIP_ERROR = "SET_MEMBERSHIP_ERROR";

export const GET_TAGS_REQUEST = "GET_TAGS_REQUEST";
export const GET_TAGS_SUCCESS = "GET_TAGS_SUCCESS";
export const GET_TAGS_ERROR = "GET_TAGS_ERROR";

export const GET_SEARCH_REQUEST = "GET_SEARCH_REQUEST";
export const GET_SEARCH_SUCCESS = "GET_SEARCH_SUCCESS";
export const GET_SEARCH_ERROR = "GET_SEARCH_ERROR";

export const GET_UPDATES_REQUEST = "GET_UPDATES_REQUEST";
export const GET_UPDATES_SUCCESS = "GET_UPDATES_SUCCESS";
export const GET_UPDATES_ERROR = "GET_UPDATES_ERROR";

export const UPDATE_COMMITTEE_MEMBERSHIPS_REQUEST =
  "UPDATE_COMMITTEE_MEMBERSHIPS_REQUEST";
export const UPDATE_COMMITTEE_MEMBERSHIPS_SUCCESS =
  "UPDATE_COMMITTEE_MEMBERSHIPS_SUCCESS";
export const UPDATE_COMMITTEE_MEMBERSHIPS_ERROR =
  "UPDATE_COMMITTEE_MEMBERSHIPS_ERROR";
