import {
  HStack,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Stack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useCommunicationList } from "../../hooks/useCommunicationList";
import CommunicationList from "./CommunicationList";
import EmailAndUpdateList from "./EmailAndUpdateList";
import PublishedDocumentList from "./PublishedDocumentList";

export interface CommitteeDocumentListProps {
  committee: any;
}

export default function CommitteeDocumentList({
  committee,
}: CommitteeDocumentListProps) {
  const [sortDropdown, setSortDropdown] = useState<string>("0");
  const communicationList = useCommunicationList(committee?.key);

  return (
    <HStack w="100%" mt="0 !important">
      <Tabs variant="unstyled" style={{ width: "100%" }}>
        <TabList mb={{ base: 4, md: "22px" }}>
          <Stack
            justify={{ base: "flex-start", md: "space-between" }}
            direction={{ base: "column", md: "row" }}
            w="100%"
            spacing={4}
          >
            {/* Tab Heads */}
            <HStack
              style={{ color: "#A9A9A9" }}
              spacing={6}
              overflowX="scroll"
              className="committee-document-tab-list"
            >
              <Tab _selected={{ color: "#202020" }} p="0">
                <Text
                  fontFamily="montserrat"
                  fontWeight="semibold"
                  fontSize={16}
                  lineHeight="20px"
                  whiteSpace="nowrap"
                >
                  Emails & Updates
                </Text>
              </Tab>
              {committee?.content?.length > 0 && (
                <Tab _selected={{ color: "#202020" }} p="0">
                  <Text
                    fontFamily="montserrat"
                    fontWeight="semibold"
                    fontSize={16}
                    lineHeight="20px"
                    whiteSpace="nowrap"
                  >
                    Published Documents
                  </Text>
                </Tab>
              )}
              {communicationList?.length && (
                <Tab _selected={{ color: "#202020" }} p="0">
                  <Text
                    fontFamily="montserrat"
                    fontWeight="semibold"
                    fontSize={16}
                    lineHeight="20px"
                    whiteSpace="nowrap"
                  >
                    Communications
                  </Text>
                </Tab>
              )}
            </HStack>

            {/* Sort dropdown */}
            <HStack display="flex">
              <Text
                color="grey.500"
                fontSize="14"
                lineHeight="24px"
                fontFamily="opensans"
                whiteSpace="nowrap"
              >
                Sort by
              </Text>
              <Select
                bg="white"
                border="none"
                w="auto"
                defaultValue="0"
                value={sortDropdown}
                onChange={(e) => setSortDropdown(e.target.value)}
                fontSize="14px"
              >
                <option value="0">New to Old</option>
                <option value="1">Old to New</option>
                <option value="2">A to Z</option>
                <option value="3">Z to A</option>
              </Select>
            </HStack>
          </Stack>
        </TabList>

        {/* Tab Panels */}
        <TabPanels>
          {/* Updates  */}
          <TabPanel p="0">
            <EmailAndUpdateList sort={sortDropdown} committee={committee} />
          </TabPanel>

          {/* Published documents */}
          {committee?.content?.length > 0 && (
            <TabPanel p="0">
              <PublishedDocumentList
                sort={sortDropdown}
                committee={committee}
              />
            </TabPanel>
          )}

          {/* Communications */}
          {communicationList?.length > 0 && (
            <TabPanel p="0">
              <CommunicationList
                sort={sortDropdown}
                committee={committee}
                data={communicationList}
              />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </HStack>
  );
}
