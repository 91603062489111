// import dayjs from "dayjs";
import { Grid, Heading, VStack } from "@chakra-ui/react";
import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NewCommitteeCard from "../components/Committees/NewCommitteeCard";
import HeadMeta from "../components/common/HeadMeta";
import Layout from "../components/Layout";
import routes from "../constants/routes.json";
import { getCommitteesRequest } from "../redux/committee/action";

const CommitteeHeading: FC = ({ children }) => (
  <Heading
    as="h3"
    fontSize="20px"
    lineHeight="24px"
    color="#202020"
    fontWeight="600"
  >
    {children}
  </Heading>
);

function Committees() {
  const dispatch = useDispatch();
  const { committees } = useSelector((state) => state.committee);
  // const { profile } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getCommitteesRequest());
  }, []);

  // let userCommittees: any[] = [];
  // let nonUserCommittees: any[] = [];
  // let issueAreas = [];

  // if (profile && profile.committees && profile.areasOfInterest) {
  //   committees.forEach((committee) => {
  //     if (
  //       committee.committeeType === "Select" ||
  //       committee.committeeType === "Issue Area"
  //     ) {
  //       issueAreas.push(committee);
  //     } else if (
  //       profile.committees.filter(
  //         (c) =>
  //           c.name.toLowerCase() === committee.name.toLowerCase() &&
  //           c.membershipstatus === 1
  //       ).length > 0 ||
  //       profile.areasOfInterest.filter(
  //         (a) => a.name.toLowerCase() === committee.name.toLowerCase()
  //       ).length > 0
  //     ) {
  //       userCommittees.push(committee);
  //     } else {
  //       nonUserCommittees.push(committee);
  //     }
  //   });
  // }

  // userCommittees = userCommittees.sort(
  //   (a, b) => dayjs(b.modifiedOn) - dayjs(a.modifiedOn)
  // );
  // nonUserCommittees = nonUserCommittees.sort(
  //   (a, b) => dayjs(b.modifiedOn) - dayjs(a.modifiedOn)
  // );

  return (
    <Layout path={[{ title: "Committees" }]} backUrl={routes.HOME}>
      <HeadMeta title="BRT Member Portal | Committees" />
      <VStack spacing="40px">
        {/* {userCommittees.length ? (
          <VStack spacing="24px" align="flex-start">
            <CommitteeHeading>My Committees</CommitteeHeading>
            <Grid
              templateColumns={{
                base: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
                lg: "repeat(3, 1fr)",
              }}
              gap={4}
              w="100%"
            >
              {userCommittees.map((committee) => (
                <NewCommitteeCard key={committee.id} committee={committee} />
              ))}
            </Grid>
          </VStack>
        ) : (
          <></>
        )} */}
        {committees.length ? (
          <VStack spacing={{ base: 4, md: 6 }} align="flex-start">
            <CommitteeHeading>Committees</CommitteeHeading>
            <Grid
              templateColumns={{
                base: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
                lg: "repeat(3, 1fr)",
              }}
              gap={5}
              w="100%"
            >
              {committees
                .filter(
                  (committee) => committee.category !== "Corporate Initiatives"
                )
                .sort((c1, c2) => {
                  if (c1.name < c2.name) {
                    return -1;
                  }
                  if (c1.name > c2.name) {
                    return 1;
                  }
                  return 0;
                })
                .map((committee) => (
                  <NewCommitteeCard key={committee.id} committee={committee} />
                ))}
            </Grid>
          </VStack>
        ) : (
          <></>
        )}
      </VStack>
    </Layout>
  );
}

export default Committees;
