import { all, call, put, takeLatest } from "redux-saga/effects";
import { setLoading } from "../ui/action";
import {
  getMemberCompaniesError,
  getMemberCompaniesSuccess,
  getMemberCompaniesRequest,
  getCompanyByIDRequest,
  getCompanyByIDSuccess,
  getCompanyByIDError,
  getCurrentUserCompanyRequest,
  getCurrentUserCompanyError,
  getCurrentUserCompanySuccess,
} from "./action";
import {
  getCompanyByIDQuery,
  getCurrentUserCompanyQuery,
  getMemberCompaniesQuery,
} from "./query";
import * as Type from "./type";
import { setViewAsCompany } from '../auth/action';

export function* getMemberCompaniesSaga({ payload }) {
  try {
    yield put(setLoading(Type.GET_MEMBER_COMPANIES_REQUEST));
    const { data } = yield call(getMemberCompaniesQuery, payload);
    yield put(setLoading(Type.GET_MEMBER_COMPANIES_SUCCESS));

    if (!data) {
      yield put(
        getMemberCompaniesError(data.error || "Get member companies error.")
      );
    } else {
      yield put(getMemberCompaniesSuccess(data || []));
    }
  } catch (error) {
    yield put(setLoading(Type.GET_MEMBER_COMPANIES_ERROR));
  }
}

export function* getCompanyByIDSaga({ payload }) {
  try {
    yield put(setLoading(Type.GET_COMPANY_BY_ID_REQUEST));
    const { data } = yield call(getCompanyByIDQuery, payload);
    yield put(setLoading(Type.GET_COMPANY_BY_ID_SUCCESS));
    yield put(getCompanyByIDSuccess(data || []));
  } catch (error) {
    yield put(setViewAsCompany(''))
    yield put(setLoading(Type.GET_COMPANY_BY_ID_ERROR));
    yield put(getCompanyByIDError("Get company by ID error"));
  }
}

export function* getCurrentUserCompanySaga({ payload }) {
  try {
    yield put(setLoading(Type.GET_CURRENT_USER_COMPANY_REQUEST));
    const { data } = yield call(getCurrentUserCompanyQuery, payload);
    yield put(setLoading(Type.GET_CURRENT_USER_COMPANY_SUCCESS));

    if (!data) {
      yield put(
        getCurrentUserCompanyError(
          data.error || "Get current user company error."
        )
      );
    } else {
      yield put(getCurrentUserCompanySuccess(data || {}));
    }
  } catch (error) {
    yield put(setLoading(Type.GET_CURRENT_USER_COMPANY_ERROR));
  }
}

export default function* companySaga() {
  yield all([takeLatest(getMemberCompaniesRequest, getMemberCompaniesSaga)]);
  yield all([takeLatest(getCompanyByIDRequest, getCompanyByIDSaga)]);
  yield all([
    takeLatest(getCurrentUserCompanyRequest, getCurrentUserCompanySaga),
  ]);
}
