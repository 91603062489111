import axios from "axios";
import { BACKEND_API_ENDPOINT, IMessageType } from "../../constants/default";

const getHeaders = () => {
  let h = {
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const token = window.localStorage
    ? window.localStorage.getItem("accessToken")
    : undefined;
  if (token) {
    h["Authorization"] = `Bearer ${token}`;
  }

  return h;
};

export const getAllMessages = (type?: IMessageType) =>
  axios
    .get(`${BACKEND_API_ENDPOINT}/messages${type ? `/${type}` : ""}`, {
      headers: getHeaders(),
    })
    .then((response) => {
      return response?.data || [];
    })
    .catch((error) => {});

export const getMessageById = ({ id }) => {
  return axios
    .get(`${BACKEND_API_ENDPOINT}/messages/${id}`, { headers: getHeaders() })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {});
};

export const getMessageByCommitteeId = (committeeId) => {
  return axios
    .get(`${BACKEND_API_ENDPOINT}/messages?committee=${committeeId}`, {
      headers: getHeaders(),
    })
    .then((response) => {
      return response.data;
    });
};
