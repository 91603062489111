import {
  Flex,
  HStack,
  Select,
  Stack,
  StackDivider,
  Text,
  VStack,
  Box,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React, { useMemo, useState } from "react";
import { AREAS_OF_INTERESTS } from "../../constants/default";
import Pagination from "../common/Pagination";
import SectionHeader from "../Home/SectionHeader";
import EventGroup from "./EventGroup";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

interface IEventAgendaProps {
  events: any[];
}

const PAGE_SIZE = 5;

const groupEventsByDate = (events: any[]): { [key: string]: any[] } => {
  return events.reduce((prev, event) => {
    const date = dayjs(event.eventStartDate).format("YYYY-MM-DD");
    if (prev.hasOwnProperty(date)) {
      prev[date] = prev[date].concat(event);
    } else {
      prev[date] = [event];
    }
    return prev;
  }, {});
};

function EventAgenda(props: IEventAgendaProps) {
  const { events } = props;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [committeeFilter, setCommitteeFilter] = useState("All");

  const currentDate = dayjs().startOf("day");

  const filteredEventsList = useMemo(() => {
    return events.filter(
      ({ eventStartDate, committee }) =>
        dayjs(eventStartDate).diff(currentDate) >= 0 &&
        (committeeFilter === "All" ||
          ((!committee || committee?.name === "Business Roundtable") &&
            committeeFilter === "Other") ||
          (committee && committee.name === committeeFilter))
    );
  }, [committeeFilter, currentDate, events]);

  const eventsOfPage = useMemo(() => {
    return filteredEventsList.slice(
      (currentPage - 1) * PAGE_SIZE,
      currentPage * PAGE_SIZE
    );
  }, [currentPage, filteredEventsList]);

  return (
    <>
      <Stack
        w="100%"
        justifyContent="space-between"
        direction={{ base: "column", md: "row" }}
        spacing={4}
      >
        <SectionHeader title="Full Event Agenda" mb={0} />

        <HStack spacing={2}>
          <Text
            color="grey.700"
            fontSize="14"
            fontFamily="opensans"
            whiteSpace="nowrap"
          >
            Policy Area
          </Text>
          <Select
            color="grey.700"
            fontSize="12"
            fontFamily="opensans"
            h="33px"
            defaultValue="0"
            bg="white"
            border="none"
            w="auto"
            onChange={(e) => setCommitteeFilter(e.target.value)}
          >
            <option value="All">All</option>
            {AREAS_OF_INTERESTS.map((area) => (
              <option key={`area-${area.id}`} value={area.title}>
                {area.title}
              </option>
            ))}
          </Select>
        </HStack>
      </Stack>

      <VStack
        mt="32px !important"
        spacing="24px"
        w="100%"
        alignItems="flex-start"
        divider={<StackDivider borderColor="#D8D8D8" />}
      >
        {Object.entries(groupEventsByDate(eventsOfPage))?.length ? (
          Object.entries(groupEventsByDate(eventsOfPage)).map(([key, data]) => (
            <EventGroup key={key} date={dayjs(key)} events={data} />
          ))
        ) : (
          <Flex
            justifyContent="center"
            alignItems="center"
            mx="auto"
            minH={{ base: "100px", md: "400px" }}
            pt="40px"
          >
            <Text
              fontSize="24"
              lineHeight="29px"
              fontWeight="500"
              fontFamily="montserrat"
              textAlign="center"
              maxInlineSize={400}
            >
              There are no upcoming events scheduled for this policy area.
            </Text>
          </Flex>
        )}
        {filteredEventsList.length ? (
          <Pagination
            mt={{ base: 0, md: "-8px !important" }}
            total={filteredEventsList.length}
            pageSize={PAGE_SIZE}
            useOuter={true}
            outerCurrentPage={currentPage}
            setOuterCurrentPage={setCurrentPage}
          />
        ) : (
          <></>
        )}
      </VStack>
    </>
  );
}

export default EventAgenda;
