import { useState, useEffect } from "react";
import { IUpdateData } from "../components/Home/UpdateCard";
import dayjs from "dayjs";
import useMessages from "./useMessages";
import { useSelector } from "react-redux";

export default function useTheLatest() {
  const messages = useMessages();
  const updates = useSelector((state) => state.committee.updates);
  const [theLatest, setTheLatest] = useState<any[]>([]);

  useEffect(() => {
    const portalContents = (updates.committee || [])
      .filter(
        (update: IUpdateData) =>
          !(update && update.content && update.content.type === "event")
      )
      .sort((a, b) =>
        dayjs(a.content.publishedDate).isSameOrBefore(
          dayjs(b.content.publishedDate)
        )
          ? 1
          : -1
      )
      .slice(0, 12);

    const emails = messages ? messages.sort((a, b) =>
      dayjs(a.memberPortalDate).isSameOrBefore(dayjs(b.memberPortalDate))
        ? 1
        : -1
    ) : [];

    const uniqueContents = {};

    // const res = [...portalContents, ...emails]
    const res = emails
      .sort((a, b) => {
        return dayjs(
          a?.memberPortalDate || a?.content?.publishedDate
        ).isSameOrBefore(
          dayjs(b?.memberPortalDate || b?.content?.publishedDate)
        )
          ? 1
          : -1;
      })
      .filter((obj) => {
        if (!uniqueContents[obj.title]) {
          uniqueContents[obj.title] = true;
          return true;
        }
        return false;
      })
      .slice(0, 12);

    setTheLatest(res);
    // filter out all events
  }, [messages, updates.committee]);

  return theLatest;
}
