import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import routes from "../../constants/routes.json";
import useProfile from "../../hooks/useProfile";
import { getUserSettings } from "../../redux/auth/action";
import { checkAuth } from "../../utils";

const PrivateRoute = ({ component: Component, acl = [], ...rest }) => {
  const dispatch = useDispatch();
  const profile = useProfile();
  const webRole = profile?.webRole || null;

  useEffect(() => {
    if (checkAuth()) {
      dispatch(getUserSettings());
    }
  }, [checkAuth()]);

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page

    <Route
      {...rest}
      render={(props) =>
        checkAuth() ? (
          acl?.includes(webRole) ? (
            <Component {...props} />
          ) : (
            <Redirect to={routes.HOME} />
          )
        ) : (
          <Redirect to={routes.SIGNIN} />
        )
      }
    />
  );
};

export default PrivateRoute;
