import {
  Badge,
  Box,
  Flex,
  HStack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { ChangeEvent, useState } from "react";
import { TABLE_COLUMNS } from "../../constants/companyDemo";
import Pagination from "../common/Pagination";
import { PageSizeSelect } from "./PageSizeSelect";

function renderColumns(row: any, type: string = "td") {
  let Component = ({ children, ...props }: { children: any }) =>
    type === "td" ? (
      <Td {...props}>{children}</Td>
    ) : (
      <div {...props}>{children}</div>
    );

  return TABLE_COLUMNS.map((item: any) => (
    <Component
      key={item.name}
      fontSize="14px"
      lineHeight="24px"
      fontWeight="400"
      fontFamily="opensans"
    >
      {item.name === "name" && (
        <HStack justifyContent="space-between" w="240px">
          <Text fontWeight={600} fontSize="14px" lineHeight="24px">
            {row.name}
          </Text>{" "}
          <Text display={{ base: "none", md: "none" }}>{row.lastEvent}</Text>
        </HStack>
      )}

      {item.name === "title" && row[item.name] && (
        <Box mt={{ base: "4px", md: "0px" }} w="300px">
          {row[item.name]}
        </Box>
      )}

      {item.name === "areasOfInterest" &&
        row[item.name] &&
        row[item.name].map((tag: any, index: number) => (
          <Badge
            key={index}
            mr="6px"
            mb="10px"
            bg="grey.80"
            color="grey.600"
            fontSize="10px"
            lineHeight="34px"
            px="20px"
            fontWeight="600"
            fontFamily="opensans"
            h="34px"
            borderRadius="20px"
            textTransform="capitalize"
          >
            {tag.name}
          </Badge>
        ))}

      {item.name === "emailaddress1" && row[item.name] && (
        <Box mt={{ base: "4px", md: "0px" }}>{row[item.name]}</Box>
      )}
    </Component>
  ));
}

export interface StaffListProps {
  staffs: any[];
}

export function StaffList(props: StaffListProps) {
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const staffs = props?.staffs?.map((item: any) => ({
    ...item,
    name: `${item.firstname} ${item.lastname}`,
    lastEvent: item.lastEventAttended
      ? dayjs(item.lastEventAttended).format("MM/DD/YYYY")
      : "-",
  }));

  const filteredStaffs = staffs?.sort(
    (member1, member2) =>
      (member1.lastname.toLowerCase() > member2.lastname.toLowerCase() && 1) ||
      -1
  );

  const slicedStaffs = filteredStaffs
    ? filteredStaffs.slice((currentPage - 1) * pageSize, currentPage * pageSize)
    : [];

  const compactPagination = useBreakpointValue({ base: true, md: false });

  return (
    <VStack w="100%" mt="-4px !important">
      <HStack
        display={{ base: "flex", md: "none" }}
        justifyContent="space-between"
        w="100%"
      >
        <PageSizeSelect
          pageSize={pageSize}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            setCurrentPage(1);
            setPageSize(Number(e.target.value));
          }}
        />
        <Pagination
          total={filteredStaffs?.length}
          pageSize={pageSize}
          useOuter={true}
          outerCurrentPage={currentPage}
          setOuterCurrentPage={setCurrentPage}
          compact
        />
      </HStack>
      <Table
        variant="rounded"
        px={{ base: 0, xl: "0px" }}
        mt="0 !important"
        position="relative"
        // style={{ tableLayout: "fixed" }}
      >
        <Box
          position="absolute"
          top="4px"
          right="0"
          display={{ base: "none", md: "block" }}
        >
          <PageSizeSelect
            pageSize={pageSize}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              setCurrentPage(1);
              setPageSize(Number(e.target.value));
            }}
          />
        </Box>
        <Thead>
          <Tr display={{ base: "none", md: "table-row" }}>
            {TABLE_COLUMNS.map((item: any) => (
              <Th key={item.name} pb="0" pt="0" _first={{ paddingLeft: "0px" }}>
                <Flex align="center">
                  <Text
                    textTransform="none"
                    fontWeight={600}
                    lineHeight="24px"
                    fontSize="14px"
                    fontFamily="montserrat"
                    whiteSpace="nowrap"
                  >
                    {item.title}
                  </Text>
                </Flex>
              </Th>
            ))}
          </Tr>
        </Thead>

        <Tbody>
          {slicedStaffs.map((row: any, index: number) => (
            <Tr key={index} display={{ base: "none", md: "table-row" }}>
              {renderColumns(row, "td")}
            </Tr>
          ))}
          {slicedStaffs.map((row: any, index: number) => (
            <Tr key={index} display={{ base: "table-row", md: "none" }}>
              <Td p="16px 20px">{renderColumns(row, "div")}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Pagination
        total={filteredStaffs?.length}
        pageSize={pageSize}
        useOuter={true}
        outerCurrentPage={currentPage}
        setOuterCurrentPage={setCurrentPage}
        showItemShown
        compact={compactPagination}
      />
    </VStack>
  );
}
