import { handleActions } from "redux-actions";
import {
  getMemberCompaniesSuccess,
  getMemberCompaniesError,
  getCompanyByIDSuccess,
  getCompanyByIDError,
  getCurrentUserCompanySuccess,
  getCurrentUserCompanyError,
} from "./action";

const DEFAULT_STATE = {
  memberCompanies: [],
  currentCompany: {},
};

const handlers = {
  [getMemberCompaniesSuccess]: (state, action) => {
    return { ...state, memberCompanies: action.payload };
  },
  [getMemberCompaniesError]: (state, action) => {
    return { ...state, error: action.payload };
  },

  [getCompanyByIDSuccess]: (state, action) => {
    return { ...state, currentCompany: action.payload };
  },
  [getCompanyByIDError]: (state, action) => {
    return { ...state, error: action.payload };
  },

  [getCurrentUserCompanySuccess]: (state, action) => {
    return { ...state, currentCompany: action.payload };
  },
  [getCurrentUserCompanyError]: (state, action) => {
    return { ...state, error: action.payload };
  },
};

export default handleActions(handlers, DEFAULT_STATE);
