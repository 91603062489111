import * as React from "react";
import { Box } from "@chakra-ui/react";
import SectionHeader from "../Home/SectionHeader";
import PrimaryButton from "./PrimaryButton";

export interface ViewAllStaffButtonProps {}

export default function ViewAllStaffButton(props: ViewAllStaffButtonProps) {
  return (
    <Box w="100%">
      <SectionHeader title="BRT Staff" mb="24px" />
      <a
        href="https://www.businessroundtable.org/about-us/staff"
        target="_blank"
        rel="noreferrer"
      >
        <PrimaryButton>VIEW ALL STAFF</PrimaryButton>
      </a>
    </Box>
  );
}
