import React from 'react';

const MemberCenter = ({color}: {color: string}) => {
   return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path
            d="M18.8598 5V19H4.96368V5H18.8598ZM19.9516 3H3.87184C3.37555 3 2.97852 3.4 2.97852 3.9V20.1C2.97852 20.5 3.37555 21 3.87184 21H19.9516C20.3487 21 20.845 20.5 20.845 20.1V3.9C20.845 3.4 20.3487 3 19.9516 3ZM10.9192 7H16.8746V9H10.9192V7ZM10.9192 11H16.8746V13H10.9192V11ZM10.9192 15H16.8746V17H10.9192V15ZM6.94884 7H8.934V9H6.94884V7ZM6.94884 11H8.934V13H6.94884V11ZM6.94884 15H8.934V17H6.94884V15Z"
            fill={color}
         />
      </svg>
   );
};

export default MemberCenter;
