import { handleActions } from "redux-actions";
import { getHelpTopicsError, getHelpTopicsSuccess } from "./action";

const DEFAULT_STATE = {
  helpTopics: [],
  error: "",
};

const handlers = {
  [getHelpTopicsSuccess]: (state, action) => {
    return { ...state, helpTopics: action.payload };
  },

  [getHelpTopicsError]: (state, action) => {
    return { ...state, error: action.payload };
  },
};

export default handleActions(handlers, DEFAULT_STATE);
