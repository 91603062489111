import React from "react";
import { Avatar } from "@chakra-ui/react";

export interface ProfilePictureProps {
  image?: string;
  firstName?: string;
  lastName?: string;
  size?: number;
}

export default function ProfilePicture({
  image,
  firstName,
  lastName,
  size = 156,
}: ProfilePictureProps) {
  if (image) {
    return (
      <Avatar boxSize={`${size}px`} src={`data:image/png;base64,${image}`} />
    );
  }

  return (
    <div
      style={{
        width: size,
        height: size,
        borderRadius: "50%",
        backgroundColor: "var(--chakra-colors-grey-200)",
        fontSize: `calc(${size}px / 4)`,
        color: "var(--chakra-colors-white)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {`${firstName ? firstName[0].toUpperCase() : ""}${
        lastName ? lastName[0].toUpperCase() : ""
      }`}
    </div>
  );
}
