import { Box, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import HeadMeta from "../../components/common/HeadMeta";
import ForgotPasswordForm from "../../components/Onboard/ForgotPasswordForm";
import PublicLayout from "../../components/PublicLayout";
import routes from "../../constants/routes.json";
import { forgotPassword, setUserError } from "../../redux/auth/action";

function ForgotPassword({ userError, isAuthenticated }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [error, setError] = useState("");

  const onSubmit = (values: any) => {
    dispatch(forgotPassword(values));
  };

  const onBack = () => {
    history.push(routes.SIGNIN);
  };

  useEffect(() => {
    if (isAuthenticated) {
      history.push(routes.HOME);
    }

    if (userError) {
      setError(userError);
    }

    return () => dispatch(setUserError(""));
  }, [isAuthenticated]);

  return (
    <PublicLayout>
      <HeadMeta />

      <Flex
        bg="white"
        w="100%"
        justify="center"
        align="center"
        mt={{ base: "3.5rem", sm: "0px" }}
      >
        <Box w="500px">
          <ForgotPasswordForm
            onSubmit={onSubmit}
            onBack={onBack}
            userError={error}
          />
        </Box>
      </Flex>
    </PublicLayout>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userError: (state.auth && state.auth.error) || "",
    isAuthenticated: (state.auth && state.auth.isAuthenticated) || "",
  };
};

export default connect(mapStateToProps)(ForgotPassword);
