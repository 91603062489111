import React from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Icon,
} from "@chakra-ui/react";
import { MdHome } from "react-icons/md";
import { Link } from "react-router-dom";

const Breadcrumbs = ({ path }: { path: any[] }) => {
  const size = (path && path.length) || 0;
  const links = size > 1 ? path.slice(0, size - 1) : [];
  const lastIndex = size > 0 ? size - 1 : -1;
  const last = lastIndex > -1 ? path[lastIndex] : null;

  return path ? (
    <Breadcrumb
      separator=""
      bg="primary.100"
      overflow="hidden"
      borderRadius="0 17px 17px 0"
      fontSize="10px"
      lineHeight="10px"
      color="primary.500"
      fontFamily="opensans"
      fontWeight={600}
      minH="34px"
      h="34px"
      position="relative"
      w="fit-content"
      display="flex"
    >
      <BreadcrumbItem
        bg="grey.80"
        p="0 10px 0 32px"
        borderRadius="0 17px 17px 0"
        fontSize="10px"
        color="grey.600"
        lineHeight="10px"
        filter="drop-shadow(4px 0px 15px rgba(0, 0, 0, 0.05))"
        zIndex={51}
        minH="35px"
        top="-1px"
        position="relative"
        whiteSpace="nowrap"
      >
        <BreadcrumbLink href="/">
          <Icon as={MdHome} boxSize="18px" />
        </BreadcrumbLink>
      </BreadcrumbItem>

      {links.map((item: any, index: any) => (
        <BreadcrumbItem
          key={index}
          zIndex={50 - parseInt(index)}
          bg="grey.80"
          color="grey.600"
          borderRadius="0 17px 17px 0"
          ml="-20px"
          p="11px 8px 11px 35px"
          position="relative"
          minH="34px"
          filter="drop-shadow(4px 0px 15px rgba(0, 0, 0, 0.05))"
          whiteSpace="nowrap"
        >
          <BreadcrumbLink as={Link} to={item.url}>
            {item.title}
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}

      {last && (
        <BreadcrumbItem
          isCurrentPage
          isLastChild
          minH="34px"
          p={{ base: "10px 21px", md: "10px 24px" }}
          whiteSpace="nowrap"
        >
          <BreadcrumbLink>{last.title}</BreadcrumbLink>
        </BreadcrumbItem>
      )}
    </Breadcrumb>
  ) : null;
};

export default Breadcrumbs;
