import { all } from "redux-saga/effects";
import auth from "./auth/saga";
import ui from "./ui/saga";
import committee from "./committee/saga";
import article from "./article/saga";
import event from "./event/saga";
import helpCenter from "./helpCenter/saga";
import companySaga from "./company/saga";

function* rootSaga() {
  yield all([
    auth(),
    ui(),
    committee(),
    article(),
    event(),
    helpCenter(),
    companySaga(),
  ]);
}

export default rootSaga;
