import { Box, Stack, Text, useMediaQuery, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeadMeta from "../components/common/HeadMeta";
import SectionHeader from "../components/Home/SectionHeader";
import Layout from "../components/Layout";
import POVBar from "../components/Layout/POVBar";
import TwoColLayout from "../components/Layout/TwoColLayout";
import { MembershipRequestForm } from "../components/MemberCenter/MembershipRequestForm";
import ProfileInfo from "../components/MemberCenter/ProfileInfo";
import { StaffList } from "../components/MemberCenter/StaffList";
import { WEB_ROLES } from "../constants/default";
import useScrollPosition from "../hooks/useScrollPosition";
import { getCommitteesRequest } from "../redux/committee/action";
import {
  getCompanyByIDRequest,
  getCurrentUserCompanyRequest,
} from "../redux/company/action";
import "../styles/memberCustomStyling.css";

function MemberCenter() {
  const scrollPosition = useScrollPosition();
  const [isMD] = useMediaQuery("(min-width: 768px)");
  const dispatch = useDispatch();

  const {
    viewAsCompanyID,
    profile: { webRole = null, company: userCompany },
  } = useSelector((state) => state.auth);
  const currentCompany = useSelector((state) => state.company.currentCompany);
  const company = currentCompany?.id ? currentCompany : userCompany;
  const isSuperAdmin = [WEB_ROLES.SUPER_ADMIN, WEB_ROLES.CEO].includes(webRole);
  useEffect(() => {
    if (isSuperAdmin && viewAsCompanyID) {
      dispatch(getCompanyByIDRequest(viewAsCompanyID));
    } else if (!viewAsCompanyID) {
      dispatch(getCurrentUserCompanyRequest());
    }
  }, [viewAsCompanyID, isSuperAdmin]);

  useEffect(() => {
    dispatch(getCommitteesRequest());
  }, []);

  return (
    <>
      <Layout
        path={[{ title: "Member Center" }]}
        subHeader={
          isSuperAdmin ? <POVBar fixed={scrollPosition !== 0} /> : undefined
        }
      >
        <HeadMeta title="BRT Member Portal | Member Center" />
        {/* Page top */}
        {/* Profile block */}
        <TwoColLayout
          mt={{ base: "0px !important", md: "8px !important" }}
          spacing={{ base: 0, lg: 12 }}
        >
          <Stack
            direction={{ base: "column", md: "row" }}
            justifyContent={{ base: "flex-start", md: "space-between" }}
            spacing={{ base: 6, md: "auto" }}
          >
            <VStack align="flex-start" spacing="40px">
              <ProfileInfo
                image={company?.ceoImage}
                firstName={
                  company?.ceoName ? company?.ceoName.split(" ")[0] : ""
                }
                lastName={
                  company?.ceoName && company?.ceoName.split(" ").length > 1
                    ? company?.ceoName.split(" ")[1]
                    : ""
                }
                size={isMD ? 156 : 108}
                subTitle={company?.ceoTitle}
              />
            </VStack>

            <VStack align="flex-start" spacing="40px">
              <ProfileInfo
                image={company?.logoImage}
                firstName={company?.name}
                size={isMD ? 156 : 108}
                subTitle={company?.location}
              />
            </VStack>
          </Stack>
          <Box></Box>
        </TwoColLayout>

        {/* Contact info */}
        <TwoColLayout
          mt={{ base: "40px !important", md: "40px !important" }}
          spacing={{ base: 0, lg: 12 }}
        >
          <Stack
            direction={{ base: "column", md: "row" }}
            justifyContent={{ base: "flex-start", md: "space-between" }}
          >
            <VStack align="flex-start" spacing="40px">
              {company?.contactEmail && (
                <Box>
                  <Text
                    as="h3"
                    fontSize="16px"
                    lineHeight="20px"
                    fontWeight="600"
                    mb="8px"
                    fontFamily="montserrat"
                  >
                    Member Email
                  </Text>

                  <Text
                    fontSize="14px"
                    lineHeight="19px"
                    fontWeight="400"
                    fontFamily="opensans"
                    color="primary.500"
                  >
                    <a
                      href={`mailto:${company?.contactEmail}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {company?.contactEmail}
                    </a>
                  </Text>
                </Box>
              )}
            </VStack>

            <VStack align="flex-start" spacing="40px"></VStack>
          </Stack>
          <Box></Box>
        </TwoColLayout>

        {/* Bottom */}
        <TwoColLayout mt="40px !important">
          {/* List */}
          <VStack flex="1">
            {/* Table */}
            <SectionHeader
              title="Member Company Staff List"
              subTitle="This is a comprehensive list of your company staff who are in the BRT database. Each contact does not necessarily receive BRT communications. To add or remove staff from this list, or to view a list of staff receiving BRT communications, please fill out the request form."
              w="100%"
              mb={6}
            />
            <StaffList staffs={company?.members} />
          </VStack>

          {/* Form */}
          <MembershipRequestForm />
        </TwoColLayout>
      </Layout>
    </>
  );
}

export default MemberCenter;
