import { EVENT_TYPES } from "./events";

export const BACKEND_API_ENDPOINT =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BACKEND_DEPLOY
    : process.env.REACT_APP_BACKEND_HOST;

export const CardType = { News: "news", Article: "article", Event: "event" };
export const AREAS_OF_INTERESTS = [
  { id: 1, title: "Corporate Governance", type: EVENT_TYPES.corporate },
  {
    id: 2,
    title: "Corporate Initiatives",
    type: EVENT_TYPES.corporateInitiatives,
  },
  { id: 3, title: "Education and Workforce", type: EVENT_TYPES.education },
  { id: 4, title: "Energy and Environment", type: EVENT_TYPES.energy },
  { id: 5, title: "Health and Retirement", type: EVENT_TYPES.health },
  { id: 6, title: "Immigration", type: EVENT_TYPES.immigration },
  { id: 7, title: "Infrastructure", type: EVENT_TYPES.infrastructure },
  { id: 8, title: "Smart Regulation", type: EVENT_TYPES.smart },
  { id: 9, title: "Tax and Fiscal Policy", type: EVENT_TYPES.tax },
  { id: 10, title: "Technology", type: EVENT_TYPES.technology },
  { id: 11, title: "Trade and International", type: EVENT_TYPES.trade },
  { id: 12, title: "Other", type: EVENT_TYPES.other },
];

export const EXTRA_AREAS_OF_INTERESTS = [
  { id: 1, title: "Business Roundtable", type: EVENT_TYPES.businessRoundtable },
];

export const pageSize = 10;
export const autocompleteDispalayItems = 6;

export const ENEVTS_VISIBILITY = {
  CEO: "CEOs only",
  BRT: "all BRT members",
};

export const PASSWORD_PATTERN =
  /^\S*(?=\S{8,})(?=\S*[a-z])(?=\S*[A-Z])(?=\S*[\d])(?=\S*[\W])\S*$/;
export const EMAIL_PATTERN =
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; // eslint-disable-line

export const PASSWORD_REQUIREMENTS = [
  "8 character minimum",
  "At least one uppercase letter",
  "At least one lowercase letter",
  "At least one number",
  "At least one special character",
];

export const NETWORKS = [
  { id: 1, name: "instagram", title: "Instagram" },
  { id: 2, name: "facebook", title: "Facebook" },
  { id: 3, name: "twitter", title: "Twitter" },
  { id: 4, name: "linkedin", title: "LinkedIn" },
];

export const LOCATIONS = [
  { id: 1, name: "Company HQ", title: "Company HQ" },
  { id: 2, name: "Washington, DC", title: "Washington, DC" },
  { id: 3, name: "Other", title: "Other" },
];

export const MEMBER_STATUS = [
  {
    id: 1,
    name: "Not Active",
    title: "You have not expressed interest in Immigration Updates",
    styles: { color: "#D39843", bg: "#FBF9D8" },
  },
  {
    id: 2,
    name: "Member",
    title: "Member",
    styles: { color: "#027A3D", bg: "#AFE3CD" },
  },
  {
    id: 3,
    name: "Interested",
    title: "You have expressed interest in Immigration Updates",
    styles: { color: "#027A3D", bg: "#BCF0D8" },
  },
];

export const WEB_ROLES = {
  SUPER_ADMIN: "SuperAdmin",
  CEO: "CEO",
  ADMIN: "Administrator",
  STANDARD: "Standard",
};

export type IMessageType =
  | "updates"
  | "priority"
  | "leadership"
  | "government-relations"
  | "communications"
  | "antitrust-and-legal-advisory";

export const MESSAGE_TYPE: Record<string, string> = {
  updates: "Updates",
  priority: "Priority Issues Update & Resources",
  leadership: "Leadership Communications",
  "government-relations": "Government Relations",
  communications: "Communications",
};

export type SearchResultType =
  | "event"
  | "email"
  | "document"
  | "committee"
  | "company";

export const SearchResultTypes = {
  event: "Upcomming Events",
  email: "Emails & Updates",
  document: "Publised Documents",
  committee: "Committee",
  company: "Members Company",
};

export const colors = {
  immigration: "#D5D3E9",
  corporate_governance: "#F1E2FF",
  education_and_workforce: "#FBF9D8",
  energy_and_environment: "#BADFCF",
  health_and_retirement: "#FBEFD3",
  infrastructure: "#C8E6FB",
  smart_regulation: "#BCF0D8",
  tax_and_fiscal_policy: "#C4D7ED",
  technology: "#FFDBC7",
  trade_and_international: "#FBCACA",
  corporate_initiatives: "#dddddd",
};

export const CORPORATE_INITIATIVES = "corporate_initiatives";

export const TOKEN_KEY = "accessToken";
export const USER_KEY = "user";
