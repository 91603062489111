import axios from "axios";
import { BACKEND_API_ENDPOINT } from "../../constants/default";

const getHeaders = () => {
  let h = {
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const token = window.localStorage
    ? window.localStorage.getItem("accessToken")
    : undefined;
  if (token) {
    h["Authorization"] = `Bearer ${token}`;
  }

  return h;
};

export const getEvents = () => {
  return axios
    .get(`${BACKEND_API_ENDPOINT}/event`, { headers: getHeaders() })
    .then((response) => {
      return response.data;
    });
};

export const getEvent = ({ id }) => {
  return axios
    .get(`${BACKEND_API_ENDPOINT}/event/${id}`, { headers: getHeaders() })
    .then((response) => {
      return response.data;
    });
};

export const getEventLocations = () => {
  return axios
    .get(`${BACKEND_API_ENDPOINT}/event/locations`, { headers: getHeaders() })
    .then((response) => {
      return response.data;
    });
};

export const getEventByCommitteeLocation = (id) => {
  return axios
    .get(
      `${BACKEND_API_ENDPOINT}/event/locations/committee/${id}`,
      { headers: getHeaders() }
    )
    .then((response) => {
      return response.data;
    });
};