export const WEEK_DAYS = {
  1: {title: 'Monday'},
  2: {title: 'Tuesday'},
  3: {title: 'Wednesday'},
  4: {title: 'Thursday'},
  5: {title: 'Friday'},
}

export const HOUR_SLOTS = [
  {value: 8, title: "8 AM"},
  {value: 9, title: "9 AM"},
  {value: 10, title: "10 AM"},
  {value: 11, title: "11 AM"},
  {value: 12, title: "12 AM"},
  {value: 13, title: "1 PM"},
  {value: 14, title: "2 PM"},
  {value: 15, title: "3 PM"},
  {value: 16, title: "4 PM"},
  {value: 17, title: "5 PM"},
  {value: 18, title: "6 PM"},
]

export const EVENT_TYPES = {
  health: {
    image: '/images/committees/health.svg',
    bgColor: '#fbefd3',
    iconColor: '#D39843',
  },
  immigration: {
    image: '/images/committees/immigration.svg',
    bgColor: '#d5d3e9',
    iconColor: '#54538F',
  },
  infrastructure: {
    image: '/images/committees/infrastructure.svg',
    bgColor: '#c8e6fb',
    iconColor: '#2E84C1',
  },
  trade: {
    image: '/images/committees/trade.svg',
    bgColor: '#fbcaca',
    iconColor: '#BE3839',
  },
  technology: {
    image: '/images/committees/technology.svg',
    bgColor: '#FFDBC7',
    iconColor: '#F4864B',
  },
  energy: {
    image: '/images/committees/energy.svg',
    bgColor: '#badfcf',
    iconColor: '#027A3D',
  },
  education: {
    image: '/images/committees/education.svg',
    bgColor: '#fbf9d8',
    iconColor: '#FBD60C',
  },
  corporate: {
    image: '/images/committees/corporate.svg',
    bgColor: '#cac7dc',
    iconColor: '#382E6A',
  },
  smart: {
    image: '/images/committees/smart.svg',
    bgColor: '#bcf0d8',
    iconColor: '#00A056',
  },
  tax: {
    image: '/images/committees/tax.svg',
    bgColor: '#c4d7ed',
    iconColor: '#265C9A',
  },
  racialEquity: {
    image: '/images/demo/preview-2.svg',
    bgColor: '#DDDDDD',
    iconColor: '#4D4D4E',
  },
  defaultCommittee: {
    image: '/images/committees/default-committee.svg',
    bgColor: '#D4D3E9',
    iconColor: '#54538F',
  },
  corporateInitiatives: {
    image: '/images/committees/corporate-initiatives.svg',
    bgColor: '#DDDDDD',
    iconColor: '#4D4D4E',
  },
  businessRoundtable: {
    image: '/images/committees/br-badge.svg',
    bgColor: '#DDDDDD',
    iconColor: '#333333',
  },
  other: {
    image: '/images/committees/other.svg',
    bgColor: '#b7b7b7',
    iconColor: '#b7b7b7',
  },
  brand: {
    image: '/images/logo.svg',
    bgColor: '#C4C4C4',
    iconColor: '#333333',
  },
}
