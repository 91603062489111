import { Box, Text, UnorderedList } from "@chakra-ui/react";
import React from "react";
import Layout from "../components/Layout";
import useScrollToTop from '../hooks/useScrollToTop';

interface PrivacyPolicyProps {}

export default function PrivacyPolicy(props: PrivacyPolicyProps) {
  useScrollToTop()
  return (
    <Layout showHeader={false}>
      <Box mt={{ base: "32px", md: "0px" }}>
        <Text
          fontSize={{ base: "24px" }}
          lineHeight={{ base: "29px" }}
          fontWeight={700}
          fontFamily="montserrat"
          mb={{ base: "32px", sm: "40px" }}
          alignSelf="flex-start"
        >
          Business Roundtable Privacy Policy & Terms of Use
        </Text>
        <Text
          fontFamily="montserrat"
          fontSize="20px"
          lineHeight="24px"
          fontWeight="700"
          as="h2"
          mb="24px"
        >
          <strong>Privacy Policy</strong>
        </Text>
        <p>Last Updated: March 30, 2023</p>
        <br />
        <p>
          <strong>Overview</strong>
        </p>
        <br />
        <p>
          Business Roundtable ("Business Roundtable," "us," or "we") is
          committed to respecting and protecting your privacy. We want you to
          understand how we may collect information about you through our
          websites, and how that information may be used, maintained, and in
          some cases shared. This privacy policy ("Privacy Policy") sets forth
          the privacy practices and policies governing our data collection
          practices including relating to data collected via the websites that
          we operate and that link to this Privacy Policy, including
          <a href="https://www.businessroundtable.org/">
            https://www.businessroundtable.org/
          </a>{" "}
          and any portal, interface, or application used in connection with such
          websites (referred to collectively, as (referred to collectively, as
          the "Website").
        </p>
        <br />
        <p>
          BY USING OR ACCESSING THE WEBSITE OR BY PROVIDING PERSONAL INFORMATION
          TO US ON OR THROUGH THE WEBSITE, YOU CONSENT TO THE COLLECTION, USE
          AND DISCLOSURE OF THAT INFORMATION IN ACCORDANCE WITH THIS PRIVACY
          POLICY AND APPLICABLE LAW.
        </p>
        <br />
        <p>Please read our Privacy Policy to understand:</p>
        <br />
        <p>
          <span>
            <strong>Information We Collect</strong>
          </span>
        </p>
        <UnorderedList pl="2rem">
          <li>
            <span>
              <em>Information You Provide to Us</em>
            </span>
          </li>
          <li>
            <span>
              <em>Website Use Information</em>
            </span>
          </li>
          <li>
            <span>
              <em>Use of Cookies</em>
            </span>
          </li>
          <li>
            <span>
              <em>Social Networking Activities</em>
            </span>
          </li>
        </UnorderedList>
        <p>
          <span>
            <strong>How We Use Information</strong>
          </span>
        </p>

        <p>
          <span>
            <strong>Opting Out of Communications</strong>
          </span>
        </p>

        <p>
          <span>
            <strong>Disclosing Information to Third Parties</strong>
          </span>
        </p>

        <p>
          <span>
            <strong>Security</strong>
          </span>
        </p>

        <p>
          <span>
            <strong>Links to Other Websites</strong>
          </span>
        </p>

        <p>
          <span>
            <strong>Child Privacy</strong>
          </span>
        </p>

        <p>
          <span>
            <strong>[California Privacy Rights]</strong>
          </span>
        </p>

        <p>
          <span>
            <strong>EU/UK-Specific Notice</strong>
          </span>
        </p>

        <p>
          <span>
            <strong>Access from Outside the United States</strong>
          </span>
        </p>
        <p>
          <span>
            <strong>Governing Law</strong>
          </span>
        </p>
        <p>
          <span>
            <strong>Changes to this Privacy Policy</strong>
          </span>
        </p>
        <p>
          <span>
            <strong>Contact</strong>
          </span>
        </p>
        <br />
        <p>
          <span>
            <strong>Information We Collect</strong>
          </span>
        </p>
        <p>
          <span>
            The following describes the types of personal and other information
            we may collect about you, and how we use and maintain that
            information:
          </span>
        </p>
        <br />
        <p>
          <span>
            <em>Information You Provide to Us</em>
          </span>
        </p>
        <br />
        <p>
          <span>
            Business Roundtable may gather and retain personally identifiable
            information about you, including name, email address, physical
            address, phone number, or other financial, employment, or background
            information ("Personal Information"), when you voluntarily submit it
            to us, including for the purpose of accessing certain features of
            the Website. We may use any information that you provide for our
            general purposes, and may share it with third parties only as
            described in this Privacy Policy.
          </span>
        </p>
        <p>
          <span>
            If you provide us with a telephone number, address or an email
            address, you expressly agree that we, or our authorized agents, can
            use that information to contact you about Business Roundtable and
            its associated activities.
          </span>
        </p>
        <p>
          <span>
            To the extent permitted by applicable law, we may keep any
            information that you provide to us indefinitely.
          </span>
        </p>
        <br />
        <p>
          <span>
            <em>Website Use Information</em>
          </span>
        </p>
        <br />
        <p>
          <span>
            Our web servers may collect technical information, including IP
            address, browser type, domain names, access times and referring
            website addresses of visitors to our Website. We may use this
            information to measure the use of our Website, including number of
            visits, average time spent on the Website, pages viewed, etc., and
            to improve the content we offer.
          </span>
        </p>
        <br />
        <p>
          <span>
            We may also use technical information to determine what Website
            services are most popular.
          </span>
        </p>
        <br />
        <p>
          <span>
            We do not use technology that recognizes a "do-not-track" signal
            from your web browser.
          </span>
        </p>
        <br />
        <p>
          <span>
            <em>Use of Cookies</em>
          </span>
        </p>
        <br />
        <p>
          <span>
            Like most websites, we employ "cookies" or similar technologies on
            certain pages of our Website. Cookies make the use of the Website
            easier by, among other things, saving your preferences. We may also
            use cookies to deliver content tailored to your interests. For more
            information about how we use cookies and similar technologies,
            please see our Cookie Notice.
          </span>
        </p>
        <br />
        <p>
          <span>
            <em>Social Networking Activities</em>
          </span>
        </p>
        <br />
        <p>
          <span>
            Our Website includes the ability for you and other users to link to
            social networking websites. As a result, we may receive information
            about you when you choose to post or otherwise share information
            about our Website on these social networking websites. We may use
            such information about you in a variety of ways, including to
            administer the Website and enhance your experience with the Website,
            and to communicate with you about the Website and new offerings or
            activities associated with the Website. We may keep all of this
            information indefinitely, as permitted by law.
          </span>
        </p>
        <br />
        <p>
          <span>
            <strong>How We Use Information</strong>
          </span>
        </p>
        <br />
        <p>
          <span>
            We may process your Personal Information for the following purposes
            in line with this Privacy Policy:
          </span>
        </p>
        <UnorderedList pl="2rem">
          <li>
            <p>to fulfill your requests;</p>
          </li>
          <li>
            <p>to manage the Website;</p>
          </li>
          <li>
            <p>
              to communicate with you about membership, events and education;
            </p>
          </li>
          <li>
            <p>to detect and prevent fraud or other financial crime;</p>
          </li>
          <li>
            <p>
              to monitor and protect the security of our information, systems
              and network;
            </p>
          </li>
          <li>
            <p>
              to notify you about changes to our Website or other services; and
            </p>
          </li>
          <li>
            <p>to conduct research and analysis.</p>
          </li>
        </UnorderedList>
        <br />
        <p>
          <span>
            <strong>Disclosing Information to Third Parties</strong>
          </span>
        </p>
        <br />
        <p>
          <span>
            We will not share, rent, sell or otherwise disclose any Personal
            Information that we collect about you through our Website, except in
            any of the following situations:
          </span>
        </p>
        <UnorderedList pl="2rem">
          <li>
            <p>
              You request or authorize the release of your Personal Information.
            </p>
          </li>
          <li>
            <p>
              We may disclose Personal Information that we collect about you to
              our third-party contractors who perform services for us in
              connection with the Website, or to complete or confirm a
              transaction or series of transactions that you conduct with us. We
              may also disclose Personal Information to service providers or
              suppliers if the disclosure will enable that party to perform
              business, professional or technical support for us.
            </p>
          </li>
          <li>
            <p>
              We may disclose Personal Information about you as part of a
              merger, acquisition or other sale or transfer of the assets or
              business of Business Roundtable. We do not guarantee that any
              entity receiving such information in connection with one of these
              transactions will comply with all terms of this Privacy Policy.
            </p>
          </li>
          <li>
            <p>
              We may disclose Personal Information about you to comply with the
              law, applicable regulations, governmental and quasi-governmental
              requests, court orders or subpoenas, to enforce our Terms of Use
              or other agreements, or to protect our rights, property or safety
              or the rights, property or safety of our users or others. We
              reserve the right to release information that we collect to law
              enforcement or other government officials, as we, in our sole and
              absolute discretion, deem necessary or appropriate.
            </p>
          </li>
        </UnorderedList>
        <br />
        <p>
          <span>
            We may also share aggregated or anonymous information that cannot
            identify you with third parties. For example, we may disclose the
            number of visitors to our Website or the number of people who have
            downloaded a particular document, and we may disclose statistical
            information based on responses to online surveys and other data
            collection tools on our website.
          </span>
        </p>
        <br />
        <p>
          <span>
            <strong>Security</strong>
          </span>
        </p>
        <br />
        <p>
          <span>
            You should keep in mind that the Website is run on software,
            hardware and networks, any component of which may, from time to
            time, require maintenance or experience problems or breaches of
            security beyond our control.
          </span>
        </p>
        <br />
        <p>
          <span>
            While we take steps to protect your Personal Information and keep it
            secure, you also play a role in protecting your information. You
            must maintain the security of your online transactions by not
            sharing your account information and passwords with any unauthorized
            parties.
          </span>
        </p>
        <br />
        <p>
          <span>
            Please also be aware that despite our best intentions and the
            guidelines outlined in this Privacy Policy, no data transmission
            over the Internet or encryption method can be guaranteed to be 100%
            secure. Business Roundtable cannot guarantee the security of any
            information you transmit to us or from our Website.
          </span>
        </p>
        <br />
        <p>
          <span>
            <strong>Links to Other Websites</strong>
          </span>
        </p>
        <br />
        <p>
          <span>
            Our Website contains links to other websites. However, this Privacy
            Policy only addresses Business Roundtable's use and disclosure of
            your information collected on our Website, if any. If you choose to
            visit an external website linked from our Website, you will leave
            our Website. We are not responsible for the privacy practices of any
            third parties or the content of linked websites. We encourage you to
            read the applicable privacy policies and terms and conditions of
            such parties or websites.
          </span>
        </p>
        <br />
        <p>
          <span>
            <strong>Child Privacy</strong>
          </span>
        </p>
        <br />
        <p>
          <span>
            The Website is not designed or intended to be attractive to use by
            children. It is not our intention to collect Personal Information
            from anyone under 18 years of age, and we will not knowingly do so.
            If we are made aware that we have collected any Personal Information
            from children under the age of 18, and are asked to delete such
            information from our databases, we will promptly do so.
          </span>
        </p>
        <br />
        <p>
          <strong>EU/UK-Specific Notice</strong>
        </p>
        <br />
        <p>
          <span>
            These terms apply to Personal Information collected by Business
            Roundtable if you are an EU or UK resident. "Personal Information"
            as used in this section means any information that enables us to
            identify you either directly or indirectly. Where Business
            Roundtable processes Personal Information about you, such
            information is controlled by Business Roundtable which is
            headquartered in the United States at Business Roundtable, 300 New
            Jersey Avenue, NW, Suite 800, Washington, DC 20001.
          </span>
        </p>
        <br />
        <p>
          <span>
            We process your Personal Information for purposes described in this
            Privacy Policy where we have a legal basis under applicable European
            and UK data protection law. We will store your Personal Information,
            in a form that permits us to identify you, for no longer than is
            necessary for the purpose for which the Personal Information is
            processed.
          </span>
        </p>
        <br />
        <p>
          <span>
            Subject to applicable law, in certain circumstances you may have the
            right to access, correct, restrict the processing of, delete or
            transfer the Personal Information we hold about you. We will
            generally ask for your consent in line with the applicable law
            before sending you direct marketing communications related to third
            party products and services.
          </span>
        </p>
        <br />
        <p>
          <span>
            Where you believe that we have not complied with our obligations
            under this Privacy Policy or applicable law, we ask that you contact
            us first to see if we can resolve the issue. However, you may have
            the right to lodge a complaint with an appropriate supervisory
            authority.
          </span>
        </p>
        <br />
        <p>
          <span>
            <strong>Access from Outside the United States</strong>
          </span>
        </p>
        <br />
        <p>
          <span>
            The Personal Information we may receive may be held on our
            computers, systems, and servers in the United States. If you access
            the Website from outside of the United States, information that we
            collect about you will be transferred to servers inside the United
            States, which may involve the transfer of information out of your
            country of origin. By allowing us to collect information about you,
            you consent to such transfer and processing of your data, as
            described in this Privacy Policy.
          </span>
        </p>
        <br />
        <p>
          <span>
            <strong>Governing Law</strong>
          </span>
        </p>
        <br />
        <p>
          <span>
            By choosing to visit our Website or provide information to us, you
            agree that any dispute over privacy or the terms contained in this
            Privacy Policy will be governed by the law of the District of
            Columbia. You also agree to abide by any limitation on damages
            contained in our Terms of Use, or other agreement that we have with
            you.
          </span>
        </p>
        <br />
        <p>
          <span>
            <strong>Changes to this Privacy Policy</strong>
          </span>
        </p>
        <br />
        <p>
          <span>
            We may occasionally amend this Privacy Policy to reflect Business
            Roundtable activities and user feedback, and we reserve the right to
            make changes to this Privacy Policy at any time. The use of your
            information is subject to the Privacy Policy and Terms of Use in
            effect at the time of use. The provisions contained in this Privacy
            Policy supersede all previous notices or policies regarding our
            privacy practices with respect to our Website. Please check the
            "Last Updated" legend at the top of this page to see when this
            Privacy Policy was last revised. We encourage you to check
            frequently to see the current Privacy Policy to be informed of how
            Business Roundtable is committed to protecting your information and
            providing you with improved content on our Website to enhance your
            experience.
          </span>
        </p>
        <br />
        <p>
          <span>
            <strong>Contact</strong>
          </span>
        </p>
        <br />
        <p>
          <span>
            If you have any questions or comments regarding our Privacy Policy
            or our Website, please contact us at info@businessroundtable.org or
            by phone at 202-872-1260.
          </span>
        </p>
        <br />
        <br />
        <br />

        <Text
          fontFamily="montserrat"
          fontSize="20px"
          lineHeight="24px"
          fontWeight="700"
          as="h2"
        >
          <strong>Terms of Use</strong>
        </Text>
        <br />
        <p>
          <span>Last Updated: March 30, 2023</span>
        </p>
        <br />
        <p>
          <span>
            This Terms of Use Agreement ("Agreement") is a legal agreement
            between you and Business Roundtable ("Business Roundtable", "we", or
            "us"), governing your use of the websites that we operate that link
            to this Terms of Use, including
            <a href="https://www.businessroundtable.org/">
              https://www.businessroundtable.org/
            </a>{" "}
            and any portal, interface, or application used in connection with
            such websites (referred to collectively, as the "Website") By using
            or accessing the Website or downloading materials from the Website,
            you agree to be legally bound by this Agreement.
          </span>
        </p>
        <br />
        <p>
          <span>
            <strong>Updates to this Agreement</strong>
          </span>
        </p>
        <p>
          <span>
            <strong>Authorized Use</strong>
          </span>
        </p>
        <p>
          <span>
            <strong>Intellectual Property Rights</strong>
          </span>
        </p>
        <p>
          <span>
            <strong>User-Submitted Information</strong>
          </span>
        </p>
        <p>
          <span>
            <strong>Secure User Accounts</strong>
          </span>
        </p>
        <p>
          <span>
            <strong>Changes to the Website</strong>
          </span>
        </p>
        <p>
          <span>
            <strong>Third Party Content</strong>
          </span>
        </p>
        <p>
          <span>
            <strong>Links to Third Party Websites</strong>
          </span>
        </p>
        <p>
          <span>
            <strong>Social Media Pages</strong>
          </span>
        </p>
        <p>
          <span>
            <strong>Notice of Copyright Infringement</strong>
          </span>
        </p>
        <p>
          <span>
            <strong>Other Policies and Terms</strong>
          </span>
        </p>
        <p>
          <span>
            <strong>Termination</strong>
          </span>
        </p>
        <p>
          <span>
            <strong>Children</strong>
          </span>
        </p>
        <p>
          <span>
            <strong>Disclaimer of Warranty</strong>
          </span>
        </p>
        <p>
          <span>
            <strong>Limitation of Liability</strong>
          </span>
        </p>
        <p>
          <span>
            <strong>Indemnity</strong>
          </span>
        </p>
        <p>
          <span>
            <strong>Consent to Communication</strong>
          </span>
        </p>
        <p>
          <span>
            <strong>Effect of Invalidity</strong>
          </span>
        </p>
        <p>
          <span>
            <strong>Waivers</strong>
          </span>
        </p>
        <p>
          <span>
            <strong>International Users</strong>
          </span>
        </p>
        <p>
          <span>
            <strong>Choice of Law</strong>
          </span>
        </p>
        <p>
          <span>
            <strong>Contact</strong>
          </span>
        </p>
        <br />
        <p>
          <span>
            <strong>Updates to this Agreement</strong>
          </span>
        </p>
        <br />
        <p>
          <span>
            We may revise or otherwise change or update this Agreement. Please
            check the "Last Updated" legend at the top of this page to see when
            this Agreement was last revised. Changes will become effective
            immediately after they are posted. A current version of this
            Agreement showing the effective date is always available at this
            location. We encourage you to periodically review this Agreement to
            see if there have been any changes that may affect you. If you do
            not agree to this Agreement as modified, then you must discontinue
            your use of the Website. Your continued use of the Website will
            signify your continued agreement to this Agreement as it may be
            revised from time to time.
          </span>
        </p>
        <br />
        <p>
          <span>
            <strong>Authorized Use</strong>
          </span>
        </p>
        <br />
        <p>
          <span>
            While using the Website, you are required to comply with all
            applicable statutes, orders, regulations, rules, and other laws. You
            may not use the Website for any fraudulent or unlawful purpose, and
            you may not take any action to interfere with the Website or any
            other user's use of the Website. In addition, we expect users of the
            Website to respect the rights and dignity of others. By way of
            example and not of limitation, you may not (and you expressly agree
            that you will not) do any of the following, which violate this
            Agreement:
          </span>
        </p>
        <br />
        <UnorderedList pl="2rem">
          <li>
            <p>
              Post, upload, share, transmit, distribute, facilitate distribution
              of or otherwise make available to or through the Website any
              unlawful, infringing, harmful, harassing, defamatory, threatening,
              intimidating, fraudulent, tortious, vulgar or otherwise
              objectionable material of any kind, including unauthorized or
              unsolicited advertising;
            </p>
          </li>
          <li>
            <p>
              Reproduce, duplicate, or copy any portion of the Website, except
              as authorized by this Agreement;
            </p>
          </li>
          <li>
            <p>
              Sell, resell, or otherwise exploit for any commercial purposes any
              portion of the use of or access to the Website without the prior
              written consent of Business Roundtable;
            </p>
          </li>
          <li>
            <p>
              Impersonate any person or entity, falsely state or otherwise
              misrepresent your affiliation with any person or entity in
              connection with the Website, or express or imply that we endorse
              any statement you make;
            </p>
          </li>

          <li>
            <p>
              Remove any copyright, trademark, or other proprietary rights
              notice from the Website or materials originating from the Website;
            </p>
          </li>

          <li>
            <p>Violate or attempt to violate the security of the Website;</p>
          </li>
          <li>
            <p>
              Disseminate on the Website any viruses, worms, spyware, adware, or
              other malicious computer code, file or program that is harmful,
              invasive or may or is intended to damage or hijack the operation
              of, or monitor the use of, any hardware, software, or equipment;
            </p>
          </li>
          <li>
            <p>
              Use any data mining, bots, spiders, automated tools or similar
              data gathering and extraction methods, directly or indirectly, on
              the Website or to collect any information from the Website or any
              other user of the Website; or
            </p>
          </li>
          <li>
            <p>
              Assist or permit any persons in violating this Agreement or
              applicable statutes, orders, regulations, rules, and other laws
              governing the use of the Website.
            </p>
          </li>
        </UnorderedList>
        <br />
        <p>
          <span>
            <strong>Intellectual Property Rights</strong>
          </span>
        </p>
        <br />
        <p>
          <span>
            All information, materials, or other content ("Content") available
            through the Website is protected by copyrights, trademarks, or other
            proprietary rights and laws.
          </span>
        </p>
        <p>
          <span>
            You may use Content purposely made available by us for public use,
            provided that you (a) keep intact all copyright and other
            proprietary notices, (b) do not copy or post such Content on any
            networked computer or broadcast it in any media, (d) make no
            modifications to the Content, and (e) do not make any additional
            representations or warranties relating to the Content on behalf of
            Business Roundtable.
          </span>
        </p>
        <br />
        <p>
          <span>
            Except as set forth herein or otherwise agreed in writing by
            Business Roundtable or other rights owner(s), the use of any Content
            available on the Website is strictly prohibited.
          </span>
        </p>
        <br />
        <p>
          <span>Any rights not expressly granted herein are reserved.</span>
        </p>
        <br />
        <p>
          <span>
            <strong>User-Submitted Information</strong>
          </span>
        </p>
        <br />
        <p>
          <span>
            You are responsible for any Content you transmit through our
            Website. You agree, represent, and warrant that any Content you
            transmit through our Website or to us is truthful, accurate, not
            misleading and offered in good faith, and that you have the right to
            transmit such Content. You shall not upload, post, or otherwise make
            available on or through the Website any Content protected by
            copyright, trademark, or other proprietary right of any third party
            without the express written permission of the owner of such right(s)
            or the authority to do so. You shall be solely liable for any
            damages resulting from any infringement of copyright, trademark, or
            other proprietary rights, or any other harm resulting from such a
            submission.
          </span>
        </p>
        <br />
        <p>
          <span>
            We do not want you to, and you should not, send any confidential or
            proprietary Content to us unless specifically requested by us.
            Please note that any unsolicited Content sent to Business Roundtable
            will be deemed not to be confidential or proprietary.
          </span>
        </p>
        <br />
        <p>
          <span>
            You also agree that Business Roundtable is free to use any ideas,
            concepts, know-how or techniques that you send to us for any
            purpose.
          </span>
        </p>
        <br />
        <p>
          <span>
            <strong>Secure User Accounts</strong>
          </span>
        </p>
        <br />
        <p>
          <span>
            Certain portions of our Website are accessible only to users who
            have registered with the Website and obtained login credentials
            (Secure Users). If you are a Secure User, you agree to accurately
            maintain and update any information about yourself and your account
            that you have provided to Business Roundtable.
          </span>
        </p>
        <br />
        <p>
          <span>
            You further agree that you are responsible for all activities that
            occur under your Secure User account. You are responsible for
            maintaining the confidentiality of your login credentials and you
            agree not to share your login credentials with any unauthorized
            parties. You also agree to notify us promptly of any unauthorized
            use of your login credentials or any other breach of security that
            you become aware of involving or relating to the Website.
          </span>
        </p>
        <br />
        <p>
          <span>
            Business Roundtable reserves the right to take any and all action,
            as it deems necessary or reasonable, to maintain the security of the
            Website and your account, including without limitation, terminating
            your account, changing your password or requesting information to
            authorize transactions on your account.
          </span>
        </p>
        <p>
          <span>
            We explicitly disclaim liability for any and all losses and damages
            arising from your failure to comply with this section.
          </span>
        </p>
        <br />
        <p>
          <span>
            <strong>Changes to the Website</strong>
          </span>
        </p>
        <br />
        <p>
          <span>
            We may make improvements and/or changes to the Website, add new
            features, or terminate the Website at any time without notice. We
            also: (a) reserve the right (but have no obligation) to change the
            Content or other offerings on the Website, at any time and from time
            to time without any notice or liability to you or any other person;
            and (b) do not warrant that information on the Website is accurate,
            complete, reliable, current, or error-free. Some jurisdictions may
            not allow the exclusions and disclaimers of certain implied
            warranties, so some of the provisions of this section may not apply
            to you.
          </span>
        </p>
        <br />
        <p>
          <span>
            <strong>Third Party Content</strong>
          </span>
        </p>
        <br />
        <p>
          <span>
            Any information, statements, opinions, or other Content provided by
            third parties and made available on our Website are those of the
            respective author(s) and not Business Roundtable. We do not
            guarantee the validity, accuracy, truthfulness, completeness,
            reliability, or usefulness of any information, statement, opinion,
            or other Content on our Website other than from an authorized
            Business Roundtable representative acting in his or her official
            capacity. Under no circumstance will Business Roundtable be liable
            for any loss or damage caused, directly or indirectly, by your
            reliance on any such third-party Content.
          </span>
        </p>
        <br />
        <p>
          <span>
            <strong>Links to Third-Party Websites</strong>
          </span>
        </p>
        <br />
        <p>
          <span>
            Business Roundtable may provide on the Website, solely as a
            convenience to users, links to websites operated by third parties.
            If you use these links, you will leave our Website. If you decide to
            visit any linked website, you do so at your own risk and it is your
            responsibility to take all protective measures to guard against
            viruses or other destructive elements. Business Roundtable does not
            make any warranty or representation regarding, or endorse or
            otherwise sponsor, any linked websites or the information appearing
            thereon or any of the products or services described thereon. Links
            do not imply that Business Roundtable is legally authorized to use
            any trademark, trade name, logo, or copyright symbol displayed in or
            accessible through the links, or that any linked website is
            authorized to use any trademark, trade name, logo, or copyright
            symbol of Business Roundtable.
          </span>
        </p>
        <br />
        <p>
          <span>
            YOU AGREE THAT YOUR USE OF THIRD-PARTY WEBSITES AND RESOURCES,
            INCLUDING WITHOUT LIMITATION YOUR USE OF ANY CONTENT, INFORMATION,
            DATA, ADVERTISING, PRODUCTS, OR OTHER MATERIALS ON OR AVAILABLE
            THROUGH SUCH WEBSITES AND RESOURCES, IS AT YOUR OWN RISK AND IS
            SUBJECT TO THE TERMS AND CONDITIONS OF USE APPLICABLE TO SUCH
            WEBSITES AND RESOURCES.
          </span>
        </p>
        <br />
        <p>
          <span>
            <strong>Social Media Pages</strong>
          </span>
        </p>
        <br />
        <p>
          <span>
            Business Roundtable may maintain a presence on social media
            websites, including Twitter, LinkedIn, Medium, Facebook, YouTube,
            and Instagram (collectively, "Social Media Pages"), to provide a
            place for the public to learn more about Business Roundtable and to
            share comments. All comments, visuals and other materials posted by
            visitors to our Social Media Pages do not necessarily reflect the
            opinions or ideas of Business Roundtable. All visitors to our Social
            Media Pages must comply with the respective social media website's
            Terms of Use. We review some but not all postings to our Social
            Media Pages, and may remove postings that we determine are
            inappropriate or offensive.
          </span>
        </p>
        <br />
        <p>
          <span>
            <strong>Notice of Copyright Infringement</strong>
          </span>
        </p>
        <br />
        <p>
          <span>
            If you believe that any Content on the Website infringes upon any
            copyright which you own or control, you may contact us
            atinfo@businessroundtable.org.
          </span>
        </p>
        <br />
        <p>
          <span>
            <strong>Other Policies and Terms</strong>
          </span>
        </p>
        <br />
        <p>
          <span>
            This Agreement applies exclusively to your access to, and use of,
            the Website and does not alter in any way the terms or conditions of
            any other agreement you may have with Business Roundtable.
            Additional policies and terms may apply to use of all or portions of
            the Website and are incorporated by reference into this Agreement.
            Please refer to and review all additional specific terms and
            conditions as applicable, including Business Roundtable's Privacy
            Policy.
          </span>
        </p>
        <br />
        <p>
          <span>
            <strong>Termination</strong>
          </span>
        </p>
        <br />
        <p>
          <span>
            The Website and this Agreement are in effect until terminated by
            Business Roundtable. In addition to any right or remedy that may be
            available to Business Roundtable under applicable law, Business
            Roundtable may suspend, limit, or terminate all or a portion of your
            access to the Website or any of its features at any time with or
            without notice and with or without cause, including without
            limitation, if Business Roundtable believes that you have violated
            or acted inconsistently with the letter or spirit of this Agreement.
            The provisions of this Agreement concerning authorized use,
            intellectual property rights, disclaimer of warranty, limitation of
            liability and indemnity, as well as any other provisions that by
            their nature should survive, shall survive any such termination.
          </span>
        </p>
        <br />
        <p>
          <span>
            You agree that if your use of the Website is terminated pursuant to
            this Agreement, you will not attempt to use the Website under any
            name, real or assumed. You further agree that if you violate this
            restriction after your use of the Website is terminated, you will
            indemnify and hold us harmless from any and all liability that we
            may incur therefor. We reserve the right to have all violators
            prosecuted to the fullest extent of the law.
          </span>
        </p>
        <br />
        <p>
          <span>
            <strong>Children</strong>
          </span>
        </p>
        <br />
        <p>
          <span>
            This Website is intended for use only by persons over the age of 18.
            We do not seek to collect information about children under the age
            of 18, and we will not knowingly do so. If you are under the age of
            18, please do not use or access the Website. By using the Website,
            you affirm that you are over the age of 18.
          </span>
        </p>
        <br />
        <p>
          <span>
            <strong>Disclaimer of Warranty</strong>
          </span>
        </p>
        <br />
        <p>
          <span>
            Business Roundtable does not guarantee, represent, or warrant that
            your use of the Website will be uninterrupted and you agree that
            from time to time Business Roundtable may suspend a Website for
            indefinite periods of time for technical maintenance, upgrades, or
            other reasons.
          </span>
        </p>
        <br />
        <p>
          <span>
            Business Roundtable DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS
            REGARDING THE USE, VALIDITY, ACCURACY, RELIABILITY OF, OR OTHERWISE
            RESPECTING THE CONTENT AVAILABLE ON THE WEBSITE OR ANY OTHER WEBSITE
            LINKED TO OR FROM THE WEBSITE. DOWNLOADING OR OTHERWISE OBTAINING
            ANY CONTENT THROUGH THE WEBSITE IS DONE AT YOUR OWN RISK. THE
            CONTENT OF THE WEBSITE IS PROVIDED "AS IS" AND ON AN "AS AVAILABLE"
            BASIS, WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED. TO
            THE FULLEST EXTENT POSSIBLE UNDER APPLICABLE LAW, Business
            Roundtable DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING
            BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
            FOR A PARTICULAR PURPOSE, AND NONINFRINGEMENT.
          </span>
        </p>
        <br />
        <p>
          <span>
            Some jurisdictions may not allow the exclusions and disclaimers of
            certain implied warranties, so some of the provisions of this
            section may not apply to you.
          </span>
        </p>
        <br />
        <p>
          <span>
            <strong>Limitation of Liability</strong>
          </span>
          <span></span>
        </p>
        <br />
        <p>
          <span>
            Business Roundtable AND EACH OF ITS DIRECTORS, OFFICERS, EMPLOYEES,
            AGENTS AND CONTRACTORS (COLLECTIVELY, THE "RELEASEES") WILL NOT BE
            LIABLE FOR ANY DAMAGES OF ANY KIND ARISING OUT OF OR RELATING TO THE
            USE OF OR THE INABILITY TO USE THE WEBSITE, THE WEBSITE CONTENT OR
            LINKS, INCLUDING BUT NOT LIMITED TO DAMAGES CAUSED BY OR RELATED TO
            ERRORS, OMISSIONS, INTERRUPTIONS, DEFECTS, DELAYS IN OPERATION OR
            TRANSMISSION, COMPUTER VIRUSES OR LINE FAILURES. THE RELEASEES SHALL
            NOT HAVE ANY LIABILITY OR RESPONSIBILITY FOR ANY ACTS, OMISSIONS OR
            CONDUCT OF ANY USER OR OTHER THIRD PARTY.
          </span>
        </p>
        <br />
        <p>
          <span>
            THE RELEASEES WILL ALSO NOT BE LIABLE FOR ANY INDIRECT, SPECIAL,
            INCIDENTAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, EVEN IF THEY have
            BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS
            DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR
            CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONs MAY NOT APPLY TO
            YOU.
          </span>
        </p>
        <br />
        <p>
          <span>
            <strong>Indemnity</strong>
          </span>
        </p>
        <br />
        <p>
          <span>
            You agree to indemnify, defend and hold harmless Business Roundtable
            and its directors, officers, employees, agents, and contractors from
            and against any and all claims, damages, losses, costs (including
            without limitation reasonable attorneys' fees), or other expenses
            that arise directly or indirectly out of or from (a) your breach of
            any provision of this Agreement; (b) your activities in connection
            with the Website; or (c) unsolicited information you provide to
            Business Roundtable through the Website.
          </span>
        </p>
        <br />
        <p>
          <span>
            <strong>Consent to Communication</strong>
          </span>
        </p>
        <br />
        <p>
          <span>
            If you provide us with a telephone number, address, or email
            address, you expressly agree that we, or our authorized agents, can
            use that telephone number, address, or email address to contact you.
          </span>
        </p>
        <br />
        <p>
          <span>
            When you use the Website or send emails to Business Roundtable, you
            are communicating with Business Roundtable electronically. You
            consent to receive electronically any communications related to your
            use of the Website. We may communicate with you by email or by
            posting notices on the Website. You agree that all agreements,
            notices, disclosures, and other communications that are provided to
            you electronically satisfy any legal requirement that such
            communications be in writing. All notices from Business Roundtable
            intended for receipt by a user shall be deemed delivered and
            effective when sent to the email address you provide on the Website.
          </span>
        </p>
        <br />
        <p>
          <span>
            <strong>Effect of Invalidity</strong>
          </span>
        </p>
        <br />
        <p>
          <span>
            In the event a court having jurisdiction finds any portion of this
            Agreement unenforceable, that portion shall not be effective and the
            remainder of the Agreement shall remain effective.
          </span>
        </p>
        <br />
        <p>
          <span>
            <strong>Waivers</strong>
          </span>
        </p>
        <br />
        <p>
          <span>
            No waiver, express or implied, by either party of any breach of or
            default under this Agreement will constitute a continuing waiver of
            such breach or default or be deemed to be a waiver of any preceding
            or subsequent breach or default.
          </span>
        </p>
        <br />
        <p>
          <span>
            <strong>International Users</strong>
          </span>
        </p>
        <br />
        <p>
          <span>
            The Website is controlled, operated, and administered by Business
            Roundtable (or its licensees) from its offices within the United
            States of America and is not intended to subject Business Roundtable
            to the laws or jurisdiction of any state, country, or territory
            other than that of the United States. Business Roundtable DOES NOT
            REPRESENT OR WARRANT THAT THE WEBSITE OR ANY PART THEREOF IS
            APPROPRIATE OR AVAILABLE FOR USE IN ANY PARTICULAR JURISDICTION
            OTHER THAN THE UNITED STATES. Those who choose to access the Website
            do so on their own initiative and at their own risk, and are
            responsible for complying with all statutes, orders, regulations,
            rules, and other laws of applicable jurisdictions.
          </span>
        </p>
        <br />
        <p>
          <span>
            <strong>Choice of Law</strong>
          </span>
        </p>
        <br />
        <p>
          <span>
            You agree that any dispute in connection with the Website, this
            Agreement, or the Privacy Policy will be governed by the laws of the
            District of Columbia. You also consent to the adjudication of any
            disputes arising in connection with the Website in courts located in
            the District of Columbia.
          </span>
        </p>
        <br />
        <p>
          <span>
            <strong>Contact</strong>
          </span>
        </p>
        <br />
        <p>
          <span>
            If you have questions about this Agreement, or if you have technical
            questions about the operation of the Website, please contact us via
            email at info@businessroundtable.org or by phone at 202-872-1260.
          </span>
        </p>
      </Box>
    </Layout>
  );
}
