import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  HStack,
  VStack,
  Text,
  Box,
  RadioGroup,
  Stack,
  Radio,
} from "@chakra-ui/react";
import Layout from "../components/Layout";
import HeadMeta from "../components/common/HeadMeta";
import {
  getMessageDetailByCommitteeId,
  getCommittee,
} from "../services/committee";
import { MEMBER_STATUS } from "../constants/default";
import ApplyModal from "../components/common/ApplyModal";
import { setMembershipRequest } from "../redux/committee/action";
import { useDispatch, useSelector } from "react-redux";
import DownloadIcon from "../components/Icons/Download";
import "../styles/message.css";
import { setLoading } from "../redux/ui/action";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const getStaticContent = (committeeName) => {
  if (committeeName === "covid_19_response") {
    return {
      id: "covid_19_response",
      name: "COVID-19 Response",
      content: [],
      referenceMaterials: [],
      workingToward: [],
      workingGroups: [],
    };
  } else if (committeeName === "racial_equity_and_justice") {
    return {
      id: "racial_equity_and_justice",
      name: "Racial Equity & Justice",
      content: [],
      referenceMaterials: [],
      workingToward: [],
      workingGroups: [],
    };
  }
};

function MessageDetail() {
  const params: any = useParams();
  const [message, setMessage] = useState<any>({});
  const [status, setStatus] = useState();
  const [newStatus, setNewStatus] = useState("Not Active");
  const [showModal, setShowModal] = useState(false);
  const [committeeData, setCommitteeData] = useState<any>({});
  const [committee, setCommittee] = useState<any>({});
  const dispatch = useDispatch();
  const query: any = useQuery();
  const { profile } = useSelector((state) => state.auth);

  useEffect(() => {
    if (params.id) {
      const fetchData = async () => {
        dispatch(setLoading("GET_COMMITTEE_MESSAGE_DETAIL_REQUEST"));
        try {
          const type = query.get("type");
          const id = params.id;
          const emailId = params.emailId;
          const committeeRes = await getCommittee({ id, type });
          setCommittee(committeeRes);
          const message = await getMessageDetailByCommitteeId({ id: emailId });
          setMessage(message);
          dispatch(setLoading("GET_COMMITTEE_MESSAGE_DETAIL_SUCCESS"));
        } catch (error) {
          dispatch(setLoading("GET_COMMITTEE_MESSAGE_DETAIL_ERROR"));
        }
      };

      fetchData();
    }
  }, [params.id]);

  useEffect(() => {
    if (committee.id) {
      if (
        params.id === "covid_19_response" ||
        params.id === "racial_equity_and_justice"
      ) {
        setCommitteeData(getStaticContent(params.id));
      } else {
        setCommitteeData(committee);
      }
    }
  }, [committee, params.id]);

  useEffect(() => {
    let newStatus = "Not Active";
    if (committeeData && profile) {
      if (profile.areasOfInterest) {
        profile.areasOfInterest.forEach((a) => {
          if (a.name === committeeData.name || a.id === committeeData.id) {
            newStatus = "Interested";
          }
        });

        profile.committees.forEach((c) => {
          if (c.name === committeeData.name || c.id === committeeData.id) {
            newStatus = "Member";
          }
        });
      }
    }

    updateStatus(newStatus);
  }, [profile, committeeData]);

  useEffect(() => {
    if (showModal) {
      const currentStatus = status && status.name ? status.name : "Not Active";

      if (profile?.userType && profile?.userType.toLowerCase() === "ceo") {
        setNewStatus("Interested");
      } else if (
        profile?.userType &&
        profile?.userType.toLowerCase() === "staff"
      ) {
        if (currentStatus === "Not Active") {
          setNewStatus("Interested");
        } else if (currentStatus === "Interested") {
          setNewStatus("Not Active");
        }
      }
    }
  }, [showModal]);

  const updateStatus = (s: string) => {
    const newStatus = MEMBER_STATUS.find((item: any) => item.name === s);

    setStatus(newStatus);
  };

  const updateMemberStatus = () => {
    if (!status || (status && status.name !== newStatus)) {
      let ms = "";
      switch (newStatus) {
        case "Interested":
          ms = "interested";
          break;
        case "Member":
          ms = "join";
          break;
        case "Not Active":
          ms = "leave";
          break;
      }

      dispatch(
        setMembershipRequest({
          name: committeeData.key || committeeData.id,
          membership: ms,
          type: query.get("type") ? "subcommittee" : "committee",
        })
      );
      setShowModal(false);
      updateStatus(newStatus);
    }
  };

  const ModalSectionAddInterested = () => {
    return (
      <>
        <Radio size="lg" value="Interested">
          Interested
        </Radio>
        <Text fontSize="14px" fontFamily="opensans" lineHeight="19px" pl="28px">
          Selecting the "Interested" status automatically adds you to receive
          communications and updates from the committee.
        </Text>
      </>
    );
  };

  const ModalSectionAddMember = () => {
    return (
      <>
        <Radio size="lg" value="Member">
          Join Committee
        </Radio>
        <Text fontSize="14px" fontFamily="opensans" lineHeight="19px" pl="28px">
          Selecting the "Join Committee" status makes you an active member of
          the committee.
        </Text>
      </>
    );
  };

  const ModalSectionRemoveInterested = () => {
    return (
      <>
        <Radio size="lg" value="Not Active">
          Remove Interest
        </Radio>
        <Text fontSize="14px" fontFamily="opensans" lineHeight="19px" pl="28px">
          Selecting the "Remove Interest" status automatically removes you from
          receiving communications and updates from the committee.
        </Text>
      </>
    );
  };

  const ModalSectionRemoveMember = () => {
    return (
      <>
        <Radio size="lg" value="Not Active">
          Leave Committee
        </Radio>
        <Text fontSize="14px" fontFamily="opensans" lineHeight="19px" pl="28px">
          Selecting the "Leave Committee" status removes you as an active member
          of the committee.
        </Text>
      </>
    );
  };

  // const getMemberStatusTitle = (title) => {
  //   if (title.includes("Immigration")) {
  //     const newTitle = title.replace("Immigration", committeeData.name);
  //     return newTitle;
  //   }
  //   return title;
  // };

  const getAttachment = (attachment: string) => {
    const arr = attachment.split("/");
    const index = arr.length - 1;
    const attachmentInfo = arr[index];
    const fileInfoArr = attachmentInfo.split(".");
    const fileExtensionIndex = fileInfoArr.length - 1;
    const fileExtension = fileInfoArr[fileExtensionIndex];
    const fileName = fileInfoArr.slice(0, fileExtensionIndex).join(".");

    let fileType = "";
    if (fileExtension === "docx" || fileType === "doc") {
      fileType = "Microsoft Word";
    } else if (fileExtension === "ppt") {
      fileType = "Microsoft Powerpoint";
    } else {
      fileType = "PDF";
    }

    return (
      <HStack bg="white" borderRadius="17px" p="15px 24px">
        <VStack alignItems="flex-start" mr="75px">
          <Text
            fontSize="16px"
            fontWeight="600"
            as="h4"
            pb="8px"
            lineHeight="21px"
            color="#2D9CDB"
            fontFamily="montserrat"
          >
            {fileName}
          </Text>
          <Text
            fontSize="16px"
            fontWeight="400"
            as="h5"
            lineHeight="24px"
            color="#202020"
            fontFamily="opensans"
          >
            {fileType}
          </Text>
        </VStack>
        <VStack cursor="pointer" onClick={() => downloadFile(attachment)}>
          <DownloadIcon color="#0D7DBD" />
        </VStack>
      </HStack>
    );
  };

  const downloadFile = (path: string) => {
    const filePath = path.includes("https://") ? path : "https://" + path;
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";

    a.href = filePath;
    a.target = "_blank";
    a.click();
    window.URL.revokeObjectURL(filePath);
  };

  const backgroundWhiteRegex = /style="background-color:white"/g;

  let messageBody =
    message && message.body
      ? /<div[^>]*>[\s\S]*<\/div>/gi
          .exec(message.body)
          ?.toString()
          .replaceAll(backgroundWhiteRegex, "")
      : "";
  const attachments = message && message.attachments ? message.attachments : [];

  return (
    <Layout
      path={[
        {
          title: "BRT Inbox",
          url: `/committees/${committeeData?.key || ""}/updates`,
        },
        { title: "Update" },
      ]}
    >
      <HeadMeta
        title={`BRT Member Portal | ${
          message && message.title ? message.title : "Committee Update"
        }`}
      />

      <HStack w="100%" spacing="48px" align="flex-start">
        <VStack w={{ base: "60%", xl: "76%" }} flex="1" align="flex-start">
          <Text
            fontSize="36px"
            fontWeight="600"
            as="h2"
            pt="8px"
            lineHeight="44px"
            color="grey.950"
            fontFamily="montserrat"
          >
            {message && message.title}
          </Text>
          <Box
            className="message-body"
            fontWeight="400"
            pt="0"
            pb="32px"
            lineHeight="22px"
            color="grey.950"
            fontFamily="opensans"
          >
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: messageBody }}
            ></div>
          </Box>
          {attachments.length > 0 && (
            <VStack pt="48px" alignItems="flex-start">
              <Text
                fontSize="20px"
                fontWeight="600"
                as="h2"
                pb="16px"
                lineHeight="24px"
                color="#202020"
                fontFamily="montserrat"
              >
                Attachments
              </Text>
              {attachments.map((attachment, index) => (
                <VStack key={index}>{getAttachment(attachment)}</VStack>
              ))}
            </VStack>
          )}
        </VStack>
        {/* <VStack
          w={{ base: "40%", xl: "24%" }}
          display={{ base: "none", lg: "flex" }}
        >
          <VStack w="100%" alignItems="flex-start">
            <HStack
              alignItems="center"
              justifyContent="flex-start"
              w="100%"
              pt="5px"
            >
              <Text
                fontSize="16px"
                fontFamily="montserrat"
                fontWeight="600"
                lineHeight="18px"
              >
                Subscription
              </Text>
              <Icon
                as={MdEdit}
                boxSize="30"
                color="primary.500"
                pl="10px"
                onClick={() => setShowModal(true)}
                cursor="pointer"
              />
            </HStack>
            <HStack pb="24px" width="100%" textAlign="center" m="0">
              {
                <Badge
                  fontSize="14px"
                  fontFamily="opensans"
                  fontWeight="400"
                  p="8px 12px"
                  borderRadius="6px"
                  textTransform="none"
                  whiteSpace="pre-wrap"
                  {...(status ? status.styles : MEMBER_STATUS[0].styles)}
                >
                  {status
                    ? getMemberStatusTitle(status.title)
                    : `You have not expressed interest in ${committeeData.name} Updates`}
                </Badge>
              }
            </HStack>
          </VStack>
        </VStack> */}
      </HStack>

      {status && (
        <ApplyModal
          show={showModal}
          onClose={() => setShowModal(false)}
          onApply={() => updateMemberStatus()}
        >
          <Text
            fontSize="20px"
            fontFamily="montserrat"
            fontWeight="600"
            lineHeight="24px"
            pb="24px"
          >
            Change Your Committee Status
          </Text>
          <RadioGroup onChange={setNewStatus} value={newStatus}>
            <Stack direction="column">
              {profile?.userType &&
                profile?.userType.toLowerCase() === "ceo" && (
                  <>
                    {(!status.name || status.name === "Not Active") && (
                      <>
                        <ModalSectionAddInterested />
                        <ModalSectionAddMember />
                      </>
                    )}
                    {status.name === "Interested" && (
                      <>
                        <ModalSectionRemoveInterested />
                        <ModalSectionAddMember />
                      </>
                    )}
                    {status.name === "Member" && (
                      <>
                        <ModalSectionAddInterested />
                        <ModalSectionRemoveMember />
                      </>
                    )}
                  </>
                )}
              {profile?.userType &&
                profile?.userType.toLowerCase() === "staff" && (
                  <>
                    {(!status.name || status.name === "Not Active") && (
                      <ModalSectionAddInterested />
                    )}
                    {status.name === "Interested" && (
                      <ModalSectionRemoveInterested />
                    )}
                  </>
                )}
            </Stack>
          </RadioGroup>
        </ApplyModal>
      )}
    </Layout>
  );
}

export default MessageDetail;
