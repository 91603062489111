import { Box, GridItem, Text, VStack } from "@chakra-ui/react";
import { snakeCase } from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { colors } from "../../constants/default";
import { cardShadowHover } from "../../styles/theme";

interface INewCommitteeCardProps {
  committee: any;
}

function NewCommitteeCard(props: INewCommitteeCardProps) {
  const { committee } = props;

  return (
    <GridItem>
      <Link
        to={
          committee.category === "Corporate Initiatives"
            ? `/${committee.key}`
            : committee.url ||
              `/committees/${
                committee.key || snakeCase(committee.name || committee.title)
              }`
        }
        onClick={() => window.scrollTo(0, 0)}
      >
        <VStack
          spacing="0"
          _hover={{ filter: cardShadowHover }}
          willChange="transform"
          align="stretch"
          h="100%"
        >
          <Box
            w="100%"
            h={{ base: "68px", md: "96px" }}
            // bgColor={
            //   committee.key === "corporate_governance"
            //     ? "#F1E2FF"
            //     : committee.bgColor || "#D4D3E9"
            // }
            // bgImage={committee.image || "/images/committees/br-badge.svg"}
            bgImage={
              !committee
                ? "/images/committees/br-badge.svg"
                : `/images/committees/${snakeCase(
                    committee.name || committee.title
                  )}.svg`
            }
            bgColor={
              !committee
                ? "#DDDDDD"
                : colors[snakeCase(committee.name || committee.title)]
            }
            bgRepeat="no-repeat"
            bgPosition="center"
            bgSize="auto 48px"
            borderTopRadius="16px"
          />
          <Box
            bgColor="#fff"
            w="100%"
            h={{ base: "calc(100% - 68px)", md: "calc(100% - 96px)" }}
            p={{ base: "16px 24px", md: "24px 24px" }}
            borderBottomRadius="16px"
          >
            <Text
              color="#202020"
              fontSize="14px"
              lineHeight="17px"
              fontWeight="700"
              textTransform="uppercase"
              fontFamily="Montserrat"
            >
              {committee.name || committee.title}
            </Text>
            <Text
              color="#3F3F3F"
              fontSize="14px"
              lineHeight="19px"
              fontWeight="400"
              mt="12px"
            >
              {committee.description}
            </Text>
          </Box>
        </VStack>
      </Link>
    </GridItem>
  );
}

export default NewCommitteeCard;
