import axios from "axios";
import { BACKEND_API_ENDPOINT } from "../../constants/default";

const getHeaders = () => {
  let h = {
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const token = window.localStorage
    ? window.localStorage.getItem("accessToken")
    : undefined;
  if (token) {
    h["Authorization"] = `Bearer ${token}`;
  }

  return h;
};

export const getHelpTopics = () => {
  return axios
    .get(`${BACKEND_API_ENDPOINT}/article/help`, { headers: getHeaders() })
    .then((response) => {
      return response;
    });
};
