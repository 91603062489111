import { all, takeLatest, put } from "redux-saga/effects";
import { setError, setLoading, setUILoading } from "./action";
import routes from "../../constants/routes.json";
import history from "../../history";

export function* setErrorSaga({ payload }) {
  try {
    const error = payload.error;
    yield put(setLoading());

    if (history && error) {
      let route;

      if (!error.response) {
        route = routes.PAGE_NO_CONNECTION;
      } else {
        const status = error.response.status;

        switch (status) {
          case 401:
            route = routes.PAGE_NOT_AUTORIZED;
            break;
          case 403:
            route = routes.PAGE_FORBIDDEN;
            break;
          default:
            route = routes.PAGE_NOT_FOUND;
        }
      }

      route && history.push(route);
    }
  } catch (error) {
  }
}

export function* setLoadingSaga({ payload = null }) {
  yield put(setLoading(payload));
}

export default function* uiManagerSaga() {
  yield all([takeLatest(setError, setErrorSaga)]);
  yield all([takeLatest(setUILoading, setLoadingSaga)]);
}