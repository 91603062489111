import {
  HStack,
  Select,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { BiCaretDown } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { CommitteeProfileBlock } from "../components/Committees/CommitteeProfileBlock";
import { EmailAndUpdateCard } from "../components/Committees/EmailAndUpdateCard";
import HeadMeta from "../components/common/HeadMeta";
import Pagination from "../components/common/Pagination";
import SectionHeader from "../components/Home/SectionHeader";
import Layout from "../components/Layout";
import TwoColLayout from "../components/Layout/TwoColLayout";
import routes from "../constants/routes.json";
import { useCommitteeDetail } from "../hooks/useCommitteeDetail";
import useCommitteeLeads from "../hooks/useCommitteeLeads";
import { useMEPQuery } from "../hooks/useMEPQuery";
import { setLoading } from "../redux/ui/action";
import { getMessagesByCommitteeId } from "../services/committee";
import { isCorporateInitiatives } from "../utils";

var dayjsUTC = require("dayjs/plugin/utc");
var dayjsTimezone = require("dayjs/plugin/timezone");
var dayjsAdvancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(dayjsUTC);
dayjs.extend(dayjsTimezone);
dayjs.extend(dayjsAdvancedFormat);

function CommitteesUpdates() {
  const pageSize = 25;
  const dispatch = useDispatch();
  const params: any = useParams();
  const query: any = useMEPQuery();
  const type = query.get("type") || false;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortDropdown, setSortDropdown] = useState<string>("0");
  const [messages, setMessages] = useState([]);
  const committee = useCommitteeDetail(params.id, type);
  const committeeLeads = useCommitteeLeads(committee?.id);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLoading("GET_COMM_UPDATES_REQUEST"));
      try {
        const messages = await getMessagesByCommitteeId({ id: committee.id });
        setMessages(messages);
        dispatch(setLoading("GET_COMM_UPDATES_SUCCESS"));
      } catch (error) {
        dispatch(setLoading("GET_COMM_UPDATES_ERROR"));
      }
    };

    fetchData();
  }, [committee.id]);

  const fileteredMessages = messages.sort((result1, result2) => {
    if (sortDropdown === "0") {
      return (
        (new Date(result1.memberPortalDate) >
          new Date(result2.memberPortalDate) &&
          -1) ||
        1
      );
    } else if (sortDropdown === "1") {
      return (
        (new Date(result1.memberPortalDate) >
          new Date(result2.memberPortalDate) &&
          1) ||
        -1
      );
    } else if (sortDropdown === "2") {
      return (
        (result1.title.toLowerCase() > result2.title.toLowerCase() && 1) || -1
      );
    } else if (sortDropdown === "3") {
      return (
        (result1.title.toLowerCase() > result2.title.toLowerCase() && -1) || 1
      );
    } else {
      return 0;
    }
  });

  const currentPageFilteredMessages = fileteredMessages
    ? messages.slice((currentPage - 1) * pageSize, currentPage * pageSize)
    : [];

  const compactPagination = useBreakpointValue({ base: true, md: false });

  let path = [
    { title: "Committees", url: routes.COMMITTEES },
    {
      title: get(committee, "name", ""),
      url: `/committees/${get(committee, "key", "")}`,
    },
    { title: "Updates" },
  ];

  if (isCorporateInitiatives(get(committee, "key", ""))) {
    path = [
      {
        title: get(committee, "name", ""),
        url: `/${get(committee, "key", "")}`,
      },
      { title: "Updates" },
    ];
  }

  return (
    <Layout path={path} backUrl={routes.COMMITTEES}>
      <HeadMeta
        title={`BRT Member Portal | ${
          get(committee, "name", "") + " Committee"
        }`}
      />

      <TwoColLayout>
        <VStack spacing={{ base: 4, md: "21px" }} align="stretch">
          {messages.length > 0 && (
            <>
              {/* Header */}
              <Stack
                justify="space-between"
                align={{ base: "flex-start", md: "center" }}
                w="100%"
                direction={{ base: "column", md: "row" }}
                spacing={4}
              >
                <SectionHeader
                  title={
                    committee?.name
                      ? `${committee.name} Updates`
                      : query.get("type") === "sub"
                      ? "Working Group Updates"
                      : "Committee Updates"
                  }
                  mb={0}
                />
                <HStack
                  justify={{ base: "space-between", md: "flex-end" }}
                  w="100%"
                >
                  <Text
                    color="grey.500"
                    fontSize="14"
                    lineHeight="24px"
                    fontFamily="opensans"
                    whiteSpace="nowrap"
                  >
                    Sort by
                  </Text>
                  <Select
                    bg="white"
                    border="none"
                    minW="144px"
                    w="auto"
                    defaultValue="0"
                    value={sortDropdown}
                    onChange={(e) => setSortDropdown(e.target.value)}
                  >
                    <option value="0">New to Old</option>
                    <option value="1">Old to New</option>
                    <option value="2">A to Z</option>
                    <option value="3">Z to A</option>
                  </Select>
                  {/* mobile pagination navigation */}
                  <Pagination
                    total={messages.length}
                    pageSize={pageSize}
                    useOuter={true}
                    outerCurrentPage={currentPage}
                    setOuterCurrentPage={setCurrentPage}
                    compact
                    display={{ base: "flex", md: "none" }}
                  />
                </HStack>
              </Stack>

              {/* List */}
              <VStack spacing={3} align="stretch" position="relative">
                {currentPageFilteredMessages.map((item: any, index: number) => (
                  <EmailAndUpdateCard
                    {...item}
                    key={index}
                    committeeData={committee}
                    image={committee.image}
                    bgColor={committee.bgColor}
                  />
                ))}

                <Pagination
                  mt="16px !important"
                  total={messages.length}
                  pageSize={pageSize}
                  useOuter={true}
                  outerCurrentPage={currentPage}
                  setOuterCurrentPage={setCurrentPage}
                  showItemShown
                  compact={compactPagination}
                />
              </VStack>
            </>
          )}
        </VStack>

        {Object.keys(committeeLeads).length > 0 && (
          <CommitteeProfileBlock
            committee={committee}
            members={committeeLeads}
          />
        )}
      </TwoColLayout>
    </Layout>
  );
}

export default CommitteesUpdates;
