import { Stack, Flex, VStack, HStack, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import React from "react";
import { cardShadow } from "../../styles/theme";
import dayjs from "dayjs";
import { IMessage } from "../../types";

interface Props {
  data: IMessage;
}

export default function MessageCard({ data }: Props) {
  return (
    <Link to={"/brt-inbox/message/" + data.id}>
      <Stack
        direction={{ base: "column", md: "row" }}
        w="100%"
        bg="white"
        borderRadius="16px"
        alignItems={{ base: "flex-start", md: "stretch" }}
        overflow="hidden"
        _hover={{ filter: cardShadow }}
        transition="0.3s all"
      >
        <Flex
          minH={{ base: "52px", md: "86px" }}
          minW={{ base: "100%", md: "78px" }}
          backgroundColor="grey.100"
          backgroundImage={`url('/images/logo.svg')`}
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          backgroundSize="24px 30px"
          justify="center"
          align="center"
        ></Flex>

        <VStack
          flex="1"
          alignItems="flex-start"
          p={{ base: "16px 20px", md: "20px 24px" }}
          spacing={0}
        >
          <HStack justify="space-between" w="100%" align="flex-start">
            <VStack alignItems="flex-start">
              {data && data.title && (
                <Text
                  fontSize="12px"
                  lineHeight="16px"
                  fontWeight="400"
                  fontFamily="opensans"
                  color="grey.700"
                >
                  {dayjs(data && data.memberPortalDate)
                    .tz("America/New_York")
                    .format("MMM D, YYYY")}
                </Text>
              )}
              <Text
                w="fit-content"
                m="0"
                fontSize="16px"
                lineHeight="22px"
                fontWeight="600"
                fontFamily="opensans"
              >
                {data.title}
              </Text>
            </VStack>
          </HStack>
        </VStack>
      </Stack>
    </Link>
  );
}
