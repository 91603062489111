import { handleActions } from "redux-actions";
import {
  getArticleError,
  getArticlesError,
  getArticlesSuccess,
  getArticleSuccess,
} from "./action";

const DEFAULT_STATE = {
  articles: [],
  article: {},
  error: "",
};

const handlers = {
  [getArticlesSuccess]: (state, action) => {
    return { ...state, articles: action.payload };
  },

  [getArticlesError]: (state, action) => {
    return { ...state, error: action.payload };
  },

  [getArticleSuccess]: (state, action) => {
    return { ...state, article: action.payload };
  },

  [getArticleError]: (state, action) => {
    return { ...state, error: action.payload };
  },
};

export default handleActions(handlers, DEFAULT_STATE);
