import { Box, useBreakpointValue, VStack } from "@chakra-ui/react";
import * as React from "react";
import Pagination from "../common/Pagination";
import EventCard from "../EventAgenda/EventCard";
import SectionHeader from "../Home/SectionHeader";

interface CommitteeUpcomingEventListProps {
  data: any[];
  committeeName: string;
}

export function CommitteeUpcomingEventList({
  data,
  committeeName,
}: CommitteeUpcomingEventListProps) {
  const pageSize = 1;
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const compactPagination = useBreakpointValue({ base: true, md: false });

  const slicedEvents =
    data?.length > 0
      ? data.slice((currentPage - 1) * pageSize, currentPage * pageSize)
      : [];

  const title =
    committeeName === "Corporate Initiatives"
      ? `Upcoming ${committeeName} Events`
      : `Upcoming ${committeeName} Committee Events`;

  return (
    <Box w="100%" mt="0 !important">
      <SectionHeader
        title={title}
        pagination={
          <Pagination
            total={data.length}
            pageSize={pageSize}
            useOuter={true}
            outerCurrentPage={currentPage}
            setOuterCurrentPage={setCurrentPage}
            compact={compactPagination}
          />
        }
      />

      <VStack alignItems="stretch">
        {slicedEvents.map((item: any, index: number) => (
          <EventCard key={item.id} event={item} />
        ))}
      </VStack>
    </Box>
  );
}
