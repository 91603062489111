import { Flex, Stack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router";
import HeadMeta from "../../components/common/HeadMeta";
import PrimaryButton from "../../components/common/PrimaryButton";
import PublicLayout from "../../components/PublicLayout";
import routes from "../../constants/routes.json";

function ResetPasswordDone() {
  const history = useHistory();

  const onSignIn = () => {
    history.push(routes.SIGNIN);
  };

  return (
    <PublicLayout>
      <HeadMeta />

      <Stack
        direction={{ base: "column", md: "row" }}
        spacing="25px"
        w="100%"
        flex={1}
        mt={{ base: "3.5rem", sm: "0px" }}
      >
        <VStack
          h="100%"
          w={{ base: "100%", md: "100%", xl: "100%" }}
          bg="white"
          flex={1}
          alignItems={{ base: "flex-start", md: "center" }}
          justifyContent={{ base: "flex-start", md: "center" }}
        >
          <Flex
            direction="column"
            w={{ base: "100%", md: "100%", xl: "29%" }}
            alignItems={{ base: "flex-start", md: "center" }}
            justifyContent={{ base: "flex-start", md: "center" }}
          >
            <Text
              fontSize={{ base: "24px", md: "36px" }}
              lineHeight={{ base: "29px", md: "44px" }}
              fontWeight={700}
              fontFamily="montserrat"
              textAlign={{ base: "left", md: "center" }}
              color="grey.950"
            >
              Your password
              <br />
              has been changed
            </Text>
            <PrimaryButton mt="56px" onClick={onSignIn}>
              Sign In
            </PrimaryButton>
          </Flex>
        </VStack>
      </Stack>
    </PublicLayout>
  );
}

export default ResetPasswordDone;
