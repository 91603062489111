import { Box, Grid, HStack, Stack, Tag, Text, VStack } from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import NewCommitteeCard from "../components/Committees/NewCommitteeCard";
import { CustomSelect } from "../components/common/CustomSelect";
import HeadMeta from "../components/common/HeadMeta";
import EventCard from "../components/EventAgenda/EventCard";
import SectionHeader from "../components/Home/SectionHeader";
import TheLatest from "../components/Home/TheLatest";
import Layout from "../components/Layout";
import SearchBar from "../components/Layout/SearchBar";
import { RelevantContentList } from "../components/Search/RelevantContentList";
import routes from "../constants/routes.json";
import useTheLatest from "../hooks/useTheLatest";
import { getSearchRequest } from "../redux/committee/action";

const tags = [
  "Antitrust",
  "Artificial Intelligence (AI)",
  "China",
  "Climate",
  "Corporate Governance",
  "Corporate Initiatives",
  "Cyber Security",
  "Privacy",
  "Diversity,  Equity & Inclusion (DEI)",
  "Education",
  "Energy",
  "Environment",
  "ESG",
  "Health Care Reform",
  "Immigration",
  "Infrastructure",
  "Purpose of a Corporation (POC)",
  "Racial Equity",
  "Regulation",
  "Russia",
  "Stock Buybacks",
  "Supply Chain",
  "Tax",
  "Technology",
  "Trade",
  "Workforce",
];

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function SearchResults() {
  const query: any = useQuery();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [, setSortDropdown] = useState<string>("0");
  const theLatestData = useTheLatest();

  const { searchResults, searchResultCount: resultCount } = useSelector(
    (state) => state.committee
  );
  const resultsGroupByType = useMemo(
    () =>
      searchResults.reduce((obj, currentResult) => {
        if (obj[currentResult.type]?.length > 0) {
          obj[currentResult.type] = [...obj[currentResult.type], currentResult];
        } else {
          obj[currentResult.type] = [currentResult];
        }
        return obj;
      }, {}),
    [searchResults]
  );

  useEffect(() => {
    const searchQuery = query.get("query") || "";
    setSearchQuery(searchQuery);
  }, [query]);

  useEffect(() => {
    if (searchQuery) {
      dispatch(getSearchRequest(searchQuery));
    }
  }, [searchQuery]);

  const relevantContent = Object.keys(resultsGroupByType)
    .filter((key) => ["email", "document"].includes(key))
    .reduce((arr: any[], key) => {
      return [...arr, ...resultsGroupByType[key]];
    }, []);

  const upcomingEvents = resultsGroupByType?.event
    ?.filter((e) => dayjs(e.startDate).isSameOrAfter(dayjs().startOf("day")))
    .sort((e1, e2) => dayjs(e1?.startDate) - dayjs(e2?.startDate));

  return (
    <Layout showHeader={false}>
      <HeadMeta title="BRT Member Portal | Search Results" />
      <VStack spacing={6} w="100%">
        {!searchQuery && (
          <VStack alignItems="flex-start" w="100%">
            <SectionHeader title="Portal Search" mb={0} />
            <Text
              fontSize="14px"
              lineHeight="17px"
              maxW="759px"
              fontFamily="montserrat"
            >
              We invite you to search the Portal by keyword or explore the
              topics below. Your search query will surface relevant
              Committee(s), emails, documents and upcoming events.
            </Text>
          </VStack>
        )}
        {/* Search input */}
        <Box w="100%">
          <SearchBar searchTerm={searchQuery} />
        </Box>

        {/* Tags */}
        {!searchQuery && (
          <Stack
            direction={{ base: "column", md: "row" }}
            alignItems="flex-start"
            mt={{ base: "36px !important", md: "30px !important" }}
            w="100%"
            spacing={{ base: 4, md: 3 }}
          >
            <Text
              fontFamily="montserrat"
              fontSize="12px"
              lineHeight="15px"
              fontWeight="500"
              mr={1}
              mt={1.5}
            >
              Topics:
            </Text>
            <VStack flexWrap="wrap" w="100%" justify="flex-start">
              <Box w="100%">
                {tags.sort().map((filter) => (
                  <Tag
                    cursor="pointer"
                    key={filter}
                    borderRadius="full"
                    padding="6px 16px"
                    border="0.5px solid #0D7DBD"
                    backgroundColor="transparent"
                    fontFamily="montserrat"
                    color="#0D7DBD"
                    fontSize="12px"
                    lineHeight="15px"
                    fontWeight="600"
                    mr={3}
                    mb={3}
                    onClick={() => window.open(`${routes.SEARCH_RESULTS}?query=${encodeURIComponent(
                      filter
                    )}`, "_self")}
                  >
                    {filter}
                  </Tag>
                ))}
              </Box>
            </VStack>
          </Stack>
        )}

        {/* Search result info */}
        {searchQuery && (
          <Stack
            justify="space-between"
            w="100%"
            direction={{ base: "column", md: "row" }}
            spacing={6}
          >
            {/* Sort/Filter */}
            <HStack
              fontSize="20px"
              lineHeight="24px"
              fontFamily="montserrat"
              flexWrap="wrap"
              spacing={0}
            >
              <Text>
                {searchResults.length} result
                {searchResults.length === 1 ? "" : "s"}
              </Text>
              {searchQuery && (
                <>
                  <Text mx="8px !important">for</Text>
                  <Text fontWeight={600}>"{searchQuery}"</Text>
                </>
              )}
            </HStack>

            {resultCount > 0 && (
              <Box w={{ base: "60%", md: "unset" }}>
                <CustomSelect
                  label="Sort by"
                  options={[
                    {
                      value: "0",
                      name: "New to Old",
                    },
                    {
                      value: "1",
                      name: "Old To New",
                    },
                  ]}
                  onChange={setSortDropdown}
                />
              </Box>
            )}
          </Stack>
        )}

        {/* Main - Search results */}
        <VStack w="100%" spacing={10} align="stretch">
          {/* Default latest updates */}
          {(searchResults?.length <= 0 || !searchQuery) && theLatestData && (
            <TheLatest data={theLatestData} />
          )}

          {/* Relevant Committee -> committee type */}
          {resultsGroupByType?.committee?.length && searchQuery && (
            <VStack spacing={{ base: 4, md: 6 }} align="flex-start">
              <SectionHeader title="Relevant Committees" mb={0} />
              <Grid
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  md: "repeat(2, 1fr)",
                  lg: "repeat(3, 1fr)",
                }}
                columnGap={{ base: "12px", md: "16px" }}
                rowGap={{ base: "12px", md: "12px" }}
                w="100%"
              >
                {resultsGroupByType?.committee
                  .filter(
                    (committee) =>
                      committee.category !== "Corporate Initiatives"
                  )
                  .sort((c1, c2) => {
                    if (c1.name < c2.name) {
                      return -1;
                    }
                    if (c1.name > c2.name) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((committee) => (
                    <NewCommitteeCard
                      key={committee.id}
                      committee={committee}
                    />
                  ))}
              </Grid>
            </VStack>
          )}

          {/* Relevant Event -> event type*/}
          {upcomingEvents?.length > 0 && searchQuery && (
            <Box>
              <SectionHeader title="Relevant Events" />
              <VStack
                spacing="12px"
                w="100%"
                alignItems="stretch"
                padding="0"
                mx="0"
              >
                {upcomingEvents.map((item: any) => (
                  <EventCard key={item.id} event={item} />
                ))}
              </VStack>
            </Box>
          )}

          {/* Relevant Content -> other types */}
          {relevantContent?.length > 0 && searchQuery && (
            <RelevantContentList data={relevantContent} />
          )}
        </VStack>
      </VStack>
    </Layout>
  );
}

export default SearchResults;
