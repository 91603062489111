import { Box, HStack, Icon, Text, VStack } from "@chakra-ui/react";
import dayjs from "dayjs";
import { get } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommitteeDocumentList from "../components/Committees/CommitteeDocumentList";
import { CommitteeProfileBlock } from "../components/Committees/CommitteeProfileBlock";
import { CommitteeUpcomingEventList } from "../components/Committees/CommitteeUpcomingEventList";
import { ReferenceCard } from "../components/Committees/ReferenceCard";
import HeadMeta from "../components/common/HeadMeta";
import SectionHeading from "../components/common/SectionHeading";
import BRTEssentials from "../components/Home/BRTEssentials";
import SectionHeader from "../components/Home/SectionHeader";
import Layout from "../components/Layout";
import TwoColLayout from "../components/Layout/TwoColLayout";
import routes from "../constants/routes.json";
import { useCommitteeDetail } from "../hooks/useCommitteeDetail";
import useCommitteeLeads from "../hooks/useCommitteeLeads";
import { getEventsRequest } from "../redux/event/action";

var dayjsUTC = require("dayjs/plugin/utc");
var dayjsTimezone = require("dayjs/plugin/timezone");
var dayjsAdvancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(dayjsUTC);
dayjs.extend(dayjsTimezone);
dayjs.extend(dayjsAdvancedFormat);

function CorporateInitiatives() {
  const committeeKey = "corporate_initiatives";
  const dispatch = useDispatch();
  const { events } = useSelector((state) => state.event);
  const committee = useCommitteeDetail(committeeKey, "");
  const committeeLeads = useCommitteeLeads(committee?.id);

  // get events
  useEffect(() => {
    dispatch(getEventsRequest());
  }, [dispatch]);

  const filteredEvents = events
    .filter(
      (e) =>
        e.committee &&
        committee &&
        e.committee.name === committee.name &&
        e.eventStartDate &&
        dayjs() < dayjs(e.eventStartDate)
    )
    .sort((e1, e2) => dayjs(e1.eventStartDate) - dayjs(e2.eventStartDate));

  const referenceMaterials =
    committee &&
    committee.referenceMaterials &&
    committee.referenceMaterials.length > 0
      ? committee.referenceMaterials.map((mat) => ({
          id: mat.id,
          date: mat.created,
          url: "/articles/" + mat.id,
          title: mat.title,
        }))
      : [];

  const Weekly = [
    {
      id: "6a7070d8-2aa3-ed11-aad1-000d3a5a70e0",
      category: "Corporate Initiatives",
      created: "2023-02-02T18:53:14.000Z",
      modified: "2023-02-06T18:24:53.000Z",
      content: {
        id: "6a7070d8-2aa3-ed11-aad1-000d3a5a70e0",
        title: "2024 BRT Corporate Initiatives",
        author: null,
        created: "2023-02-02T18:53:14.000Z",
        body: "",
        totalViews: 0,
        type: "article",
        relatedArticles: [],
        publishedDate: "2023-01-02T05:00:00Z",
        category: "Business Roundtable",
      },
      location: "BRT Highlights 2",
    },
  ];

  return (
    <Layout
      path={[{ title: get(committee, "name", "") }]}
      backUrl={routes.COMMITTEES}
    >
      <HeadMeta
        title={`BRT Member Portal | ${
          get(committee, "name", "") + " Committee"
        }`}
      />

      <TwoColLayout>
        <VStack align="flex-start" spacing={10}>
          <VStack spacing={{ base: 4, md: 6 }}>
            <SectionHeader title={get(committee, "name", "")} mb={0} />

            <Text fontSize="16px" lineHeight="22px" color="#3F3F3F">
              {committee?.description}
            </Text>

            {committee?.workingToward && committee.workingToward.length > 0 && (
              <VStack
                align="flex-start"
                px="0"
                mb="40px !important"
                spacing={0}
              >
                <Text
                  fontWeight="600"
                  fontFamily="montserrat"
                  fontSize="14px"
                  lineHeight="17px"
                >
                  Overview of Priorities:
                </Text>
                <VStack spacing={{ base: 4, md: 6 }} align="flex-start">
                  {committee.workingToward.map(
                    (item: string, index: number) => (
                      <HStack key={index} align="flex-start" spacing={0}>
                        <Icon
                          boxSize="9px"
                          viewBox="0 0 9 9"
                          mt="8px"
                          mr="24px"
                        >
                          <circle cx="4.5" cy="4.5" r="4.5" fill="#B3B3B3" />
                        </Icon>
                        <Text
                          fontFamily="opensans"
                          fontSize="16px"
                          lineHeight="22px"
                        >
                          {item}
                        </Text>
                      </HStack>
                    )
                  )}
                </VStack>
              </VStack>
            )}
            <BRTEssentials showHeader={false} data={Weekly} />
          </VStack>

          {/* Committee leads on mobile */}
          <VStack
            w="100%"
            alignItems="flex-start"
            display={{ base: "flex", lg: "none" }}
          >
            {Object.keys(committeeLeads).length > 0 && (
              <CommitteeProfileBlock
                committee={committee}
                members={committeeLeads}
              />
            )}
          </VStack>

          <Box w="100%">
            {filteredEvents.length > 0 && (
              <CommitteeUpcomingEventList
                data={filteredEvents}
                committeeName={get(committee, "name", "")}
              />
            )}
          </Box>

          {/* documents */}
          <Box mb="40px" w="100%">
            <CommitteeDocumentList committee={committee} />
          </Box>

          {referenceMaterials.length > 0 && (
            <HStack display={{ base: "block", xl: "none" }}>
              <SectionHeading
                fontSize="20px"
                lineHeight="24px"
                fontWeight={600}
                pt="32px"
                pb="8px"
              >
                Reference Materials
              </SectionHeading>
              <VStack ml="0 !important">
                <ReferenceCard data={referenceMaterials} />
              </VStack>
            </HStack>
          )}
        </VStack>

        <VStack display={{ base: "none", lg: "flex" }}>
          <VStack w="100%" alignItems="flex-start">
            {Object.keys(committeeLeads).length > 0 && (
              <CommitteeProfileBlock
                committee={committee}
                members={committeeLeads}
              />
            )}
          </VStack>

          {referenceMaterials.length > 0 && (
            <>
              <SectionHeading
                fontSize="20px"
                lineHeight="24px"
                fontWeight={600}
                pt="32px"
                pb="8px"
              >
                Reference Materials
              </SectionHeading>
              <ReferenceCard data={referenceMaterials} />
            </>
          )}
        </VStack>
      </TwoColLayout>
    </Layout>
  );
}

export default CorporateInitiatives;
