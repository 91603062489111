import React from "react";
import { Box, VStack, useMediaQuery } from "@chakra-ui/react";
import EventCard from "../EventAgenda/EventCard";
import SectionHeader from "./SectionHeader";

interface Props {
  data: any;
  showSubtitle?: boolean;
}

export default function UpcomingEvents({ data, showSubtitle = true }: Props) {
  const [isLG] = useMediaQuery("(min-width: 1024px)");

  return (
    <Box w="100%">
      <SectionHeader
        title="Upcoming Events"
        subTitle={
          showSubtitle ? "In-person and virtual meetings on the horizon." : ""
        }
      />
      <VStack spacing="12px" w="100%" alignItems="stretch" padding="0" mx="0">
        {data.map((item: any) => (
          <EventCard key={item.id} event={item} small={!isLG} />
        ))}
      </VStack>
    </Box>
  );
}
