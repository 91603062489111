import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
} from "@chakra-ui/react";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";

export type Props = {
  children: any;
  title: string;
  show: boolean;
  size: string;
  onClose: () => void;
  onApply: () => void;
};

function ApplyModal({ children, title, show, onClose, onApply, size }: Props) {
  return (
    <Modal isOpen={show} onClose={onClose} size={size || "xl"} isCentered>
      <ModalOverlay zIndex={1300} />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>

        <ModalFooter>
          <SecondaryButton mr={3} onClick={onClose} filter={false}>
            Cancel
          </SecondaryButton>
          <PrimaryButton onClick={onApply} filter={false}>
            Apply
          </PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ApplyModal;
