import {
  Box,
  HStack,
  Icon,
  Radio,
  RadioGroup,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CommitteeDocumentList from "../components/Committees/CommitteeDocumentList";
import { CommitteeProfileBlock } from "../components/Committees/CommitteeProfileBlock";
import { CommitteeUpcomingEventList } from "../components/Committees/CommitteeUpcomingEventList";
import { ReferenceCard } from "../components/Committees/ReferenceCard";
import ApplyModal from "../components/common/ApplyModal";
import HeadMeta from "../components/common/HeadMeta";
import SectionHeading from "../components/common/SectionHeading";
import SectionHeader from "../components/Home/SectionHeader";
import Layout from "../components/Layout";
import TwoColLayout from "../components/Layout/TwoColLayout";
import { MEMBER_STATUS } from "../constants/default";
import routes from "../constants/routes.json";
import { useCommitteeDetail } from "../hooks/useCommitteeDetail";
import useCommitteeLeads from "../hooks/useCommitteeLeads";
import { useEventByCommittee } from "../hooks/useEventByCommittee";
import { useMEPQuery } from "../hooks/useMEPQuery";
import { setMembershipRequest } from "../redux/committee/action";

var dayjsUTC = require("dayjs/plugin/utc");
var dayjsTimezone = require("dayjs/plugin/timezone");
var dayjsAdvancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(dayjsUTC);
dayjs.extend(dayjsTimezone);
dayjs.extend(dayjsAdvancedFormat);

function CommitteeDetails() {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [status, setStatus] = useState();
  const [newStatus, setNewStatus] = useState("Not Active");
  const { profile } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const params: any = useParams();
  const query: any = useMEPQuery();
  const type = query.get("type") || false;
  const committee = useCommitteeDetail(params.id, type);
  const committeeLeads = useCommitteeLeads(params.id);
  const commiteeEvents = useEventByCommittee(params.id);

  useEffect(() => {
    let newStatus = "Not Active";
    if (committee && profile) {
      if (profile.areasOfInterest) {
        profile.areasOfInterest.forEach((a) => {
          if (a.name === committee.name || a.id === committee.id) {
            newStatus = "Interested";
          }
        });

        profile.committees.forEach((c) => {
          if (c.name === committee.name || c.id === committee.id) {
            newStatus = "Member";
          }
        });
      }
    }

    updateStatus(newStatus);
  }, [profile, committee]);

  useEffect(() => {
    if (showModal) {
      const currentStatus = status && status.name ? status.name : "Not Active";

      if (profile?.userType && profile?.userType.toLowerCase() === "ceo") {
        setNewStatus("Interested");
      } else if (
        profile?.userType &&
        profile?.userType.toLowerCase() === "staff"
      ) {
        if (currentStatus === "Not Active") {
          setNewStatus("Interested");
        } else if (currentStatus === "Interested") {
          setNewStatus("Not Active");
        }
      }
    }
  }, [profile?.userType, showModal, status]);

  const updateStatus = (s: string) => {
    const newStatus = MEMBER_STATUS.find((item: any) => item.name === s);
    setStatus(newStatus);
  };

  const updateMemberStatus = () => {
    if (!status || (status && status.name !== newStatus)) {
      let ms = "";
      switch (newStatus) {
        case "Interested":
          ms = "interested";
          break;
        case "Member":
          ms = "join";
          break;
        case "Not Active":
          ms = "leave";
          break;
      }

      dispatch(
        setMembershipRequest({
          name: committee.key || committee.id,
          membership: ms,
          type: query.get("type") ? "subcommittee" : "committee",
        })
      );
      setShowModal(false);
      updateStatus(newStatus);
    }
  };

  const referenceMaterials =
    committee &&
    committee.referenceMaterials &&
    committee.referenceMaterials.length > 0
      ? committee.referenceMaterials.map((mat) => ({
          id: mat.id,
          date: mat.created,
          url: "/articles/" + mat.id,
          title: mat.title,
        }))
      : [];

  const ModalSectionAddInterested = () => {
    return (
      <>
        <Radio size="lg" value="Interested">
          Interested
        </Radio>
        <Text fontSize="14px" fontFamily="opensans" lineHeight="19px" pl="28px">
          Selecting the "Interested" status automatically adds you to receive
          communications and updates from the committee.
        </Text>
      </>
    );
  };

  const ModalSectionAddMember = () => {
    return (
      <>
        <Radio size="lg" value="Member">
          Join Committee
        </Radio>
        <Text fontSize="14px" fontFamily="opensans" lineHeight="19px" pl="28px">
          Selecting the "Join Committee" status makes you an active member of
          the committee.
        </Text>
      </>
    );
  };

  const ModalSectionRemoveInterested = () => {
    return (
      <>
        <Radio size="lg" value="Not Active">
          Remove Interest
        </Radio>
        <Text fontSize="14px" fontFamily="opensans" lineHeight="19px" pl="28px">
          Selecting the "Remove Interest" status automatically removes you from
          receiving communications and updates from the committee.
        </Text>
      </>
    );
  };

  const ModalSectionRemoveMember = () => {
    return (
      <>
        <Radio size="lg" value="Not Active">
          Leave Committee
        </Radio>
        <Text fontSize="14px" fontFamily="opensans" lineHeight="19px" pl="28px">
          Selecting the "Leave Committee" status removes you as an active member
          of the committee.
        </Text>
      </>
    );
  };

  return (
    <Layout
      path={[
        { title: "Committees", url: routes.COMMITTEES },
        { title: get(committee, "name", "") },
      ]}
      backUrl={routes.COMMITTEES}
    >
      <HeadMeta
        title={`BRT Member Portal | ${
          get(committee, "name", "") + " Committee"
        }`}
      />

      <TwoColLayout>
        <VStack align="flex-start" spacing={10}>
          <VStack spacing={{ base: 4, md: 6 }}>
            <SectionHeader title={get(committee, "name", "")} mb={0} />

            <Text fontSize="16px" lineHeight="22px" color="#3F3F3F">
              {committee?.description}
            </Text>

            {committee?.workingToward?.length > 0 && (
              <VStack
                align="flex-start"
                px="0"
                spacing={{ base: 4, md: 5 }}
                mt="0px"
              >
                <Text
                  fontWeight="600"
                  fontFamily="montserrat"
                  fontSize="14px"
                  lineHeight="17px"
                >
                  Overview of Priorities:
                </Text>
                <VStack spacing={{ base: 4, md: 6 }} align="flex-start">
                  {committee.workingToward.map(
                    (item: string, index: number) => (
                      <HStack key={index} align="flex-start" spacing={0}>
                        <Icon
                          boxSize="9px"
                          viewBox="0 0 9 9"
                          mt="8px"
                          mr="24px"
                        >
                          <circle cx="4.5" cy="4.5" r="4.5" fill="#B3B3B3" />
                        </Icon>
                        <Text
                          fontFamily="opensans"
                          fontSize="16px"
                          lineHeight="22px"
                        >
                          {item}
                        </Text>
                      </HStack>
                    )
                  )}
                </VStack>
              </VStack>
            )}
          </VStack>

          {/* Start CommitteeLeads on mobile */}
          <VStack
            w="100%"
            alignItems="flex-start"
            display={{ base: "flex", lg: "none" }}
          >
            {Object.keys(committeeLeads).length > 0 && (
              <CommitteeProfileBlock
                committee={committee}
                members={committeeLeads}
              />
            )}
          </VStack>
          {/* End CommitteeLeads on mobile */}

          {commiteeEvents.length > 0 && (
            <Box w="100%">
              <CommitteeUpcomingEventList
                data={commiteeEvents}
                committeeName={get(committee, "name", "")}
              />
            </Box>
          )}

          {/* documents */}
          <Box w="100%">
            <CommitteeDocumentList committee={committee} />
          </Box>

          {referenceMaterials.length > 0 && (
            <HStack display={{ base: "block", xl: "none" }}>
              <SectionHeading
                fontSize="20px"
                lineHeight="24px"
                fontWeight={600}
                pt="32px"
                pb="8px"
              >
                Reference Materials
              </SectionHeading>
              <VStack ml="0 !important">
                <ReferenceCard data={referenceMaterials} />
              </VStack>
            </HStack>
          )}
        </VStack>

        <VStack display={{ base: "none", lg: "flex" }}>
          <VStack w="100%" alignItems="flex-start">
            {Object.keys(committeeLeads).length > 0 && (
              <CommitteeProfileBlock
                committee={committee}
                members={committeeLeads}
              />
            )}
          </VStack>

          {referenceMaterials.length > 0 && (
            <>
              <SectionHeading
                fontSize="20px"
                lineHeight="24px"
                fontWeight={600}
                pt="32px"
                pb="8px"
              >
                Reference Materials
              </SectionHeading>
              <ReferenceCard data={referenceMaterials} />
            </>
          )}
        </VStack>
      </TwoColLayout>

      {status && (
        <ApplyModal
          show={showModal}
          onClose={() => setShowModal(false)}
          onApply={() => updateMemberStatus()}
        >
          <Text
            fontSize="20px"
            fontFamily="montserrat"
            fontWeight="600"
            lineHeight="24px"
            pb="24px"
          >
            Change Your Committee Status
          </Text>
          <RadioGroup onChange={setNewStatus} value={newStatus}>
            <Stack direction="column">
              {profile?.userType &&
                profile?.userType.toLowerCase() === "ceo" && (
                  <>
                    {(!status.name || status.name === "Not Active") && (
                      <>
                        <ModalSectionAddInterested />
                        <ModalSectionAddMember />
                      </>
                    )}
                    {status.name === "Interested" && (
                      <>
                        <ModalSectionRemoveInterested />
                        <ModalSectionAddMember />
                      </>
                    )}
                    {status.name === "Member" && (
                      <>
                        <ModalSectionAddInterested />
                        <ModalSectionRemoveMember />
                      </>
                    )}
                  </>
                )}
              {profile?.userType &&
                profile?.userType.toLowerCase() === "staff" && (
                  <>
                    {(!status.name || status.name === "Not Active") && (
                      <ModalSectionAddInterested />
                    )}
                    {status.name === "Interested" && (
                      <ModalSectionRemoveInterested />
                    )}
                  </>
                )}
            </Stack>
          </RadioGroup>
        </ApplyModal>
      )}
    </Layout>
  );
}

export default CommitteeDetails;
