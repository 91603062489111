import React from "react";
import { Progress } from "@chakra-ui/react";

const StepProgress = ({
  value,
  display,
}: {
  value: number;
  display?: string;
}) => (
  <Progress
    colorScheme="primary"
    bg={{ base: "grey.50", md: "white" }}
    size="md"
    value={value}
    w="100%"
    display={display}
    sx={{ "& > div": { background: "primary.700" } }}
  />
);
export default StepProgress;
