import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

export const cardShadow = 'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1))'
export const cardShadowHover = 'drop-shadow(0px 4px 8px rgba(94, 94, 94, 0.1))'

export const breakpoints = createBreakpoints({
  sm: "30em",
  md: "48em",
  lg: "64.01em",
  xl: "80em",
  "2xl": "96em",
});

const Checkbox = {
  // The styles all button have in common
  baseStyle: {
    control: {
      borderRadius: "8px",
      boxShadow: "none !important",
      outline: "none !important",
    },
  },
  // Two sizes: sm and md
  sizes: {
    sm: {
      control: {
        width: "12px",
        height: "12px",
      },
    },
    md: {
      control: {
        width: "16px",
        height: "16px",
      },
    },
    lg: {
      control: {
        width: "24px",
        height: "24px",
      },
    },
  },
  defaultProps: {
    size: "lg",
  },
};
const Button = {
  // The styles all button have in common
  baseStyle: {
    fontWeight: "bold",
    textTransform: "uppercase",
    fontFamily: "montserrat",
    borderRadius: "4px",
  },
  // Two sizes: sm and md
  sizes: {
    sm: {
      fontSize: "12px",
      lineHeight: "24px",
      padding: "7px 16px",
    },
    md: {
      fontSize: "16px",
      padding: "24px",
    },
    lg: {
      fontSize: "12px",
      padding: "24px",
      height: "48px",
      fontWeight: "600",
      fontFamily: "montserrat",
    },
  },
  // Two variants: outline and solid
  variants: {
    outline: {
      border: "1px solid",
      borderColor: "#E7E7E7",
    },
    solid: {
      bg: "primary.500",
      color: "white",
      filter: "drop-shadow(0px 8px 16px rgba(146, 204, 236, 0.6))",
    },
    light: {
      bg: "primary.100",
      color: "primary.500",
      fontWeight: 600,
    },
    flat: {
      bg: "grey.50",
    },
  },
  // The default size and variant values
  defaultProps: {
    size: "lg",
    variant: "solid",
  },
};

const Input = {
  baseStyle: {
    borderRadius: 0,
    fontFamily: "opensans",
    height: "56px",
  },
  variants: {
    filled: {
      bg: "white",
    },
  },
  defaultProps: {
    size: "md",
    variant: "filled",
  },
};

const Table = {
  parts: ["table", "thead", "tbody", "tr", "th", "td", "caption"],
  baseStyle: {
    table: {},
  },
  variants: {
    rounded: {
      table: {
        borderSpacing: "0 10px",
        borderCollapse: "separate",
      },
      td: {
        bg: "rgba(255,255,255,0.6)",
        fontFamily: "opensans",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "24px",
        "& a": {
          color: "primary.500",
          fontWeight: 600,
          textDecoration: "underline",
        },
        "&:first-of-type": {
          borderTopLeftRadius: "8px",
          borderBottomLeftRadius: "8px",
        },
        "&:last-of-type": {
          borderTopRightRadius: "8px",
          borderBottomRightRadius: "8px",
        },
      },
    },
  },
};

const theme: any = extendTheme({
  breakpoints,
  fonts: {
    heading: "Montserrat",
    opensans: "Open Sans",
    montserrat: "Montserrat",
    roboto: "Roboto",
  },
  styles: {
    global: {
      "html, body": {
        background: "#f4f4f4",
        color: "#202020",
        fontFamily: "opensans",
      },
    },
  },
  colors: {
    primary: {
      50: "#E9F5FB",
      100: "#D3EAF8",
      400: "#2D9CDB",
      500: "#0D7DBD",
      700: "#5EBDF2",
    },
    green: {
      50: "#027A3D",
      100: "#027A3D",
      400: "#027A3D",
      500: "#027A3D",
      700: "#027A3D",
    },
    grey: {
      50: "#F4F4F4",
      80: "#E7E7E7",
      100: "#DCDCDC",
      200: "#C4C4C4",
      300: "#B2B2B2",
      400: "#999999",
      500: "#888888",
      600: "#777777",
      700: "#696969",
      900: "#4D4D4E",
      930: "#3F3F3F",
      950: "#202020",
    },
    error: {
      500: "#BE3839",
    },
  },
  components: {
    Button,
    Table,
    Input,
    Checkbox,
  },
  textStyles: {
    pagination: {
      fontSize: "14px",
      lineHeight: "24px",
      fontWeight: "400",
      fontFamily: "opensans",
    },
  },
});

export default theme;
