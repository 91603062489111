import React from "react";
import { HStack, Box, VStack, Text } from "@chakra-ui/react";
import { EMAIL_PATTERN } from "../../constants/default";
import FormLabel from "./FormLabel";
import FormField from "./FormField";
import PrimaryButton from "../common/PrimaryButton";
import useEventListener from "../../hooks/useEventListener";

const initialValues = {
  email: "",
  password: "",
};

const errorMessages = {
  required: "* Fields are required",
  email: "Email not correct",
};

const SignInForm = ({
  onSubmit,
  onForgotPassword,
  userError,
}: {
  onSubmit: (fields: any) => {};
  onForgotPassword: () => {};
  userError: string;
}) => {
  const [fields, setFields] = React.useState(initialValues);
  const [errors, setErrors] = React.useState<any>({});
  const [messages, setMessages] = React.useState<any>({});
  const handleChange = (field: string) => (event: any) =>
    setFields({ ...fields, [field]: event.target.value });

  const validate = () => {
    let errors = {};
    let messages = {};

    if (!fields.email) {
      errors.email = true;
      messages.required = true;
    }

    if (fields.email && !EMAIL_PATTERN.test(fields.email)) {
      errors.email = true;
      messages.email = true;
    }

    if (!fields.password) {
      errors.password = true;
      messages.required = true;
    }

    setErrors(errors);
    setMessages(messages);
    return !Object.keys(errors).find((key: string) => errors[key]);
  };

  const onSubmitForm = () => {
    if (validate()) {
      onSubmit(fields);
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      onSubmitForm();
    }
  };

  useEventListener("keydown", handleKeyDown);

  return (
    <VStack w="100%" alignItems="flex-start" spacing={0}>
      <Text
        fontSize={{ base: "24px", md: "36px" }}
        lineHeight={{ base: "29px", md: "44px" }}
        fontWeight={700}
        fontFamily="montserrat"
        color="grey.950"
        mb={{ base: "1rem", sm: "1.25rem" }}
      >
        Business Roundtable
        <br />
        Member Portal
      </Text>

      <Text
        maxW="434px"
        fontSize={{ base: "14px", sm: "16px" }}
        lineHeight={{ base: "19px", sm: "22px" }}
      >
        The Business Roundtable Member Portal is for members and member company
        staff. Please enter your member portal credentials to gain access. If
        you need support, contact{" "}
        <Text color="primary.500" display="inline">
          <a href="mailto:membership@brt.org">membership@brt.org</a>.
        </Text>
      </Text>

      <VStack
        w="100%"
        alignItems="flex-start"
        mt={{ base: "2rem !important", sm: "2rem !important" }}
        maxW="371px"
      >
        <FormLabel mb="0">Email</FormLabel>
        <FormField
          value={fields.email}
          onChange={handleChange("email")}
          placeholder="Email"
          autoComplete="email"
          isInvalid={errors.email}
          required
        />

        <FormLabel mt="24px !important">Password</FormLabel>
        <FormField
          autoComplete="password"
          value={fields.password}
          onChange={handleChange("password")}
          placeholder="Password"
          type="password"
          isInvalid={errors.password}
          required
        />

        <HStack w="100%" justifyContent="space-between" alignItems="flex-top">
          <Box>
            {Object.keys(messages)
              .filter((key: string) => messages[key])
              .map((key: string) => (
                <Text
                  key={key}
                  fontSize="12px"
                  fontWeight={600}
                  color="error.500"
                  fontFamily="opensans"
                >
                  {errorMessages[key]}
                </Text>
              ))}
          </Box>
          <Text
            _hover={{ textDecoration: "underline" }}
            mt="3px"
            fontSize="12px"
            lineHeight="15px"
            color="primary.500"
            fontFamily="opensans"
            cursor="pointer"
            onClick={onForgotPassword}
          >
            Forgot Password?
          </Text>
        </HStack>
      </VStack>

      {userError && (
        <Text
          fontSize="12px"
          fontWeight={600}
          color="error.500"
          fontFamily="opensans"
        >
          {userError}
        </Text>
      )}

      <Box mt="56px !important">
        <PrimaryButton onClick={onSubmitForm}>Sign In</PrimaryButton>
      </Box>
    </VStack>
  );
};
export default SignInForm;
