import { Box, Stack, Text, VStack, useBreakpointValue } from "@chakra-ui/react";
import React, { useState } from "react";
import MessageCard from "./MessageCard";
import Pagination from "../common/Pagination";
import { IMessage } from "../../types";
import dayjs from "dayjs";
import SectionHeader from "../Home/SectionHeader";

interface Props {
  data: IMessage[];
  title: string;
  type: string;
}

export default function BRTInboxSection({ data = [], title, type }: Props) {
  const pageSize = 4;
  const [currentMsgPage, setCurrentMsgPage] = useState<number>(1);

  const sortedMessages = data.sort(
    (a, b) => dayjs(b.memberPortalDate) - dayjs(a.memberPortalDate)
  );

  const currentPageFilteredMessages = sortedMessages
    ? sortedMessages.slice(
        (currentMsgPage - 1) * pageSize,
        currentMsgPage * pageSize
      )
    : [];

  const showPaginationNav = useBreakpointValue({
    base: false,
    md: true,
  });

  return (
    <Box w="100%">
      <SectionHeader
        title={title}
        pagination={
          <Pagination
            total={data.length}
            pageSize={pageSize}
            useOuter={true}
            outerCurrentPage={currentMsgPage}
            setOuterCurrentPage={setCurrentMsgPage}
            compact
            display={{ base: "flex", md: "none" }}
          />
        }
      />
      <VStack spacing={3} align="stretch" position="relative">
        {currentPageFilteredMessages?.length ? (
          currentPageFilteredMessages.map((message, index) => (
            <MessageCard data={message} key={index} />
          ))
        ) : (
          <Text fontSize="14px" color="grey.500" textAlign="center">
            No emails
          </Text>
        )}
        <Pagination
          mt="16px !important"
          showItemShown
          total={data.length}
          pageSize={pageSize}
          useOuter={true}
          outerCurrentPage={currentMsgPage}
          setOuterCurrentPage={setCurrentMsgPage}
          nameOfResult="emails"
          showNavigation={showPaginationNav}
          viewAllUrl={`brt-inbox/${type}`}
        />
      </VStack>
    </Box>
  );
}
