import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../redux/ui/action";
import { getCommittee } from "../services/committee";
import { getStaticContent } from "../utils";

export function useCommitteeDetail(committeeKey: string, type: string) {
  const dispatch = useDispatch();
  const [data, setData] = useState<any>({});

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLoading("GET_COMMITTEE_DETAILS_REQUEST"));
      try {
        const res = await getCommittee({ id: committeeKey, type: type });
        setData(res);
        dispatch(setLoading("GET_COMMITTEE_DETAILS_SUCCESS"));
      } catch (error) {
        dispatch(setLoading("GET_COMMITTEE_DETAILS_ERROR"));
      }
    };

    if (
      committeeKey === "covid_19_response" ||
      committeeKey === "racial_equity_and_justice"
    ) {
      setData(getStaticContent(committeeKey));
    } else {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [committeeKey]);
  return data;
}
