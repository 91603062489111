import { handleActions } from "redux-actions";
import {
  getEventError,
  getEventsError,
  getEventLocationsError,
  getEventsSuccess,
  getEventSuccess,
  getEventLocationsSuccess,
} from "./action";

const DEFAULT_STATE = {
  events: [],
  event: {},
  eventLocations: [],
  error: "",
};

const handlers = {
  [getEventsSuccess]: (state, action) => {
    return { ...state, events: action.payload };
  },

  [getEventsError]: (state, action) => {
    return { ...state, error: action.payload };
  },

  [getEventSuccess]: (state, action) => {
    return { ...state, event: action.payload };
  },

  [getEventError]: (state, action) => {
    return { ...state, error: action.payload };
  },

  [getEventLocationsSuccess]: (state, action) => {
    return { ...state, eventLocations: action.payload };
  },

  [getEventLocationsError]: (state, action) => {
    return { ...state, error: action.payload };
  },
};

export default handleActions(handlers, DEFAULT_STATE);
