import React from "react";
import { Text, Box } from "@chakra-ui/react";

const Descriptions = ({ title, description }) => {
  return (
    <Box w="100%" mt="0 !important">
      <Text
        fontWeight="semibold"
        fontFamily="montserrat"
        mb="4px"
        fontSize={{ base: "14px", sm: "16px" }}
        lineHeight={{ base: "19px", sm: "21px" }}
      >
        {title}
      </Text>
      <Text
        fontSize={{ base: "14px", sm: "12px" }}
        lineHeight={{ base: "19px", sm: "16px" }}
        fontWeight={400}
        fontFamily="opensans"
      >
        {description}
      </Text>
    </Box>
  );
};
export default Descriptions;
