import { all, call, put, takeLatest } from "redux-saga/effects";
import { setLoading } from "../ui/action";
import {
  getHelpTopicsRequest,
  getHelpTopicsSuccess,
  getHelpTopicsError,
} from "./action";
import { getHelpTopics as getHelpTopicsQuery } from "./query";
import * as Type from './type';

export function* getHelpTopicsSaga({ payload }) {
  try {
    yield put(setLoading(Type.GET_HELP_TOPICS_REQUEST));
    const { data } = yield call(getHelpTopicsQuery, payload);
    yield put(setLoading(Type.GET_HELP_TOPICS_SUCCESS));

    if (data !== null) {
      yield put(getHelpTopicsSuccess(data));
    } else {
      yield put(getHelpTopicsError(data.error || "Get help topics error."));
    }
  } catch (error) {
    yield put(setLoading(Type.GET_HELP_TOPICS_ERROR));
  }
}

export default function* helpTopicsManagerSaga() {
  yield all([takeLatest(getHelpTopicsRequest, getHelpTopicsSaga)]);
}
