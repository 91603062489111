import React from 'react';

const Committees = ({color}: {color: string}) => {
   return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
         <mask
            id="mask0_6457_13137"
            style={{maskType: 'alpha'}}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
         >
            <rect width="24" height="24" fill="#D9D9D9" />
         </mask>
         <g mask="url(#mask0_6457_13137)">
            <path
               d="M3.3 20.375C2.8 20.375 2.375 20.2 2.025 19.85C1.675 19.5 1.5 19.075 1.5 18.575V7.8C1.5 7.58333 1.57067 7.404 1.712 7.262C1.854 7.12067 2.03333 7.05 2.25 7.05C2.46667 7.05 2.646 7.12067 2.788 7.262C2.92933 7.404 3 7.58333 3 7.8V18.575C3 18.6583 3.02933 18.7293 3.088 18.788C3.146 18.846 3.21667 18.875 3.3 18.875H18.775C18.9917 18.875 19.1707 18.95 19.312 19.1C19.454 19.25 19.525 19.425 19.525 19.625C19.525 19.8417 19.454 20.021 19.312 20.163C19.1707 20.3043 18.9917 20.375 18.775 20.375H3.3ZM6.8 16.875C6.3 16.875 5.875 16.7 5.525 16.35C5.175 16 5 15.575 5 15.075V4.3C5 3.8 5.175 3.375 5.525 3.025C5.875 2.675 6.3 2.5 6.8 2.5H11.25C11.5 2.5 11.7333 2.54567 11.95 2.637C12.1667 2.729 12.3583 2.85833 12.525 3.025L13.625 4.125H20.7C21.2 4.125 21.625 4.3 21.975 4.65C22.325 5 22.5 5.425 22.5 5.925V15.075C22.5 15.575 22.325 16 21.975 16.35C21.625 16.7 21.2 16.875 20.7 16.875H6.8Z"
               fill={color}
            />
         </g>
      </svg>
   );
};

export default Committees;
