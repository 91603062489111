import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { BsCaretDownFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import HeadMeta from "../components/common/HeadMeta";
import Pagination from "../components/common/Pagination";
import PrimaryButton from "../components/common/PrimaryButton";
import ViewAllStaffButton from "../components/common/ViewAllStaffButton";
import SectionHeader from "../components/Home/SectionHeader";
import Layout from "../components/Layout";
import TwoColLayout from "../components/Layout/TwoColLayout";
import { NEW_MEMBER_COMPANIES_TABLE_COLUMNS } from "../constants/companyDemo";
import { postHelpFormSubmission } from "../redux/auth/query";
import { setLoading, setUILoading } from "../redux/ui/action";
import { getHelpTopics, getNewMemberCompanies } from "../services/helpCenter";
import { cardShadow } from "../styles/theme";
import { Article } from "../types";

const AboutBRTCard = ({ data }: { data: Article }) => {
  return (
    <Link to={"/articles/" + data.id}>
      <Stack
        direction={{ base: "column", md: "row" }}
        w="100%"
        bg="white"
        borderRadius="16px"
        alignItems={{ base: "flex-start", md: "stretch" }}
        overflow="hidden"
        _hover={{ filter: cardShadow }}
        transition="0.3s all"
        spacing={0}
      >
        <Flex
          minH={{ base: "52px", md: "100%" }}
          minW={{ base: "100%", md: "78px" }}
          backgroundColor="grey.100"
          backgroundImage={`url('/images/logo.svg')`}
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          backgroundSize="24px 30px"
          justify="center"
          align="center"
        ></Flex>

        <Box p={{ base: "16px 20px", md: "28.5px 24px 28.5px 16px" }}>
          <Text
            w="fit-content"
            m="0"
            fontSize="16px"
            lineHeight="21px"
            fontWeight="700"
            fontFamily="opensans"
          >
            {data.title}
          </Text>
        </Box>
      </Stack>
    </Link>
  );
};

function HelpRequestForm() {
  const [subject, setSubject] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [contactPreference, setContactPreference] = useState<string>("");
  const [submissionStatus, setSubmissionStatus] = useState<Boolean>(false);
  const [submitStatus, setSubmitStatus] = useState<Boolean>(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setUILoading(true));

    try {
      setLoading(true);
      await postHelpFormSubmission({
        title: subject,
        message: message,
        contactPreference: contactPreference,
      });

      setSubmissionStatus(true);
      setSubmitStatus(true);
    } catch {
      setSubmissionStatus(false);
      setSubmitStatus(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <VStack w="100%" align="flex-start">
      <SectionHeader title="Help Request Form" mb="4px" />
      {submitStatus && submissionStatus && (
        <div style={{ color: "green" }}>
          Form has been successfully submitted! A BRT representative will reply
          within one business day.
        </div>
      )}
      {submitStatus && !submissionStatus && (
        <div style={{ color: "red" }}>
          There was an error when submitting the form. Please wait a few minutes
          and try again.
        </div>
      )}
      {!submitStatus && (
        <Stack spacing={3} w="100%">
          <Text mb="16px">
            Please fill out this form and a BRT representative will reply within
            one business day.
          </Text>
          <form onSubmit={handleSubmit} style={{ marginTop: 0 }}>
            <FormControl id="title" isRequired mb="16px">
              <FormLabel>Subject</FormLabel>
              <Input
                size="md"
                w="100%"
                variant="filled"
                bg="white"
                borderRadius="4px"
                borderColor="grey.80"
                borderWidth="1px"
                value={subject}
                height="52px"
                onChange={(e) => setSubject(e.target.value)}
              />
            </FormControl>
            <FormControl id="message" isRequired mb="16px">
              <FormLabel>Message</FormLabel>
              <Textarea
                w="100%"
                variant="filled"
                bg="white"
                borderRadius="4px"
                borderColor="grey.80"
                borderWidth="1px"
                h={{ base: "150px", md: "240px" }}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </FormControl>
            <FormControl id="contact-preference" isRequired>
              <FormLabel>Contact Preference</FormLabel>
              <Select
                placeholder="Select one..."
                size="md"
                w="100%"
                bg="white"
                borderRadius="4px"
                borderColor="grey.80"
                borderWidth="1px"
                value={contactPreference}
                onChange={(e) => setContactPreference(e.target.value)}
                icon={<BsCaretDownFill fontSize="12px" />}
                height="52px"
              >
                <option value="email">Email</option>
                <option value="phone">Phone</option>
              </Select>
            </FormControl>
            <PrimaryButton
              type="submit"
              isLoading={loading}
              mt="40px !important"
            >
              Submit
            </PrimaryButton>
          </form>
        </Stack>
      )}
    </VStack>
  );
}

const AboutBRT = () => {
  const dispatch = useDispatch();
  const [committeeHelpTopics, setCommitteeHelpTopics] = useState<Article[]>([]);
  const [aboutHelpTopics, setAboutHelpTopics] = useState<Article[]>([]);
  const [newMemberCompanies, setNewMemberCompanies] = useState([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortField, setSortField] = useState("company");
  const [sortBy, setSortBy] = useState(true);

  const pageSize = 12;

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLoading("GET_NEW_MEMBER_COM_REQUEST"));
      try {
        const helpTopics = await getHelpTopics();
        const newMemberCompanies = await getNewMemberCompanies();
        setAboutHelpTopics(helpTopics.about);
        setCommitteeHelpTopics(helpTopics.committee);
        setNewMemberCompanies(newMemberCompanies);
        dispatch(setLoading("GET_NEW_MEMBER_COM_SUCCESS"));
      } catch (error) {
        dispatch(setLoading("GET_NEW_MEMBER_COM_ERROR"));
      }
    };

    fetchData();
  }, []);

  const aboutTopics = aboutHelpTopics.sort((a, b) => {
    return dayjs(a.publishedDate).isSameOrAfter(dayjs(b.publishedDate))
      ? -1
      : 1;
  });

  const committeeTopics = committeeHelpTopics.sort((a, b) => {
    return dayjs(a.publishedDate).isSameOrAfter(dayjs(b.publishedDate))
      ? -1
      : 1;
  });

  const openUrl = (url) => {
    if (!url) return;
    window.open(url, "_blank");
  };

  const renderColumns = (row: any, type: string = "td") => {
    let Component = ({ children, ...props }: { children: any; props: any }) =>
      type === "td" ? (
        <Td {...props} minW="300px">
          {children}
        </Td>
      ) : (
        <div {...props}>{children}</div>
      );

    let firstName, lastName, middleName, fullName;

    firstName =
      row["ceo"]?.firstName && row["ceo"]?.firstName !== "null"
        ? row["ceo"].firstName
        : false;
    middleName =
      row["ceo"]?.middleName && row["ceo"]?.middleName !== "null"
        ? row["ceo"].middleName
        : false;
    lastName =
      row["ceo"]?.lastName && row["ceo"]?.lastName !== "null"
        ? row["ceo"].lastName
        : false;
    fullName = `${firstName ? firstName : ""} ${middleName ? middleName : ""} ${
      lastName ? lastName : ""
    }`;

    return NEW_MEMBER_COMPANIES_TABLE_COLUMNS.map((item: any) => (
      <Component
        key={item.name}
        fontSize="14px"
        lineHeight="24px"
        fontWeight="400"
        fontFamily="opensans"
        style={{ cursor: "pointer" }}
        onClick={() => openUrl(row.brtWebsiteProfile)}
      >
        {item.name === "company" && (
          <HStack
            justifyContent="space-between"
            pb={{ base: "16x", md: "0px" }}
          >
            <Text fontWeight={600}>{row.name}</Text>{" "}
          </HStack>
        )}
        {item.name === "ceo" && row[item.name] && <Box>{fullName}</Box>}
      </Component>
    ));
  };

  const filteredMemberCompanies =
    sortField === "company"
      ? newMemberCompanies.sort((a, b) =>
          a.name > b.name
            ? sortBy
              ? 1
              : -1
            : b.name > a.name
            ? sortBy
              ? -1
              : 1
            : 0
        )
      : newMemberCompanies.sort((a, b) =>
          a?.ceo?.lastName > b?.ceo?.lastName
            ? sortBy
              ? 1
              : -1
            : b?.ceo?.lastName > a?.ceo?.lastName
            ? sortBy
              ? -1
              : 1
            : 0
        );

  const paginatedFilteredNewMemberCompanies = filteredMemberCompanies
    ? filteredMemberCompanies.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
      )
    : [];

  return (
    <Layout path={[{ title: "About BRT" }]}>
      <HeadMeta title="BRT Member Portal | About BRT" />
      <TwoColLayout>
        <VStack>
          <SectionHeader
            title="About BRT"
            subTitle="Business Roundtable is an association of more than 200 chief executive officers (CEOs) of America’s leading companies, representing every sector of the U.S. economy. Business Roundtable CEOs lead U.S.-based companies that support one in four American jobs and almost a quarter of U.S. GDP. Through CEO-led policy committees, Business Roundtable members develop and advocate directly for policies to promote a thriving U.S. economy and expanded opportunity for all Americans."
            mb="40px"
          />
          {/* committee Topics */}
          <SectionHeader title="Policy Committees & Corporate Initiatives" />
          <VStack alignItems="stretch" w="100%" spacing={3} mt="0px !important">
            {committeeTopics.map((topic) => (
              <AboutBRTCard data={topic} key={topic.id} />
            ))}
          </VStack>

          {/* about Topics */}
          <SectionHeader title="About Us" mt="40px !important" />
          <VStack w="100%" alignItems="stretch" spacing={3} mt="0px !important">
            {aboutTopics.map((topic) => (
              <AboutBRTCard data={topic} key={topic.id} />
            ))}
          </VStack>

          {/* Member table */}
          <SectionHeader title="Member Companies" mt="40px !important" />
          <Table
            variant="rounded"
            mt="0 !important"
            style={{ tableLayout: "fixed" }}
          >
            <Thead>
              <Tr display={{ base: "none", md: "table-row" }}>
                {NEW_MEMBER_COMPANIES_TABLE_COLUMNS.map((item: any) => (
                  <Th key={item.name} pb="8px">
                    <Flex align="center">
                      <HStack
                        cursor="pointer"
                        onClick={() => {
                          if (item.name === "brtWebsiteProfile") return;
                          setSortField(item.name);
                          setSortBy((val) => !val);
                        }}
                      >
                        <Text
                          textTransform="none"
                          fontWeight={600}
                          lineHeight="24px"
                          fontSize="14px"
                          fontFamily="montserrat"
                          whiteSpace="nowrap"
                        >
                          {item.title}
                        </Text>
                        {item.name !== "brtWebsiteProfile" && (
                          <img src="/images/icons/sort.svg" alt="" />
                        )}
                      </HStack>
                    </Flex>
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {paginatedFilteredNewMemberCompanies.map(
                (row: any, index: number) => (
                  <Tr
                    transition="box-shadow 0.25s"
                    borderRadius="8px"
                    key={index}
                    display={{ base: "none", md: "table-row" }}
                    _hover={{
                      boxShadow: "0px 3px 5px rgba(210, 210, 210, 0.5);",
                    }}
                  >
                    {renderColumns(row, "td")}
                  </Tr>
                )
              )}
              {/* mobile */}
              {paginatedFilteredNewMemberCompanies.map(
                (row: any, index: number) => (
                  <Tr
                    key={index}
                    display={{ base: "table-row", md: "none" }}
                    _hover={{
                      boxShadow: "0px 3px 5px rgba(210, 210, 210, 0.5);",
                    }}
                  >
                    <Td p="8px 16px">{renderColumns(row, "div")}</Td>
                  </Tr>
                )
              )}
            </Tbody>
          </Table>
          <Box w="100%">
            <Pagination
              total={filteredMemberCompanies.length}
              showItemShown
              pageSize={pageSize}
              useOuter={true}
              outerCurrentPage={currentPage}
              setOuterCurrentPage={setCurrentPage}
            />
          </Box>
        </VStack>

        <VStack spacing="40px" w="100%">
          <ViewAllStaffButton />
          <HelpRequestForm />
        </VStack>
      </TwoColLayout>
    </Layout>
  );
};

export default AboutBRT;
