import {
  Box,
  HStack,
  Select,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { useState } from "react";
import { BiCaretDown } from "react-icons/bi";
import { Redirect, useParams } from "react-router";
import MessageCard from "../components/BRTInbox/MessageCard";
import HeadMeta from "../components/common/HeadMeta";
import Pagination from "../components/common/Pagination";
import ViewAllStaffButton from "../components/common/ViewAllStaffButton";
import SectionHeader from "../components/Home/SectionHeader";
import Layout from "../components/Layout";
import TwoColLayout from "../components/Layout/TwoColLayout";
import { MESSAGE_TYPE } from "../constants/default";
import useMessages from "../hooks/useMessages";

var dayjsUTC = require("dayjs/plugin/utc");
var dayjsTimezone = require("dayjs/plugin/timezone");
var dayjsAdvancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(dayjsUTC);
dayjs.extend(dayjsTimezone);
dayjs.extend(dayjsAdvancedFormat);

interface BRTInboxViewAllProps {}

const BRTInboxViewAll = (props: BRTInboxViewAllProps) => {
  const params: Record<string, any> = useParams();
  const pageSize = 25;
  const type = params?.id;
  const data = useMessages(type);
  const [sortDropdown, setSortDropdown] = useState<string>("0");
  const [currentPage, setCurrentPage] = useState<number>(1);

  const fileteredData = data?.sort((result1, result2) => {
    if (sortDropdown === "0") {
      return (
        (new Date(result1.memberPortalDate) >
          new Date(result2.memberPortalDate) &&
          -1) ||
        1
      );
    } else if (sortDropdown === "1") {
      return (
        (new Date(result1.memberPortalDate) >
          new Date(result2.memberPortalDate) &&
          1) ||
        -1
      );
    } else if (sortDropdown === "2") {
      return (
        (result1.title.toLowerCase() > result2.title.toLowerCase() && 1) || -1
      );
    } else if (sortDropdown === "3") {
      return (
        (result1.title.toLowerCase() > result2.title.toLowerCase() && -1) || 1
      );
    } else {
      return 0;
    }
  });

  const paginatedFilteredData = fileteredData
    ? fileteredData?.slice((currentPage - 1) * pageSize, currentPage * pageSize)
    : [];

  const compactPagination = useBreakpointValue({ base: true, md: false });

  const name = MESSAGE_TYPE[type];

  return (
    <>
      {Object.keys(MESSAGE_TYPE).includes(type) ? (
        <Layout
          path={[
            {
              title: "BRT Inbox",
              url: "/brt-inbox",
            },
            { title: name },
          ]}
        >
          <HeadMeta title={`BRT Inbox | ${name}`} />
          <TwoColLayout>
            <VStack spacing={{ base: 4, md: "21px" }} align="stretch">
              {data?.length > 0 && (
                <>
                  {/* Header */}
                  <Stack
                    justify="space-between"
                    align={{ base: "flex-start", md: "center" }}
                    w="100%"
                    direction={{ base: "column", md: "row" }}
                    spacing={4}
                  >
                    <SectionHeader title={`${name}`} mb={0} />
                    <HStack
                      justify={{ base: "space-between", md: "flex-end" }}
                      w="100%"
                    >
                      <Text
                        color="grey.500"
                        fontSize="14"
                        lineHeight="24px"
                        fontFamily="opensans"
                        whiteSpace="nowrap"
                      >
                        Sort by
                      </Text>
                      <Select
                        bg="white"
                        border="none"
                        minW="144px"
                        w="auto"
                        defaultValue="0"
                        value={sortDropdown}
                        onChange={(e) => setSortDropdown(e.target.value)}
                        icon={<BiCaretDown fontSize="16px" />}
                      >
                        <option value="0">New to Old</option>
                        <option value="1">Old to New</option>
                        <option value="2">A to Z</option>
                        <option value="3">Z to A</option>
                      </Select>
                      {/* mobile pagination navigation */}
                      <Pagination
                        total={data.length}
                        pageSize={pageSize}
                        useOuter={true}
                        outerCurrentPage={currentPage}
                        setOuterCurrentPage={setCurrentPage}
                        compact
                        display={{ base: "flex", md: "none" }}
                      />
                    </HStack>
                  </Stack>

                  {/* List */}
                  <VStack spacing={3} align="stretch" position="relative">
                    {paginatedFilteredData.map((item: any, index: number) => (
                      <MessageCard data={item} key={item?.id} />
                    ))}

                    <Pagination
                      mt="16px !important"
                      total={data.length}
                      pageSize={pageSize}
                      useOuter={true}
                      outerCurrentPage={currentPage}
                      setOuterCurrentPage={setCurrentPage}
                      showItemShown
                      compact={compactPagination}
                    />
                  </VStack>
                </>
              )}
            </VStack>

            {/* Staff leads */}
            <Box>
              <ViewAllStaffButton />
            </Box>
          </TwoColLayout>
        </Layout>
      ) : (
        <Redirect to="/brt-inbox" />
      )}
    </>
  );
};

export default BRTInboxViewAll;
