import { MdNotifications } from "react-icons/md";
import { WEB_ROLES } from "./default";
import routes from "./routes.json";

export const MENU_TOP = [
  {
    id: 1,
    name: "home",
    title: "Home",
    icon: "home",
    custom: true,
    path: routes.HOME,
    isActive: (path: string) => path === routes.HOME,
    acl: [
      WEB_ROLES.SUPER_ADMIN,
      WEB_ROLES.ADMIN,
      WEB_ROLES.CEO,
      WEB_ROLES.STANDARD,
    ],
  },
  {
    id: 2,
    name: "about",
    title: "About BRT",
    icon: "about",
    custom: true,
    path: routes.HELP_CENTER,
    isActive: (path: string) => path === routes.HELP_CENTER,
    acl: [
      WEB_ROLES.SUPER_ADMIN,
      WEB_ROLES.ADMIN,
      WEB_ROLES.CEO,
      WEB_ROLES.STANDARD,
    ],
  },
  {
    id: 3,
    name: "events",
    title: "Events",
    icon: "events",
    custom: true,
    path: routes.EVENTS,
    isActive: (path: string) => path === routes.EVENTS,
    acl: [
      WEB_ROLES.SUPER_ADMIN,
      WEB_ROLES.ADMIN,
      WEB_ROLES.CEO,
      WEB_ROLES.STANDARD,
    ],
  },
  {
    id: 4,
    name: "committees",
    title: "Committees",
    icon: "committees",
    custom: true,
    path: routes.COMMITTEES,
    isActive: (path: string) => path === routes.COMMITTEES,
    acl: [
      WEB_ROLES.SUPER_ADMIN,
      WEB_ROLES.ADMIN,
      WEB_ROLES.CEO,
      WEB_ROLES.STANDARD,
    ],
  },
  // {
  //   id: 5,
  //   name: 'content-browser',
  //   title: 'Content Browser',
  //   icon: BiWindows,
  //   path: routes.CONTENT_BROWSER,
  //   isActive: (path: string) => path === routes.CONTENT_BROWSER,
  // },
  {
    id: 5,
    name: "corporate_initiatives",
    title: "Corporate Initiatives",
    icon: "corporate",
    custom: true,
    isActive: (path: string) => path === routes.CORPORATE_INITIATIVES,
    acl: [
      WEB_ROLES.SUPER_ADMIN,
      WEB_ROLES.ADMIN,
      WEB_ROLES.CEO,
      WEB_ROLES.STANDARD,
    ],
    path: routes.CORPORATE_INITIATIVES,
  },
  {
    id: 6,
    name: "brt-inbox",
    title: "BRT Inbox",
    icon: "mdpiu",
    custom: true,
    path: routes.BRT_INBOX,
    isActive: (path: string) => path === routes.BRT_INBOX,
    acl: [
      WEB_ROLES.SUPER_ADMIN,
      WEB_ROLES.ADMIN,
      WEB_ROLES.CEO,
      WEB_ROLES.STANDARD,
    ],
  },
  {
    id: 7,
    name: "member-center",
    title: "Member Center",
    icon: "memberCenter",
    custom: true,
    path: routes.MEMBER_CENTER,
    isActive: (path: string) => path === routes.MEMBER_CENTER,
    acl: [WEB_ROLES.SUPER_ADMIN, WEB_ROLES.ADMIN, WEB_ROLES.CEO],
  },
  {
    id: 8,
    name: "search",
    title: "Search",
    icon: "search",
    custom: true,
    path: routes.SEARCH_RESULTS,
    isActive: (path: string) => path === routes.SEARCH_RESULTS,
    acl: [
      WEB_ROLES.SUPER_ADMIN,
      WEB_ROLES.ADMIN,
      WEB_ROLES.CEO,
      WEB_ROLES.STANDARD,
    ],
  },
];

export const MENU_BOTTOM = [
  {
    id: 9,
    name: "notifications",
    title: "Notifications",
    icon: MdNotifications,
    path: routes.NOTIFICATIONS,
    isActive: (path: string) => path === routes.NOTIFICATIONS,
    acl: [
      WEB_ROLES.SUPER_ADMIN,
      WEB_ROLES.ADMIN,
      WEB_ROLES.CEO,
      WEB_ROLES.STANDARD,
    ],
  },
];
