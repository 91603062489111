import React from "react";
import { Box, Stack, useMediaQuery } from "@chakra-ui/react";
import NewsCard from "./NewsCard";
import SectionHeader from "./SectionHeader";

interface Props {
  data: any;
  showHeader?: boolean;
}

export default function BRTEssentials({ data, showHeader = true }: Props) {
  const [isLG] = useMediaQuery("(min-width: 1024px)");

  return (
    <Box w="100%">
      {showHeader && (
        <SectionHeader
          subTitle="Important items for easy reference."
          title="BRT Essentials"
        />
      )}
      <Stack
        direction={isLG ? "row" : "column"}
        spacing={{ base: 3, lg: 5 }}
        w="100%"
        alignItems={{ md: "stretch" }}
      >
        {data
          .filter((item: any) => item !== null)
          .map((item: any, index: number) => {
            return (
              <Box key={`weekly-${index}`} width="100%">
                <NewsCard data={item} />
              </Box>
            );
          })}
      </Stack>
    </Box>
  );
}
