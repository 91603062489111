import { ChakraProvider } from "@chakra-ui/react";
import * as React from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "./components/Auth/PrivateRoute";
import PublicRoute from "./components/Auth/PublicRoute";
import { WEB_ROLES } from "./constants/default";
import routes from "./constants/routes.json";
import PageNotFound from "./pages/404";
import AboutBRT from "./pages/AboutBRT";
import ArticleDetails from "./pages/ArticleDetails";
import BRTInbox from "./pages/BRTInbox";
import BRTInboxDetail from "./pages/BRTInboxDetail";
import BRTInboxViewAll from "./pages/BRTInboxViewAll";
import CommitteeDetails from "./pages/CommitteeDetails";
import Committees from "./pages/Committees";
import CommitteesCommunications from "./pages/CommitteesCommunications";
import CommitteesDocuments from "./pages/CommitteesDocuments";
import CommitteesUpdates from "./pages/CommitteesUpdates";
import CorporateInitiatives from "./pages/CorporateInitiatives";
import EventDetails from "./pages/EventDetails";
import Events from "./pages/Events";
import Home from "./pages/Home";
import MemberCenter from "./pages/MemberCenter";
import MessageDetail from "./pages/MessageDetail";
import ForgotPassword from "./pages/Onboard/ForgotPassword";
import ForgotPasswordSent from "./pages/Onboard/ForgotPasswordSent";
import ResetPassword from "./pages/Onboard/ResetPassword";
import ResetPasswordDone from "./pages/Onboard/ResetPasswordDone";
import SignIn from "./pages/Onboard/SignIn";
import StepAbout from "./pages/Onboard/StepAbout";
import StepWelcome from "./pages/Onboard/StepWelcome";
import UpdateExpiredPassword from "./pages/Onboard/UpdateExpiredPassword";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ProfileInformation from "./pages/ProfileInformation";
import SearchResults from "./pages/SearchResults";
import "./styles/fonts.css";
import "./styles/globals.css";
import theme from "./styles/theme";

export const App = () => (
  <ChakraProvider theme={theme}>
    <Switch>
      <PrivateRoute
        exact
        path={routes.HOME}
        component={Home}
        acl={[
          WEB_ROLES.SUPER_ADMIN,
          WEB_ROLES.ADMIN,
          WEB_ROLES.CEO,
          WEB_ROLES.STANDARD,
        ]}
      />
      <PublicRoute restricted exact path={routes.SIGNIN} component={SignIn} />
      <PublicRoute
        restricted
        exact
        path={routes.WELCOME}
        component={StepWelcome}
      />
      <PublicRoute restricted exact path={routes.ABOUT} component={StepAbout} />
      <PublicRoute
        restricted
        exact
        path={routes.FORGOT_PASSWORD}
        component={ForgotPassword}
      />
      <PublicRoute
        restricted
        exact
        path={routes.FORGOT_PASSWORD_SENT}
        component={ForgotPasswordSent}
      />
      <PublicRoute
        restricted
        exact
        path={routes.RESET_PASSWORD}
        component={ResetPassword}
      />
      <PublicRoute
        restricted
        exact
        path={routes.RESET_PASSWORD_DONE}
        component={ResetPasswordDone}
      />
      <PrivateRoute
        acl={[
          WEB_ROLES.SUPER_ADMIN,
          WEB_ROLES.ADMIN,
          WEB_ROLES.CEO,
          WEB_ROLES.STANDARD,
        ]}
        exact
        path={routes.COMMITTEES}
        component={Committees}
      />
      <PrivateRoute
        acl={[
          WEB_ROLES.SUPER_ADMIN,
          WEB_ROLES.ADMIN,
          WEB_ROLES.CEO,
          WEB_ROLES.STANDARD,
        ]}
        exact
        path={routes.COMMITTEE_DETAILS}
        component={CommitteeDetails}
      />
      <PrivateRoute
        acl={[
          WEB_ROLES.SUPER_ADMIN,
          WEB_ROLES.ADMIN,
          WEB_ROLES.CEO,
          WEB_ROLES.STANDARD,
        ]}
        exact
        path={routes.COMMITTEES_UPDATES}
        component={CommitteesUpdates}
      />
      <PrivateRoute
        acl={[
          WEB_ROLES.SUPER_ADMIN,
          WEB_ROLES.ADMIN,
          WEB_ROLES.CEO,
          WEB_ROLES.STANDARD,
        ]}
        exact
        path={routes.COMMITTEES_DOCUMENTS}
        component={CommitteesDocuments}
      />
      <PrivateRoute
        acl={[
          WEB_ROLES.SUPER_ADMIN,
          WEB_ROLES.ADMIN,
          WEB_ROLES.CEO,
          WEB_ROLES.STANDARD,
        ]}
        exact
        path={routes.COMMITTEES_COMMUNICATIONS}
        component={CommitteesCommunications}
      />
      <PrivateRoute
        acl={[WEB_ROLES.SUPER_ADMIN, WEB_ROLES.ADMIN, WEB_ROLES.CEO]}
        exact
        path={routes.MEMBER_CENTER}
        component={MemberCenter}
      />
      <PrivateRoute
        acl={[
          WEB_ROLES.SUPER_ADMIN,
          WEB_ROLES.ADMIN,
          WEB_ROLES.CEO,
          WEB_ROLES.STANDARD,
        ]}
        exact
        path={routes.PROFILE}
        component={ProfileInformation}
      />
      <PrivateRoute
        acl={[
          WEB_ROLES.SUPER_ADMIN,
          WEB_ROLES.ADMIN,
          WEB_ROLES.CEO,
          WEB_ROLES.STANDARD,
        ]}
        exact
        path={routes.EVENT_DETAILS}
        component={EventDetails}
      />
      <PrivateRoute
        acl={[
          WEB_ROLES.SUPER_ADMIN,
          WEB_ROLES.ADMIN,
          WEB_ROLES.CEO,
          WEB_ROLES.STANDARD,
        ]}
        exact
        path={routes.EVENTS}
        component={Events}
      />
      <PrivateRoute
        acl={[
          WEB_ROLES.SUPER_ADMIN,
          WEB_ROLES.ADMIN,
          WEB_ROLES.CEO,
          WEB_ROLES.STANDARD,
        ]}
        exact
        path={routes.ARTICLE_DETAILS}
        component={ArticleDetails}
      />

      {/* CORPORATE_INITIATIVES */}
      <PrivateRoute
        acl={[
          WEB_ROLES.SUPER_ADMIN,
          WEB_ROLES.ADMIN,
          WEB_ROLES.CEO,
          WEB_ROLES.STANDARD,
        ]}
        exact
        path={routes.CORPORATE_INITIATIVES}
        component={CorporateInitiatives}
      />
      <PrivateRoute
        acl={[
          WEB_ROLES.SUPER_ADMIN,
          WEB_ROLES.ADMIN,
          WEB_ROLES.CEO,
          WEB_ROLES.STANDARD,
        ]}
        exact
        path={routes.CORPORATE_INITIATIVES_UPDATES}
        component={CommitteesUpdates}
      />
      <PrivateRoute
        acl={[
          WEB_ROLES.SUPER_ADMIN,
          WEB_ROLES.ADMIN,
          WEB_ROLES.CEO,
          WEB_ROLES.STANDARD,
        ]}
        exact
        path={routes.CORPORATE_INITIATIVES_DOCUMENTS}
        component={CommitteesDocuments}
      />
      <PrivateRoute
        acl={[
          WEB_ROLES.SUPER_ADMIN,
          WEB_ROLES.ADMIN,
          WEB_ROLES.CEO,
          WEB_ROLES.STANDARD,
        ]}
        exact
        path={routes.CORPORATE_INITIATIVES_COMMUNICATIONS}
        component={CommitteesCommunications}
      />
      <PrivateRoute
        acl={[
          WEB_ROLES.SUPER_ADMIN,
          WEB_ROLES.ADMIN,
          WEB_ROLES.CEO,
          WEB_ROLES.STANDARD,
        ]}
        exact
        path={routes.CORPORATE_INITIATIVES_MESSAGE_DETAILS}
        component={MessageDetail}
      />
      {/*  */}

      <PrivateRoute
        acl={[
          WEB_ROLES.SUPER_ADMIN,
          WEB_ROLES.ADMIN,
          WEB_ROLES.CEO,
          WEB_ROLES.STANDARD,
        ]}
        exact
        path={routes.MESSAGE_DETAILS}
        component={MessageDetail}
      />
      <PrivateRoute
        acl={[
          WEB_ROLES.SUPER_ADMIN,
          WEB_ROLES.ADMIN,
          WEB_ROLES.CEO,
          WEB_ROLES.STANDARD,
        ]}
        exact
        path={routes.HELP_CENTER}
        component={AboutBRT}
      />
      <PrivateRoute
        acl={[
          WEB_ROLES.SUPER_ADMIN,
          WEB_ROLES.ADMIN,
          WEB_ROLES.CEO,
          WEB_ROLES.STANDARD,
        ]}
        exact
        path={routes.SEARCH_RESULTS}
        component={SearchResults}
      />
      <PrivateRoute
        acl={[
          WEB_ROLES.SUPER_ADMIN,
          WEB_ROLES.ADMIN,
          WEB_ROLES.CEO,
          WEB_ROLES.STANDARD,
        ]}
        exact
        path={routes.BRT_INBOX}
        component={BRTInbox}
      />
      <PrivateRoute
        acl={[
          WEB_ROLES.SUPER_ADMIN,
          WEB_ROLES.ADMIN,
          WEB_ROLES.CEO,
          WEB_ROLES.STANDARD,
        ]}
        exact
        path={routes.BRT_INBOX_VIEW_ALL}
        component={BRTInboxViewAll}
      />
      <PrivateRoute
        acl={[
          WEB_ROLES.SUPER_ADMIN,
          WEB_ROLES.ADMIN,
          WEB_ROLES.CEO,
          WEB_ROLES.STANDARD,
        ]}
        exact
        path={routes.BRT_INBOX_DETAIL}
        component={BRTInboxDetail}
      />
      <PublicRoute exact path={routes.NOT_FOUND} component={PageNotFound} />
      <PrivateRoute
        acl={[
          WEB_ROLES.SUPER_ADMIN,
          WEB_ROLES.ADMIN,
          WEB_ROLES.CEO,
          WEB_ROLES.STANDARD,
        ]}
        exact
        path={routes.PRIVACY_POLICY}
        component={PrivacyPolicy}
      />
      <PublicRoute
        exact
        path={routes.UPDATE_EXPIRED_PASSSWORD}
        component={UpdateExpiredPassword}
      />
      <PublicRoute component={PageNotFound} />
    </Switch>
  </ChakraProvider>
);
