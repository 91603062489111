import * as React from "react";
import { Stack, Grid, VStack, HStack, Avatar, Text } from "@chakra-ui/react";
import { roles } from "../../constants/roles";

export interface CommitteeProfileBlockProps {
  committee: any;
  members: any;
}

export function CommitteeProfileBlock({
  committee,
  members,
}: CommitteeProfileBlockProps) {
  let order = [
    "brt_CommitteeChair",
    "brt_CoordinatingCommitteeChair",
    "brt_BRTPolicyLead",
    "brt_Director1",
    "brt_Director2",
    "brt_Director3",
    "brt_BRTPolicyEALead",
  ];

  let hasDirectorTitle = false;
  const getRoleName = (key, roles) => {
    switch (key) {
      case "brt_BRTPolicyEALead":
        return `${committee.category}, ${roles[key]}`;
      case "brt_Director1":
      case "brt_Director2":
      case "brt_Director3":
        if (hasDirectorTitle) {
          return "";
        }
        hasDirectorTitle = true;
        // return `${roles.brt_Director}`;
        return "";
      default:
        return roles[key];
    }
  };

  return (
    <Stack
      direction={{ base: "row", lg: "column" }}
      w="100%"
      align="flex-start"
    >
      <Grid
        w="100%"
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
          lg: "repeat(1, 1fr)",
        }}
        gap="16px"
      >
        {order.map((key, i) =>
          !members[key] || Object.keys(members[key]).length === 0 ? (
            <></>
          ) : (
            <VStack alignItems="flex-start" key={key} spacing={4}>
              <Text
                fontWeight="semibold"
                fontFamily="montserrat"
                fontSize={16}
                lineHeight="20px"
              >
                {getRoleName(key, roles)}
              </Text>
              <HStack mb="35px">
                <Avatar
                  boxSize="119px"
                  src={members[key].image}
                  name={members[key].firstName + " " + members[key].lastName}
                  bg="grey.200"
                  color="white"
                />
                <VStack align="flex-start" pl="10px" spacing={2}>
                  <Text
                    fontWeight="600"
                    fontFamily="montserrat"
                    fontSize={16}
                    lineHeight="21px"
                  >
                    {members[key].firstName + " " + members[key].lastName}
                  </Text>
                  {members[key].company && (
                    <Text fontSize={14} lineHeight="19px">
                      {members[key].company}
                    </Text>
                  )}
                  {members[key].jobTitle && (
                    <Text fontSize={14} lineHeight="19px">
                      {members[key].jobTitle}
                    </Text>
                  )}
                  {key !== "brt_CommitteeChair" &&
                    key !== "brt_CoordinatingCommitteeChair" &&
                    members[key].emailAddress && (
                      <a href={`mailto:${members[key].emailAddress}`}>
                        <Text
                          color="primary.500"
                          fontSize={14}
                          lineHeight="19px"
                        >
                          {members[key].emailAddress}
                        </Text>
                      </a>
                    )}
                </VStack>
              </HStack>
            </VStack>
          )
        )}
      </Grid>
    </Stack>
  );
}
