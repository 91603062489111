import { Box, Stack, Text, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import HeadMeta from "../../components/common/HeadMeta";
import PrimaryButton from "../../components/common/PrimaryButton";
import Descriptions from "../../components/Onboard/Descriptions";
import StepProgress from "../../components/Onboard/StepProgress";
import PublicLayout from "../../components/PublicLayout";
import routes from "../../constants/routes.json";
import { setInvitation, setInvitationRequest } from "../../redux/auth/action";

function StepWelcome({ userError }: { userError: string }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const first = query.get("first") || "New";
  const last = query.get("last") || "User";

  const handleClick = () => {
    if (id) {
      dispatch(setInvitation({ id, first, last }));
      dispatch(setInvitationRequest({ invitation: id }));
    }
  };

  useEffect(() => {
    if (!id) {
      history.push(routes.NOT_FOUND);
    }
  }, []);

  return (
    <PublicLayout progress={50}>
      <HeadMeta />

      <Stack
        direction={{ base: "column", md: "row" }}
        spacing="25px"
        w="100%"
        flex={1}
      >
        <VStack
          h="100%"
          w={{ base: "100%", md: "42%" }}
          maxWidth={{ base: "100%", md: "619px" }}
          bg="white"
          flex={1}
          alignItems={{ base: "flex-start", md: "center" }}
          justifyContent={"center"}
        >
          <VStack
            maxW="371px"
            h="100%"
            flex={1}
            px={{ base: "0px", md: "24px" }}
          >
            <VStack
              flex={1}
              alignItems="flex-start"
              justifyContent={{ base: "flex-start", md: "center" }}
              mt={{ base: "3.5rem", md: "0px" }}
            >
              <Text
                fontSize={{ base: "24px", lg: "36px" }}
                lineHeight={{ base: "29px", lg: "44px" }}
                fontWeight={700}
                fontFamily="montserrat"
                mb={{ base: "16px", sm: "16px" }}
              >
                Welcome to the Business Roundtable Member Portal
              </Text>
              <Text
                fontSize={{ base: "14px", lg: "16px" }}
                lineHeight={{ base: "19px", lg: "22px" }}
                fontWeight={400}
                fontFamily="opensans"
                mt="0px !important"
              >
                The portal is a secure digital platform with centralized
                Business Roundtable information and resources to support your
                engagement.
              </Text>
              <PrimaryButton onClick={handleClick} mt="56px !important">
                ACTIVATE MY ACCOUNT
              </PrimaryButton>

              {userError && (
                <Text
                  fontSize="12px"
                  fontWeight={600}
                  color="error.500"
                  fontFamily="opensans"
                >
                  {userError}
                </Text>
              )}
            </VStack>
          </VStack>
        </VStack>

        <VStack
          h={{ base: "fit-content", md: "100%" }}
          w={{ base: "100%", md: "58%" }}
          flex={1}
          alignItems={"center"}
          justifyContent={"center"}
          ml="0 !important"
          display={{ base: "none", md: "flex" }}
        >
          <VStack
            flex={1}
            alignItems={"center"}
            justifyContent={"center"}
            px={{ base: "40px", md: "60px", xl: "120px" }}
          >
            <Box
              borderRadius="8px"
              filter="drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1)) drop-shadow(0px 16px 32px rgba(0, 0, 0, 0.08));"
              mb="16px"
            >
              <img
                src="/images/onboard/welcome.png"
                alt=""
                style={{ maxHeight: "453px", maxWidth: "581px" }}
                width="100%"
              />
            </Box>
            <Box mt="0 !important" maxW="581px" pl="40px" pr="16px">
              <Descriptions
                title="View the Latest or Find Past Content"
                description="The Business Roundtable Member Portal provides information from across the organization including Policy Committees, Corporate Initiatives, Government Relations and Communications."
              />
            </Box>
          </VStack>

          <StepProgress value={50} />
        </VStack>
      </Stack>
    </PublicLayout>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userError: (state.auth && state.auth.error) || "",
  };
};

export default connect(mapStateToProps)(StepWelcome);
