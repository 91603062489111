import React from "react";
import { connect } from "react-redux";
import { Box, Flex, Spinner } from "@chakra-ui/react";

import StepProgress from "../Onboard/StepProgress";

const Layout = ({
  progress,
  children,
  path,
  hideLogo,
  loading,
}: {
  progress: number;
  children: any;
  path?: any;
  hideLogo: boolean;
  loading: {};
}) => {
  const isLoading = Object.keys(loading).some((key) => loading?.[key]);
  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      minH="100vh"
      minW="100%"
      w="100%"
      alignItems="stretch"
      bg={{ base: "white", md: "transparent" }}
      position="relative"
      px={{ base: "1.5rem", md: "0px" }}
    >
      {!hideLogo && (
        <Box
          position={{ base: "relative", md: "absolute" }}
          zIndex={100}
          left={{ base: 0, md: "24px", xl: "32px" }}
          top={{ base: 0, md: "26px", xl: "24px" }}
          h={{ base: "40px", md: "40px" }}
          w={{ base: "180px", md: "180px" }}
          ml={{ base: "-8px", md: 0 }}
          bg={{ base: "white", md: "transparent" }}
          display={{ base: "flex", md: "block" }}
          mt={{ base: "14px", md: "0px" }}
        >
          <img src="/images/logo-full.svg" alt="" />
        </Box>
      )}
      {progress && (
        <Box mx={{ base: "-24px", md: "unset" }}>
          <StepProgress
            value={progress}
            display={{ base: "block", md: "none" }}
          />
        </Box>
      )}
      {children}
      {isLoading && (
        <Flex
          minW="100%"
          w="100%"
          h="100vh"
          minH="100vh"
          position="fixed"
          zIndex={10000}
          alignItems="center"
          justifyContent="center"
          display="flex"
          bg="rgba(255,255,255,.6)"
          mx={{ base: "-1.5rem", md: "none" }}
        >
          <Spinner size="xl" color="primary.500" />
        </Flex>
      )}
    </Flex>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loading: state.ui.loading,
  };
};

export default connect(mapStateToProps)(Layout);
