import { handleActions } from "redux-actions";
import {
  getCommitteeError,
  getCommitteesError,
  getCommitteesSuccess,
  getCommitteeSuccess,
  getCommitteeMembersSuccess,
  getCommitteeMembersError,
  getSearchError,
  getSearchSuccess,
  getSubCommitteesError,
  getSubCommitteesSuccess,
  getTagsError,
  getTagsSuccess,
  getUpdatesError,
  getUpdatesSuccess,
  setCommitteesError,
  setCommitteesSuccess,
  setMembershipError,
  setMembershipSuccess,
  updateCommitteeMembershipsError,
  updateCommitteeMembershipsSuccess,
} from "./action";

const DEFAULT_STATE = {
  committees: [],
  subCommittees: [],
  committee: {},
  tags: [],
  searchResults: [],
  searchResultCount: 0,
  company: {},
  updates: {},
  error: "",
  committeeMembers: [],
  committeeMemberships: [],
};

const handlers = {
  [getCommitteesSuccess]: (state, action) => {
    return { ...state, committees: action.payload };
  },

  [getCommitteesError]: (state, action) => {
    return { ...state, error: action.payload };
  },

  [setCommitteesSuccess]: (state, action) => {
    return { ...state, committees: action.payload };
  },

  [setCommitteesError]: (state, action) => {
    return { ...state, error: action.payload };
  },

  [getCommitteeSuccess]: (state, action) => {
    return { ...state, committee: action.payload };
  },

  [getCommitteeError]: (state, action) => {
    return { ...state, error: action.payload };
  },

  [getCommitteeMembersSuccess]: (state, action) => {
    return { ...state, committeeMembers: action.payload };
  },

  [getCommitteeMembersError]: (state, action) => {
    return { ...state, error: action.payload };
  },

  [setMembershipSuccess]: (state, action) => {
    return { ...state, membership: action.payload };
  },

  [setMembershipError]: (state, action) => {
    return { ...state, error: action.payload };
  },

  [getTagsSuccess]: (state, action) => {
    return { ...state, tags: action.payload };
  },

  [getTagsError]: (state, action) => {
    return { ...state, error: action.payload };
  },

  [getSubCommitteesSuccess]: (state, action) => {
    return { ...state, subCommittees: action.payload };
  },

  [getSubCommitteesError]: (state, action) => {
    return { ...state, error: action.payload };
  },

  [getSearchSuccess]: (state, action) => {
    return {
      ...state,
      searchResults: action.payload.results || [],
      searchResultCount: action.payload.numberOfResults || 0,
    };
  },

  [getSearchError]: (state, action) => {
    return { ...state, error: action.payload };
  },

  [getUpdatesSuccess]: (state, action) => {
    return { ...state, updates: action.payload };
  },

  [getUpdatesError]: (state, action) => {
    return { ...state, error: action.payload };
  },

  [updateCommitteeMembershipsSuccess]: (state, action) => {
    return { ...state, committeeMemberships: action.payload };
  },

  [updateCommitteeMembershipsError]: (state, action) => {
    return { ...state, error: action.payload };
  },
};

export default handleActions(handlers, DEFAULT_STATE);
