import React, {
  Box,
  Text,
  UnorderedList,
  ListItem,
  Stack,
  HStack,
} from "@chakra-ui/react";
import { useState, useRef } from "react";
import useOnClickOutside from "../../hooks/useClickOutside";
import { BsFillCaretUpFill, BsFillCaretDownFill } from "react-icons/bs";

interface Option {
  value: string | number;
  name: string;
}

interface ICustomSelectProps {
  label?: string;
  placeholder?: string;
  options: Option[];
  onChange?: Function;
}

export function CustomSelect({
  placeholder = "Select",
  label,
  options,
  onChange,
}: ICustomSelectProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<Option>();

  useOnClickOutside(ref, () => setOpen(false));

  function handleSelect(option: Option) {
    setSelected(option);
    onChange?.(option.value);
    setOpen(false);
  }

  function handleToggle() {
    setOpen((open) => !open);
  }

  return (
    <Stack direction="row" alignItems="center" ref={ref} w="100%">
      <Text color="grey.500" fontSize="14">
        {label}
      </Text>
      <Box position="relative" w="180px" flex="1">
        <HStack
          onClick={handleToggle}
          cursor="pointer"
          py="7px"
          pl="16px"
          pr="14px"
          borderRadius="4px"
          bgColor="#fff"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text
            fontSize="14px"
            lineHeight="19px"
            alignItems="center"
            color="grey.950"
          >
            {selected?.name || placeholder}
          </Text>
          {open ? (
            <BsFillCaretUpFill color="grey.950" fontSize="12px" />
          ) : (
            <BsFillCaretDownFill color="grey.950"  fontSize="12px"/>
          )}
        </HStack>
        {open && (
          <UnorderedList
            position="absolute"
            top="42px"
            listStyleType="none"
            my="0px"
            mx="0px"
            py="8px"
            borderRadius="4px"
            bgColor="#fff"
            w="100%"
            boxShadow="0px 3px 5px rgba(210, 210, 210, 0.25);"
            zIndex="1"
          >
            {options
              // .filter((option) => option.value !== selected?.value)
              .map((option) => (
                <ListItem
                  onClick={() => handleSelect(option)}
                  py="8px"
                  px="16px"
                  cursor="pointer"
                  _hover={{
                    bgColor: "grey.100",
                  }}
                  fontSize="14px"
                  lineHeight="19px"
                  color="grey.950"
                >
                  {option.name}
                </ListItem>
              ))}
          </UnorderedList>
        )}
      </Box>
    </Stack>
  );
}
