import { all, call, put, takeLatest } from "redux-saga/effects";
import { setLoading } from "../ui/action";
import {
  getEventSuccess,
  getEventError,
  getEventRequest,
  getEventsError,
  getEventsRequest,
  getEventsSuccess,
  getEventLocationsSuccess,
  getEventLocationsError,
  getEventLocationsRequest,
} from "./action";
import {
  getEvent as getEventQuery,
  getEvents as getEventsQuery,
  getEventLocations as getEventLocationsQuery,
} from "./query";
import * as Type from "./type";

export function* getEventsSaga({ payload }) {
  try {
    yield put(setLoading(Type.GET_EVENTS_REQUEST));
    const { data } = yield call(getEventsQuery, payload);

    if (!data) {
      yield put(getEventsError(data.error || "Get events error."));
      yield put(setLoading(Type.GET_EVENTS_ERROR));
    } else {
      yield put(getEventsSuccess(data || []));
      yield put(setLoading(Type.GET_EVENTS_SUCCESS));
    }
  } catch (error) {
    yield put(setLoading(Type.GET_EVENTS_ERROR));
  }
}

export function* getEventSaga({ payload }) {
  try {
    yield put(setLoading(Type.GET_EVENT_REQUEST));
    const { data } = yield call(getEventQuery, payload);
    yield put(setLoading(Type.GET_EVENT_SUCCESS));

    if (data) {
      yield put(getEventSuccess(data));
    } else {
      yield put(getEventError(data.error || "Get Event error."));
    }
  } catch (error) {
    yield put(setLoading(Type.GET_EVENT_ERROR));
  }
}

export function* getEventLocationsSaga({ payload }) {
  try {
    yield put(setLoading(Type.GET_EVENT_LOCATIONS_REQUEST));
    const { data } = yield call(getEventLocationsQuery, payload);

    if (!data) {
      yield put(
        getEventLocationsError(data.error || "Get event locations error.")
      );
    } else {
      yield put(getEventLocationsSuccess(data || []));
      yield put(setLoading(Type.GET_EVENT_LOCATIONS_SUCCESS));
    }
  } catch (error) {
    yield put(setLoading(Type.GET_EVENT_LOCATIONS_ERROR));
  }
}

export default function* eventManagerSaga() {
  yield all([takeLatest(getEventsRequest, getEventsSaga)]);
  yield all([takeLatest(getEventRequest, getEventSaga)]);
  yield all([takeLatest(getEventLocationsRequest, getEventLocationsSaga)]);
}
