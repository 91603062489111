import React from "react";
import useMessages from "../../hooks/useMessages";
import BRTInboxSection from "./BRTInboxSection";

export default function GovernmentRelationsSection() {
  const messages = useMessages("government-relations");

  return (
    <BRTInboxSection
      data={messages}
      title="Government Relations"
      type="government-relations"
    />
  );
}
