import { Box, Flex, HStack, Stack, Text, VStack } from "@chakra-ui/react";
import dayjs from "dayjs";
import * as React from "react";
import { Link } from "react-router-dom";
import { cardShadowHover } from "../../styles/theme";

export interface EmailAndUpdateCardProps {
  id: number;
  title: string;
  multiCommittee: boolean;
  memberPortalDate: Date;
  updatedAt: Date;
  createdAt: Date;
  publishedDate: Date;
  bgColor: string;
  image: string;
  committee: any;
}

export function EmailAndUpdateCard({
  id,
  title,
  multiCommittee,
  memberPortalDate,
  updatedAt,
  createdAt,
  publishedDate,
  bgColor,
  image,
  committee,
}: EmailAndUpdateCardProps) {
  return (
    <Link to={`/${committee.key}/emails/${id}`}>
      <Stack
        direction={{ base: "column", md: "row" }}
        bg="white"
        borderRadius="16px"
        overflow="hidden"
        _hover={{ filter: cardShadowHover }}
        transition="0.3s all"
        spacing={0}
        w="100%"
      >
        <Flex
          minH={{ base: "52px", md: "87px" }}
          minW={{ base: "100%", md: "78px" }}
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          backgroundSize="30px 40px"
          backgroundColor={multiCommittee ? "#DDDDDD" : bgColor || "#fbefd3"}
          backgroundImage={
            multiCommittee
              ? "/images/committees/br-badge.svg"
              : `url(${image || "/images/committees/health.svg"})`
          }
        ></Flex>
        <VStack
          flex="1"
          alignItems="flex-start"
          p={{ base: "16px 20px", md: "20px 24px" }}
          spacing={0}
        >
          <HStack justify="space-between" w="100%" align="flex-start">
            <VStack alignItems="flex-start" spacing={3}>
              <Stack
                direction={{ base: "column", md: "row" }}
                w="100%"
                justify="flex-start"
                alignItems={{ base: "flex-start", md: "center" }}
              >
                <Text
                  fontSize="14px"
                  lineHeight="17px"
                  fontWeight="700"
                  fontFamily="montserrat"
                  textTransform="uppercase"
                >
                  {multiCommittee
                    ? "multi-committee"
                    : committee.category || committee.name}
                </Text>
                <Box
                  width="1px"
                  height="14px"
                  mx="8px"
                  bgColor="grey.700"
                  display={{ base: "none", md: "block" }}
                ></Box>
                {createdAt && (
                  <Text
                    fontSize="12px"
                    lineHeight="16px"
                    fontWeight="400"
                    fontFamily="opensans"
                    color="grey.700"
                  >
                    {dayjs(
                      memberPortalDate ||
                        updatedAt ||
                        createdAt ||
                        publishedDate
                    )
                      .tz("America/New_York")
                      .format("MMM D, YYYY")}
                  </Text>
                )}
              </Stack>
              <Text
                w="fit-content"
                fontSize="16px"
                lineHeight="22px"
                fontWeight="400"
                fontFamily="opensans"
                color="grey.950"
              >
                {title || ""}
              </Text>
            </VStack>
          </HStack>
        </VStack>
      </Stack>
    </Link>
  );
}
