import { Box, Flex } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import HeadMeta from "../../components/common/HeadMeta";
import ForgotPasswordForm from "../../components/Onboard/ForgotPasswordForm";
import PublicLayout from "../../components/PublicLayout";
import routes from "../../constants/routes.json";
import { forgotPassword } from "../../redux/auth/action";

function UpdateExpiredPassword({ userError, isAuthenticated }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (values: any) => {
    dispatch(forgotPassword(values));
  };

  const onBack = () => {
    history.push(routes.SIGNIN);
  };

  useEffect(() => {
    if (isAuthenticated) {
      history.push(routes.HOME);
    }
  }, [isAuthenticated]);

  return (
    <PublicLayout>
      <HeadMeta />

      <Flex
        bg="white"
        w="100%"
        justify="center"
        align="center"
        mt={{ base: "3.5rem", sm: "0px" }}
      >
        <Box w="500px">
          <ForgotPasswordForm
            title="Password expired"
            onSubmit={onSubmit}
            onBack={onBack}
            userError={userError}
          />
        </Box>
      </Flex>
    </PublicLayout>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userError: (state.auth && state.auth.error) || "",
    isAuthenticated: (state.auth && state.auth.isAuthenticated) || "",
  };
};

export default connect(mapStateToProps)(UpdateExpiredPassword);
