import React from "react";

const MdPIU = ({ color }: { color: string }) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3027 18.4445V16.7191H18.0904V18.4445H16.3027Z"
        fill={color}
      />
      <path
        d="M18.0407 3.92462V7.64409C17.3495 7.64409 16.656 7.68005 15.967 7.6351C14.919 7.56768 14.4752 7.99918 14.502 9.07569C14.5488 10.9455 14.5154 12.8154 14.5154 14.7729C14.1408 14.7976 13.8219 14.838 13.5008 14.8403C9.85279 14.8448 6.20254 14.847 2.55453 14.8403C0.804103 14.838 0.213196 14.2425 0.210966 12.4783C0.206507 9.669 0.210966 6.86199 0.210966 3.89766C0.596728 4.10666 0.884377 4.24151 1.15419 4.41006C3.55349 5.91583 5.96841 7.39687 8.3365 8.95208C8.97646 9.37234 9.41351 9.31167 10.0156 8.92062C12.3279 7.41935 14.6759 5.96752 17.0128 4.50221C17.3161 4.31118 17.6371 4.14712 18.043 3.92013L18.0407 3.92462Z"
        fill={color}
      />
      <path
        d="M18.0709 2.0345C15.2122 3.83468 12.4138 5.61013 9.59525 7.34738C9.37896 7.48222 8.93522 7.47548 8.71669 7.34064C5.89818 5.60114 3.09973 3.82793 0.25 2.0345C0.475214 0.971476 1.1018 0.454571 2.11637 0.452324C6.80572 0.441087 11.4973 0.443334 16.1867 0.450077C17.1633 0.450077 17.7877 0.937764 18.0709 2.0345Z"
        fill={color}
      />
      <path
        d="M16.3594 14.7795V9.53184H18.0206V14.7795H16.3594Z"
        fill={color}
      />
    </svg>
  );
};

export default MdPIU;
