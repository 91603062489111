import { Flex, FlexProps, HStack, Text, VStack } from "@chakra-ui/react";
import dayjs, { Dayjs } from "dayjs";
import isToday from "dayjs/plugin/isToday";
import React, { useMemo } from "react";
import EventCard from "./EventCard";

dayjs.extend(isToday);

interface IEventGroupProps {
  date: Dayjs;
  events: any[];
}

function EventGroup(props: IEventGroupProps) {
  const { date, events } = props;

  const todayStyles = useMemo<FlexProps>(() => {
    return date.isToday()
      ? {
          bgColor: "#0D7DBD",
          color: "#fff",
          fontWeight: "600",
        }
      : {
          bgColor: "transparent",
          color: "#000",
          fontWeight: "500",
        };
  }, [date]);
  return (
    <VStack spacing="20px" alignItems="flex-start" w="100%">
      <HStack spacing="12px">
        <Flex
          alignItems="center"
          justifyContent="center"
          w="42px"
          h="42px"
          borderRadius="50%"
          {...todayStyles}
        >
          <Text fontSize="16px" lineHeight="21px">
            {date.format("D")}
          </Text>
        </Flex>
        <Text
          fontSize="16px"
          lineHeight="21px"
          color="#000000"
          fontWeight="500"
        >
          {date.format("MMM, ddd")}
        </Text>
      </HStack>
      <VStack spacing="12px" paddingLeft={{ base: "0", md: "60px" }} w="100%">
        {events.map((event) => (
          <EventCard key={event.id} event={event} />
        ))}
      </VStack>
    </VStack>
  );
}

export default EventGroup;
