import { Box, HStack, VStack, Stack } from "@chakra-ui/react";
import React, { useState } from "react";
import { CustomSelect } from "../common/CustomSelect";
import Pagination from "../common/Pagination";
import SectionHeader from "../Home/SectionHeader";
import RelevantContentCard from "./RelevantContentCard";

export interface IRelevantContentListProps {
  data: any[];
}

export function RelevantContentList({ data }: IRelevantContentListProps) {
  const pageSize = 24;
  const [page, setPage] = useState<number>(1);
  const [filterType, setFilterType] = useState<string>("");

  const filteredData = data.filter((item) => {
    if (!filterType) return true;
    return item.type === filterType;
  });

  const paginatedData = filteredData
    ? filteredData.slice((page - 1) * pageSize, page * pageSize)
    : [];

  return data.length > 0 ? (
    <VStack alignItems="flex-start" spacing={4}>
      <HStack
        justifyContent="space-between"
        w="100%"
        spacing={{ base: 4, md: 5 }}
      >
        {/* Filter */}
        <Stack
          spacing={4}
          justify="space-between"
          w="100%"
          direction={{ base: "column", md: "row" }}
        >
          <SectionHeader title="Relevant Content" mb={0} />
          <HStack align="center">
            <CustomSelect
              label="Filter by"
              placeholder="Type"
              options={[
                {
                  value: "",
                  name: "All",
                },
                {
                  value: "document",
                  name: "Documents",
                },
                {
                  value: "email",
                  name: "Emails",
                },
              ]}
              onChange={setFilterType}
            />
            <Pagination
              total={filteredData.length}
              pageSize={pageSize}
              useOuter={true}
              outerCurrentPage={page}
              setOuterCurrentPage={setPage}
              compact
            />
          </HStack>
        </Stack>
      </HStack>

      {/* List */}
      <VStack w="100%" align="stretch" spacing={3}>
        {paginatedData.map((data) => (
          <RelevantContentCard data={data} />
        ))}
      </VStack>

      <Pagination
        mt="16px !important"
        showItemShown
        total={filteredData.length}
        pageSize={pageSize}
        useOuter={true}
        outerCurrentPage={page}
        setOuterCurrentPage={setPage}
      />
    </VStack>
  ) : null;
}
