import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setViewAsCompany } from "../../redux/auth/action";
import SelectCompanyModal from "./SelectCompanyModal";

function POVBar({ fixed = false }: { fixed: boolean }): JSX.Element {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const userCompany = useSelector((state) => state.auth.profile.company);
  const currentCompany = useSelector((state) => state.company.currentCompany);

  const handleGoBack = () => {
    dispatch(setViewAsCompany(userCompany?.id));
  };

  const company = currentCompany?.id ? currentCompany : userCompany;

  return (
    <>
      <SelectCompanyModal
        show={showModal}
        onClose={() => setShowModal(false)}
      />
      <Flex
        bg="primary.100"
        w="100%"
        minH="44px"
        maxH="fit-content"
        p={3}
        position={fixed ? "fixed" : "static"}
        marginTop={{ base: 0, md: fixed ? "0px" : "-32px !important" }}
        top="0"
        alignItems="center"
        justifyContent="center"
        color="primary.500"
        zIndex={999}
        textAlign="center"
      >
        <Flex
          w={{ base: "100%", md: "calc(100% - 80px)" }}
          maxW="1440px"
          flexDirection={{ base: "column", sm: "row" }}
          justifyContent="center"
        >
          {currentCompany?.id && currentCompany.id !== userCompany.id && (
            <Flex alignItems="center" cursor="pointer" onClick={handleGoBack}>
              <Box mr="8px">
                <svg
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 16L0 8L8 0L9.12 1.14L2.26 8L9.12 14.86L8 16Z"
                    fill="#0D7DBD"
                  />
                </svg>
              </Box>

              <Text>Back</Text>
            </Flex>
          )}

          <Flex
            flexDirection={{ base: "column", lg: "row" }}
            justifyContent="center"
            flex="1"
          >
            <Box>
              Currently Viewing
              <Text fontWeight="bold" mx={1} display="inline-block">
                {company?.name}'s
              </Text>
              Company Profile.
            </Box>
            <Box
              as="button"
              fontWeight="bold"
              textDecoration="underline"
              ml={1}
              onClick={() => setShowModal(true)}
            >
              View Another Member Company
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}

export default POVBar;
