import React from "react";
import { Box, VStack, Text } from "@chakra-ui/react";
import { EMAIL_PATTERN } from "../../constants/default";
import FormField from "./FormField";
import FormLabel from "./FormLabel";
import PrimaryButton from "../common/PrimaryButton";
import SecondaryButton from "../common/SecondaryButton";

const initialValues = {
  email: "",
};

const errorMessages = {
  required: "* Fields are required",
  email: "Email not correct",
};

const ForgotPasswordForm = ({
  onSubmit,
  onBack,
  userError,
  title,
}: {
  onSubmit: (fields: any) => {};
  onBack: () => {};
  userError: string;
  title?: string;
}) => {
  const [fields, setFields] = React.useState(initialValues);
  const [errors, setErrors] = React.useState<any>({});
  const [messages, setMessages] = React.useState<any>({});
  const handleChange = (field: string) => (event: any) =>
    setFields({ ...fields, [field]: event.target.value });

  const validate = () => {
    let errors = {};
    let messages = {};

    if (!fields.email) {
      errors.email = true;
      messages.required = true;
    }

    if (fields.email && !EMAIL_PATTERN.test(fields.email)) {
      errors.email = true;
      messages.email = true;
    }

    setErrors(errors);
    setMessages(messages);
    return !Object.keys(errors).find((key: string) => errors[key]);
  };

  const onSubmitForm = () => {
    if (validate()) {
      onSubmit(fields);
    }
  };

  return (
    <VStack w="100%" alignItems="flex-start" spacing={0}>
      <Text
        fontSize={{ base: "24px", md: "36px" }}
        lineHeight={{ base: "29px", md: "44px" }}
        fontWeight={700}
        fontFamily="montserrat"
        color="grey.950"
        mb={{ base: "16px", sm: "16px" }}
      >
        {title || "Forgot your password?"}
      </Text>
      <Box pr={{ base: "0", md: "150px" }}>
        <Text
          fontSize={{ base: "14px", sm: "16px" }}
          lineHeight={{ base: "19px", sm: "22px" }}
          fontWeight={400}
          fontFamily="opensans"
          color="grey.950"
          mb={{ base: "32px", sm: "32px" }}
        >
          Enter your registered email address and we'll send you a link to reset
          your password.
        </Text>

        <FormLabel>Email</FormLabel>
        <FormField
          value={fields.email}
          onChange={handleChange("email")}
          placeholder="Email"
          autocomplete="email"
          isInvalid={errors.email}
          required
        />
      </Box>

      {Object.keys(messages)
        .filter((key: string) => messages[key])
        .map((key: string) => (
          <Text
            key={key}
            fontSize="12px"
            fontWeight={600}
            color="error.500"
            fontFamily="opensans"
          >
            {errorMessages[key]}
          </Text>
        ))}
      {userError && (
        <Text
          fontSize="12px"
          fontWeight={600}
          color="error.500"
          fontFamily="opensans"
        >
          {userError}
        </Text>
      )}

      <Box mt="56px !important">
        <SecondaryButton onClick={onBack} filter={false}>
          Back
        </SecondaryButton>
        <PrimaryButton ml="24px" onClick={onSubmitForm}>
          Send
        </PrimaryButton>
      </Box>
    </VStack>
  );
};
export default ForgotPasswordForm;
