import { HStack, Icon, Input } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { CgClose } from "react-icons/cg";
import { MdSearch } from "react-icons/md";
import { connect } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import routes from "../../constants/routes.json";

export interface Item {
  label: string;
  value: string;
}

export function getUrl(type: string, id: string | number) {
  let route;

  switch (type) {
    case "article":
      route = routes.ARTICLE_DETAILS.replace(":id", `${id}`);
      break;
    case "event":
      route = routes.EVENTS;
      break;
    case "committee":
      route = routes.COMMITTEE_DETAILS.replace(":id", `${id}`);
      break;
    case "subcommittee":
      route = routes.COMMITTEE_DETAILS.replace(":id", `${id}`) + "?type=sub";
      break;
    case "Priority Email":
    case "Government Relations":
    case "Leadership Communication":
    case "Email":
      route = routes.BRT_INBOX_DETAIL.replace(":id", `${id}`);
      break;
    default:
      route = routes.BRT_INBOX;
  }

  return route;
}
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const SearchBar = ({
  isMobile,
  searchTerm = "",
}: {
  searchResults: any;
  resultCount: number;
  isMobile?: boolean;
  searchTerm?: string;
}) => {
  const initQuery = useQuery();
  const history = useHistory();
  const searchQuery = initQuery.get("query") || "";
  const [query, setQuery] = useState<string>(searchQuery || searchTerm);

  useEffect(() => {
    setQuery(searchQuery);
  }, [searchQuery]);

  const handleChange = (e: any) => {
    const value = (e && e.target && e.target.value) || "";
    setQuery(value);
  };

  const handleClearSearch = () => {
    setQuery("");
    // history.push(`${routes.SEARCH_RESULTS}?query=`);
    window.open(`${routes.SEARCH_RESULTS}?query=`, "_self")
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.charCode === 13) {
      // history.push(`${routes.SEARCH_RESULTS}?query=${query}`);
      window.open(`${routes.SEARCH_RESULTS}?query=${query}`, "_self")
    }
  };

  return (
    <HStack
      bg={isMobile ? "white" : "white"}
      w="100%"
      zIndex={100}
      h={isMobile ? "unset" : "40px"}
      borderRadius="7px"
      px={isMobile ? "12px" : "18px"}
    >
      <Link to={`${routes.SEARCH_RESULTS}?query=${query}`}>
        <Icon as={MdSearch} fontSize={isMobile ? "20px" : "20px"} mt="-2px" />
      </Link>

      <Input
        ml="16px !important"
        value={query}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        bg="transparent"
        outline="none !important"
        _focus={{
          border: "unset",
        }}
        _hover={{
          background: "unset",
        }}
        p="0"
        border="0px"
        // placeholder="Search"
        fontFamily="montserrat"
        fontWeight="500"
      />
      {!!query && (
        <Icon
          as={CgClose}
          boxSize="20px"
          onClick={handleClearSearch}
          cursor="pointer"
        />
      )}
    </HStack>
  );
};

const mapStateToProps = (state: any) => ({
  searchResults: state.committee.searchResults,
  resultCount: state.committee.searchResultCount,
});

export default connect(mapStateToProps)(SearchBar);
