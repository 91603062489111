import * as React from "react";
import { Button } from "@chakra-ui/react";
import { cardShadowHover } from "../../styles/theme";
import { Link } from "react-router-dom";

export interface AboutBRTButtonProps {
  title: string;
  link: string;
}

export default function AboutBRTButton(props: AboutBRTButtonProps) {
  return (
    <Link to={props.link}>
      <Button
        size="sm"
        variant="light"
        h="38px"
        borderRadius="24px"
        _hover={{ filter: cardShadowHover }}
        transition="0.3s all"
        p="12px 28px"
      >
        {props.title}
      </Button>
    </Link>
  );
}
