import {
  Box,
  HStack,
  Icon,
  InputGroup,
  InputRightElement,
  List,
  ListIcon,
  ListItem,
  Text,
  VStack
} from "@chakra-ui/react";
import React from "react";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import {
  PASSWORD_PATTERN,
  PASSWORD_REQUIREMENTS
} from "../../constants/default";
import PrimaryButton from "../common/PrimaryButton";
import FormField from "./FormField";
import FormLabel from "./FormLabel";

const CircleIcon = (props: any) => (
  <Icon viewBox="0 0 8px 8px" {...props}>
    <circle cx="4px" cy="4px" r="4px" fill="#202020" />
  </Icon>
);

const initialValues = {
  password: "",
  confirmPassword: "",
};

const errorMessages = {
  required: "* Fields are required",
  password: "* Password doesn't meet the requirements",
  passwordsNotEqual: "* Password not equal",
};

const ResetPasswordForm = ({
  onSubmit,
  userError,
}: {
  onSubmit: (fields: any) => {};
  userError: string;
}) => {
  const [errors, setErrors] = React.useState<any>({});
  const [messages, setMessages] = React.useState<any>({});
  const [show, setShow] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [fields, setFields] = React.useState({ ...initialValues });

  const handleClick = () => setShow(!show);
  const handleClickConfirm = () => setShowConfirm(!showConfirm);

  const handleChange = (field: string) => (event: any) =>
    setFields({ ...fields, [field]: event.target.value });

  const validate = () => {
    let errors = {};
    let messages = {};

    if (!fields.password) {
      errors.password = true;
      messages.required = true;
    }

    if (fields.password && !PASSWORD_PATTERN.test(fields.password)) {
      errors.password = true;
      messages.password = true;
    }

    if (
      !fields.confirmPassword ||
      (fields.confirmPassword &&
        fields.password &&
        fields.password !== fields.confirmPassword)
    ) {
      errors.confirmPassword = true;
      messages.passwordsNotEqual = true;
    }

    setErrors(errors);
    setMessages(messages);
    return !Object.keys(errors).find((key: string) => errors[key]);
  };

  const onSubmitForm = () => {
    if (validate()) {
      onSubmit({
        password: fields.password,
      });
    }
  };

  return (
    <VStack w="100%" alignItems="flex-start">
      <Text
        fontSize={{ base: "24px", md: "36px" }}
        lineHeight={{ base: "29px", md: "44px" }}
        fontWeight={700}
        fontFamily="montserrat"
        color="grey.950"
        whiteSpace="nowrap"
        mb={{ base: "24px", sm: "32px" }}
      >
        Change password
      </Text>

      <Box w="100%" maxW="371px">
        <HStack
          w="100%"
          justifyContent="space-between"
          alignItems="center"
          mb="8px"
        >
          <FormLabel mb={0}>New Password</FormLabel>
        </HStack>
        <InputGroup size="lg" mb="24px">
          <FormField
            value={fields.password}
            type={show ? "text" : "password"}
            onChange={handleChange("password")}
            placeholder="Password"
            isInvalid={errors.password}
          />
          {fields.password && (
            <InputRightElement h="100%" mr="20px" w="fit-content">
              <Icon
                onClick={handleClick}
                bg="transparent"
                color="grey.200"
                boxSize="16px"
                as={show ? BsEyeFill : BsEyeSlashFill}
              />
            </InputRightElement>
          )}
        </InputGroup>

        <FormLabel>Confirm Password </FormLabel>
        <InputGroup size="lg" pb="0px">
          <FormField
            value={fields.confirmPassword}
            type={showConfirm ? "text" : "password"}
            onChange={handleChange("confirmPassword")}
            placeholder="Confirm Password"
            isInvalid={errors.confirmPassword}
          />
          {fields.confirmPassword && (
            <InputRightElement h="100%" mr="20px" w="fit-content">
              <Icon
                onClick={handleClickConfirm}
                bg="transparent"
                color="grey.200"
                as={showConfirm ? BsEyeFill : BsEyeSlashFill}
                boxSize="16px"
              />
            </InputRightElement>
          )}
        </InputGroup>
      </Box>
      {Object.keys(messages)
        .filter((key: string) => messages[key])
        .map((key: string) => (
          <Text
            key={key}
            fontSize="12px"
            lineHeight="15px"
            fontWeight={600}
            color="error.500"
            fontFamily="opensans"
          >
            {errorMessages[key]}
          </Text>
        ))}
      {userError && (
        <Text
          fontSize="12px"
          lineHeight="15px"
          fontWeight={600}
          color="error.500"
          fontFamily="opensans"
        >
          {userError}
        </Text>
      )}

      <Box mt="32px !important">
        <Text
          fontSize="16px"
          fontFamily="montserrat"
          fontWeight="600"
          lineHeight="24px"
          pb="10px"
          color="grey.950"
        >
          Password Requirements
        </Text>
        <List>
          {PASSWORD_REQUIREMENTS.map((item: any, index: any) => (
            <ListItem key={index}>
              <HStack spacing={0}>
                <ListIcon as={CircleIcon} />
                <Text
                  fontSize="14px"
                  fontFamily="opensans"
                  fontWeight="400"
                  lineHeight="24px"
                  pb="10px"
                  color="grey.950"
                >
                  {item}
                </Text>
              </HStack>
            </ListItem>
          ))}
        </List>
      </Box>

      <Box mt="56px !important">
        <PrimaryButton onClick={onSubmitForm}>Reset Password</PrimaryButton>
      </Box>
    </VStack>
  );
};

export default ResetPasswordForm;
